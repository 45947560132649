<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Изменение сотрудника</strong>
          </template>
          <b-form-group
            label="Имя"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.firstName)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.firstName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="lastName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.lastName)"
            :state="state5">
            <b-form-input id="lastName" type="text" :state="state5" v-model="model.lastName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Отчество"
            label-for="patronymicName"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="patronymicName" type="text" v-model="model.patronymicName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Должность сотрудника"
            label-for="role"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.postId)"
            :state="state9">
            <b-form-select id="role"
                           :options="role"
                           placeholder="Выберите должность"
                           v-model="model.postId"
                           :state="state9">
              <template slot="first">
                <option :value="null" disabled>Выберите должность</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.email(model.email)"
            :state="state6">
            <b-form-input id="lastName" type="text" :state="state6" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state7">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
            ></masked-input>
          </b-form-group>
         <!-- <b-form-group
            label="Пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.editPass(password, sPassword)"
            :state="state8">
            <b-form-input id="lastName" type="password" :state="state8" v-model="password"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Повторите пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.editPass(password, sPassword)"
            :state="state8">
            <b-form-input id="lastName" type="password" :state="state8" v-model="sPassword"></b-form-input>
          </b-form-group> -->
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Сотрудник успешно изменён">
      <p class="my-4">Сотрудник успешно изменён</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import loading from 'vue-full-loading'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput
    },
    computed: {
      state4 () {
        return validation.long(this.model.firstName)
      },
      state5 () {
        return validation.long(this.model.lastName)
      },
      state6 () {
        return validation.emailBool(this.model.email)
      },
      state7 () {
        return validation.phoneNumberBool(this.phone)
      },
      // state8 () {
      //   return validation.editPassBool(this.password, this.sPassword)
      // },
      state9 () {
        return validation.numId(this.model.postId)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state4 === true && _this.state5 === true && _this.state6 === true && _this.state7 === true && _this.state9 === true) {
          _this.show = true
          _this.model.phone = _this.phone.replace(/\D/g, '').replace('7', '')
          // if (_this.password !== '') {
          //   _this.model.password = _this.password
          // }
          if (_this.model.patronymicName === null) {
            delete _this.model.patronymicName
          }
          delete _this.model.photo
          _this.model.key = 'specialist'
          api.put(_this.urls.specialist + '/' + _this.$route.params.id, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/specialist/list')
              }, 1000)
            }
          })
        }
      },
      getPost () {
        let _this = this
        api.get(_this.urls.post).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.role.push(temp)
            })
          }
        })
      },
      goBack () {
        this.$router.go(-1)
      }
    },
    watch: {
    },
    mounted: function () {
      let _this = this
      let query = {
        key: 'specialist'
      }
      api.get(_this.urls.specialist + '/' + _this.$route.params.id, query).then(function (response) {
        let jData = response.data
        _this.model = jData.data
        _this.phone = `+7 (${_this.model.phone.substring(0, 3)}) ${_this.model.phone.substring(3, 6)}-${_this.model.phone.substring(6, 8)}-${_this.model.phone.substring(8, 10)}`
      })
      _this.getPost()
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'specialist'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false && response.data.data.isWrite === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
          email: '',
          roleId: 0
        },
        accessList: {},
        date: '',
        role: [
        ],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        urls: {
          specialist: 'specialist',
          post: 'post'
        },
        password: '',
        sPassword: '',
        check: 'true',
        phone: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

