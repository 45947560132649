<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание опроса</strong>
          </template>
          <b-form-group
            label="Заголовок"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.title)"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="model.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Текст"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.text)"
            :state="state2">
            <b-form-input id="name" type="text" :state="state2" v-model="model.text"></b-form-input>
          </b-form-group>
          <b-form-group
            v-for=" (n, item) in model.answers"
            :key="item"
            label="Вариант ответа"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.answers[item].value)"
            :state="model.answersErrorState[item]">
            <b-form-input id="name" type="text" :state="model.answersErrorState[item]" v-model.trim="model.answers[item]"></b-form-input>
          </b-form-group>
          <div class="invalid-feedback d-block" v-if="model.answers.length < 2">
            Добавьте минимум 2 варианта ответа
          </div>
          <b-btn size="sm" @click.prevent="addAnswer" text="Button" variant="success">Добавить вариант ответа</b-btn>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Опрос успешно добавлен">
      <p class="my-4">Опрос успешно добавлен</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.model.title)
      },
      state2 () {
        return validation.long(this.model.text)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true && _this.model.answers.length >= 2 && this.model.answersIsError === false) {
          _this.show = true
          let temp = []
          for (let i of _this.model.answers) {
            i && temp.push(i)
          }
          _this.model.answers = temp
          api.post(_this.urls.poll, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/poll/list')
              }, 1000)
            }
          })
        }
      },
      addAnswer () {
        let _this = this
        _this.model.answers.push('')
        _this.model.answersErrorState.push('')
      }
    },
    mounted: function () {

    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'poll'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    watch: {
      'model.answers': {
        deep: true,
        handler: function (arr) {
          let answersIsError = false

          for (let elem in arr) {
            const validationStatus = validation.long(arr[elem])
            this.model.answersErrorState[elem] = validationStatus
            if (validationStatus === false) {
              answersIsError = true
            }
          }

          this.model.answersIsError = answersIsError
        }
      }
    },
    data () {
      return {
        model: {
          answersErrorState: [],
          answersIsError: false,
          answers: [],
          title: '',
          text: ''
        },
        accessList: {},
        date: '',
        posts: [],
        urls: {
          poll: 'poll'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
