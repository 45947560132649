<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <template #header>
          <strong>Платежный документ</strong>
        </template>

        <b-form-group
          label="Месяц"
          label-for="month"
          :label-cols="3"
          :horizontal="true">
          <b-form-select
            id="month"
            v-model="model.month"
            :state="stateMonth"
            :disabled="disabled"
            placeholder="Выберите месяц"
          >
            <template slot="first">
              <option :value="null" disabled>Выберите месяц</option>
              <option v-for="month in 12" :key="month" :value="month">{{ month }}</option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Год"
          label-for="year"
          :label-cols="3"
          :horizontal="true">
          <b-form-select
            id="year"
            v-model="model.year"
            :disabled="disabled"
            :state="stateYear"
            placeholder="Выберите год"
          >
            <template slot="first">
              <option :value="null" disabled>Выберите год</option>
              <option v-for="year in 50" :key="year" :value="year + 2000">{{ year + 2000 }}</option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Начислено"
          label-for="accruedPayable"
          :label-cols="3"
          :horizontal="true">
          <b-form-input
            id="accruedPayable"
            type="number"
            v-model="model.accruedPayable"
            :state="stateAccrued"
            :disabled="disabled"
            :invalid-feedback="validation.isNumber(model.accruedPayable)"
          />
        </b-form-group>

        <b-form-group
          label="Общая сумма"
          label-for="totalPayable"
          :label-cols="3"
          :horizontal="true">
          <b-form-input
            id="totalPayable"
            type="number"
            v-model="model.totalPayable"
            :state="stateTotal"
            :disabled="disabled"
            :invalid-feedback="validation.isNumber(model.totalPayable)"
          />
        </b-form-group>

        <b-form-group
          label="Получатель средств"
          label-for="paymentRecipient"
          :label-cols="3"
          :horizontal="true">
          <b-form-select
            id="paymentRecipient"
            :options="paymentRecipientOptions"
            v-model="model.paymentRecipientId"
            :state="statePaymentRecipientId"
            :disabled="disabled">
            <template slot="first">
              <option :value="null" disabled>Выберите получателя средств</option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Исполнитель платежа"
          label-for="paymentExecutor"
          :label-cols="3"
          :horizontal="true">
          <b-form-select
            id="paymentExecutor"
            :options="paymentExecutorOptions"
            v-model="model.paymentExecutorId"
            :state="statePaymentExecutorId"
            :disabled="disabled">
            <template slot="first">
              <option :value="null" disabled>Выберите исполнителя платежа</option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Оплачено"
          label-for="paid"
          :label-cols="3"
          :horizontal="true">
          <b-form-input
            id="paid"
            type="number"
            v-model="model.paid"
            :state="statePaid"
            :disabled="disabled"
            :invalid-feedback="validation.isNumber(model.paid)"
          />
        </b-form-group>

        <b-btn size="sm" @click.prevent="goBack" text="Button">Назад</b-btn>
        <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success" v-if="!disabled">Сохранить</b-btn>

        <template>
          <loading
            :show="show"
            label="Пожалуйста, подождите...">
          </loading>
        </template>

        <b-modal ref="successModal" hide-footer title="Успешно сохранено">
          <p class="my-4">Успешно сохранено</p>
        </b-modal>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
  import api from '../../config/api'
  import validation from '../../config/validation'
  import loading from 'vue-full-loading'
  import vueCookie from 'vue-cookie'

  export default {
    name: 'detailList',

    components: {
      loading
    },

    data: function () {
      return {
        model: {
          month: undefined,
          year: undefined,
          accruedPayable: 0,
          paid: 0,
          totalPayable: 0,
          paymentRecipientId: undefined,
          paymentExecutorId: undefined,
          isManual: false,
          accountId: 0
        },
        accessList: {},
        urls: {
          detail: 'paymentDocument/detail',
          create: 'paymentDocument/create',
          update: 'paymentDocument/update',
          paymentExecutorList: 'paymentExecutor',
          paymentRecipientList: 'paymentRecipient'
        },
        paymentRecipientOptions: [],
        paymentExecutorOptions: [],
        validation,
        show: false,
        allowedRoles: [
          'admin',
          'adminUK',
          'admin_Specialistpoplatezham'
        ]
      }
    },

    created () {
      api
        .get('role/components/access', { keyWord: 'payment' })
        .then(response => {
          this.accessList = response.data.data
          if (this.accessList.isRead === false) {
            this.$router.push('/pages/403')
          }
        })
    },

    computed: {
      disabled: function () {
        return !!this.$route.query.readonly ||
          !this.userHasAccess() ||
          !this.model.isManual ||
          !this.accessList.isUpdate
      },
      // validation rules
      stateMonth: function () {
        return validation.isNumberBool(this.model.month)
      },
      stateYear: function () {
        return validation.isNumberBool(this.model.year)
      },
      stateAccrued: function () {
        return validation.isNumberBool(this.model.accruedPayable)
      },
      stateTotal: function () {
        return validation.isNumberBool(this.model.totalPayable)
      },
      statePaymentRecipientId: function () {
        return validation.numId(this.model.paymentRecipientId)
      },
      statePaymentExecutorId: function () {
        return validation.numId(this.model.paymentExecutorId)
      },
      statePaid: function () {
        return validation.isNumberBool(this.model.paid)
      }
    },

    mounted () {
      Promise.all([
        this.loadPaymentExecutors(),
        this.loadPaymentRecipients()
      ]).then(() => {
        if (this.$route.params.id) {
          this.loadData()
        } else {
          this.model.isManual = true
          this.model.accountId = this.$route.query.accountId
        }
      })
    },

    methods: {
      loadData () {
        api.get(this.urls.detail + '/' + this.$route.params.id)
          .then((response) => {
            const data = response.data
            if (data.status) {
              Object.assign(this.model, data.data)
            }
          })
      },
      sendForm () {
        if (!this.isFormValid()) {
          return
        }

        const id = this.$route.params.id
        if (id) {
          this.update(id)
        } else {
          this.create()
        }
      },
      create () {
        api.put(this.urls.create, this.model)
          .then(response => {
            this.handleResponse(response)
          })
      },
      update (id) {
        api.put(this.urls.update + '/' + id, this.model)
          .then(response => {
            this.handleResponse(response)
          })
      },
      handleResponse (response) {
        this.show = false

        const data = response.data
        if (data.status) {
          this.$refs.successModal.show()

          setTimeout(() => {
            this.$refs.successModal.hide()

            this.goBack()
          }, 1000)
        }
      },
      isFormValid () {
        return this.stateMonth &&
                this.stateYear &&
                this.stateAccrued &&
                this.statePaid &&
                this.statePaymentExecutorId &&
                this.statePaymentRecipientId &&
                this.stateTotal
      },
      goBack () {
        this.$router.go(-1)
      },
      loadPaymentExecutors () {
        return api.get(this.urls.paymentExecutorList)
          .then((response) => {
            this.paymentExecutorOptions = response.data.data.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })
          })
      },
      loadPaymentRecipients () {
        return api.get(this.urls.paymentRecipientList)
          .then((response) => {
            this.paymentRecipientOptions = response.data.data.map(item => {
              return {
                value: item.id,
                text: item.name
              }
            })
          })
      },
      userHasAccess () {
        console.log(vueCookie.get('role'))
        return this.allowedRoles.includes(vueCookie.get('role'))
      }
    }
  }
</script>

