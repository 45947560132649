<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template>
            <loading
              :show="show"
              :label="label">
            </loading>
          </template>
          <template #header>
            <strong>Добавление автомобиля</strong>
          </template>
          <b-form-group
            label="Номер автомобиля"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="'Обязательное поле'"
            :state="state1">
            <!-- <b-form-input id="carNumber" type="text" :state="state1" v-model="model.carNumber"></b-form-input> -->
            <masked-input
              id="carNumber"
              type="text"
              name="carNumber"
              class="form-control car-new__car-number-input-field"
              v-bind:class="{invalid__dateTime: !state1, valid__dateTime: state1 === true}"
              v-model="model.carNumber"
              :mask="[/[укенхваросмтУКЕНХВАРОСМТ]/, ' ', /\d/, /\d/, /\d/, ' ', /[укенхваросмтУКЕНХВАРОСМТ]/, /[укенхваросмтУКЕНХВАРОСМТ]/, ' ', /\d/, /\d?/, /\d?/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
            ></masked-input>
          </b-form-group>
          <b-form-group
            label="Владелец автомобиля"
            label-for="file"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.emptyObj(value)"
            :state="state2">
            <multiselect v-model="value" v-bind:class="{invalid__dateTime: !state2, valid__dateTime: state2 === true}" :options="options" :custom-label="nameWithLang" :show-labels="false" placeholder="Выберите владельца" label="name" track-by="id">
              <span slot="noResult">Совпадений не найдено!</span>
            </multiselect>
          </b-form-group>
          <!--<b-form-group-->
          <!--label="Клиент"-->
          <!--label-for="userId"-->
          <!--:label-cols="3"-->
          <!--:horizontal="true">-->
          <!--<b-form-select id="userId"-->
          <!--:options="clients"-->
          <!--v-model="model.userId">-->
          <!--<template slot="first">-->
          <!--<option :value="null" disabled>Выберите клиента</option>-->
          <!--</template>-->
          <!--</b-form-select>-->
          <!--</b-form-group>-->
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state3">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state3, valid__dateTime: state3 === true}"
            ></masked-input>
          </b-form-group>
          <b-form-group
            label="Адрес"
            label-for="premise"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.premiseId)"
            :state="state4">
            <b-form-select id="premise"
                           :options="accommodations"
                           placeholder="Выберите адрес"
                           v-model="model.premiseId"
                           :state="state4">
              <template slot="first">
                <option :value="null" disabled>Выберите адрес</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Ограничение парковки"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="check" class="switch-input" checked>
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Время окончания парковки"
            v-if="check"
            label-for="time"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.time)"
            :state="state5">
            <datetime type="datetime"
                      id="time"
                      v-model="date.time"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Выберите время окончания парковки"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state5, valid__dateTime: state5 === true}">
            </datetime>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Автомобиль успешно добавлен">
      <p class="my-4">Автомобиль успешно добавлен</p>
    </b-modal>
  </div>
</template>


<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput,
      datetime: Datetime
    },
    computed: {
      state1 () {
        if (this.model.carNumber && this.model.carNumber.replaceAll(/[_\s]+/g, '').length > 6) return true
        return false
      },
      state2 () {
        return validation.longObj(this.value)
      },
      state3 () {
        return validation.phoneNumberBool(this.phone)
      },
      state4 () {
        return validation.numId(this.model.premiseId)
      },
      state5 () {
        if (new Date(this.date.time).getTime() < new Date().getTime()) return false
        return validation.dateSelectBool(this.date.time)
      }
    },
    methods: {
      nameWithLang ({ name, id }) {
        if (name !== '') {
          return `${name}`
        }
      },
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true && _this.state3 === true && _this.state4 === true && (_this.state5 === true || !_this.check)) {
          _this.show = true

          _this.model.userId = _this.value.id
          _this.model.phone = _this.phone.replace(/\D/g, '').replace('7', '')
          if (_this.check && _this.date.time) {
            _this.model.time = parseInt((new Date(_this.date.time).getTime() - new Date().getTime()) / 1000)
          } else {
            _this.model.time = 0
          }
          
          api.post(_this.urls.car, _this.model).then(function (response) {
            if (response.status) {
              _this.show = false
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/car/list')
              }, 1000)
            }

            _this.show = false
          }).catch(() => {
            _this.show = false
          })
        }
      },
      getClients () {
        let _this = this
        api.get(_this.urls.users, {role: 'user'}).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (user) {
              let temp = {}
              temp.id = user.id
              temp.name = user.lastName.concat(' ', user.firstName, ' ', user.patronymicName === null ? '' : user.patronymicName)
              _this.options.push(temp)
            })
          }
        })
      },
      getAccomodation () {
        api.get(this.urls.accommodation, {userId: this.value.id}).then((response) => {
          this.accommodations = []
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              let temp = {}
              temp.value = item.premiseId
              temp.text = item.address
              this.accommodations.push(temp)
            })
          }
        })
      }
    },
    mounted () {
      this.getClients()
      this.getAccomodation()
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'car'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    watch: {
      value () {
        if (this.value !== null && this.value !== undefined) {
          this.getAccomodation()
        }
      }
    },
    data () {
      return {
        model: {
          carNumber: '',
          buildingId: 0
        },
        accessList: {},
        value: {
          name: ''
        },
        options: [
        ],
        date: {
          time: 0
        },
        posts: [],
        accommodations: [],
        urls: {
          car: 'car',
          users: 'users',
          accommodation: 'accommodation'
        },
        phone: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        check: '',
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

