<template>
  <b-card :header="caption">
   <b-row>
      <b-col lg="2">
        <b-form-group
          label="Начало периода:"
          label-for="serviceDate"
          :label-cols="4"
          :invalid-feedback="validation.dateSelect(date.from)"
          :state="state1">
          <datetime type="date"
                    id="serviceDate"
                    v-model="date.from"
                    class="theme-orange dateTimePick__report_width"
                    input-class="dataTimePick__report"
                    placeholder="Дата"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}"
                    v-bind:class="{invalid__dateTime: !state1, valid__dateTime: state1 === true}">
          </datetime>
        </b-form-group>
      </b-col>
      <b-col lg="2">
        <b-form-group
          label="Конец периода:"
          label-for="serviceDate"
          :label-cols="4"
          :invalid-feedback="validation.dateSelect(date.to)"
          :state="state2">
          <datetime type="date"
                    id="serviceDate"
                    v-model="date.to"
                    class="theme-orange dateTimePick__report_width"
                    input-class="dataTimePick__report"
                    placeholder="Дата"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}"
                    v-bind:class="{invalid__dateTime: !state2, valid__dateTime: state2 === true}">
          </datetime>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col lg="2">
        <b-form-group
          label="Жилищный комплекс"
          label-for="housingComplex"
          :label-cols="4"
          :invalid-feedback="validation.notSelect(housingComplexId)"
          :state="state3">
          <b-form-select id="housingComplex"
                          :options="hcList"
                          placeholder="Выберите ЖК"
                          v-model="housingComplexId"
                          :state="state3">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group
          label="Дом"
          label-for="housingComplex"
          :label-cols="4"
          :invalid-feedback="validation.notSelect(buildingId)"
          :state="state4">
          <b-form-select id="housingComplex"
                          :options="buildingsList"
                          placeholder="Выберите дом"
                          v-model="buildingId"
                          :state="state4">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group
          label="Тип счётчика"
          label-for="housingComplex"
          :label-cols="4"
          :invalid-feedback="validation.empty(meterTypeKey)"
          :state="state5">
          <b-form-select id="housingComplex"
                          :options="meterTypeList"
                          placeholder="Выберите тип счётчика"
                          v-model="meterTypeKey"
                          :state="state5">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-btn size="sm" class="change_size btn-excel" @click.stop="download()"><i class="fa fa-file-excel-o fa-2x"></i></b-btn>
      </b-col>
    </b-row>
<!--    <div class="not_data__table" v-if="viewError">Отчёты не найдены! Укажите срез даты, место и тип счётчика, для получения отчёта!</div>-->
<!--    <div class="not_data__table" v-if="viewEmpty">Отчёты не найдены! Выберите другой срез даты, место или тип счётчика, для получения отчёта!</div>-->
<!--    <div class="table_block">-->
    <div v-if="loading" class="spinner d-flex align-items-center"><i class="fa fa-spinner fa-spin fa-3x mr-2"></i>Отчёт формируется, пожалуйста, подождите. <br> Не закрывайте страницу и не покидайте её, иначе загрузка отчёта будет прервана.</div>
<!--      <table class="report_meters__table" v-if="viewTable && !viewError && !viewEmpty && meterTypeKey !== 'electro'" border="1" cellpadding="4" cellspacing="0">-->
<!--        <thead class="report_meters__table_header">-->
<!--          <tr align="center">-->
<!--            <th rowspan="3">#</th>-->
<!--            <th rowspan="3">Адрес дома</th>-->
<!--            <th rowspan="3">Квартира</th>-->
<!--            <th rowspan="3">Дата последнего обновления</th>-->
<!--            <th rowspan="3">Дата следующей поверки</th>-->
<!--            <th colspan="5">{{ meterTypeName }}</th>-->
<!--          </tr>-->
<!--          <tr align="center">-->
<!--            <th rowspan="2">Серийный № счётчика</th>-->
<!--            <th rowspan="2">Модем</th>-->
<!--            <th colspan="3">Показания (Гкал)</th>-->
<!--          </tr>-->
<!--          <tr align="center">-->
<!--            <th>Начало</th>-->
<!--            <th>Расход</th>-->
<!--            <th>Конец</th>-->
<!--          </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--          <tr align="center" v-for="(item, id) in items">-->
<!--            <th class="report_meters__table_body__tr">{{id + 1}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.object}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.premiseNum}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.lastInspection}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.verificDate}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.number}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.modem}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.history.begin}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.history.consumption}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.history.end}}</th>-->
<!--          </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--      <table class="report_meters__table" v-if="viewTable && !viewError && !viewEmpty && meterTypeKey === 'electro'" border="1" cellpadding="4" cellspacing="0">-->
<!--        <thead class="report_meters__table_header">-->
<!--          <tr align="center">-->
<!--            <th rowspan="3">#</th>-->
<!--            <th rowspan="3">Адрес дома</th>-->
<!--            <th rowspan="3">Кв</th>-->
<!--            <th rowspan="3">Дата последнего обновления</th>-->
<!--            <th rowspan="3">Дата следующей поверки</th>-->
<!--            <th rowspan="3">Серийный № счётчика</th>-->
<!--            <th rowspan="3">Модем</th>-->
<!--            <th v-for="n in 4" colspan="3">{{'A*T' + n}}</th>-->
<!--            <th colspan="3">A*Tсумм</th>-->
<!--          </tr>-->
<!--          <tr align="center">-->
<!--            <th colspan="3" v-for="n in 5">Показания (кВт*ч)</th>-->
<!--          </tr>-->
<!--          <tr align="center">-->
<!--            <th>Нач</th>-->
<!--            <th>Рас</th>-->
<!--            <th>Кон</th>-->
<!--            <th>Нач</th>-->
<!--            <th>Рас</th>-->
<!--            <th>Кон</th>-->
<!--            <th>Нач</th>-->
<!--            <th>Рас</th>-->
<!--            <th>Кон</th>-->
<!--            <th>Нач</th>-->
<!--            <th>Рас</th>-->
<!--            <th>Кон</th>-->
<!--            <th>Нач</th>-->
<!--            <th>Рас</th>-->
<!--            <th>Кон</th>-->
<!--          </tr>-->
<!--        </thead>-->
<!--        <tbody>-->
<!--          <tr align="center" v-for="(item, id) in items">-->
<!--            <th class="report_meters__table_body__tr">{{id + 1}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.object}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.premiseNum}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.lastInspection}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.verificDate}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.number}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.modem}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t1.begin}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t1.consumption}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t1.end}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t2.begin}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t2.consumption}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t2.end}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t3.begin}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t3.consumption}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t3.end}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t4.begin}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t4.consumption}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.t4.end}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.sum.begin}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.sum.consumption}}</th>-->
<!--            <th class="report_meters__table_body__tr">{{item.sum.end}}</th>-->
<!--          </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </div>-->
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import consts from '../../config/const'
  import validation from '../../config/validation'

  export default {
    name: 'c-table',
    components: {
      datetime: Datetime,
      Datepicker
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        hcList: [],
        buildingsList: [],
        meterTypeList: [
          {
            value: 'electro',
            text: 'Электричество'
          },
          {
            value: 'hot',
            text: 'ГВС'
          },
          {
            value: 'cold',
            text: 'ХВС'
          },
          {
            value: 'heat',
            text: 'Тепло'
          }
        ],
        street: [],
        accessList: {},
        fields: [
          {key: 'address', label: 'Адрес здания', class: 'width_address'},
          {key: 'hot', label: 'ГВС'},
          {key: 'cold', label: 'ХВС'},
          {key: 'electro', label: 'Электричество'},
          {key: 'heat', label: 'Тепло'}
        ],
        date: {
          from: '',
          to: ''
        },
        housingComplexId: 0,
        buildingId: 0,
        meterTypeKey: '',
        meterTypeName: '',
        currentPage: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        viewError: true,
        viewEmpty: false,
        loading: false,
        consts: consts,
        urls: {
          hc: 'housingcomplex',
          list: 'report/meters',
          street: 'street',
          building: 'building',
          download: 'report/docs'
        },
        validation: validation
      }
    },
    computed: {
      state1 () {
        return validation.dateSelectBool(this.date.from)
      },
      state2 () {
        return validation.dateSelectBool(this.date.to)
      },
      state3 () {
        return validation.numId(this.housingComplexId)
      },
      state4 () {
        return validation.numId(this.buildingId)
      },
      state5 () {
        return validation.long(this.meterTypeKey)
      }
    },
    watch: {
      'date.from' () {
        // this.getData()
      },
      'date.to' () {
        // this.getData()
      },
      housingComplexId () {
        this.buildingsList = []
        this.buildingId = 0
        this.getStreet()
      },
      buildingId () {
        // this.getData()
      },
      meterTypeKey () {
        switch (this.meterTypeKey) {
          case 'electro':
            this.meterTypeName = 'Электричество'
            break
          case 'hot':
            this.meterTypeName = 'ГВС'
            break
          case 'cold':
            this.meterTypeName = 'ХВС'
            break
          case 'heat':
            this.meterTypeName = 'Тепло'
            break
        }
        // this.getData()
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'report'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },
    mounted () {
      this.getHousingComplex()
    },
    methods: {
      async download () {
        if (this.state1 && this.state2 && this.state4 && this.state5) {
          // this.viewEmpty = false
          this.loading = true
          const query = {
            type: this.meterTypeKey,
            buildingId: this.buildingId,
            process: 'new',
            from: moment(this.date.from).format('X'),
            to: parseInt(moment(this.date.to).format('X'), 10) + 86399
          }

          const response = await api.get(this.urls.download, query)

          query.process = 'status'
          query.guid = response.data.data.guid

          const nextResponse = await api.get(this.urls.download, query)

          this.loading = false
          if (nextResponse.data.data.success && nextResponse.data.data.url) {
            window.location.href = consts.storageUrl + '/' + nextResponse.data.data.url
          }
        }
      },
      getHousingComplex () {
        api.get(this.urls.hc).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.hcList.push(temp)
            })
          }
        })
      },
      getStreet () {
        api.get(this.urls.street).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.street = []
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        if (this.housingComplexId !== '') {
          this.street.forEach((street) => {
            let query = {
              hcId: this.housingComplexId,
              streetId: street.value
            }
            api.get(this.urls.building, query).then((response) => {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach((spec) => {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    this.buildingsList.push(temp)
                  }
                })
              }
            })
          })
          this.buildingsList.unshift({value: 0, text: 'Не выбрано'})
        }
      },
      // getData () {
      //   let _this = this
      //   let query = {}
      //   if (_this.date.from !== '' && _this.date.to !== '' && _this.buildingId !== 0 && _this.meterTypeKey !== '') {
      //     _this.viewError = false
      //     _this.viewEmpty = false
      //     _this.viewTable = false
      //     query = {
      //       type: _this.meterTypeKey,
      //       buildingId: _this.buildingId,
      //       from: moment(_this.date.from).format('X'),
      //       to: parseInt(moment(_this.date.to).format('X'), 10) + 86399
      //     }
      //     api.get(_this.urls.list, query).then(function (response) {
      //       _this.items = []
      //       let jData = response.data
      //       if (jData.status) {
      //         _this.items = [...jData.data]
      //       }
      //     }).then(() => {
      //       if (_this.items.length === 0) {
      //         _this.viewEmpty = true
      //       } else {
      //         _this.viewTable = true
      //         _this.viewEmpty = false
      //       }
      //     })
      //   } else {
      //     _this.viewError = true
      //     _this.viewEmpty = false
      //   }
      // },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      }
    }
  }
</script>


<style>
</style>
