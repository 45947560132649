<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Заявка на просмотр квартиры</strong>
          </template>
          <b-form-group
            label="ФИО"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-group
            :label="`${model.name}`"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            </b-form-group>
          </b-form-group>
          <b-form-group
            label="Номер телефона"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-group
            :label="`${model.phone}`"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            </b-form-group>
          </b-form-group>
          <b-form-group
            label="Статус отправки"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-group
            v-if="model.sent"
            label="Отправлено"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            </b-form-group>
            <b-form-group
            v-else
            label="Не отправлено"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            </b-form-group>
          </b-form-group>
          <b-form-group
            label="Дата отправки"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-group
            :label="`${model.createdDate}`"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            </b-form-group>
          </b-form-group>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Заявка на просмотр квартиры успешно изменена">
      <p class="my-4">Заявка на просмотр квартиры успешно изменена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import moment from 'moment'

  export default {
    name: 'New',
    components: {
      loading,
      MaskedInput
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true) {
          _this.show = true
          api.put(_this.urls.post + '/' + _this.$route.params.id, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({ path: '/post/list', query: { currentPage: _this.$route.query.currentPage } })
              }, 1000)
            }
          })
        }
      },
      goBack () {
        this.$router.go(-1)
      }
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.leadrequest + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
        _this.model.createdDate = moment(_this.model.createdDate * 1000).format('DD.MM.YYYY HH:mm')
        _this.model.phone = `+7 (${_this.model.phone.substring(0, 3)}) ${_this.model.phone.substring(3, 6)}-${_this.model.phone.substring(6, 8)}-${_this.model.phone.substring(8, 10)}`
      })
    },
    watch: {
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'leadRequest'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
        },
        accessList: {},
        date: '',
        posts: [],
        urls: {
          leadrequest: 'leadrequest'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
