<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Изменение устройства</strong>
          </template>
          <b-form-group
            label="Жилищный комплекс"
            label-for="housingComplex"
            :label-cols="3"
            label-class="text-sm-right pr-4"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(housingComplexId)"
            :state="state1">
            <b-form-select id="housingComplex"
                           :options="housingComplex"
                           placeholder="Выберите ЖК"
                           v-model="housingComplexId"
                           :state="state1">
              <template slot="first">
                <option :value="null" disabled>Выберите ЖК</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Дом"
            label-for="building"
            :label-cols="3"
            label-class="text-sm-right pr-4"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(buildingId)"
            :state="state2">
            <b-form-select id="building"
                           :options="building"
                           placeholder="Выберите дом"
                           v-model="buildingId"
                           :state="state2">
              <template slot="first">
                <option :value="null" disabled>Выберите дом</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Квартира"
            label-for="premise"
            :label-cols="3"
            label-class="text-sm-right pr-4"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.premiseId)"
            :state="state3">
            <b-form-select id="premise"
                           :options="premise"
                           placeholder="Выберите квартиру"
                           v-model="model.premiseId"
                           :state="state3">
              <template slot="first">
                <option :value="null" disabled>Выберите квартиру</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Номер устройства"
            label-for="name"
            :label-cols="3"
            label-class="text-sm-right pr-4"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.device)"
            :state="state6">
            <b-form-input id="id" type="text" :state="state6" v-model="model.device"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Текущая температура"
            label-for="id"
            :label-cols="3"
            label-class="text-sm-right pr-4"
            :horizontal="true">
            <b-form-input id="id" plaintext type="text" v-model="model.value"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Выбранная температура"
            label-for="id"
            :label-cols="3"
            label-class="text-sm-right pr-4"
            :horizontal="true">
            <b-form-input id="id" plaintext type="text" v-model="model.presetTemp"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Хост"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            label-class="text-sm-right pr-4"
            :invalid-feedback="validation.empty(model.host)"
            :state="state4">
            <b-form-input id="id" type="text" :state="state4" v-model="model.host"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Порт"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            label-class="text-sm-right pr-4"
            :invalid-feedback="validation.isNumber(model.port)"
            :state="state5">
            <b-form-input id="name" type="text" :state="state5" v-model="model.port"></b-form-input>
          </b-form-group>
           <b-form-group
            label="Топики"
            label-for="contacts"
            :label-cols="3"
            class="mt-5 mb-5"
            label-size="lg"
            :horizontal="false">
            <hr>
            <b-form-group
              label="Включения управления отоплением"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              :invalid-feedback="validation.empty(model.controlTopic)"
              :state="state10">
              <b-form-input id="id" type="text" :state="state10" v-model="model.controlTopic"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Текущей температуры"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              :invalid-feedback="validation.empty(model.currentTempTopic)"
              :state="state7">
              <b-form-input id="name" type="text" :state="state7" v-model="model.currentTempTopic"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Установки желаемой температуры"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              :invalid-feedback="validation.empty(model.setPresetTempTopic)"
              :state="state8">
              <b-form-input id="id" type="text" :state="state8" v-model="model.setPresetTempTopic"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Получения желаемой температуры"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              :invalid-feedback="validation.empty(model.getPresetTempTopic)"
              :state="state9">
              <b-form-input id="name" type="text" :state="state9" v-model="model.getPresetTempTopic"></b-form-input>
            </b-form-group>
            <b-form-group
               label="Положение реле"
               label-for="name"
               :label-cols="3"
               :horizontal="true"
               :invalid-feedback="validation.empty(model.getStateTopic)"
               :state="state11">
               <b-form-input id="name" type="text" :state="state11" v-model="model.getStateTopic"></b-form-input>
             </b-form-group>
             <b-form-group
               label="Инверсия значения"
               label-for="premise"
               :label-cols="3"
               :horizontal="true">
               <label class="switch switch-3d switch-primary">
                 <input type="checkbox" v-model="model.stateValueInverted" class="switch-input" checked>
                 <span class="switch-label" data-on="Да" data-off="Нет"></span>
                 <span class="switch-handle"></span>
               </label>
             </b-form-group>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Устройство успешно изменено">
      <p class="my-4">Устройство успешно изменено</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.numId(this.housingComplexId)
      },
      state2 () {
        return validation.numId(this.buildingId)
      },
      state3 () {
        return validation.numId(this.model.premiseId)
      },
      state4 () {
        return validation.long(this.model.host)
      },
      state5 () {
        return validation.isNumberBool(this.model.port)
      },
      state6 () {
        return validation.isNumberBool(this.model.device)
      },
      state7 () {
        return validation.long(this.model.currentTempTopic)
      },
      state8 () {
        return validation.long(this.model.setPresetTempTopic)
      },
      state9 () {
        return validation.long(this.model.getPresetTempTopic)
      },
      state10 () {
        return validation.long(this.model.controlTopic)
      },
      state11 () {
        return validation.long(this.model.getStateTopic)
      }
    },
    methods: {
      whenTabInFocus () {
        let _this = this
        let query = {
          id: _this.model.premiseId
        }
        api.get(_this.urls.premiseAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.hComplexId = jData.data.housingComplexId
          }
          return api.get(_this.urls.housingComplex)
        }).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.id === _this.hComplexId) {
                _this.hComplex = item.name
              }
            })
          }
        })
      },
      sendForm () {
        let _this = this
        if (_this.state1 && _this.state2 && _this.state3 && _this.state4 && _this.state5 && _this.state6 && _this.state7 && _this.state8 && _this.state9 && _this.state10) {
          _this.whenTabInFocus()
          _this.show = true
          api.put(_this.urls.heating + '/' + _this.$route.params.id, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({ path: '/heating/list', query: { tab: _this.hComplex, currentPage: _this.$route.query.currentPage } })
              }, 1000)
            }
          })
        }
      },
      getTypes () {
        let _this = this
        api.get(_this.urls.metertype).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.meterType.push(temp)
            })
          }
        })
      },
      getPremiseAddress () {
        let _this = this
        let query = {
          id: _this.model.premiseId
        }
        api.get(_this.urls.premiseAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.housingComplexId = jData.data.housingComplexId
            _this.streetId = jData.data.streetId
            _this.buildingId = jData.data.buildingId
            _this.premiseId = jData.data.premiseId
          }
        }).then(() => {
          _this.getHousingComplex()
        })
      },
      getHousingComplex () {
        let _this = this
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.housingComplex.push(temp)
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this
        if (_this.housingComplexId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.housingComplexId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    _this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      },
      getPremise () {
        let _this = this
        if ((_this.housingComplexId !== '') && (_this.streetId !== '') && (_this.buildingId !== '')) {
          let query = {
            hcId: _this.housingComplexId,
            streetId: _this.streetId,
            buildingId: _this.buildingId
          }
          api.get(_this.urls.premise, query).then(function (response) {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach(function (spec) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.premiseNum
                _this.premise.push(temp)
              })
            }
          }).then(() => {
            _this.firstLoadForm = false
          })
        }
      },
      goBack () {
        this.$router.go(-1)
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'heating'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.heating + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
      }).then(() => {
        _this.getTypes()
        _this.getPremiseAddress()
      })
    },
    watch: {
      housingComplexId: function () {
        this.street = []
        this.building = []
        this.premise = []
        this.accommodation = []
        if (!this.firstLoadForm) {
          this.buildingId = 0
        }
        this.getStreet()
      },
      buildingId: function () {
        this.premise = []
        this.accommodation = []
        if (!this.firstLoadForm) {
          this.model.premiseId = 0
        }
        this.getPremise()
      }
    },
    data () {
      return {
        model: {
        },
        meterType: [],
        posts: [],
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        premise: [
        ],
        accessList: {},
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        hComplex: '',
        hComplexId: 0,
        date: {
          installationDate: '',
          lastInspection: ''
        },
        urls: {
          premiseAddress: 'premise/premiseaddress',
          heating: 'heating',
          metertype: 'metertype',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          premise: 'premise'
        },
        firstLoadForm: true,
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
