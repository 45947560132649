<template>
  <b-card :header="caption" class="custom_overflow">
    <template #header>
      Отчёт по переданным показаниям
    </template>
    <b-row>
      <b-col lg="11">
        <b-form-group
          label="Дата"
          label-for="date"
          :label-cols="1"
          :horizontal="true">
          <datepicker :minimumView="'month'" :maximumView="'month'" :initialView="'month'" v-model="date" :format="customFormatter" input-class="data_picker__input" language="ru" placeholder="Выберите временной промежуток"></datepicker>
        </b-form-group>
        <div v-if="query.length > 0">
          <b-form-group
            v-for="obj in query"
            :label="`${obj.hcName}`"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <div class="address_picker">
              <multiselect v-model="obj.buildingIds" :options="obj.allBuildings" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" :preserve-search="false" :searchable="false" placeholder="" label="text" track-by="text" :preselect-first="true">
                <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.text }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
              </multiselect>
              <b-btn size="sm" class="address_picker__button_remove" @click.prevent="delHC(obj.hcId)" text="Button" variant="danger"><i class="fa fa-close fa-2x"></i></b-btn>
            </div>
          </b-form-group>
        </div>
        <div v-else class="not_data__table pb-3">В данный момент не указан ни один ЖК, при нажатии на кнопку "Скачать" будет загружен отчёт по всем ЖК! Чтобы добавить ЖК нажмите на плюс.</div>
        <!--   <b-form-group-->
<!--          v-if="hcList.length > 0"-->
<!--          label="ЖК"-->
<!--          label-for="file"-->
<!--          :label-cols="3"-->
<!--          :horizontal="true">-->
<!--        <b-btn size="sm" v-if="hcList.length > 0" @click.prevent="viewModalHC" text="Button" variant="success">Добавить ЖК</b-btn>-->
        <b-btn size="sm" class="add-hc__button" v-if="hcList.length > 0" @click.prevent="viewModalHC" text="Button" variant="success"><i class="fa fa-plus fa-2x"></i></b-btn>
        <div v-if="loading" class="spinner d-flex align-items-center"><i class="fa fa-spinner fa-spin fa-3x mr-2"></i> Отчёт формируется, скачивание начнётся автоматически</div>
        <b-btn v-else size="sm" class="ml-a" @click.stop="download()" variant="success">Скачать</b-btn>
        <!--        </b-form-group>-->
      </b-col>
      <b-col lg="1">
      </b-col>
    </b-row>
<!--    <div class="not_data__table" v-if="viewError">Отчёты не найдены! Укажите место для получения отчёта!</div>-->
<!--    <div class="not_data__table" v-if="viewEmpty">Отчёты не найдены! Выберите другоe место  для получения отчёта!</div>-->
<!--    <div class="table_block">-->
<!--      <div class="spinner" v-if="!viewTable && !viewEmpty && !viewError"><i class="fa fa-spinner fa-spin fa-3x"></i></div>-->
<!--      <table class="report_meters__table" v-if="viewTable && !viewError && !viewEmpty" border="1" cellpadding="4" cellspacing="0">-->
<!--        <thead class="report_meters__table_header">-->
<!--        <tr align="center">-->
<!--          <th rowspan="3">ЖК</th>-->
<!--          <th rowspan="3">Улица</th>-->
<!--          <th rowspan="3">Дом</th>-->
<!--          <th rowspan="3">Квартира</th>-->
<!--          <th rowspan="3">Номер счетчика</th>-->
<!--          <th colspan="5">Номер модема</th>-->
<!--          <th colspan="5">Тип счетчика</th>-->
<!--          <th colspan="5">Дата следующей поверки</th>-->
<!--          <th colspan="5">Дата последних ручных показаний</th>-->
<!--          <th colspan="5">Ручные показания</th>-->
<!--          <th colspan="5">Дата ближайщих автоматических показаний</th>-->
<!--          <th colspan="5">Автоматические показания</th>-->
<!--        </tr>-->
<!--&lt;!&ndash;        <tr align="center">&ndash;&gt;-->
<!--&lt;!&ndash;          <th rowspan="2">Серийный № счётчика</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th rowspan="2">Модем</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th colspan="3">Показания (Гкал)</th>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--&lt;!&ndash;        <tr align="center">&ndash;&gt;-->
<!--&lt;!&ndash;          <th>Начало</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>Расход</th>&ndash;&gt;-->
<!--&lt;!&ndash;          <th>Конец</th>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--        </thead>-->
<!--        <tbody>-->
<!--        <tr align="center" v-for="(item, id) in items">-->
<!--          <th class="report_meters__table_body__tr">{{id + 1}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.object}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.premiseNum}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.lastInspection}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.verificDate}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.number}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.modem}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.history.begin}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.history.consumption}}</th>-->
<!--          <th class="report_meters__table_body__tr">{{item.history.end}}</th>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </div>-->
    <b-modal ref="addHC" hide-footer title="Добавить ЖК">
      <b-form-group
        label="Жилищный комплекс"
        label-for="housingComplex"
        :label-cols="3"
        :horizontal="true">
        <b-form-select id="housingComplex"
                       :options="hcList"
                       placeholder="Выберите ЖК"
                       v-model="hcId">
          <template slot="first">
            <option :value="null" disabled>Выберите ЖК</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="addHC">Сохранить</b-btn>
    </b-modal>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import consts from '../../config/const'
  import validation from '../../config/validation'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'c-table',
    components: {
      datetime: Datetime,
      Datepicker,
      Multiselect
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        queryTemp: [
          {
            hсId: 0,
            hcName: '',
            allBuildings: [],
            buildingIds: []
          }
        ],
        date: '',
        query: [],
        items: [],
        hcList: [],
        allHCList: [],
        buildingsList: [],
        street: [],
        accessList: {},
        hcId: 0,
        currentPage: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        viewError: true,
        viewEmpty: false,
        loading: false,
        consts: consts,
        urls: {
          hc: 'housingcomplex',
          list: 'report/meters',
          street: 'street',
          building: 'building',
          download: 'report/meters/measurement'
        },
        validation: validation
      }
    },
    computed: {
      state3 () {
        return validation.numId(this.housingComplexId)
      }
    },
    methods: {
      customFormatter (date) {
        moment.locale('ru')
        return moment(date).format('MM.YYYY')
      },
      download () {
        this.loading = true
        let computedQuery = {
          date: moment(this.date).format('X'),
          objects: []
        }
        let tempBuildings
        if (this.query.length > 0) {
          this.query.forEach(item => {
            tempBuildings = []
            // if (item.buildingIds.length > 0) {
            item.buildingIds.forEach(building => {
              tempBuildings.push(building.value)
            })
            // } else {
            //   item.allBuildings.forEach(building => {
            //     tempBuildings.push(building.value)
            //   })
            // }

            computedQuery.objects.push(
              {
                hcId: item.hcId,
                buildingIds: tempBuildings
              }
            )
          })
        } else {
          this.allHCList.forEach(item => {
            computedQuery.objects.push(
              {
                hcId: item.value,
                buildingIds: []
              }
            )
          })
        }
        api.post(this.urls.download, computedQuery).then((response) => {
          this.loading = false
          window.location.href = consts.storageUrl + '/' + response.data.data.url
        })
      },
      viewModalHC () {
        this.hcId = 0
        this.$refs.addHC.show()
      },
      addHC () {
        this.getBuilding()
      },
      delHC (id) {
        this.query.forEach((item, index) => {
          if (item.hcId === id) {
            this.query.splice(index, 1)
          }
        })
        this.allHCList.forEach(item => {
          if (item.value === id) {
            this.hcList.push(item)
          }
        })
      },
      hideModalHC () {
        this.$refs.addHC.hide()
      },
      getHousingComplex () {
        api.get(this.urls.hc).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.hcList.push(temp)
              this.allHCList.push(temp)
            })
          }
        })
      },
      getBuilding () {
        if (this.hcId !== 0 && !this.query.find(item => item.hcId === this.hcId)) {
          let hcObj = {
            hcId: this.hcId,
            hcName: this.getHcName(this.hcId),
            buildingIds: [],
            allBuildings: []
          }
          api.get(this.urls.building, {hcId: this.hcId}).then((response) => {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach((spec) => {
                if (spec.id !== null) {
                  let temp = {}
                  temp.value = spec.id
                  temp.text = 'ул.' + spec.street + ' д.' + spec.objectNumber
                  hcObj.allBuildings.push(temp)
                }
              })
            }
          })
          this.hcList = this.hcList.filter(item => item.value !== this.hcId)
          this.query.push(hcObj)
          this.hideModalHC()
        }
      },
      // getData () {
      //   if (this.housingComplexId !== 0) {
      //     this.viewError = false
      //     this.viewEmpty = false
      //     this.viewTable = false
      //     let query = {
      //       housingComplexId: this.housingComplexId
      //     }
      //     if (this.buildingId) {
      //       query.buildingId = this.buildingId
      //     }
      //     api.get(this.urls.list, query).then((response) => {
      //       this.items = []
      //       let jData = response.data
      //       if (jData.status) {
      //         this.items = [...jData.data]
      //       }
      //     }).then(() => {
      //       if (this.items.length === 0) {
      //         this.viewEmpty = true
      //       } else {
      //         this.viewTable = true
      //         this.viewEmpty = false
      //       }
      //     })
      //   } else {
      //     this.viewError = true
      //     this.viewEmpty = false
      //   }
      // },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      getHcName (id) {
        let complex = this.hcList.find(item => item.value === id)
        return complex.text
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'report'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },
    mounted () {
      this.date = new Date()
      this.getHousingComplex()
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
