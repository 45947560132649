<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="loadingShow"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>
          <b-form-group
            label="Название"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="stateName">
            <b-form-input id="name" type="text" :state="stateName" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Часовой пояс"
            label-for="utc"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.utcId)"
            :state="timeZoneComputed">
            <b-form-select id="utc"
                           :options="timeZones"
                           placeholder="Выберите дом"
                           v-model="model.utcId"
                           :state="timeZoneComputed">
              <template slot="first">
                <option :value="null" disabled>Выберите часовой пояс</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Сохранено">
      <p class="my-4">Сохранено</p>
    </b-modal>
  </div>
</template>

<script>
import loading from 'vue-full-loading'
import api from '../../config/api'
import validation from '../../config/validation'

export default {
  components: {
    loading
  },
  name: 'Item',
  computed: {
    pageTitle () {
      return this.cityId ? 'Редактирование ' : 'Добавить город '
    },
    stateName () {
      return validation.long(this.model.name)
    },
    timeZoneComputed () {
      return validation.numId(this.model.utcId)
    }
  },
  data () {
    return {
      validation: validation,
      model: {
      },
      urls: {
        city: 'city',
        timeZones: 'settings/timezones'
      },
      timeZones: [],
      cityId: this.$route.params.id,
      accessList: {},
      loadingShow: false,
      label: 'Пожалуйста, подождите, операция выполняется'
    }
  },
  created: function () {
    let accessKey = {
      keyWord: 'city'
    }
    api.get('role/components/access', accessKey).then((response) => {
      if (response.data.data.isRead === false) {
        this.$router.push('/pages/403')
      } else {
        this.accessList = response.data.data
      }
    })
  },
  mounted () {
    this.getTimeZones()
    if (this.cityId) {
      api.get(this.urls.city + '/' + this.$route.params.id).then((response) => {
        let jData = response.data
        this.model = jData.data
      })
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getTimeZones () {
      api.get(this.urls.timeZones).then(
        response => {
          let jData = response.data
          jData.data.forEach((spec) => {
            if (spec.id !== null) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name + ' (МСК ' + spec.msk + ', UTC ' + spec.utc + ')'
              this.timeZones.push(temp)
            }
          })
        })
    },
    sendForm () {
      let _this = this
      if (this.stateName && this.timeZoneComputed) {
        this.loadingShow = true
        let promise = null
        if (this.cityId) {
          promise = api.put(this.urls.city + '/' + this.cityId, this.model)
        } else {
          promise = api.post(this.urls.city, this.model)
        }
        promise.then(response => {
          _this.loadingShow = false
          _this.$refs.myModalRef.show()
          setTimeout(function () {
            _this.$refs.myModalRef.hide()
            _this.$router.push('/city/list')
          }, 1000)
        })
      }
    }
  }
}
</script>

