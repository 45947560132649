<template>
  <b-card :header="caption">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col v-if="accessList.isWrite === true" lg="3">
        <b-btn class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Создать заявку</b-btn>
      </b-col>
      <b-col offset="8" lg="1">
        <b-btn size="sm" class="change_size btn-excel mtm2" @click.stop="viewModal()"><i class="fa fa-file-excel-o fa-2x"></i></b-btn>
      </b-col>
    </b-row>
    <b-col lg="12">
      <b-tabs card>
        <b-tab v-for="item in statusList" :title="`${item.count === 0 ? `${item.name}` : `${item.name} (${item.requestsCount})`}`" :key="item.name" :active="isActive(item.name)" @click="changeTab(item.name)">
          <div class="spinner" v-if="!viewTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
          <div v-if="viewTable">
            <b-row>
              <b-col lg="2" class="justify-content-start">
                <label class="mt-0 d-inline-block">
                  Демо
                </label>
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" v-model="isDemo" class="switch-input" checked>
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </b-col>
              <b-col lg="2" class="justify-content-start">
                <label class="mt-0 d-inline-block">
                  Мои заявки
                </label>
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" v-model="forMeSwitch" class="switch-input" checked>
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </b-col>
              <b-col lg="4" class="justify-content-start">
                <label class="mt-0 d-inline-block">
                  Только просроченные
                </label>
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" v-model="expiredSwitch" class="switch-input" checked>
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="2">
                <b-select
                  id="nature"
                  :options="natures"
                  v-model="filter.natureId">
                  <template slot="first">
                    <option :value="null" disabled>Характер обращения</option>
                    <option value="">Все</option>
                  </template>
                </b-select>
              </b-col>

              <b-col lg="5">
                <span>Дата создания</span>
                <datetime type="datetime"
                          id="dateCreateFrom"
                          v-model="filter.dateCreateFrom"
                          class="theme-orange"
                          input-class="dataTimePick"
                          placeholder="C"
                          moment-locale="ru"
                          :phrases="{ok: 'OK', cancel: 'Отменить'}">
                </datetime>
                <datetime type="datetime"
                          id="dateCreateTo"
                          v-model="filter.dateCreateTo"
                          class="theme-orange"
                          input-class="dataTimePick"
                          placeholder="По"
                          moment-locale="ru"
                          :phrases="{ok: 'OK', cancel: 'Отменить'}">
                </datetime>
                <b-button @click.stop="resetFilter()">Сброс</b-button>
              </b-col>

              <b-col offset="2" lg="3">
                <div class="input-group mb-3">
                  <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
                  <div v-if="search.length > 0" class="input-group-append">
                    <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
                  </div>
                  <div v-else class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="table_block">
            <b-table
              v-if="viewTable"
              :hover="hover"
              :striped="striped"
              :bordered="bordered"
              :small="small"
              :fixed="fixed"
              :items="items"
              :fields="fields"
              :current-page="1"
              :per-page="perPage"
              @row-dblclicked="rowClick"
              @row-clicked="rowTouch"
              @sort-changed="sortChange"
              responsive="xs"
            >
              <template slot="alert" slot-scope="data">
                <p class="mb-0" v-if="data.item.alert === 'deadline'">
                  <i class="fa fa-calendar fa-1x danger"></i>
                </p>

                <p v-if="data.item.chat && data.item.chat.count > 0" class="badge badge-info badge-pill">
                  {{data.item.chat.count}}
                </p>
              </template>

              <template slot="description" slot-scope="data">
                <p class="desc_table">
                  {{ data.item.description }}
                </p>
              </template>

              <template slot="index" slot-scope="data">
                {{ data.item.id }}
              </template>

              <template slot="engineerName" slot-scope="data">
                  <b-badge v-if="data.item.engineer === 'Не назначен'" variant="danger">{{data.item.engineer}}</b-badge>
                  <b-badge v-else variant="info">{{data.item.engineer}}</b-badge>
              </template>

              <template slot="spec" slot-scope="data">
                  <b-badge v-if="data.item.specialist === 'Не назначен'" variant="danger">{{data.item.specialist}}</b-badge>
                  <b-badge v-else variant="info">{{data.item.specialist}}</b-badge>
              </template>

              <template slot="isDemo" slot-scope="data">
                <p v-if="data.item.isDemo === 1"><i class="fa fa-check fa-2x success"></i></p>
                <p v-else><i class="fa fa-close fa-2x danger"></i></p>
              </template>

              <template slot="innerStatus" slot-scope="data">
                <div v-if="data.item.innerStatus.keyWord === 'new'">
                  <i class="icon-close icons font-2xl"></i>
                </div>

                <div v-else-if="data.item.innerStatus.keyWord === 'done'">
                  <i class="icon-check icons font-2xl"></i>
                </div>

                <div v-else-if="data.item.innerStatus.keyWord === 'inProgress'">
                  <i class="icon-clock icons font-2xl"></i>
                </div>
              </template>

              <template slot="buttons" slot-scope="data">
                <div>
                  <b-btn size="sm" class="change_size btn-info" @click.stop="log(data.item.id)">
                    <i class="fa fa-history fa-2x"></i>
                  </b-btn>

                  <b-btn
                    size="sm"
                    v-if="accessList.isDelete === true"
                    class="change_size btn-danger"
                    @click.stop="del(data.item.id)"
                  >
                    <i class="fa fa-trash-o fa-2x"></i>
                  </b-btn>
                </div>
              </template>
            </b-table>

            <nav class="d-flex">
              <b-pagination
                v-if="viewTable"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @input="getData()"
                prev-text="Prev"
                next-text="Next"
                hide-goto-end-buttons
              />

              <label class="d-flex justify-content-center align-items-center mb-3 ml-3" v-if="viewTable">
                Страница:
                <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
              </label>
            </nav>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
    <b-modal ref="addPremise" hide-footer title="Выгрузить отчёт по заявкам">
      <b-form-group
        label="Начало периода:"
        label-for="serviceDate"
        :label-cols="4">
        <datetime type="date"
                  id="serviceDate"
                  v-model="date.from"
                  class="theme-orange dateTimePick__report_width"
                  input-class="dataTimePick__report"
                  placeholder="Дата"
                  moment-locale="ru"
                  :phrases="{ok: 'OK', cancel: 'Отменить'}"
                  v-bind:class="{invalid__dateTime: !state12, valid__dateTime: state12}"
                  :state="state12">
        </datetime>
      </b-form-group>
      <b-form-group
        label="Конец периода:"
        label-for="serviceDate"
        :label-cols="4">
        <datetime type="date"
                  id="serviceDate"
                  v-model="date.to"
                  class="theme-orange dateTimePick__report_width"
                  input-class="dataTimePick__report"
                  placeholder="Дата"
                  moment-locale="ru"
                  :phrases="{ok: 'OK', cancel: 'Отменить'}"
                  v-bind:class="{invalid__dateTime: !state13, valid__dateTime: state13}"
                  :state="state13">
        </datetime>
      </b-form-group>
      <b-form-group
        label="Вид отчёта"
        label-for="status"
        :label-cols="4"
        :horizontal="true"
        :invalid-feedback="validation.empty(downloadRoute)"
        :state="state10">
        <b-form-select id="status"
          :options="downloadRoutes"
          v-model="downloadRoute"
          :state="state10">
          <template slot="first">
            <option :value="null" disabled>Выберите вид отчёта</option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Категории"
        v-if="downloadRoute === 'report/request/guarantee'">
        <el-tree
          :data="catSubcat"
          show-checkbox
          node-key="id"
          ref="tree1"
          :props="defaultPropsCat">
        </el-tree>
      </b-form-group>

      <b-form-group
        label="Объекты УК"
        v-if="downloadRoute === 'report/request/guarantee'">
        <el-tree
          :data="hcObjects"
          show-checkbox
          node-key="id"
          ref="tree2"
          :props="defaultProps">
        </el-tree>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="download">Выгрузить</b-btn>
      <b-btn class="mt-3" variant="outline-danger" block @click="hideModal">Отмена</b-btn>
    </b-modal>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'
  import validation from '../../config/validation'
  import consts from '../../config/const'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import loading from 'vue-full-loading'
  import Vue from 'vue'
  import vueCookie from 'vue-cookie'
  import { Tree } from 'element-ui'
  import 'element-ui/lib/theme-chalk/index.css'

  Vue.use(vueCookie)
  Vue.use(Tree)

  export default {
    name: 'c-table',
    components: {
      datetime: Datetime,
      Datepicker,
      loading
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: true
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: true
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [],
        isDemo: false,
        statusesList: [],
        sortBy: 'index',
        sortDesc: 1,
        searchTimer: null,
        paginateTimer: null,
        search: '',
        statusList: [],
        accessList: {},
        tempItem: [],
        fields: [],
        downloadRoutes: [
          {
            value: 'report/request',
            text: 'Отчёт'
          },
          {
            value: 'report/request/history',
            text: 'История изменений'
          },
          {
            value: 'report/request/supervision',
            text: 'Отчёт технадзора'
          },
          {
            value: 'report/request/guarantee',
            text: 'Отчёт по гарантии'
          }
        ],
        textPage: '1',
        downloadRoute: '',
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        currentTab: 1,
        validation: validation,
        consts: consts,
        forMeSwitch: false,
        expiredSwitch: false,
        isTouchDevice: false,
        catSubcat: [],
        hcObjects: [],
        defaultProps: {
          children: 'buildings',
          label: 'name'
        },
        defaultPropsCat: {
          children: 'subcategories',
          label: 'name'
        },
        date: {
          from: '',
          to: ''
        },
        urls: {
          requestNature: 'requestnature',
          statuses: 'status',
          list: 'servicerequest',
          status: 'status/requests',
          del: 'servicerequest',
          treeHC: 'housingcomplex/tree',
          categories: 'category/withsubcategories'
        },
        show: false,
        label: 'Пожалуйста, подождите, отчёт формируется',
        pickerType: 'day',
        natures: [],
        filter: {
          natureId: null,
          dateCreateFrom: '',
          dateCreateTo: ''
        },
        watchInPause: false
      }
    },
    computed: {
      state10 () {
        return validation.long(this.downloadRoute)
      },
      state12 () {
        return validation.long(this.date.from)
      },
      state13 () {
        return validation.long(this.date.to)
      }
    },
    methods: {
      async resetFilter () {
        this.watchInPause = true
        this.filter.natureId = null
        this.filter.dateCreateFrom = ''
        this.filter.dateCreateTo = ''
        await this.getData()
        this.watchInPause = false
      },
      async getRequestNatures () {
        let query = {
          removed: 0
        }
        const response = await api.get(this.urls.requestNature, query)
        const jsonData = response.data
        if (jsonData && jsonData.status) {
          for (const value of jsonData.data) {
            const status = {}
            status.value = value.id
            status.text = value.name
            this.natures.push(status)
          }
        }
      },
      sortChange (ctx) {
        this.viewTable = false
        this.sortBy = ctx.sortBy
        this.sortDesc = this.sortDesc === 1 ? 0 : 1
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            currentTab: this.currentTab,
            currentPage: this.currentPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc
          }
        })
        this.getData()
      },
      rowClick (item) {
        if (!this.isTouchDevice) {
          this.detail(item.id)
        }
      },
      rowTouch (item) {
        if (this.isTouchDevice) {
          this.detail(item.id)
        }
      },
      download () {
        if (this.date.from !== '' && this.date.to !== '' && this.state10 && this.state12 && this.state13) {
          this.show = true
          this.viewEmpty = false
          if (this.downloadRoute !== 'report/request/guarantee') {
            let query = {
              from: moment(this.date.from).format('X'),
              to: parseInt(moment(this.date.to).format('X'), 10) + 86399
            }
            api.get(this.downloadRoute, query).then((response) => {
              location.href = consts.storageUrl + '/' + response.data.data.url
              this.show = false
              this.hideModal()
            })
            return
          }

          const categories = []
          const subcategories = []
          const hc = []
          const buildings = []
          if (this.$refs.tree1 !== undefined) {
            const arr1 = this.$refs.tree1.getCheckedNodes()
            for (const item of arr1) {
              if (item.subcategories) {
                categories.push(item.id)
                continue
              }
              subcategories.push(item.id)
            }
          }

          if (this.$refs.tree2 !== undefined) {
            const arr2 = this.$refs.tree2.getCheckedNodes()
            for (const item of arr2) {
              if (item.buildings) {
                hc.push(item.id)
                continue
              }
              buildings.push(item.id)
            }
          }

          let body = {
            from: moment(this.date.from).format('X'),
            to: parseInt(moment(this.date.to).format('X'), 10) + 86399,
            categories,
            subcategories,
            hc,
            buildings
          }
          api.post(this.downloadRoute, body).then((response) => {
            console.log(response)
            location.href = consts.storageUrl + '/' + response.data.data.url
            this.show = false
            this.hideModal()
          })
        }
      },
      viewModal () {
        let _this = this
        _this.housingComplexId = 0
        _this.check = true
        _this.$refs.addPremise.show()
      },
      hideModal () {
        this.$refs.addPremise.hide()
      },
      clearSearch () {
        this.search = ''
      },
      async changeTab (name) {
        this.viewTable = false
        this.currentTab = (name === null ? 1 : name)
        if (!name) {
          await this.setCurrents()
        }
        this.getData()
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      detail (id) {
        this.$router.push({path: '/service_requests/edit/' + id,
          query: {
            currentPage: this.currentPage,
            currentTab: this.currentTab,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc
          }
        })
      },
      del (id) {
        let _this = this
        api.delete(_this.urls.del + '/' + id).then(function (response) {
          _this.getData()
        })
      },
      view (id) {
        let _this = this
        _this.$router.push('/service_requests/chat/' + id)
      },
      log (id) {
        this.$router.push('/service_requests/log/' + id)
      },
      newItem () {
        let _this = this
        _this.$router.push('/service_requests/new/')
      },
      getHousingComplex () {
        api.get(this.urls.treeHC).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.hcObjects = jData.data
          }
        })
      },
      getPhone (phone) {
        return `+7 (${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 8)}-${phone.substring(8, 10)}`
      },
      getAddress (street, building, premise) {
        return `ул. ${street}, д. ${building}, кв. ${premise}`
      },
      getShortAddress (street, building, premise) {
        return `${street}<br/>${building}-${premise}`
      },
      getCategories () {
        api.get(this.urls.categories).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.catSubcat = jData.data
          }
        })
      },
      getData () {
        this.textPage = String(this.currentPage)
        switch (this.isDemo) {
          case true:
            this.isDemo = 1
            break
          case false:
            this.isDemo = 0
            break
        }
        this.viewTable = false
        let status = null
        api.get(this.urls.status).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.statusList = jData.data
            let allCount = 0
            jData.data.forEach(item => {
              allCount += item.requestsCount
            })
            this.statusList.push({name: 'Все заявки', requestsCount: allCount})
          }
          this.currentTab = (this.currentTab === 1 ? this.statusList[0].name : this.currentTab)
          jData.data.forEach((item) => {
            if (this.currentTab !== 'Все заявки') {
              if (this.currentTab === item.name) {
                status = item.id
              }
            } else {
              status = 'all'
            }
          })
          return status
        }).then((status) => {
          let query = {
            search: this.search,
            isDemo: this.isDemo,
            page: this.currentPage,
            limit: this.perPage,
            sortBy: this.$route.query.sortBy ? this.$route.query.sortBy : this.sortBy,
            sortDesc: this.validateSortDesc() ? this.$route.query.sortDesc : this.sortDesc,
            forMe: +this.forMeSwitch,
            expired: +this.expiredSwitch,
            filter: JSON.stringify(this.filter)
          }
          if (status !== 'all') {
            query.statusId = status
          }
          return api.get(this.urls.list, query)
        }).then((response) => {
          let jMeta = response.data.meta
          this.totalRows = jMeta.pages * this.perPage - 1
          this.lastPage = jMeta.pages
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              item.requestType = item.requestType !== null ? item.requestType.name : ''
              item.address = this.getAddress(item.premise.street, item.premise.objectNumber, item.premise.premiseNum)
              item.shortAddress = this.getShortAddress(item.premise.street, item.premise.objectNumber, item.premise.premiseNum)
              item.createdDate = moment(item.createdDate * 1000).format('DD.MM.YYYY HH:mm')
              item.serviceDate = item.serviceDate ? moment(item.serviceDate * 1000).format('DD.MM.YYYY HH:mm') : ''
              if (item.arrivalSpecialist && item.endTime) {
                item.wantDate = `${moment(item.arrivalSpecialist * 1000).format('DD.MM.YYYY HH:mm')}-${moment(item.endTime * 1000).format('HH:mm')}`
              }
              item.requestNat = item.requestNat !== null ? item.requestNat.name : ''
              item.category = item.category !== null ? item.category.name : ''
              item.status = item.status !== null ? item.status.name : ''
              if (item.userPhone) {
                item.phone = this.getPhone(item.userPhone)
              } else if (item.user) {
                item.phone = this.getPhone(item.user.phone)
              } else {
                item.phone = null
              }
              item.user = item.userName ? item.userName : null
              if (item.user && item.phone) {
                item.user += '<br>' + item.phone
              }
              if (!item.user) {
                item.user = 'Не указано'
              }
              if (!item.phone) {
                item.phone = 'Не указан'
              }
              item.specialist = item.specialist === null ? 'Не назначен' : item.specialist.lastName + ' ' + item.specialist.firstName
              item.engineer = item.engineer === null ? 'Не назначен' : item.engineer.lastName + ' ' + item.engineer.firstName
              if (item.expired && ((localStorage.getItem('userId') === String(item.engineerId) || localStorage.getItem('userId') === String(item.adminId)) || vueCookie.get('role') === 'admin')) {
                if (item.expired) {
                  item._rowVariant = 'danger'
                }
              }
            })
            this.items = [...jData.data]
            this.viewTable = true
          }
          if (screen.width > 768) {
            this.fields = [
              {key: 'alert', label: ' ', 'class': 'text-center'},
              {key: 'index', label: '№', 'class': 'text-center', sortable: true},
              {key: 'createdDate', label: 'Дата создания', sortable: true},
              {key: 'requestNat', label: 'Характер обращения', sortable: true, thStyle: 'width: 8%'},
              {key: 'address', label: 'Адрес', sortable: true, thStyle: 'width: 8%'},
              {key: 'description', label: 'Описание', sortable: true},
              {key: 'wantDate', label: 'Желаем. дата', sortable: true, thStyle: 'width: 8%'},
              {key: 'serviceDate', label: 'Назнач. дата', sortable: true, thStyle: 'width: 8%'},
              {key: 'engineerName', label: 'Постановщик', sortable: true},
              {key: 'spec', label: 'Исполнитель', sortable: true},
              {key: 'innerStatus', label: 'Внутр. статус', 'class': 'text-center', sortable: true},
              {key: 'user', label: 'Клиент', sortable: true},
              {key: 'buttons', label: ' '}
            ]
          } else {
            this.fields = [
              {key: 'alert', label: ' ', 'class': 'text-center'},
              {key: 'index', label: ' ', 'class': 'text-center, px-0 pr-1'},
              {key: 'description', label: ' ', 'class': ''},
              {key: 'shortAddress', label: ' ', 'class': 'px-0 pr-1'},
              {key: 'buttons', label: ' '}
            ]
          }
        })
      },
      validateSortDesc () {
        return [0, 1].includes(+this.$route.query.sortDesc)
      },
      isActive (statusName) {
        return statusName === this.$route.query.currentTab
      },
      setCurrents () {
        if (this.$route.query.currentPage) {
          this.currentPage = parseInt(this.$route.query.currentPage)
        }
        if (this.$route.query.currentTab) {
          this.currentTab = this.$route.query.currentTab
        }
        if (this.$route.query.sortBy) {
          this.sortBy = this.$route.query.sortBy
        }
        if (this.validateSortDesc()) {
          this.sortDesc = this.$route.query.sortDesc
        }
      },
      replaceRouterQuery () {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            currentTab: this.currentTab,
            currentPage: this.currentPage,
            sortBy: this.$route.query.sortBy ? this.$route.query.sortBy : this.sortBy,
            sortDesc: this.validateSortDesc() ? this.$route.query.sortDesc : this.sortDesc
          }
        })
      }
    },
    watch: {
      search () {
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => {
          this.getData()
        }, 1000)
      },
      'filter.natureId' () {
        if (!this.watchInPause) {
          this.getData()
          console.log('natureId = ', this.filter.natureId)
        }
      },
      'filter.dateCreateFrom' () {
        if (!this.watchInPause && this.filter.dateCreateTo !== '') {
          this.getData()
          console.log('dateCreateFrom = ', this.filter.dateCreateFrom)
        }
      },
      'filter.dateCreateTo' () {
        if (!this.watchInPause && this.filter.dateCreateFrom !== '') {
          this.getData()
          console.log('dateCreateTo = ', this.filter.dateCreateTo)
        }
      },
      textPage (value) {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      },
      isDemo () {
        this.getData()
      },
      forMeSwitch () {
        this.getData()
      },
      expiredSwitch () {
        this.getData()
      },
      currentPage (val) {
        this.replaceRouterQuery()
      },
      currentTab () {
        this.replaceRouterQuery()
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'serviceRequest'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted () {
      this.getHousingComplex()
      this.getCategories()
      this.changeTab(null)
      this.getRequestNatures()
      this.isTouchDevice = 'ontouchstart' in document.documentElement || navigator.msMaxTouchPoints || navigator.maxTouchPoints
    }
  }
</script>

<style lang="scss" scoped>
.desc_table {
  word-break: break-word;
  width: 150px;

  @media screen and (max-width: 768px) {
    overflow: scroll;
    height: 150px
  }
}
</style>
