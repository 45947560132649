<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание исполнителя</strong>
          </template>
          <b-form-group
            label="Имя"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.firstName)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.firstName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="lastName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.lastName)"
            :state="state5">
            <b-form-input id="lastName" type="text" :state="state5" v-model="model.lastName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Отчество"
            label-for="patronymicName"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="patronymicName" type="text" v-model="model.patronymicName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Компания"
            label-for="role"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.companyId)"
            :state="state9">
            <b-form-select id="role"
                           :options="company"
                           placeholder="Выберите компанию"
                           v-model="model.companyId"
                           :state="state9">
              <template slot="first">
                <option :value="null" disabled>Выберите компанию</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.email(model.email)"
            :state="state6">
            <b-form-input id="lastName" type="text" :state="state6" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state7">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
            ></masked-input>
          </b-form-group>
         <b-form-group
            label="Пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.pass(model.password, password)"
            :state="state8">
            <b-form-input id="lastName" type="password" :state="state8" v-model="model.password"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Повторите пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.pass(model.password, password)"
            :state="state8">
            <b-form-input id="lastName" type="password" :state="state8" v-model="password"></b-form-input>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer :title="titleText">
      <p class="my-4">{{ resultText }}</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import loading from 'vue-full-loading'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput
    },
    computed: {
      state4 () {
        return validation.long(this.model.firstName)
      },
      state5 () {
        return validation.long(this.model.lastName)
      },
      state6 () {
        return validation.emailBool(this.model.email)
      },
      state7 () {
        return validation.phoneNumberBool(this.phone)
      },
      state8 () {
        return validation.passBool(this.model.password, this.password)
      },
      state9 () {
        return validation.numId(this.model.companyId)
      }
    },
    methods: {
      sendForm () {
        if (this.state4 && this.state5 && this.state6 && this.state7 && this.state8 && this.state9) {
          this.show = true
          this.model.phone = this.phone.replace(/\D/g, '').replace('7', '')
          if (this.model.patronymicName === null) {
            delete this.model.patronymicName
          }
          delete this.model.photo
          api.post(this.urls.contractor, this.model).then((response) => {
            if (response.status) {
              if (response.data.error) {
                this.resultText = response.data.error.description
                this.titleText = 'Ошибка создания исполнителя'
                this.phone = ''
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.show = false
                }, 3000)
              } else {
                this.resultText = 'Исполнитель успешно создан'
                this.titleText = 'Исполнитель успешно создан'
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.$router.push('/contractor/list')
                  this.show = false
                }, 1000)
              }
            }
          })
        }
      },
      getCompany () {
        let _this = this
        api.get(_this.urls.company).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.company.push(temp)
            })
          }
        })
      }
    },
    watch: {
    },
    mounted: function () {
      let _this = this
      _this.getCompany()
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'contractor'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false || response.data.data.isWrite === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
          email: '',
          companyId: 0
          // password: ''
        },
        accessList: {},
        date: '',
        company: [
        ],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        urls: {
          contractor: 'contractor',
          company: 'company'
        },
        password: '',
        check: 'true',
        phone: '',
        resultText: '',
        titleText: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

