<template>
  <div class="animated fadeIn">

    <b-row>
      <b-col lg="12">
        <c-table caption="<i class='fa fa-align-justify'></i> Исполнители платежа"></c-table>
      </b-col><!--/.col-->
    </b-row><!--/.row-->
  </div>

</template>

<script>
    import cTable from './Table.vue'

    export default {
      name: 'List',
      components: {cTable}
    }
</script>
