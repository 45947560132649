let validation = {}

const regexpDouble = /^\d{1,}[.,]?\d{1,}$/
const regexpInt = /^\d{1,}$/
const regexpEmail = /^[a-zA-Z0-9][-._a-zA-Z0-9]+@(?:[a-z0-9][-a-z0-9]+\.)+[a-z]{2,20}$/
const regexpPhoneNumber = /^[0-9]{10}$/
const regexpGooglePlay = /https:\/\/play\.google\.com\/store\/apps/
const regexpAppStore = /https:\/\/itunes\.apple\.com/
const linkStub = 'https://openclipart.org/image/2400px/svg_to_png/194077/Placeholder.png&disposition=attachment'
const nonDeleteTags = '<p><br></p>'
const textErrorEmpty = 'Обязательное поле'
const textErrorPassword = 'Пароли не совпадают'

// Обязательность в текстовых полях

validation.isGooglePlayBool = function (str) {
  if (str !== null && str !== undefined) {
    if (regexpGooglePlay.test(str) || str.length === 0) {
      return true
    } else {
      return false
    }
  }
}

validation.isGooglePlay = function (str) {
  if (str !== null && str !== undefined) {
    if (regexpGooglePlay.test(str) || str.length === 0) {
      return ''
    } else if (str === undefined || str === '') {
      return textErrorEmpty
    } else {
      return 'Ссылка не является ссылкой на Google Play'
    }
  }
}

validation.isAppStoreBool = function (str) {
  if (str !== null && str !== undefined) {
    if (regexpAppStore.test(str)) {
      return true
    } else {
      return false
    }
  }
}

validation.isAppStore = function (str) {
  if (str !== null && str !== undefined) {
    if (regexpAppStore.test(str)) {
      return ''
    } else if (str === undefined || str === '') {
      return textErrorEmpty
    } else {
      return 'Ссылка не является ссылкой на App Store'
    }
  }
}

validation.empty = function (str) {
  if (str !== null && str !== undefined) {
    if (str.length > 0 && str !== nonDeleteTags) {
      return 'Принято'
    } else {
      return textErrorEmpty
    }
  } else {
    return textErrorEmpty
  }
}

validation.long = function (str) {
  if (str !== null && str !== undefined) {
    if (String(str).length > 0 && str !== nonDeleteTags) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

validation.interval = function (str) {
  if (str !== undefined) {
    if (str >= -100 && str <= 0) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

// Селекторы

validation.notSelect = function (str) {
  if (str > 0 && str !== null && str !== undefined) {
    return 'Принято!'
  } else {
    return textErrorEmpty
  }
}

validation.numId = function (str) {
  if (str > 0 && str !== null && str !== undefined) {
    return true
  } else {
    return false
  }
}
validation.numIds = function (ids) {
  if (ids.length > 0) {
    let result = true
    for (let id of ids) {
      if (!(id > 0 && id !== null && id !== undefined)) {
        result = false
        break
      }
    }
    return result
  } else {
    return false
  }
}

// Селекторы кастом

validation.emptyProvided = function (str, provided) {
  if (provided) {
    if (str !== null && str !== undefined) {
      if (str.length > 0 && str !== nonDeleteTags) {
        return 'Принято'
      } else {
        return textErrorEmpty
      }
    } else {
      return textErrorEmpty
    }
  }
}

validation.longProvided = function (str, provided) {
  if (provided) {
    if (str !== null && str !== undefined) {
      if (String(str).length > 0 && str !== nonDeleteTags) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
}

validation.dateUntiedSelect = function (str, isUntied) {
  if (isUntied) {
    if (str !== '' && str !== 0) {
      return ''
    } else {
      return textErrorEmpty
    }
  }
}

validation.dateUntiedSelectBool = function (str, isUntied) {
  if (isUntied) {
    if (str !== '' && str !== 0) {
      return true
    } else {
      return false
    }
  }
}

validation.notSelectWithPremiseType = function (str1, str2) {
  if (str2 !== 'pantry' && str2 !== 'сommercial') {
    if (str1 > 0 && str1 !== null && str1 !== undefined) {
      return 'Принято!'
    } else {
      return textErrorEmpty
    }
  }
}

validation.numIdWithPremiseType = function (str1, str2) {
  if (str2 !== 'pantry' && str2 !== 'сommercial') {
    if (str1 > 0 && str1 !== null && str1 !== undefined) {
      return true
    } else {
      return false
    }
  }
}

validation.customNumId = function (status, str) {
  if (status > 1) {
    if (str !== 0 && str !== null) {
      return true
    } else {
      return false
    }
  } else {
    return '1'
  }
}

validation.customNotSelect = function (status, str) {
  if (status > 1) {
    if (str !== 0 && str !== null) {
      return 'Принято!'
    } else {
      return textErrorEmpty
    }
  }
}

validation.customDate = function (status, str) {
  if (status > 1) {
    if (str !== '') {
      return true
    } else {
      return false
    }
  } else {
    return '-1'
  }
}

validation.customDateSelect = function (status, str) {
  if (status > 1) {
    if (str !== '') {
      return 'Принято!'
    } else {
      return textErrorEmpty
    }
  }
}

validation.closableRequestBool = function (status, str) {
  if (status === 3) {
    if (str !== '') {
      return true
    } else {
      return false
    }
  } else {
    return '-1'
  }
}

validation.closableRequest = function (status, str) {
  if (status === 3) {
    if (str !== '') {
      return ''
    } else {
      return textErrorEmpty
    }
  }
}

validation.dateSelect = function (str) {
  if (str !== '' && str !== 0) {
    return ''
  } else {
    return textErrorEmpty
  }
}

validation.dateSelectBool = function (str) {
  if (str !== '' && str !== 0) {
    return true
  } else {
    return false
  }
}

validation.twoDatePicker = function (date1, date2) {
  if (date1 !== '' && date2 !== '') {
    return ''
  } else {
    return textErrorEmpty
  }
}

// Числовые значения

validation.isNumberBool = function (str) {
  if (regexpDouble.test(str) || regexpInt.test(str)) {
    return true
  } else {
    return false
  }
}

validation.isNumber = function (str) {
  if (regexpDouble.test(str) || regexpInt.test(str)) {
    return ''
  } else if (str === undefined || str === '') {
    return textErrorEmpty
  } else {
    return 'Недопустимый формат или символ'
  }
}

// Числовые значения кастом

validation.isNumberBoolWithPremiseType = function (str1, str2) {
  if (str2 !== 'pantry' && str2 !== 'сommercial') {
    if (regexpDouble.test(str1) || regexpInt.test(str1)) {
      return true
    } else {
      return false
    }
  }
}

validation.isNumberWithPremiseType = function (str1, str2) {
  if (str2 !== 'pantry' && str2 !== 'сommercial') {
    if (regexpDouble.test(str1) || regexpInt.test(str1)) {
      return ''
    } else if (str1 === undefined || str1 === '') {
      return textErrorEmpty
    } else {
      return 'Недопустимый формат или символ'
    }
  }
}

// Числовые значения кастом

validation.isNumberBool = function (str) {
  if (regexpDouble.test(str) || regexpInt.test(str)) {
    return true
  } else {
    return false
  }
}

validation.isNumber = function (str) {
  if (regexpDouble.test(str) || regexpInt.test(str)) {
    return ''
  } else if (str === undefined || str === '') {
    return textErrorEmpty
  } else {
    return 'Недопустимый формат или символ'
  }
}

// Целочисленные значения

validation.isIntBool = function (str) {
  if (regexpInt.test(str)) {
    return true
  } else {
    return false
  }
}

validation.isInt = function (str) {
  if (regexpInt.test(str)) {
    return ''
  } else if (str === undefined || str === '') {
    return textErrorEmpty
  } else {
    return 'Недопустимый формат или символ'
  }
}

// Кастом для номеров подъездов

validation.isIntEntranceBool = function (str) {
  if (regexpInt.test(str) && parseInt(str) > 0) {
    return true
  } else {
    return false
  }
}

validation.isIntEntrance = function (str) {
  if (regexpInt.test(str) && parseInt(str) > 0) {
    return ''
  } else if (str === undefined || str === '') {
    return textErrorEmpty
  } else if (parseInt(str) === 0) {
    return 'Количество подъездов не может быть равно 0'
  } else {
    return 'Недопустимый формат или символ'
  }
}

// Номера телефонов

validation.phoneNumberBool = function (str) {
  str = str.replace(/\D/g, '').replace('7', '')
  if (regexpPhoneNumber.test(str)) {
    return true
  } else {
    return false
  }
}

validation.phoneNumber = function (str) {
  str = str.replace(/\D/g, '').replace('7', '')
  if (regexpPhoneNumber.test(str)) {
    return 'Принято'
  } else {
    return textErrorEmpty
  }
}

// Электронная почта

validation.email = function (str) {
  if (regexpEmail.test(str)) {
    return ''
  } else {
    return textErrorEmpty
  }
}

validation.emailBool = function (str) {
  if (regexpEmail.test(str)) {
    return true
  } else {
    return false
  }
}

// Картинка

validation.picture = function (str) {
  if (str !== '' && str !== linkStub) {
    return ''
  } else {
    return textErrorEmpty
  }
}

validation.pictureBool = function (str) {
  if (str !== '' && str !== linkStub) {
    return true
  } else {
    return false
  }
}

// Тектовые поля с форматированием

validation.emptyObj = function (str) {
  if (str && str.name) {
    if (str.name.length > 0 && str.name !== nonDeleteTags) {
      return ''
    } else {
      return textErrorEmpty
    }
  } else {
    return textErrorEmpty
  }
}

validation.longObj = function (str) {
  if (str && str.name) {
    if (String(str.name).length > 0 && str.name !== nonDeleteTags) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

// Пароли

validation.pass = function (str1, str2) {
  if (str1 === str2 && str1 !== null && str2 !== null && str1 !== undefined && str2 !== undefined && str1 !== '' && str2 !== '') {
    return ''
  } else if (str1 === str2) {
    return textErrorEmpty
  } else {
    return textErrorPassword
  }
}

validation.passBool = function (str1, str2) {
  if (str1 === str2 && str1 !== null && str2 !== null && str1 !== undefined && str2 !== undefined && str1 !== '' && str2 !== '') {
    return true
  } else if (str1 === str2) {
    return false
  } else {
    return false
  }
}

// Пароли при редактировании

validation.editPass = function (str1, str2) {
  if (str1 === str2 && str1 !== null && str2 !== null && str1 !== undefined && str2 !== undefined) {
    return ''
  } else if (str1 === str2) {
    return textErrorEmpty
  } else {
    return textErrorPassword
  }
}

validation.editPassBool = function (str1, str2) {
  if (str1 === str2 && str1 !== null && str2 !== null && str1 !== undefined && str2 !== undefined) {
    return true
  } else if (str1 === str2) {
    return false
  } else {
    return false
  }
}

// Целочисленные значения или ничего

validation.isIntOrEmptyBool = function (str) {
  if (str !== '') {
    if (regexpInt.test(str)) {
      return true
    } else {
      return false
    }
  }
}

validation.isIntOrEmpty = function (str) {
  if (regexpInt.test(str) || str === '') {
    return ''
  } else if (str === undefined) {
    return textErrorEmpty
  } else {
    return 'Недопустимый формат или символ'
  }
}

export default validation
