<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>

    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>

          <b-form-group
            label="Название категории"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="stateName">
            <b-form-input id="name" type="text" :state="stateName" v-model="model.name"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Через сколько дней напомнить об истечении срока заявки"
            label-for="remindAfter"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isInt(model.remindAfter)"
            :state="stateRemindAfter">
            <b-form-input id="remindAfter" type="number" :state="stateRemindAfter" v-model="model.remindAfter"></b-form-input>
          </b-form-group>

          <b-card>
            <template #header>
              <strong>Настройки для мобильного приложения</strong>
            </template>

            <b-form-group
              label="Иконка для отправки заявки"
              label-for="file"
              :label-cols="3"
              :horizontal="true">
              <label class="load-file__input">
                <input type="file" accept="image/x-png" @change="loadIcon">
                <b-btn size="sm" text="Button" variant="success">Добавить иконку</b-btn>
              </label>
              <img v-if="model.icon !== null" :src="model.icon" alt="hex shorthand color" class="chat__picture_small m-1 pb-3">
            </b-form-group>

            <b-form-group
              v-if="model.icon !== ''"
              label=""
              label-for="file"
              :label-cols="3"
              :horizontal="true">
            </b-form-group>

            <b-form-group
              label="Иконка для отображения заявок в чате"
              label-for="file"
              :label-cols="3"
              :horizontal="true">
              <label class="load-file__input">
                <input type="file" accept="image/x-png" @change="loadTargetIcon">
                <b-btn size="sm" text="Button" variant="success">Добавить иконку</b-btn>
              </label>
              <img v-if="model.targetIcon !== null" :src="model.targetIcon" alt="hex shorthand color" fluid class="chat__picture_small m-1 pb-3">
            </b-form-group>

            <b-form-group
              label="Фон иконки заявки в чате"
              label-for="name"
              :label-cols="3"
              :horizontal="true">
              <input type="color" v-model="model.iconColor">
            </b-form-group>
          </b-card>

          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>

    <b-modal ref="myModalRef" hide-footer title="Категория успешно сохранена">
      <p class="my-4">Категория успешно сохранена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../../config/validation'
  import consts from '../../../config/const'

  export default {
    name: 'Item',

    components: {
      loading
    },

    computed: {
      stateName () {
        return validation.long(this.model.name)
      },

      stateRemindAfter () {
        return validation.isIntBool(this.model.remindAfter)
      },

      pageTitle () {
        return this.categoryId ? 'Редактирование категории заявки' : 'Создание категории заявок'
      }
    },

    methods: {
      loadIcon (e) {
        this.file = e.target.files || e.dataTransfer.files
      },

      loadTargetIcon (e) {
        this.targetIcon = e.target.files || e.dataTransfer.files
      },

      sendForm () {
        if (this.stateName === true && this.stateRemindAfter === true) {
          if (this.model.icon === '') {
            this.model.icon = null
          }

          if (this.model.targetIcon === '') {
            this.model.targetIcon = null
          }

          this.show = true

          let promise = null
          if (this.categoryId) {
            promise = api.put(this.urls.category + '/' + this.categoryId, this.model)
          } else {
            promise = api.post(this.urls.category, this.model)
          }

          promise.then(response => {
            this.show = false
            if (response.status) {
              this.$refs.myModalRef.show()
              setTimeout(() => {
                this.$refs.myModalRef.hide()
                this.$router.push({
                  path: '/service_requests/categories/list',
                  query: { currentPage: this.$route.query.currentPage }
                })
              }, 1000)
            }
          })
        }
      },

      goBack () {
        this.$router.go(-1)
      }
    },

    mounted: function () {
      if (this.categoryId) {
        api.get(this.urls.category + '/' + this.categoryId).then(response => {
          this.model = response.data.data
        })
      }
    },

    watch: {
      file (newFile) {
        const fd = new FormData()
        fd.append('file', newFile[0])
        api.post(this.urls.file, fd).then(response => {
          if (response.data.status === false) {
            alert(response.data.error.description)
            return
          }
          this.model.icon = consts.storageUrl + '/' + response.data.data.url
        })
      },

      targetIcon (newFile) {
        const fd = new FormData()
        fd.append('file', newFile[0])
        api.post(this.urls.file, fd).then(response => {
          if (response.data.status === false) {
            alert(response.data.error.description)
            return
          }
          this.model.targetIcon = consts.storageUrl + '/' + response.data.data.url
        })
      }
    },

    created: function () {
      const accessKey = {
        keyWord: 'category'
      }

      api.get('role/components/access', accessKey).then(response => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },

    data () {
      return {
        model: {
          name: '',
          remindAfter: 0,
          icon: null,
          targetIcon: null,
          iconColor: null
        },

        categoryId: this.$route.params.id,
        date: '',
        file: '',
        targetIcon: '',
        accessList: {},
        posts: [],
        urls: {
          category: 'category',
          file: 'file'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        consts,
        validation
      }
    }
  }
</script>
