<template>
  <b-card :header="caption">
    <b-row>
      <b-col sm="1" v-if="accessList.isWrite === true">
        <b-btn class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Создать новость</b-btn>
      </b-col>
      <b-col sm="auto" offset="1" class="pl-2 pr-2">
        <b-select
          id="status"
          :options="statuses"
          v-model="filter.statusId">
          <template slot="first">
            <option :value="null" disabled>Статус</option>
            <option value="">Все</option>
          </template>
        </b-select>
      </b-col>
      <b-col sm="auto" class="pl-0 pr-2">
        <datetime type="datetime"
          id="publishesAt"
          v-model="filter.publishesAt"
          class="theme-orange"
          input-class="dataTimePick"
          placeholder="Дата публикации"
          moment-locale="ru"
          :phrases="{ok: 'OK', cancel: 'Отменить'}">
        </datetime>
      </b-col>
      <b-col sm="auto" class="pl-0 pr-2">
        <datetime type="datetime"
          id="endsAt"
          v-model="filter.endsAt"
          class="theme-orange"
          input-class="dataTimePick"
          placeholder="Дата завершения"
          moment-locale="ru"
          :phrases="{ok: 'OK', cancel: 'Отменить'}">
        </datetime>
      </b-col>
      <b-col sm="auto" class="pl-0 pr-2">
        <b-button @click.stop="resetFilter()">Сброс</b-button>
      </b-col>
      <b-col offset="1" lg="3">
        <div class="input-group mb-3">
          <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
          <div v-if="search.length > 0" class="input-group-append">
            <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
          </div>
          <div v-else class="input-group-append">
            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-table :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
      <template slot="index" slot-scope="data">
        {{perPage * (currentPage - 1) + data.index + 1}}
      </template>
      <template slot="publishesAt" slot-scope="data">
        {{ data.item.publishesAt ? formatDate(data.item.publishesAt) : '' }}
      </template>
      <template slot="endsAt" slot-scope="data">
        {{ data.item.endsAt ? formatDate(data.item.endsAt) : '' }}
      </template>
      <template slot="buttons" slot-scope="data">
        <div class="table__two_buttons">
          <b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
          <b-btn size="sm" v-if="accessList.isDelete === true" class="change_size btn-danger" @click.stop="del(data.item.id)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
        </div>
      </template>
    </b-table>
    <nav class="d-flex">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
      <label class="d-flex justify-content-center align-items-center mb-3 ml-3">
        Страница:
        <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
      </label>
    </nav>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import moment from 'moment'

  export default {
    name: 'c-table',
    components: {
      datetime: Datetime
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [],
        accessList: {},
        fields: [
          {key: 'index', label: '#'},
          {key: 'title', label: 'Описание'},
          {key: 'type', label: 'Тип новости'},
          {key: 'status', label: 'Статус'},
          {key: 'publishesAt', label: 'Дата публикации'},
          {key: 'endsAt', label: 'Дата завершения'},
          {key: 'buttons', label: ' '}
        ],
        searchTimer: null,
        search: '',
        textPage: '1',
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        totalRows: 1,
        urls: {
          list: 'news',
          npstatus: 'npstatus'
        },
        statuses: [],
        filter: {
          statusId: null,
          publishesAt: '',
          endsAt: ''
        },
        watchInPause: false
      }
    },
    methods: {
      clearSearch () {
        this.search = ''
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      newItem () {
        let _this = this
        _this.$router.push('/news/new/')
      },
      detail (id) {
        var _this = this
        _this.$router.push('/news/edit/' + id)
      },
      del (id) {
        var _this = this
        api.delete(_this.urls.list + '/' + id).then(function (response) {
          for (let i = 0; i < _this.items.length; i++) {
            if (String(_this.items[i].id) === String(id)) {
              _this.items.splice(i, 1)
            }
          }
        })
      },
      formatDate (timestamp) {
        return moment(timestamp * 1000).format('DD.MM.YYYY HH:mm')
      },
      async getNPStatuses () {
        const response = await api.get(this.urls.npstatus)
        const jsonData = response.data
        if (jsonData && jsonData.status) {
          for (const value of jsonData.data) {
            const status = {}
            status.value = value.id
            status.text = value.name
            this.statuses.push(status)
          }
        }
      },
      async getData () {
        this.textPage = String(this.currentPage)
        const query = {
          search: this.search,
          page: this.currentPage,
          limit: this.perPage,
          statusId: this.filter.statusId || '',
          publishesAt: this.filter.publishesAt ? moment(this.filter.publishesAt).format('X') : '',
          endsAt: this.filter.endsAt ? moment(this.filter.endsAt).format('X') : ''
        }
        const response = await api.get(this.urls.list, query)

        const jMeta = response.data.meta
        this.lastPage = jMeta.pages
        this.totalRows = jMeta.pages * this.perPage - 1

        const jData = response.data
        if (jData.status) {
          this.items = jData.data
        }
      },
      async resetFilter () {
        this.watchInPause = true

        this.filter.statusId = null
        this.filter.publishesAt = ''
        this.filter.endsAt = ''

        await this.getData()

        this.watchInPause = false
      }
    },
    watch: {
      search: function () {
        let _this = this
        clearTimeout(_this.searchTimer)
        _this.searchTimer = setTimeout(function () { _this.getData() }, 1000)
      },
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      },
      'filter.statusId' () {
        if (!this.watchInPause) {
          this.getData()
        }
      },
      'filter.publishesAt' () {
        if (!this.watchInPause) {
          this.getData()
          console.log(2)
        }
      },
      'filter.endsAt' () {
        if (!this.watchInPause) {
          this.getData()
          console.log(3)
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'news'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      this.getData()
      this.getNPStatuses()
    }
  }
</script>

