<template>
  <div class="animated fadeIn">
    <b-card no-body class="card-default">
      <template #header>
        <i class="fa fa-picture-o"></i> Simple Line Icons
      </template>
      <b-card-body>
        <b-row class="text-center">

          <b-col cols="6" sm="4" md="3">
            <i class="icon-user icons font-2xl d-block mt-4"></i>icon-user
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-people icons font-2xl d-block mt-4"></i>icon-people
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-user-female icons font-2xl d-block mt-4"></i>icon-user-female
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-user-follow icons font-2xl d-block mt-4"></i>icon-user-follow
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-user-following icons font-2xl d-block mt-4"></i>icon-user-following
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-user-unfollow icons font-2xl d-block mt-4"></i>icon-user-unfollow
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-login icons font-2xl d-block mt-4"></i>icon-login
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-logout icons font-2xl d-block mt-4"></i>icon-logout
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-emotsmile icons font-2xl d-block mt-4"></i>icon-emotsmile
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-phone icons font-2xl d-block mt-4"></i>icon-phone
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-call-end icons font-2xl d-block mt-4"></i>icon-call-end
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-call-in icons font-2xl d-block mt-4"></i>icon-call-in
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-call-out icons font-2xl d-block mt-4"></i>icon-call-out
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-map icons font-2xl d-block mt-4"></i>icon-map
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-location-pin icons font-2xl d-block mt-4"></i>icon-location-pin
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-direction icons font-2xl d-block mt-4"></i>icon-direction
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-directions icons font-2xl d-block mt-4"></i>icon-directions
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-compass icons font-2xl d-block mt-4"></i>icon-compass
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-layers icons font-2xl d-block mt-4"></i>icon-layers
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-menu icons font-2xl d-block mt-4"></i>icon-menu
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-list icons font-2xl d-block mt-4"></i>icon-list
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-options-vertical icons font-2xl d-block mt-4"></i>icon-options-vertical
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-options icons font-2xl d-block mt-4"></i>icon-options
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-down icons font-2xl d-block mt-4"></i>icon-arrow-down
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-left icons font-2xl d-block mt-4"></i>icon-arrow-left
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-right icons font-2xl d-block mt-4"></i>icon-arrow-right
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-up icons font-2xl d-block mt-4"></i>icon-arrow-up
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-up-circle icons font-2xl d-block mt-4"></i>icon-arrow-up-circle
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-left-circle icons font-2xl d-block mt-4"></i>icon-arrow-left-circle
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-right-circle icons font-2xl d-block mt-4"></i>icon-arrow-right-circle
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-arrow-down-circle icons font-2xl d-block mt-4"></i>icon-arrow-down-circle
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-check icons font-2xl d-block mt-4"></i>icon-check
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-clock icons font-2xl d-block mt-4"></i>icon-clock
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-plus icons font-2xl d-block mt-4"></i>icon-plus
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-close icons font-2xl d-block mt-4"></i>icon-close
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-trophy icons font-2xl d-block mt-4"></i>icon-trophy
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-screen-smartphone icons font-2xl d-block mt-4"></i>icon-screen-smartphone
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-screen-desktop icons font-2xl d-block mt-4"></i>icon-screen-desktop
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-plane icons font-2xl d-block mt-4"></i>icon-plane
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-notebook icons font-2xl d-block mt-4"></i>icon-notebook
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-mustache icons font-2xl d-block mt-4"></i>icon-mustache
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-mouse icons font-2xl d-block mt-4"></i>icon-mouse
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-magnet icons font-2xl d-block mt-4"></i>icon-magnet
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-energy icons font-2xl d-block mt-4"></i>icon-energy
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-disc icons font-2xl d-block mt-4"></i>icon-disc
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-cursor icons font-2xl d-block mt-4"></i>icon-cursor
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-cursor-move icons font-2xl d-block mt-4"></i>icon-cursor-move
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-crop icons font-2xl d-block mt-4"></i>icon-crop
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-chemistry icons font-2xl d-block mt-4"></i>icon-chemistry
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-speedometer icons font-2xl d-block mt-4"></i>icon-speedometer
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-shield icons font-2xl d-block mt-4"></i>icon-shield
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-screen-tablet icons font-2xl d-block mt-4"></i>icon-screen-tablet
          </b-col>

          <b-col> cols="6" sm="4" md="3">
            <i class="icon-magic-wand icons font-2xl d-block mt-4"></i>icon-magic-wand
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-hourglass icons font-2xl d-block mt-4"></i>icon-hourglass
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-graduation icons font-2xl d-block mt-4"></i>icon-graduation
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-ghost icons font-2xl d-block mt-4"></i>icon-ghost
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-game-controller icons font-2xl d-block mt-4"></i>icon-game-controller
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-fire icons font-2xl d-block mt-4"></i>icon-fire
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-eyeglass icons font-2xl d-block mt-4"></i>icon-eyeglass
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-envelope-open icons font-2xl d-block mt-4"></i>icon-envelope-open
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-envelope-letter icons font-2xl d-block mt-4"></i>icon-envelope-letter
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-bell icons font-2xl d-block mt-4"></i>icon-bell
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-badge icons font-2xl d-block mt-4"></i>icon-badge
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-anchor icons font-2xl d-block mt-4"></i>icon-anchor
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-wallet icons font-2xl d-block mt-4"></i>icon-wallet
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-vector icons font-2xl d-block mt-4"></i>icon-vector
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-speech icons font-2xl d-block mt-4"></i>icon-speech
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-puzzle icons font-2xl d-block mt-4"></i>icon-puzzle
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-printer icons font-2xl d-block mt-4"></i>icon-printer
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-present icons font-2xl d-block mt-4"></i>icon-present
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-playlist icons font-2xl d-block mt-4"></i>icon-playlist
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-pin icons font-2xl d-block mt-4"></i>icon-pin
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-picture icons font-2xl d-block mt-4"></i>icon-picture
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-handbag icons font-2xl d-block mt-4"></i>icon-handbag
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-globe-alt icons font-2xl d-block mt-4"></i>icon-globe-alt
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-globe icons font-2xl d-block mt-4"></i>icon-globe
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-folder-alt icons font-2xl d-block mt-4"></i>icon-folder-alt
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-folder icons font-2xl d-block mt-4"></i>icon-folder
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-film icons font-2xl d-block mt-4"></i>icon-film
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-feed icons font-2xl d-block mt-4"></i>icon-feed
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-drop icons font-2xl d-block mt-4"></i>icon-drop
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-drawer icons font-2xl d-block mt-4"></i>icon-drawer
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-docs icons font-2xl d-block mt-4"></i>icon-docs
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-doc icons font-2xl d-block mt-4"></i>icon-doc
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-diamond icons font-2xl d-block mt-4"></i>icon-diamond
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-cup icons font-2xl d-block mt-4"></i>icon-cup
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-calculator icons font-2xl d-block mt-4"></i>icon-calculator
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-bubbles icons font-2xl d-block mt-4"></i>icon-bubbles
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-briefcase icons font-2xl d-block mt-4"></i>icon-briefcase
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-book-open icons font-2xl d-block mt-4"></i>icon-book-open
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-basket-loaded icons font-2xl d-block mt-4"></i>icon-basket-loaded
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-basket icons font-2xl d-block mt-4"></i>icon-basket
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-bag icons font-2xl d-block mt-4"></i>icon-bag
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-action-undo icons font-2xl d-block mt-4"></i>icon-action-undo
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-action-redo icons font-2xl d-block mt-4"></i>icon-action-redo
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-wrench icons font-2xl d-block mt-4"></i>icon-wrench
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-umbrella icons font-2xl d-block mt-4"></i>icon-umbrella
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-trash icons font-2xl d-block mt-4"></i>icon-trash
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-tag icons font-2xl d-block mt-4"></i>icon-tag
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-support icons font-2xl d-block mt-4"></i>icon-support
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-frame icons font-2xl d-block mt-4"></i>icon-frame
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-size-fullscreen icons font-2xl d-block mt-4"></i>icon-size-fullscreen
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-size-actual icons font-2xl d-block mt-4"></i>icon-size-actual
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-shuffle icons font-2xl d-block mt-4"></i>icon-shuffle
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-share-alt icons font-2xl d-block mt-4"></i>icon-share-alt
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-share icons font-2xl d-block mt-4"></i>icon-share
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-rocket icons font-2xl d-block mt-4"></i>icon-rocket
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-question icons font-2xl d-block mt-4"></i>icon-question
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-pie-chart icons font-2xl d-block mt-4"></i>icon-pie-chart
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-pencil icons font-2xl d-block mt-4"></i>icon-pencil
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-note icons font-2xl d-block mt-4"></i>icon-note
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-loop icons font-2xl d-block mt-4"></i>icon-loop
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-home icons font-2xl d-block mt-4"></i>icon-home
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-grid icons font-2xl d-block mt-4"></i>icon-grid
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-graph icons font-2xl d-block mt-4"></i>icon-graph
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-microphone icons font-2xl d-block mt-4"></i>icon-microphone
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-music-tone-alt icons font-2xl d-block mt-4"></i>icon-music-tone-alt
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-music-tone icons font-2xl d-block mt-4"></i>icon-music-tone
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-earphones-alt icons font-2xl d-block mt-4"></i>icon-earphones-alt
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-earphones icons font-2xl d-block mt-4"></i>icon-earphones
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-equalizer icons font-2xl d-block mt-4"></i>icon-equalizer
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-like icons font-2xl d-block mt-4"></i>icon-like
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-dislike icons font-2xl d-block mt-4"></i>icon-dislike
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-control-start icons font-2xl d-block mt-4"></i>icon-control-start
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-control-rewind icons font-2xl d-block mt-4"></i>icon-control-rewind
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-control-play icons font-2xl d-block mt-4"></i>icon-control-play
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-control-pause icons font-2xl d-block mt-4"></i>icon-control-pause
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-control-forward icons font-2xl d-block mt-4"></i>icon-control-forward
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-control-end icons font-2xl d-block mt-4"></i>icon-control-end
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-volume-1 icons font-2xl d-block mt-4"></i>icon-volume-1
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-volume-2 icons font-2xl d-block mt-4"></i>icon-volume-2
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-volume-off icons font-2xl d-block mt-4"></i>icon-volume-off
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-calendar icons font-2xl d-block mt-4"></i>icon-calendar
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-bulb icons font-2xl d-block mt-4"></i>icon-bulb
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-chart icons font-2xl d-block mt-4"></i>icon-chart
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-ban icons font-2xl d-block mt-4"></i>icon-ban
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-bubble icons font-2xl d-block mt-4"></i>icon-bubble
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-camrecorder icons font-2xl d-block mt-4"></i>icon-camrecorder
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-camera icons font-2xl d-block mt-4"></i>icon-camera
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-cloud-download icons font-2xl d-block mt-4"></i>icon-cloud-download
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-cloud-upload icons font-2xl d-block mt-4"></i>icon-cloud-upload
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-envelope icons font-2xl d-block mt-4"></i>icon-envelope
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-eye icons font-2xl d-block mt-4"></i>icon-eye
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-flag icons font-2xl d-block mt-4"></i>icon-flag
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-heart icons font-2xl d-block mt-4"></i>icon-heart
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-info icons font-2xl d-block mt-4"></i>icon-info
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-key icons font-2xl d-block mt-4"></i>icon-key
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-link icons font-2xl d-block mt-4"></i>icon-link
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-lock icons font-2xl d-block mt-4"></i>icon-lock
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-lock-open icons font-2xl d-block mt-4"></i>icon-lock-open
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-magnifier icons font-2xl d-block mt-4"></i>icon-magnifier
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-magnifier-add icons font-2xl d-block mt-4"></i>icon-magnifier-add
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-magnifier-remove icons font-2xl d-block mt-4"></i>icon-magnifier-remove
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-paper-clip icons font-2xl d-block mt-4"></i>icon-paper-clip
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-paper-plane icons font-2xl d-block mt-4"></i>icon-paper-plane
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-power icons font-2xl d-block mt-4"></i>icon-power
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-refresh icons font-2xl d-block mt-4"></i>icon-refresh
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-reload icons font-2xl d-block mt-4"></i>icon-reload
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-settings icons font-2xl d-block mt-4"></i>icon-settings
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-star icons font-2xl d-block mt-4"></i>icon-star
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-symbol-female icons font-2xl d-block mt-4"></i>icon-symbol-female
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-symbol-male icons font-2xl d-block mt-4"></i>icon-symbol-male
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-target icons font-2xl d-block mt-4"></i>icon-target
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-credit-card icons font-2xl d-block mt-4"></i>icon-credit-card
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-paypal icons font-2xl d-block mt-4"></i>icon-paypal
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-tumblr icons font-2xl d-block mt-4"></i>icon-social-tumblr
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-twitter icons font-2xl d-block mt-4"></i>icon-social-twitter
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-facebook icons font-2xl d-block mt-4"></i>icon-social-facebook
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-instagram icons font-2xl d-block mt-4"></i>icon-social-instagram
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-linkedin icons font-2xl d-block mt-4"></i>icon-social-linkedin
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-pinterest icons font-2xl d-block mt-4"></i>icon-social-pinterest
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-github icons font-2xl d-block mt-4"></i>icon-social-github
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-gplus icons font-2xl d-block mt-4"></i>icon-social-gplus
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-reddit icons font-2xl d-block mt-4"></i>icon-social-reddit
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-skype icons font-2xl d-block mt-4"></i>icon-social-skype
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-dribbble icons font-2xl d-block mt-4"></i>icon-social-dribbble
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-behance icons font-2xl d-block mt-4"></i>icon-social-behance
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-foursqare icons font-2xl d-block mt-4"></i>icon-social-foursqare
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-soundcloud icons font-2xl d-block mt-4"></i>icon-social-soundcloud
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-spotify icons font-2xl d-block mt-4"></i>icon-social-spotify
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-stumbleupon icons font-2xl d-block mt-4"></i>icon-social-stumbleupon
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-youtube icons font-2xl d-block mt-4"></i>icon-social-youtube
          </b-col>

          <b-col cols="6" sm="4" md="3">
            <i class="icon-social-dropbox icons font-2xl d-block mt-4"></i>icon-social-dropbox
          </b-col>
        </b-row><!--/.row-->
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'simple-line-icons'
}
</script>
