<template>
      <b-nav-item-dropdown right no-caret>
        <template slot="button-content" class="">
          <!--<img src="/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">-->
          <p class="p__inline">{{userName}}</p>
          <i class="icon-logout pdr5"></i>
        </template>
        <!--<b-dropdown-header tag="div" class="text-center"><strong>Account</strong></b-dropdown-header>-->
        <!--<b-dropdown-item><i class="fa fa-bell-o"></i> Updates<b-badge variant="info">{{itemsCount}}</b-badge></b-dropdown-item>-->
        <!--<b-dropdown-item><i class="fa fa-envelope-o"></i> Messages<b-badge variant="success">{{itemsCount}}</b-badge></b-dropdown-item>-->
        <!--<b-dropdown-item><i class="fa fa-tasks"></i> Tasks<b-badge variant="danger">{{itemsCount}}</b-badge></b-dropdown-item>-->
        <!--<b-dropdown-item><i class="fa fa-comments"></i> Comments<b-badge variant="warning">{{itemsCount}}</b-badge></b-dropdown-item>-->
        <!--<b-dropdown-header tag="div" class="text-center"><strong>Settings</strong></b-dropdown-header>-->
        <!--<b-dropdown-item><i class="fa fa-user"></i> Profile</b-dropdown-item>-->
        <!--<b-dropdown-item><i class="fa fa-wrench"></i> Settings</b-dropdown-item>-->
        <!--<b-dropdown-item><i class="fa fa-usd"></i> Payments<b-badge variant="light">{{itemsCount}}</b-badge></b-dropdown-item>-->
        <!--<b-dropdown-item><i class="fa fa-file"></i> Projects<b-badge variant="primary">{{itemsCount}}</b-badge></b-dropdown-item>-->
        <!--<b-dropdown-divider></b-dropdown-divider>-->
        <!--<b-dropdown-item><i class="fa fa-shield"></i> Lock Account</b-dropdown-item>-->
        <b-dropdown-item @click="logout"><i class="fa fa-lock"></i> Выход </b-dropdown-item>
      </b-nav-item-dropdown>
</template>
<script>
  export default {
    name: 'header-dropdown-accnt',
    data: () => {
      return {
        itemsCount: 42,
        userName: localStorage.getItem('userName')
      }
    },
    mounted: function () {

    },
    methods: {
      logout: function () {
        localStorage.clear()
        this.$cookie.delete('authToken')
        this.$cookie.delete('role')
        this.$cookie.delete('guid')
        this.$cookie.delete('isAdmin')
        this.$router.push('/auth/login')
        location.reload()
      }
    }
  }
</script>
