<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание роли</strong>
          </template>
          <b-form-group
            label="Наименование"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-table :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
            <template slot="isRead" slot-scope="data">
              <label class="mgt9 switch switch-3d switch-primary">
                <input type="checkbox" v-model="data.item.isRead" class="switch-input" checked>
                <span class="switch-label"></span>
                <span class="switch-handle"></span>
              </label>
            </template>
            <template slot="isWrite" slot-scope="data">
              <label class="mgt9 switch switch-3d switch-primary" v-if="data.item.isRead === true && !features.includes(data.item.keyWord)">
                <input type="checkbox" v-model="data.item.isWrite" class="switch-input" checked>
                <span class="switch-label"></span>
                <span class="switch-handle"></span>
              </label>
              <label class="mgt9 switch switch-3d switch-primary" v-if="data.item.isRead === false && !features.includes(data.item.keyWord)">
                <input type="checkbox" class="switch-input" disabled>
                <span class="switch-label" disabled></span>
                <span class="switch-handle" disabled></span>
              </label>
            </template>
            <template slot="isUpdate" slot-scope="data">
              <label class="mgt9 switch switch-3d switch-primary" v-if="data.item.isRead === true && !features.includes(data.item.keyWord)">
                <input type="checkbox" v-model="data.item.isUpdate" class="switch-input" checked>
                <span class="switch-label"></span>
                <span class="switch-handle"></span>
              </label>
              <label class="mgt9 switch switch-3d switch-primary" v-if="data.item.isRead === false && !features.includes(data.item.keyWord)">
                <input type="checkbox" class="switch-input" disabled>
                <span class="switch-label" disabled></span>
                <span class="switch-handle" disabled></span>
              </label>
            </template>
            <template slot="isDelete" slot-scope="data">
              <label class="mgt9 switch switch-3d switch-primary" v-if="data.item.isRead === true && !features.includes(data.item.keyWord)">
                <input type="checkbox" v-model="data.item.isDelete" class="switch-input" checked>
                <span class="switch-label"></span>
                <span class="switch-handle"></span>
              </label>
              <label class="mgt9 switch switch-3d switch-primary" v-if="data.item.isRead === false && !features.includes(data.item.keyWord)">
                <input type="checkbox" class="switch-input" disabled>
                <span class="switch-label" disabled></span>
                <span class="switch-handle" disabled></span>
              </label>
            </template>
          </b-table>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Роль успешно создана">
      <p class="my-4">Роль успешно создана</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import loading from 'vue-full-loading'

  export default {
    name: 'New',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    components: {
      loading,
      Multiselect,
      MaskedInput
    },
    computed: {
      state4 () {
        return validation.long(this.model.name)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state4 === true) {
          _this.show = true
          _this.model.sectionAccess = []
          _this.model.sectionAccess = [..._this.reference]
          _this.model.sectionAccess.forEach((item) => {
            if (item.isRead === false) {
              item.isWrite = false
              item.isUpdate = false
              item.isDelete = false
            }
          })
          api.post(_this.urls.role, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/role/list')
              }, 1000)
            }
          })
        }
      },
      getSections () {
        let _this = this
        api.get(_this.urls.reference).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.id = spec.id
              temp.name = spec.name
              temp.keyWord = spec.keyWord
              temp.isRead = true
              temp.isWrite = true
              temp.isUpdate = true
              temp.isDelete = true
              _this.reference.push(temp)
            })
          }
        }).then(() => {
          _this.items = _this.reference
        })
      }
    },
    watch: {
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'userRole'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getSections()
    },
    data () {
      return {
        model: {
          sectionAccess: []
        },
        currentPage: 1,
        perPage: 100,
        totalRows: 0,
        delItem: 0,
        items: [
        ],
        accessList: {},
        fields: [
          {key: 'name', label: 'Наименование'},
          {key: 'isRead', label: 'Чтение', 'class': 'w150'},
          {key: 'isWrite', label: 'Создание', 'class': 'w150'},
          {key: 'isUpdate', label: 'Изменение', 'class': 'w150'},
          {key: 'isDelete', label: 'Удаление', 'class': 'w150'}
        ],
        date: '',
        reference: [
        ],
        urls: {
          role: 'role',
          reference: 'reference'
        },
        check: 'true',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation,
        features: [
          'requestType',
          'status',
          'premiseType',
          'contactType',
          'networkType',
          'meterType',
          'postponeService'
        ]
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
