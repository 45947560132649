<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Редактирование компании</strong>
          </template>
          <b-form-group
            label="Наименование компании"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name ? model.name.trim() : '')"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="ИНН"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.inn ? model.inn.trim() : '')"
            :state="state2">
            <b-form-input id="id" type="text" :state="state2" v-model="model.inn"></b-form-input>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer :title="titleText">
      <p class="my-4">{{ resultText }}</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.model.name ? this.model.name.trim() : '')
      },
      state2 () {
        return validation.long(this.model.inn ? this.model.inn.trim() : '')
      }
    },
    methods: {
      sendForm () {
        if (this.state1 && this.state2) {
          this.show = true
          api.put(this.urls.company + '/' + this.$route.params.id, this.model).then((response) => {
            if (response.status) {
              if (response.data.error) {
                this.resultText = response.data.error.description
                this.titleText = 'Ошибка редактирования компании'
                this.model.inn = ''
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.show = false
                }, 3000)
              } else {
                this.resultText = 'Компания успешно изменён'
                this.titleText = 'Компания успешно изменён'
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.$router.push({ path: '/company/list', query: { currentPage: this.$route.query.currentPage } })
                  this.show = false
                }, 1000)
              }
            }
          })
        }
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'company'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },
    mounted () {
      api.get(this.urls.company + '/' + this.$route.params.id).then((response) => {
        let jData = response.data
        this.model = jData.data
      })
    },
    data () {
      return {
        model: {
        },
        accessList: {},
        date: '',
        posts: [],
        urls: {
          company: 'company'
        },
        resultText: '',
        titleText: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>

