<template>
  <div class="app">
    <div class="loader" v-if="!isLoad">
      <div class="loader__content">
        <div class="loader__text">
          Maжордом
        </div>
        <div class="loader__container">
          <div class="loader__cover"></div>
          <div class="loader__container_white-border"></div>
        </div>
      </div>
    </div>
    <AppHeader/>
    <div class="app-body">
      <Sidebar :navItems="navbar"/>
      <main class="main">
        <breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside/>
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import nav from '../_nav'
import { AppHeader, Sidebar, Aside as AppAside, Footer as AppFooter, Breadcrumb } from '../components/'
import vueCookie from 'vue-cookie'
import consts from '../config/const.js'
import api from '../config/api.js'
import accessService from '/src/assets/scripts/services/access.js'

export default {
  name: 'full',
  components: {
    AppHeader,
    Sidebar,
    AppAside,
    AppFooter,
    Breadcrumb
  },
  data () {
    return {
      nav: nav.items,
      navbar: [],
      model: [],
      urls: {
        list: 'servicerequest',
        chat: 'chat/list'
      },
      isLoad: false,
      isLoadMessagesCounter: false,
      consts: consts,
      navItems: nav.items,
      blockList: [],
      updateMessages: null,
      unreadMsgCount: 0
    }
  },
  sockets: {
    connect () {
      console.log('socket connected')
    },
    adminAllUnread (msg) {
      if (msg.type === 'all') {
        document.getElementsByClassName('chat')[0].firstChild.firstChild.lastElementChild.innerHTML = parseInt(msg.countAllUnread)
      }
      this.isLoadMessagesCounter = true
    },
    read (msg) {
      this.$socket.emit('adminAllUnread', {type: 'all'})
    },
    message (msg) {
      this.$socket.emit('adminAllUnread', {type: 'all'})
    },
    newRequestCount (data) {
      this.fillServiceRequestCounter(data.count)
    }
  },
  beforeCreate () {
    global.socketObj.io.opts.query.auth_token = vueCookie.get('authToken').slice(4)
    global.socketObj.disconnect()
    global.socketObj.connect()
  },
  created () {
    api.get('role/list').then((response) => {
      this.blockList = response.data.data
    }).then(() => {
      const role = vueCookie.get('role')
      this.nav.forEach((item, index, array) => {
        if (item.keyWord === 'menuDelimeter') {
          let check = false
          item.keyArray.forEach((elem) => {
            if (this.blockList.indexOf(elem) === -1) {
              check = true
            }
          })
          if (check) {
            this.navbar.push(item)
          }
        } else if (item.keyWord === 'serviceRequest') {
          if (!accessService.checkServiceRequestIsBlocked()) {
            this.navbar.push(item)
          }
        } else if (item.keyWord === 'premise' && role !== 'admin') {
          return;
        } else if (['intercom', 'barrier', 'housingComplex'].includes(item.keyWord)) {
          return;
        } else if (this.blockList.indexOf(item.keyWord) === -1) {
          this.navbar.push(item)
        }
      })
    })
  },
  async mounted () {
    this.updateMessages = setInterval(() => {
      this.$socket.emit('adminAllUnread', {type: 'all'})
      if (this.isLoadMessagesCounter) {
        clearInterval(this.updateMessages)
      }
    }, 2000)

    this.loadRequestCount()

    setTimeout(() => {
      this.isLoad = true
    }, 2500)
  },
  methods: {
    fillServiceRequestCounter (count) {
      if (count === undefined || count === null) return
      try {
        document.getElementsByClassName('requests')[0].firstChild.firstChild.lastElementChild.innerHTML = count
      } catch {
        setTimeout(() => {
          this.fillServiceRequestCounter(count)
          console.log('Не удалось отобразить данные счетчика заявок, следующая попытка через 0.5сек')
        }, 500);
      }
    },
    
    loadRequestCount () {
      api.get('Statistics/newbid').then((response) => {
        this.fillServiceRequestCounter(response.data.data.count)
        this.isLoad = true
      })
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched
    }
  },
  beforeDestroy () {
    this.$socket.removeListener('read')
    this.$socket.removeListener('message')
    this.$socket.removeListener('adminAllUnread')
    this.$socket.removeListener('newRequestCount')
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Rubik:700&subset=cyrillic');
</style>
