<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
              <strong>Рабочие сессии сотрудника: <b-link :to="{ path: '/employee/edit/' + $route.params.id }">
                {{ employeeName }}
              </b-link>
              </strong>
          </template>
          <div class="container ml-0 mb-3 mt-3">
            <div class="spinner" v-if="!showTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
            <div class="table_block">
              <b-table id="table_log"
                       :items="items"
                       :fields="fields"
                       :per-page="perPage"
                       :current-page="1"
                       v-if="showTable"
              >
                <template slot="id" slot-scope="data">
                  {{ data.item.id }}
                </template>
                <template slot="createdAt" slot-scope="data">
                  {{ formatDate(data.item.createdAt) }}
                </template>
                <template slot="userAgent" slot-scope="data">
                  {{ data.item.userAgent }}
                </template>
              </b-table>

              <p v-if="showTable && !totalRows">Нет записей.</p>

              <b-pagination
                v-if="showTable && totalRows > 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="table_log"
                @input="changePage()"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'

  export default {
    name: 'UserLoginHistory',
    data () {
      return {
        items: [],
        fields: [
          {key: 'createdAt', label: 'Дата и время входа', thStyle: {'width': '20%'}},
          {key: 'userAgent', label: 'User-Agent'}
        ],
        currentPage: 1,
        perPage: 10,
        totalRows: 0,
        showTable: false,
        employeeName: null
      }
    },
    methods: {
      getData () {
        this.showTable = false
        api.get('employee/' + this.$route.params.id + '/loginLog', {page: this.currentPage, limit: this.perPage})
          .then(response => {
            // console.log(response)
            const data = response.data
            if (data.status === true) {
              this.items = data.data
              this.totalRows = data.meta.pages * this.perPage
              this.showTable = true

              api.get('employee/' + this.$route.params.id).then(response => {
                const data = response.data
                if (data && data.status === true) {
                  this.employeeName = data.data.firstName + ' ' + data.data.lastName
                }
              }).catch(error => {
                console.log(error)
                this.showTable = false
              })
            } else {
              this.totalRows = 0
              this.showTable = false
            }
          })
          .catch(error => {
            console.log(error)
            this.showTable = false
          })
      },
      formatDate (value) {
        if (value) {
          return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
        }
      },
      changePage () {
        this.getData()
        window.scrollTo(0, 0)
      }
    },
    mounted () {
      this.getData()
    }
  }
</script>
