<template>
  <b-card :header="caption">
   <b-row>
      <b-col lg="3">
        <b-form-group
          label="Дата среза данных"
          label-for="serviceDate"
          :label-cols="4"
          :invalid-feedback="validation.dateSelect(date.history)"
          :state="state1">
          <datetime type="date"
                    id="serviceDate"
                    v-model="date.history"
                    class="theme-orange dateTimePick__report_width"
                    input-class="dataTimePick__report"
                    placeholder="Дата среза данных"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}"
                    v-bind:class="{invalid__dateTime: !state1, valid__dateTime: state1 === true}">
          </datetime>
        </b-form-group>
      </b-col>
      <b-col lg="3">
        <b-form-group
          label="Жилищный комплекс"
          label-for="housingComplex"
          :label-cols="4"
          :invalid-feedback="validation.notSelect(housingComplexId)"
          :state="state2">
          <b-form-select id="housingComplex"
                          :options="hcList"
                          placeholder="Выберите ЖК"
                          v-model="housingComplexId"
                          :state="state2">
            <template slot="first">
              <option :value="null" disabled>Выберите ЖК</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="not_data__table" v-if="viewError">Отчёты не найдены! Укажите срез даты и жилищный комлекс, для получения отчёта!</div>
    <div class="not_data__table" v-if="viewEmpty">Отчёты не найдены! Выберите другой срез даты и жилищный комлекс, для получения отчёта!</div>
    <div class="spinner" v-if="!viewTable && !viewEmpty && !viewError"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
    <b-table v-if="viewTable && !viewError && !viewEmpty" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="true" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
    </b-table>
    <nav class="d-flex">
      <b-pagination :total-rows="getRowCount(items)" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/> 
    </nav>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import validation from '../../config/validation'

  export default {
    name: 'c-table',
    components: {
      datetime: Datetime,
      Datepicker
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      state1 () {
        return validation.dateSelectBool(this.date.history)
      },
      state2 () {
        return validation.numId(this.housingComplexId)
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'report'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data: () => {
      return {
        items: [
        ],
        hcList: [],
        accessList: {},
        fields: [
          {key: 'address', label: 'Адрес здания', class: 'width_address'},
          {key: 'hot', label: 'ГВС'},
          {key: 'cold', label: 'ХВС'},
          {key: 'electro', label: 'Электричество'},
          {key: 'heat', label: 'Тепло'}
        ],
        date: {
          history: ''
        },
        housingComplexId: 0,
        currentPage: 1,
        perPage: 20,
        totalRows: 0,
        viewError: true,
        viewEmpty: false,
        viewTable: false,
        urls: {
          hc: 'housingcomplex',
          list: 'report/meter'
        },
        validation: validation
      }
    },
    watch: {
      'date.history': function () {
        let _this = this
        _this.getData()
      },
      housingComplexId: function () {
        let _this = this
        _this.getData()
      }
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      getHousingComplex () {
        let _this = this
        api.get(_this.urls.hc).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.hcList.push(temp)
            })
          }
        })
      },
      getData () {
        let _this = this
        let query = {}
        if (_this.state1 === true && _this.state2 === true) {
          _this.viewError = false
          _this.viewEmpty = false
          _this.viewTable = false
          query = {
            type: 'house',
            hcId: _this.housingComplexId,
            timestamp: moment(_this.date.history).format('X')
          }
          api.get(_this.urls.list, query).then(function (response) {
            _this.items = []
            let jData = response.data
            if (jData.status) {
              _this.items = [...jData.data]
            }
          }).then(() => {
            if (_this.items.length === 0) {
              _this.viewEmpty = true
            } else {
              _this.viewTable = true
              _this.viewEmpty = false
            }
          })
        } else {
          _this.viewError = true
          _this.viewEmpty = false
        }
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      }
    },
    mounted: function () {
      let _this = this
      _this.getHousingComplex()
    }
  }
</script>
