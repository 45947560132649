<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">403</h1>
            <h4 class="pt-3">FORBIDDEN</h4>
            <p class="text-muted">You don't have permission to access for this page!</p>
          </div>
          <b-btn size="sm" class="btn-success" @click.stop="click()">На главную</b-btn>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page403',
  methods: {
    click () {
      this.$router.push('/')
    }
  }
}
</script>
