<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>
          <b-form-group
            label="Наименование чата"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="stateName">
            <b-form-input id="id" type="text" :state="stateName" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Город"
            label-for="cities"
            :label-cols="3"
            :horizontal="true">
            <b-form-select id="cities"
                           :options="cities"
                           v-model="model.cityId">
              <template slot="first">
                <option :value="0" disabled>Выберите город</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Объекты"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <div v-for="(hc, i) in treeHC">
              <b-form-checkbox-group stacked id="basicCustomCheckboxes">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" v-model="hc.checked" :id="`node${hc.id}`" value="true" true-value="true" falser-value="false">
                  <label class="custom-control-label" :for="`node${hc.id}`" @click.self="actionAll(hc)">
                    <div @click.self.prevent="toggleNode(hc)">
                      {{ hc.name }}
                    </div>
                  </label>
                </div>
              </b-form-checkbox-group>
              <div v-for="obj in hc.buildings" v-show="hc.isOpen" class="tree-branch">
                <b-form-checkbox-group stacked id="basicCustomCheckboxes">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" v-model="obj.checked" :id="`branch${obj.id}`" value="true" true-value="true" falser-value="false">
                    <label class="custom-control-label" :for="`branch${obj.id}`"  @click.self="lastCheckHandler(hc, obj.checked)">
                      <div>
                        {{ obj.name }}
                      </div>
                    </label>
                  </div>
                </b-form-checkbox-group>
              </div>
            </div>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Чат успешно сохранен">
      <p class="my-4">Чат успешно сохранен</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'Item',
    components: {
      loading
    },
    watch: {
      'model.cityId' (val, old) {
        // val - это cityId в model.cityId
        if ((old !== 0 && this.chatId) || this.chatId === undefined) {
          this.getHousingComplex()
        }
      }
    },
    data () {
      return {
        model: {
          chatTypeId: 5,
          name: '',
          cityId: 0
        },
        chatId: this.$route.params.id,
        accessList: {},
        date: '',
        posts: [],
        cities: [],
        treeHC: [],
        streetList: [],
        hcList: [],
        chatType: [],
        urls: {
          chat: 'chat',
          chatTheme: 'chat/theme',
          chattype: 'chattype',
          treeHC: 'housingcomplex/tree',
          city: 'city'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    },
    computed: {
      stateName () {
        return validation.long(this.model.name)
      },
      stateCity () {
        return validation.numId(this.model.cityId)
      },
      pageTitle () {
        return this.chatId ? 'Редактирование чата' : 'Создание чата'
      }
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      sendForm () {
        if (this.stateName) {
          this.show = true
          let promise = null
          this.model.objects = this.treeHC
          if (this.chatId) {
            promise = api.put(this.urls.chatTheme + '/' + this.$route.params.id, this.model)
          } else {
            promise = api.post(this.urls.chat, this.model)
          }
          promise.then(response => {
            this.show = false
            if (response.status) {
              this.$refs.myModalRef.show()
              setTimeout(() => {
                this.$refs.myModalRef.hide()
                this.$router.push('/chats/list')
              }, 1000)
            }
          })
        }
      },
      getHousingComplex () {
        let query = {}
        if (this.model.cityId > 0) {
          query.cityId = this.model.cityId
        }
        api.get(this.urls.treeHC, query).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.treeHC = jData.data.map(item => {
              item.isOpen = true
              return item
            })
          }
        })
      },
      toggleNode (node) {
        node.isOpen = !node.isOpen
      },
      actionAll (node) {
        node.buildings.forEach(item => {
          item.checked = !node.checked
        })
      },
      lastCheckHandler (node, checked) {
        let checkedItems = node.buildings.filter(item => item.checked)
        if (checkedItems.length <= node.buildings.length && checked) {
          node.checked = false
        } else if (checkedItems.length === node.buildings.length - 1 && !checked) {
          node.checked = true
        }
      },
      getCities () {
        api.get(this.urls.city).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name + ' [' + spec.utc + ']'
                this.cities.push(temp)
              }
            })
          }
        })
      }
    },
    mounted () {
      this.getCities()
      if (this.chatId) {
        api.get(this.urls.chatTheme + '/' + this.$route.params.id).then((response) => {
          let jData = response.data
          jData.data.objects.map(item => {
            item.isOpen = true
            return item
          })
          this.model = jData.data
          this.treeHC = this.model.objects
        })
      } else {
        this.getHousingComplex()
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'chat'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    }
  }
</script>
