<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="4">
          <b-card-group @keypress="formKeyPress">
            <b-card no-body class="p-4">
              <b-card-body>
                <h1>Вход</h1>
                <p class="text-muted">УК Железно, личный кабинет подрядчика</p>
                <b-input-group class="mb-3">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-user"></i></span></div>
                  <masked-input
                    type="tel"
                    name="phone"
                    class="form-control"
                    v-model="phone"
                    :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
                    :guide="true"
                    :showMask="true"
                    :keepCharPositions="true"
                  >
                  </masked-input>
                </b-input-group>
                <b-input-group class="mb-4">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-lock"></i></span></div>
                  <input type="password" class="form-control" v-model="password" placeholder="Password">
                </b-input-group>
                <b-row>
                  <b-col cols="6">
                    <b-button variant="primary" @click="sendLoginForm" class="px-4">Войти</b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-card-group>
          <b-modal ref="myModalRef" hide-footer title="Неверный логин или пароль">
            <p class="my-4">Неверный логин или пароль</p>
          </b-modal>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import MaskedInput from 'vue-text-mask'
  import api from '../../config/api'
  import consts from '../../config/const.js'

  export default {
    name: 'LoginContractor',
    components: {
      MaskedInput
    },
    data () {
      return {
        phone: '',
        password: '',
        urls: {
          login: 'auth/contractorlogin',
          list: 'servicerequest'
        },
        model: [],
        consts: consts
      }
    },
    methods: {
      sendLoginForm: function () {
        var _this = this
        var phoneMatch = _this.phone.match(/^[+][7]\s[(]\d{3}[)]\s\d{3}[-]\d{2}[-]\d{2}$/)
        var phoneReady = _this.phone.replace(/\D/g, '')
        phoneReady = phoneReady.replace('7', '')
        if (phoneMatch !== null && _this.password) {
          api.post(_this.urls.login, {
            phone: phoneReady,
            password: _this.password
          }).then(function (response) {
            let jData = response.data
            if (jData.status) {
              // status true..login..saveingTokenToCookie
              _this.$cookie.set('authToken', jData.data.token, 1)
              _this.$cookie.set('role', jData.data.role, 1)
              _this.$cookie.set('guid', jData.data.client.guid, 1)
              _this.$store.state.user = jData.data
              localStorage.setItem('userId', jData.data.id)
              localStorage.setItem('userName', jData.data.firstName + ' ' + jData.data.lastName)
              if (_this.$route.params.id) {
                location.href = `/requests/edit/${_this.$route.params.id}`
              } else {
                location.href = '/requests'
              }
            } else {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
              }, 3000)
            }
          })
        }
        // if ()
      },
      formKeyPress: function (e) {
        if (e.keyCode === 13) {
          this.sendLoginForm()
        }
      }
    },
    mounted: function () {
    }
  }
</script>
