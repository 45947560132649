<template>
  <b-card :header="caption">
    <b-row v-if="accessList.isWrite === true">
      <b-col lg="3">
        <b-btn v-if="false" class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Добавить версию</b-btn>
      </b-col>
      <b-col offset="6" lg="3" class="justify-content-start">
        <b-form-group
          :label-cols="5"
          label="Платформа"
          label-for="housingComplex"
          :horizontal="true">
          <b-form-select id="housingComplex"
                        :options="platforms"
                        placeholder="Выберите платформу"
                        v-model="selectPlatform">
            <template slot="first">
              <option :value="null" disabled>Выберите платформу</option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
      <template slot="index" slot-scope="data">
        {{perPage * (currentPage - 1) + data.index + 1}}
      </template>
      <template slot="buttons" slot-scope="data">
        <div class="table__two_buttons">
          <b-btn v-if="false" size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
          <b-btn size="sm" v-if="accessList.isDelete === true && false" class="change_size btn-danger"  @click.stop="showModal(data.item.id)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
        </div>
      </template>
    </b-table>
    <nav class="d-flex">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
      <label class="d-flex justify-content-center align-items-center mb-3 ml-3">
        Страница: 
        <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
      </label>
    </nav>
    <b-modal ref="myModalRef" hide-footer title="Удаление версии">
      <div class="d-block text-center">
        <h3>Вы действительно хотите удалить запись?</h3>
      </div>
      <b-btn class="mt-3" variant="outline-danger" block @click="del()">Да</b-btn>
      <b-btn class="mt-3" variant="outline-danger" block @click="hideModal">Нет</b-btn>
    </b-modal>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        accessList: {},
        // searchTimer: null,
        // search: '',
        fields: [
          {key: 'index', label: '#'},
          {key: 'versionNumber', label: 'Версия'},
          {key: 'date', label: 'Дата'},
          {key: 'platform', label: 'Платформа'},
          {key: 'buttons', label: ' '}
        ],
        textPage: '1',
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        totalRows: 1,
        delItem: 0,
        urls: {
          list: 'version'
        },
        platforms: [
          {text: 'Все', value: 'all'},
          {text: 'Android', value: 'android'},
          {text: 'iOS', value: 'ios'}
        ],
        selectPlatform: 'all'
      }
    },
    methods: {
      showModal (id) {
        var _this = this
        this.delItem = id
        _this.$refs.myModalRef.show()
      },
      del () {
        var _this = this
        api.delete(_this.urls.list + '/' + _this.delItem).then(function (response) {
          _this.getData()
        })
        this.delItem = 0
        _this.hideModal()
      },
      hideModal () {
        this.$refs.myModalRef.hide()
      },
      clearSearch () {
        this.search = ''
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      newItem () {
        let _this = this
        _this.$router.push('new/')
      },
      detail (id) {
        var _this = this
        _this.$router.push('edit/' + id)
      },
      getData () {
        this.textPage = String(this.currentPage)
        let _this = this
        let query = {
          page: _this.currentPage,
          limit: _this.perPage
        }
        if (this.selectPlatform !== 'all') {
          query.platform = this.selectPlatform
        }
        api.get(_this.urls.list, query).then(function (response) {
          let jMeta = response.data.meta
          _this.lastPage = jMeta.pages
          _this.totalRows = jMeta.pages * _this.perPage - 1
          let jData = response.data
          if (jData.status) {
            _this.items = jData.data
          }
        })
      }
    },
    watch: {
      // search: function () {
      //   let _this = this
      //   clearTimeout(_this.searchTimer)
      //   _this.searchTimer = setTimeout(function () { _this.getData() }, 1000)
      // },
      selectPlatform: function () {
        this.getData()
      },
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'version'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getData()
    }
  }
</script>
