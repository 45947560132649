<template>
  <b-card :header="caption">
    <b-row v-if="accessList.isWrite === true">
      <b-col offset="9" lg="3">
        <div class="input-group mb-3">
          <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
          <div v-if="search.length > 0" class="input-group-append">
            <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
          </div>
          <div v-else class="input-group-append">
            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-table :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
      <template slot="buttons" slot-scope="data">
        <div class="table__one_buttons">
          <b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
        </div>
      </template>
      <!--<template slot="deleteButton" slot-scope="data">-->
      <!--<b-btn size="sm" class="btn-danger" @click.stop="del(data.item.id)">Удалить</b-btn>-->
      <!--</template>-->
    </b-table>
    <nav class="d-flex">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
      <label class="d-flex justify-content-center align-items-center mb-3 ml-3">
        Страница:
        <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
        из {{lastPage}}
      </label>
    </nav>
  </b-card>
</template>

<script>
    import api from '../../config/api'

    export default {
      name: 'c-table',
      props: {
        caption: {
          type: String,
          default: 'Table'
        },
        hover: {
          type: Boolean,
          default: false
        },
        striped: {
          type: Boolean,
          default: false
        },
        bordered: {
          type: Boolean,
          default: false
        },
        small: {
          type: Boolean,
          default: false
        },
        fixed: {
          type: Boolean,
          default: false
        }
      },
      data: () => {
        return {
          items: [
          ],
          accessList: {},
          fields: [
            {key: 'id', label: '#'},
            {key: 'name', label: 'Наименование'},
            {key: 'buttons', label: ' '}
            // {key: 'deleteButton', label: 'Удалить'}
          ],
          searchTimer: null,
          search: '',
          textPage: '1',
          lastPage: 1,
          currentPage: 1,
          perPage: 20,
          totalRows: 1,
          urls: {
            list: 'paymentexecutor'
          }
        }
      },
      methods: {
        clearSearch () {
          this.search = ''
        },
        getBadge (status) {
          return status === 'Active' ? 'success'
            : status === 'Inactive' ? 'secondary'
              : status === 'Pending' ? 'warning'
                : status === 'Banned' ? 'danger' : 'primary'
        },
        getRowCount (items) {
          return items.length
        },
        newItem () {
          let _this = this
          _this.$router.push('new/')
        },
        detail (id) {
          var _this = this
          _this.$router.push('edit/' + id)
        },
        del (id) {
          var _this = this
          api.delete(_this.urls.list + '/' + id).then(function (response) {
            for (let i = 0; i < _this.items.length; i++) {
              if (String(_this.items[i].id) === String(id)) {
                _this.items.splice(i, 1)
              }
            }
          })
        },
        getData () {
          this.textPage = String(this.currentPage)
          let _this = this
          let query = {
            search: _this.search,
            page: _this.currentPage,
            limit: _this.perPage,
            removed: 0
          }
          api.get(_this.urls.list, query).then(function (response) {
            let jMeta = response.data.meta
            _this.totalRows = jMeta.pages * _this.perPage - 1
            _this.lastPage = jMeta.pages
            let jData = response.data
            if (jData.status) {
              _this.items = jData.data
            }
          })
        }
      },
      created: function () {
        let _this = this
        let accsessKey = {
          keyWord: 'payment'
        }
        api.get('role/components/access', accsessKey).then((response) => {
          if (response.data.data.isRead === false) {
            _this.$router.push('/pages/403')
          } else {
            _this.accessList = response.data.data
          }
        })
      },
      watch: {
        search: function () {
          let _this = this
          clearTimeout(_this.searchTimer)
          _this.searchTimer = setTimeout(function () { _this.getData() }, 1000)
        },
        textPage () {
          clearTimeout(this.paginateTimer)
          if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
            this.paginateTimer = setTimeout(() => {
              this.currentPage = parseInt(this.textPage)
            }, 1000)
          }
        }
      },
      mounted: function () {
        let _this = this
        this.getData()
      }
    }
</script>
