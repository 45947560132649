<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание сотрудника</strong>
          </template>
          <b-form-group
            label="Имя"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.firstName)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.firstName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="lastName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.lastName)"
            :state="state5">
            <b-form-input id="lastName" type="text" :state="state5" v-model="model.lastName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Отчество"
            label-for="patronymicName"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="patronymicName" type="text" v-model="model.patronymicName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Роль сотрудника"
            label-for="role"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.roleId)"
            :state="state9">
            <b-form-select id="role"
                           :options="role"
                           placeholder="Выберите роль"
                           v-model="model.roleId"
                           :state="state9">
              <template slot="first">
                <option :value="null" disabled>Выберите роль</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="getRoleKey === 'specialist'"
            label="Должность сотрудника"
            label-for="role"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.postId)"
            :state="state3">
            <b-form-select id="role"
                           :options="post"
                           placeholder="Выберите должность"
                           v-model="model.postId"
                           :state="state3">
              <template slot="first">
                <option :value="null" disabled>Выберите должность</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.email(model.email)"
            :state="state6">
            <b-form-input id="email" type="text" :state="state6" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state7">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
            ></masked-input>
          </b-form-group>
          <b-form-group
            label=""
            label-for="basicCustomCheckboxes"
            :label-cols="3"
            :horizontal="true">
            <b-form-checkbox-group stacked id="basicCustomCheckboxes">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" :disabled="getRoleKey === 'admin'" v-model="isAccess" id="published" value="1" true-value="1" falser-value="0">
                <label class="custom-control-label" for="published">Имеет доступ к ИС</label>
              </div>
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group
            v-if="isAccess"
            label="Пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.pass(model.password, password)"
            :state="state8">
            <b-form-input id="pass" type="password" :state="state8" v-model="model.password"></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="isAccess"
            label="Повторите пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.pass(model.password, password)"
            :state="state8">
            <b-form-input id="twinpass" type="password" :state="state8" v-model="password"></b-form-input>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer :title="titleText">
      <p class="my-4">{{ resultText }}</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import loading from 'vue-full-loading'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput
    },
    computed: {
      state3 () {
        return validation.numId(this.model.postId)
      },
      state4 () {
        return validation.long(this.model.firstName)
      },
      state5 () {
        return validation.long(this.model.lastName)
      },
      state6 () {
        return validation.emailBool(this.model.email)
      },
      state7 () {
        return validation.phoneNumberBool(this.phone)
      },
      state8 () {
        return validation.passBool(this.model.password, this.password)
      },
      state9 () {
        return validation.numId(this.model.roleId)
      },
      getRoleKey () {
        let findItem = this.role.find(item => item.value === this.model.roleId)
        if (findItem) {
          if (findItem.key === 'admin') {
            this.isAccess = '1'
          }
          return findItem.key
        } else {
          return ''
        }
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if ((_this.state3 || _this.getRoleKey !== 'specialist') && _this.state4 && _this.state5 && _this.state6 && _this.state7 && (_this.state8 || !_this.isAccess) && _this.state9) {
          _this.show = true
          if (!_this.isAccess) {
            delete this.model.password
          }
          if (_this.model.patronymicName === '') {
            delete _this.model.patronymicName
          }
          _this.model.phone = _this.phone.replace(/\D/g, '').replace('7', '')
          api.post(_this.urls.employee, _this.model).then((response) => {
            this.show = false
            if (response.status) {
              if (response.data.error) {
                this.resultText = response.data.error.description
                this.titleText = 'Ошибка создания сотрудника'
                this.phone = ''
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.show = false
                }, 3000)
              } else {
                this.resultText = 'Исполнитель успешно создан'
                this.titleText = 'Исполнитель успешно создан'
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.$router.push('/employee/list')
                }, 1000)
              }
            }
          })
        }
      },
      getRole () {
        let _this = this
        api.get(_this.urls.role).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              temp.key = spec.keyWord
              _this.role.push(temp)
            })
          }
        })
      },
      getPost () {
        let _this = this
        api.get(_this.urls.post).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.post.push(temp)
            })
          }
        })
      }
    },
    watch: {
    },
    mounted: function () {
      let _this = this
      _this.getRole()
      _this.getPost()
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'admin'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false || response.data.data.isWrite === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
          email: '',
          roleId: 0,
          password: ''
        },
        accessList: {},
        date: '',
        role: [
        ],
        isAccess: false,
        post: [],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        resultText: '',
        titleText: '',
        urls: {
          employee: 'employee',
          role: 'role',
          post: 'post'
        },
        password: '',
        check: 'true',
        phone: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

