<template>
  <div class="animated fadeIn">
    <div class="chat__button" :class="{ 'chat__button_active': !chatIsOpen && chatIsLoaded }" @click="controlChat(true)">
      <i class="fa fa-comments fa-3x"></i>
      <div class="badge-new">
        <b-badge pill variant="warning">{{ model.count }}</b-badge>
      </div>
    </div>
    <div class="modal-chat" :class="{ 'modal-chat_active': chatIsOpen }">
      <div class="header">
        <div class="chat__close" @click="controlChat(false)">
          <div class="cross">
            <div class="cross_horizontal"></div>
            <div class="cross_vertical"></div>
          </div>
        </div>
        <div class="header__title"> Чат с клиентом </div>
      </div>
      <div class="wrapper" id="chatBody">
        <div v-for="(message, i) in messages" :key="i">
          <div
            v-if="message.user !== null && message.contentText !== '' && message.contentText !== null"
            class="message"
            :class="{'my-message': isUser(message.user.id), 'them-message': !isUser(message.user.id)}">
            {{ message.contentText }}
          </div>
          <div
            v-if="message.user === null && message.contentText !== '' && message.contentText !== null"
            class="message them-message">
            {{ message.contentText }}
          </div>
          <div
            v-if="message.attachment !== null"
            class="message"
            :class="{'my-message': isUser(message.user.id), 'them-message': !isUser(message.user.id)}">
            <viewer :images="imagesMsg" :options="{toolbar: false, navbar: false, zoom: 4}" class="request_picture">
              <img :src="consts.storageUrl + '/' + message.attachment.imgPreview"  alt="hex shorthand color" class="chat__picture">
            </viewer>
          </div>
        </div>
      </div>
    </div>
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-card v-if="model.code">
      <div class="container ml-0 mb-3 mt-3">
        <b-row>
          <b-col md="12">
            <b-alert :show="true" variant="danger">
              <h4 class="alert-heading">Ошибка доступа</h4>
              <p>{{ model.description }}</p>
              <b-btn size="sm" @click.prevent="goBack" class="mt-2" text="Button" variant="success">Вернуться к списку заявок</b-btn>
            </b-alert>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card v-else-if="model.id">
      <div class="container ml-0 mb-3 mt-3">
        <b-row>
          <b-col md="12">
            <template #header>
              <strong>Изменение заявки</strong>
            </template>
            <b-alert :show="model.desiredTermToWork" variant="danger">
              <h4 class="alert-heading">Запрос на изменение срока устранения!</h4>
              <p>
                Вами был отправлен запрос на изменение срока устранения причины заявки. Запрошенная дата: {{ desiredTermToWork }}
              </p>
            </b-alert>
            <b-form-group
              label="Номер заявки"
              label-for="id"
              :label-cols="2"
              label-class="text-left pb-0"
              class="mb-1"
              :horizontal="true">
              <b-form-input id="id" class="pb-0" plaintext type="text" v-model="model.id"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Дата создания"
              label-for="id"
              :label-cols="2"
              label-class="text-left pb-0"
              class="mb-1"
              :horizontal="true">
              <b-form-input id="id" class="pb-0 w_180" plaintext type="text" v-model="date.createdDate"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Автор"
              label-for="id"
              :label-cols="2"
              label-class="text-left pb-0"
              class="mb-1"
              :horizontal="true">
              <b-form-input id="id" class="pb-0" plaintext type="text" v-model="model.author"></b-form-input>
            </b-form-group>
            <fieldset>
              <legend>Информация о клиенте</legend>
              <b-form-group
                label="Адрес"
                label-for="housingComplex"
                :label-cols="1"
                label-class="col-sm-2 col-lg-2 col-xl-1 text-left mt-xl-4"
                class="text-left mb-0"
                :horizontal="true">
                <b-form-group
                  label="Жилой комплекс:"
                  label-for="housingComplex"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0">
                  <b-form-select id="housingComplex"
                    :options="housingComplex"
                    placeholder="Выберите ЖК"
                    v-model="housingComplexId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите ЖК</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Дом:"
                  label-for="building"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0">
                  <b-form-select id="building"
                    :options="building"
                    placeholder="Выберите дом"
                    v-model="buildingId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите дом</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Квартира:"
                  label-for="premise"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left"
                  disabled>
                  <b-form-select id="premise"
                    :options="premise"
                    placeholder="Выберите квартиру"
                    v-model="model.premiseId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите квартиру</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form-group>
              <b-form-group
                label="Клиент"
                label-for="client"
                :label-cols="1"
                label-class="col-sm-2 col-lg-2 col-xl-1 text-left mt-xl-4"
                class="text-left"
                :horizontal="true">
                <b-form-group
                  label="ФИО:"
                  label-for="client"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0">
                  <label ref="containerSelect" class="mb-0">
                    <div class="container__select">
                      <input ref="selectInput" type="text" readonly v-model="model.userName" class="custom_input_text">
                      <div class="select__select_items" :class="{'select__select_items--block': false}">
                        <div id="selectItem" class="select_item" @click="changeSelect(item)" v-for="item in filtredAcommodations" :key="item.id">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </label>
                </b-form-group>
                <b-form-group
                  label="Телефон:"
                  label-for="client"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0">
                  <b-form-input id="id" class="pb-0 w_180" plaintext type="text" v-model="phone"></b-form-input>
                </b-form-group>
              </b-form-group>
            </fieldset>
            <fieldset>
              <legend>Информация о заявке</legend>
              <div class="row">
                <b-form-group
                  label="Заявление по гарантии"
                  label-for="userId"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-input id="id" plaintext type="text" class="w_160" v-model="model.warrantyStatement"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Тип заявки"
                  label-for="requestType"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-xl-5 col-lg-5 col-md-5 сol-12"
                  :horizontal="true">
                  <b-form-select id="requestType"
                    :options="requestTypes"
                    v-model="model.requestTypeId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите тип</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Характер заявки"
                  label-for="requestType"
                  :label-cols="3"
                  label-class="text-left"
                  class="offset-md-1 col-xl-6 col-lg-6 col-md-6 сol-12"
                  :horizontal="true">
                  <b-form-select id="requestType"
                    :options="requestNatures"
                    v-model="model.requestNatId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите характер</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Категория"
                  label-for="category"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-xl-5 col-lg-5 col-md-5 сol-12"
                  :horizontal="true">
                  <b-form-select id="category"
                    :options="categories"
                    v-model="model.categoryId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите категорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Подкатегория"
                  label-for="subcategory"
                  :label-cols="3"
                  label-class="text-left"
                  class="offset-md-1 col-xl-6 col-lg-6 col-md-6 сol-12"
                  :horizontal="true">
                  <b-form-select id="subcategory"
                    :options="subcategories"
                    placeholder="Выберите подкатегорию"
                    v-model="model.subcategoryId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите подкатегорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Описание заявки"
                  label-for="description"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-textarea
                    plaintext
                    id="inlineFormInputName2"
                    placeholder="Текст описания..."
                    :rows="1"
                    :max-rows="3"
                    :no-resize="true"
                    v-model="model.description"/>
                </b-form-group>
                <b-form-group
                  label="Изображения"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <div class="d-block">
                    <viewer :images="images" v-for="(i, index) in images" :key="index" :options="{toolbar: false, navbar: false}" class="request_picture">
                      <img :src="consts.storageUrl + '/' + images[index].url"  alt="hex shorthand color" class="chat__picture">
                      <div v-if="myRole === 'admin' || myRole === 'adminUK'" class="del" @click="deletePicture(index)"><icon name="window-close"></icon></div>
                    </viewer>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Желаемое время прихода специалиста"
                  label-for="arrivalSpecialist"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-10 col-lg-10 col-md-10 сol-12"
                  :horizontal="true">
                  <label for="arrivalSpecialist" class="pr-2 pr-lg-0"> с</label>
                  <b-form-input id="id" class="pb-0 w_180" plaintext type="text" v-model="date.arrivalSpecialist"></b-form-input>
                  <br class="d-block d-lg-none">
                  <label for="endTime"> по</label>
                  <b-form-input id="id" class="pb-0 w_180" plaintext type="text" v-model="date.endTime"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Назначенное время прихода специалиста"
                  label-for="serviceDate"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true">
                  <b-form-input id="id" class="pb-0 w_180" plaintext type="text" v-model="date.serviceDate"></b-form-input>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset>
              <legend>Администратор УК</legend>
              <div class="row">
                <div class="col-xl-5 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="ФИО"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-select id="userId"
                        :options="admins"
                        v-model="model.adminId"
                        disabled>
                        <template slot="first">
                          <option :value="null" disabled>Выберите администратора</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Телефон"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-input id="id" plaintext type="text" v-model="adminPhone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий"
                      label-for="description"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-textarea
                        plaintext
                        id="inlineFormInputName2"
                        placeholder="Текст описания..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.adminComment"/>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Постановщик</legend>
              <div class="row">
                <div class="col-xl-5 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="ФИО"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-select id="userId"
                        :options="employees"
                        v-model="model.engineerId"
                        disabled>
                        <template slot="first">
                          <option :value="null" disabled>Выберите постановщика</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Телефон"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-input id="id" plaintext type="text" v-model="engineerPhone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий"
                      label-for="description"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-textarea
                        plaintext
                        id="inlineFormInputName2"
                        placeholder="Текст описания..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.engineerComment"/>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Исполнитель</legend>
              <div class="row">
                <b-form-group
                  label="Категория исполнителя"
                  label-for="status"
                  :label-cols="5"
                  label-class="text-left"
                  class="col-xl-5 col-lg-8 col-md-10 col-12"
                  :horizontal="true">
                  <b-form-select id="status"
                    :options="groups"
                    v-model="model.groupId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите категорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Исполнитель"
                  label-for="status"
                  :label-cols="5"
                  label-class="text-xl-right pr-xl-4 text-left"
                  class="col-xl-5 col-lg-8 col-md-9 col-12"
                  :horizontal="true">
                  <b-form-select id="status"
                    :options="specialists"
                    v-model="model.specialistId"
                    disabled>
                    <template slot="first">
                      <option :value="null" disabled>Выберите исполнителя</option>
                    </template>
                  </b-form-select>
                  <label class="mb-5 mb-md-0 w_150 wspw">{{specialistGroup}}</label>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  v-if="isContractorGroup"
                  label="Исходящий номер предписания"
                  label-for="userId"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-input id="id" plaintext type="text" class="w_160" v-model="model.ordinanceNumber"></b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                label="Коментарий"
                label-for="description"
                :label-cols="3"
                label-class="text-left"
                class="col-xl-9 col-12"
                :horizontal="true">
                <b-textarea
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="6"
                  :max-rows="6"
                  :no-resize="true"
                  v-model="model.contractorComment"/>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Внутенний статус"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-select id="status"
                    :options="innerStatuses"
                    v-model="model.innerStatusId"
                    class="w_160">
                    <template slot="first">
                      <option :value="null" disabled>Выберите статус</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Срок устранения"
                  label-for="requestClosed"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-input id="id" class="pb-0 w_180" plaintext type="text" v-if="date.termToWork" v-model="date.termToWork"></b-form-input>
                  <div @click="showDesiredTimeForm()" :class="{ 'desiredterm': date.termToWork, 'yellow': !date.termToWork }">
                    <i class="fa fa-warning fa-2x"></i>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Файлы"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-xl-12 col-lg-12 сol-12"
                  :horizontal="true">
                  <label class="load-file__input">
                    <input id="fileCont" type="file" accept="image/*, .xlsx, .xls, .doc, .docx, .pdf" @change="loadFile">
                    <b-btn size="sm" text="Button" variant="success">Добавить файл</b-btn>
                  </label>
                  <div class="d-block">
                    <div v-for="(i, index) in model.documentIds" :key="index" class="request_picture">
                      <a :href="consts.storageUrl + '/' + documents[index].url" target="_blank">
                        <div v-if="documents[index].ext === 'doc' || documents[index].ext === 'docx'" class="file">
                          <img src="/img/doc.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <div v-else-if="documents[index].ext === 'xls' || documents[index].ext === 'xlsx'" class="file">
                          <img src="/img/xlsx.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <div v-else-if="documents[index].ext === 'pdf'" class="file">
                          <img src="/img/pdf.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <img v-else :src="consts.storageUrl + '/' + documents[index].url"  alt="hex shorthand color" class="chat__picture">
                      </a>
                      <div class="del" @click="deleteFile(index)"><icon name="window-close"></icon></div>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset>
              <legend>Информация о закрытии</legend>
              <div class="row">
                <b-form-group
                label="Коментарий при возврате"
                label-for="description"
                :label-cols="2"
                label-class="text-left"
                class="col-12"
                :horizontal="true">
                <b-textarea
                  plaintext
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="1"
                  :max-rows="3"
                  :no-resize="true"
                  v-model="model.feedback"/>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                label="Коментарий при выполнении"
                label-for="description"
                :label-cols="2"
                label-class="text-left"
                class="col-12"
                :horizontal="true">
                <b-textarea
                  plaintext
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="1"
                  :max-rows="3"
                  :no-resize="true"
                  v-model="model.comment"/>
                </b-form-group>
              </div>
              <div
                class="row"
                v-if="isContractorGroup && pickedInnerStatus === 'done'">
                <b-form-group
                  label="Причина неисправности"
                  label-for="requestClosed"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12 mt-3 mb-1"
                  :horizontal="true">
                    <multiselect :disabled="true" v-model="model.requestProblems" :options="problemTypes"  tag-placeholder="Add this as new tag" placeholder="Укажите причину" label="name" track-by="code" :multiple="true" :taggable="true"></multiselect>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Дата и время закрытия заявки"
                  label-for="requestClosed"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <div class="d-flex align-items-center h-100">
                  <b-form-input id="id" class="pb-0 w_180" plaintext type="text" v-model="date.requestClosed"></b-form-input>
                  </div>
                </b-form-group>
              </div>
            </fieldset>
            <b-btn size="sm" @click.prevent="sendForm" class="mt-2" text="Button" variant="success">Сохранить</b-btn>
            <b-btn size="sm" @click.prevent="goBack" class="mt-2" text="Button" variant="success">Вернуться к списку заявок</b-btn>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="myModalRef" hide-footer title="Заявка успешно изменена">
      <p class="my-4">Заявка успешно изменена</p>
    </b-modal>
    <b-modal ref="desiredTimeForm" centered hide-footer title="Отправить запрос на изменение сроков">
      <b-form-group
        label="Дата и время"
        label-for="requestClosed"
        :label-cols="6"
        label-class="text-left"
        class="col-12"
        :horizontal="true"
        :invalid-feedback="validation.dateSelect(date.desiredTermToWork)"
        :state="state18">
        <datetime type="datetime"
          id="requestClosed"
          v-model="date.desiredTermToWork"
          class="theme-orange"
          input-class="dataTimePick"
          placeholder="Дата и время"
          moment-locale="ru"
          :phrases="{ok: 'OK', cancel: 'Отменить'}"
          v-bind:class="{invalid__dateTime: !state18, valid__dateTime: state18 === true}"
          :state="state18">
        </datetime>
      </b-form-group>
      <b-btn size="sm" @click.prevent="desiredterm" text="Button" variant="success">Отправить запрос</b-btn>
    </b-modal>
  </div>
</template>

<script>
  import loading from 'vue-full-loading'
  import validation from '../../../config/validation'
  import consts from '../../../config/const'
  import api from '../../../config/api'
  import MaskedInput from 'vue-text-mask'
  import Multiselect from 'vue-multiselect'
  import Viewer from 'v-viewer'
  import 'vue-awesome/icons/window-close'
  import Icon from 'vue-awesome/components/Icon'
  import Vue from 'vue'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import moment from 'moment'
  import vueCookie from 'vue-cookie'

  Vue.use(vueCookie)
  Vue.use(Viewer)

  export default {
    name: 'new',
    components: {
      datetime: Datetime,
      loading,
      MaskedInput,
      Icon,
      Multiselect
    },
    data () {
      return {
        myIdIs: localStorage.getItem('userId'),
        pictureMsg: null,
        model: {
          attachmentIds: [],
          requestProblems: []
        },
        housingComplex: [],
        street: [],
        building: [],
        premise: [],
        accommodation: [],
        filtredAcommodations: [],
        requestTypes: [],
        requestNatures: [],
        categories: [],
        subcategories: [],
        images: [],
        documents: [],
        imagesMsg: [],
        admins: [],
        employees: [],
        specialists: [],
        statuses: [],
        innerStatuses: [],
        groups: [],
        messages: [],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        lastScrollHeight: 0,
        unreadMessage: 0,
        phone: '',
        adminPhone: 'Выберите контроллёра',
        engineerPhone: 'Выберите постановщика',
        specialistGroup: '',
        pickedInnerStatus: '',
        file: '',
        fileContractor: '',
        fileMsg: '',
        msg: '',
        date: {
          arrivalSpecialist: '',
          endTime: '',
          createdDate: '',
          serviceDate: '',
          requestClosed: '',
          termToWork: '',
          desiredTermToWork: ''
        },
        urls: {
          serviceRequest: 'servicerequest',
          premiseAddress: 'premise/premiseaddress',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          premise: 'premise',
          accommodation: 'accommodation/userlist',
          requesttype: 'requesttype',
          requestnature: 'requestnature',
          categories: 'category',
          subcategories: 'category/subcategories',
          users: 'users',
          admin: 'admin',
          employee: 'employee',
          statuses: 'status',
          innerStatuses: 'status/inner',
          group: 'contractor/group',
          specialist: 'contractor/all',
          desiredterm: 'servicerequest/desiredterm',
          problemType: 'problemtype',
          file: 'file',
          fileContractor: 'file/document'
        },
        problemTypes: [],
        focusSelect: false,
        show: false,
        firstLoadForm: true,
        firstLoadFormCategory: true,
        isContractorGroup: false,
        chatIsOpen: false,
        chatIsLoaded: false,
        msgInputDisabled: false,
        addLoadMessages: true,
        label: 'Пожалуйста, подождите, заявка сохраняется',
        consts: consts,
        validation: validation
      }
    },
    computed: {
      state1 () {
        return validation.numId(this.housingComplexId)
      },
      state3 () {
        return validation.numId(this.buildingId)
      },
      state4 () {
        return validation.numId(this.model.premiseId)
      },
      state6 () {
        return validation.numId(this.model.requestTypeId)
      },
      state7 () {
        return validation.numId(this.model.requestNatId)
      },
      state8 () {
        return validation.numId(this.model.categoryId)
      },
      state9 () {
        return validation.numId(this.model.subcategoryId)
      },
      state10 () {
        return validation.numId(this.model.statusId)
      },
      state11 () {
        return validation.customNumId(this.model.statusId, this.model.specialistId)
      },
      state15 () {
        return validation.customDate(this.model.statusId, this.date.serviceDate)
      },
      state17 () {
        return validation.closableRequestBool(this.model.statusId, this.date.requestClosed)
      },
      state18 () {
        return validation.dateSelectBool(this.date.desiredTermToWork)
      },
      desiredTermToWork () {
        return this.model.desiredTermToWork ? moment(this.model.desiredTermToWork * 1000).format('DD.MM.YYYY HH:mm') : 'Нет данных'
      }
    },
    sockets: {
      message (msg) {
        if (!msg) return
        console.log('message', msg, this.myIdIs)
        this.addMessageInList(msg)
        let readMsg = {
          chatId: this.model.chatId,
          messageId: this.messages[this.messages.length - 1].id
        }
        if (String(this.myIdIs) !== String(msg.messages[0].user.id)) {
          if (this.chatIsOpen) {
            this.model.count = 0
            this.$socket.emit('read', readMsg)
          } else {
            this.model.count++
          }
        }
        this.$nextTick(() => {
          this.scrollToEnd()
        })
      },
      loadMore (msg) {
        console.log('loadMore', msg)
        msg.messages.forEach((item) => {
          item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY HH:mm')
          if (msg.deletedMessage.indexOf(item.id) === -1) {
            if (item.contentText !== null) {
              item.contentText = item.contentText.replace('<a>Детали заявки</a>', '')
            }
            this.messages.unshift(item)
          }
        })
        if (msg.messages.length === 0) {
          this.lastScrollHeight = 0
        } else {
          this.$nextTick(() => {
            document.getElementById('chatBody').scrollTop = document.getElementById('chatBody').scrollHeight - this.lastScrollHeight
          })
        }
        this.addLoadMessages = true
      },
      adminAllUnread (msg) {
        console.log('adminAllUnread', msg)
      },
      update (msg) {
        if (!msg) return
        console.log('update', msg)
        this.updateMessageInList(msg)
        let readMsg = {
          chatId: this.model.chatId,
          messageId: this.messages[this.messages.length - 1].id
        }
        if (msg.messages[this.messages.length - 1].user && String(this.myIdIs) !== String(msg.messages[this.messages.length - 1].user.id)) {
          this.$socket.emit('read', readMsg)
        }
        this.$nextTick(() => {
          this.scrollToEnd()
          this.chatIsLoaded = true
        })
      }
    },
    methods: {
      updateMessageInList (msg) {
        if (!msg) return
        if (msg.messages.length > 1) {
          if (!((parseInt(msg.messages[0].id, 10)) < parseInt(msg.messages[1].id, 10))) {
            msg.messages.reverse()
          }
        }
        msg.messages.forEach((item) => {
          item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY HH:mm')
          if (item.attachment !== null) {
            this.imagesMsg.push(item.attachment.url)
          }
          if (msg.deletedMessage.indexOf(item.id) === -1) {
            if (item.contentText !== null) {
              item.contentText = item.contentText.replace('<a>Детали заявки</a>', '')
            }
            this.messages.push(item)
          }
        })
      },
      addMessageInList (msg) {
        console.log('add', msg)
        if (String(msg.chatId) === String(this.model.chatId)) {
          msg.messages[0].timestamp = moment(msg.messages[0].timestamp * 1000).format('DD.MM.YYYY HH:mm')
          this.messages.push(msg.messages[0])
        }
      },
      isUser (id) {
        if (String(this.myIdIs) === String(id)) {
          return true
        } else {
          return false
        }
      },
      scrollToEnd () {
        let chatInput = document.getElementById('chatBody')
        chatInput.scrollTop = chatInput.scrollHeight
      },
      addBreak () {
        this.msg += '\r\n'
      },
      cancelPicture () {
        this.imagesMsg.splice(-1, 1)
        this.pictureMsg = 0
        this.msg = ''
        this.fileMsg = ''
        this.msgInputDisabled = false
        document.getElementById('upload-photo').value = ''
      },
      loadMore () {
        let msgData = {
          chatId: this.model.chatId,
          messageId: this.messages[0].id
        }
        this.$socket.emit('loadMore', msgData)
      },
      sendMessage () {
        let nonSpaceMsg = this.msg.replace(/\s/g, '')
        if (this.pictureMsg !== 0 || nonSpaceMsg.length > 0) {
          this.myIdIs = localStorage.getItem('userId')
          let msgData = {}
          if (this.pictureMsg) {
            msgData = {
              chatId: this.model.chatId,
              contentImage: this.pictureMsg
            }
          } else {
            msgData = {
              chatId: this.model.chatId,
              contentText: this.msg
            }
          }
          this.$socket.emit('message', msgData)
          this.pictureMsg = 0
          this.msgInputDisabled = false
          this.msg = ''
          this.fileMsg = ''
          this.$nextTick(() => {
            this.scrollToEnd()
          })
        }
      },
      controlChat (active) {
        let readMsg = {
          chatId: this.model.chatId,
          messageId: this.messages[this.messages.length - 1].id
        }
        this.$socket.emit('read', readMsg)
        this.model.count = 0
        this.$nextTick(async () => {
          await this.scrollToEnd()
        })
        this.chatIsOpen = active
      },
      goBack () {
        this.$router.push({path: '/requests', query: {currentPage: this.$route.query.currentPage, currentTab: this.$route.query.currentTab}})
      },
      showDesiredTimeForm () {
        this.$refs.desiredTimeForm.show()
      },
      desiredterm () {
        if (this.state18) {
          let query = {
            requestId: this.$route.params.id,
            desiredTermToWork: moment(this.date.desiredTermToWork).format('X')
          }
          api.post(this.urls.desiredterm, query).then((response) => {
            this.date.desiredTermToWork = ''
            this.$refs.desiredTimeForm.hide()
          })
        }
      },
      sendForm () {
        this.show = true
        let query = {
          contractorComment: this.model.contractorComment,
          innerStatusId: this.model.innerStatusId,
          imageIds: this.model.imageIds,
          documentIds: this.model.documentIds,
          specialistId: this.model.specialistId,
          premiseId: this.model.premiseId,
          statusId: this.model.statusId,
          userPhone: this.model.userPhone
        }
        delete this.model.images
        delete this.model.documents
        api.put(this.urls.serviceRequest + '/' + this.$route.params.id, query).then((response) => {
          this.show = false
          if (response.status) {
            this.$refs.myModalRef.show()
            setTimeout(() => {
              this.$refs.myModalRef.hide()
              this.$router.push({path: '/requests', query: {currentPage: this.$route.query.currentPage, currentTab: this.$route.query.currentTab}})
            }, 1000)
          }
        })
      },
      loadIcon (e) {
        this.file = e.target.files || e.dataTransfer.files
      },
      loadFile (e) {
        this.fileContractor = e.target.files || e.dataTransfer.files
      },
      changeSelect (selectItem) {
        this.model.userName = selectItem.name.slice(0, -19)
        this.phone = `+7 (${selectItem.phone.substring(0, 3)}) ${selectItem.phone.substring(3, 6)}-${selectItem.phone.substring(6, 8)}-${selectItem.phone.substring(8, 10)}`
        this.focusSelect = false
      },
      getAdminPhone () {
        let findItem = this.admins.find(item => item.value === this.model.adminId)
        if (findItem) {
          this.adminPhone = findItem.phone
        }
      },
      getEngineerPhone () {
        let findItem = this.employees.find(item => item.value === this.model.engineerId)
        if (findItem) {
          this.engineerPhone = findItem.phone
        }
      },
      getSpecialistsTitle () {
        let findItem = this.specialists.find(item => item.value === this.model.specialistId)
        if (findItem) {
          this.specialistGroup = findItem.title
        } else {
          this.specialistGroup = ''
        }
      },
      getPickedInnerStatus () {
        let findItem = this.innerStatuses.find(item => item.value === this.model.innerStatusId)
        if (findItem) {
          this.pickedInnerStatus = findItem.key
        }
      },
      getRoleContractor () {
        let findItem = this.groups.find(item => item.value === this.model.groupId)
        if (findItem) {
          this.isContractorGroup = findItem.key === 'contractor'
        }
      },
      getPremiseAddress () {
        let query = {
          id: this.model.premiseId
        }
        api.get(this.urls.premiseAddress, query).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.housingComplexId = jData.data.housingComplexId
            this.streetId = jData.data.streetId
            this.buildingId = jData.data.buildingId
            this.premiseId = jData.data.premiseId
          }
        }).then(() => {
          this.getHousingComplex()
        })
      },
      getHousingComplex () {
        api.get(this.urls.housingComplex).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.housingComplex.push(temp)
              }
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        api.get(this.urls.street).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        if (this.housingComplexId !== '') {
          this.street.forEach((street) => {
            let query = {
              hcId: this.housingComplexId,
              streetId: street.value
            }
            api.get(this.urls.building, query).then((response) => {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach((spec) => {
                  if (spec.id !== null && !spec.removalMark) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      },
      getPremise () {
        if ((this.housingComplexId !== '') && (this.streetId !== '') && (this.buildingId !== '')) {
          let query = {
            hcId: this.housingComplexId,
            streetId: this.streetId,
            buildingId: this.buildingId
          }
          api.get(this.urls.premise, query).then((response) => {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach((spec) => {
                if (!spec.removalMark) {
                  let temp = {}
                  temp.value = spec.id
                  temp.text = spec.premiseNum
                  this.premise.push(temp)
                }
              })
            }
          }).then(() => {
            this.firstLoadForm = false
          })
        }
      },
      getAccommodation () {
        let params = {
          premiseId: this.model.premiseId
        }
        api.get(this.urls.accommodation, params).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {
                  name: ''
                }
                if (spec.lastName) temp.name += spec.lastName + ' '
                if (spec.firstName) temp.name += spec.firstName + ' '
                if (spec.patronymicName) temp.name += spec.patronymicName + ' '
                if (spec.phone) temp.name += ` +7 (${spec.phone.substring(0, 3)}) ${spec.phone.substring(3, 6)}-${spec.phone.substring(6, 8)}-${spec.phone.substring(8, 10)}`
                temp.phone = spec.phone
                temp.id = spec.id
                this.accommodation.push(temp)
                this.filtredAcommodations.push(temp)
              }
            })
          }
        })
      },
      getRequestTypes () {
        api.get(this.urls.requesttype).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.requestTypes.push(temp)
              }
            })
          }
        })
      },
      getRequestNatures () {
        api.get(this.urls.requestnature).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.requestNatures.push(temp)
              }
            })
          }
        })
      },
      getCategories () {
        api.get(this.urls.categories).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.categories.push(temp)
              }
            })
          }
        })
      },
      getSubcategories () {
        let params = {
          id: this.model.categoryId
        }
        api.get(this.urls.subcategories, params).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.subcategories.push(temp)
              }
            })
          }
        }).then(() => {
          this.firstLoadFormCategory = false
        })
      },
      deleteFile (index) {
        this.documents.splice(index, 1)
        this.model.documentIds.splice(index, 1)
        document.getElementById('fileCont').value = ''
      },
      getAdmins () {
        api.get(this.urls.admin, {role: 'adminUK'}).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((user) => {
              if (!user.removalMark) {
                let temp = {}
                temp.value = user.id
                temp.text = user.lastName.concat(' ', user.firstName, ' ', user.patronymicName === null ? '' : user.patronymicName + ' ')
                temp.phone = `+7 (${user.phone.substring(0, 3)}) ${user.phone.substring(3, 6)}-${user.phone.substring(6, 8)}-${user.phone.substring(8, 10)}`
                this.admins.push(temp)
              }
            })
          }
        }).then(() => {
          this.getAdminPhone()
        })
      },
      getEmployee () {
        api.get(this.urls.employee).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((user) => {
              if (!user.removalMark) {
                let temp = {}
                temp.value = user.id
                temp.text = user.lastName.concat(' ', user.firstName, ' ', user.patronymicName === null ? '' : user.patronymicName + ' ')
                temp.phone = `+7 (${user.phone.substring(0, 3)}) ${user.phone.substring(3, 6)}-${user.phone.substring(6, 8)}-${user.phone.substring(8, 10)}`
                this.employees.push(temp)
              }
            })
          }
        }).then(() => {
          this.getEngineerPhone()
        })
      },
      getStatuses () {
        api.get(this.urls.statuses).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.statuses.push(temp)
              }
            })
          }
        })
      },
      getInnerStatuses () {
        api.get(this.urls.innerStatuses).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.innerStatuses.push(temp)
              }
            })
          }
        }).then(() => {
          this.getPickedInnerStatus()
        })
      },
      getGroups () {
        api.get(this.urls.group).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.groups.push(temp)
              }
            })
          }
        }).then(() => {
          this.getRoleContractor()
        })
      },
      getSpecialist () {
        api.get(this.urls.specialist, {groupId: this.model.groupId}).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.title = spec.title
                temp.text = spec.lastName.concat(' ', spec.firstName, ' ', spec.patronymicName === null ? '' : spec.patronymicName + ' ')
                this.specialists.push(temp)
              }
            })
          }
        }).then(() => {
          this.getSpecialistsTitle()
        })
      },
      getProblemType () {
        api.get(this.urls.problemType).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.id = spec.id
                temp.code = spec.id
                temp.name = spec.name
                this.problemTypes.push(temp)
              }
            })
          }
        })
      }
    },
    watch: {
      'model.userName' () {
        this.filtredAcommodations = this.accommodation.filter(item => item.name.indexOf(this.model.userName) !== -1)
      },
      'model.adminId' () {
        this.getAdminPhone()
      },
      'model.engineerId' () {
        this.getEngineerPhone()
      },
      'model.groupId' () {
        if (!this.firstLoadFormCategory) {
          this.specialists = []
          this.specialistGroup = ''
        }
        this.getSpecialist()
        this.getRoleContractor()
      },
      'model.specialistId' () {
        this.getSpecialistsTitle()
      },
      'model.innerStatusId' () {
        this.getPickedInnerStatus()
      },
      housingComplexId () {
        this.street = []
        this.building = []
        this.premise = []
        this.accommodation = []
        if (this.firstLoadForm === false && this.firstLoadForm !== undefined && this.firstLoadForm !== null) {
          this.buildingId = 0
        }
        this.getStreet()
      },
      buildingId () {
        this.premise = []
        this.accommodation = []
        if (this.firstLoadForm === false && this.firstLoadForm !== undefined && this.firstLoadForm !== null) {
          this.model.premiseId = 0
        }
        this.getPremise()
      },
      'model.premiseId' () {
        this.accommodation = []
        this.filtredAcommodations = []
        if (this.firstLoadForm === false && this.firstLoadForm !== undefined && this.firstLoadForm !== null) {
          this.model.userName = ''
          this.phone = ''
        }
        this.getAccommodation()
      },
      'model.categoryId' () {
        this.subcategories = []
        if (!this.firstLoadFormCategory) {
          this.model.subcategoryId = 0
        }
        this.getSubcategories()
      },
      fileContractor (value) {
        if (value !== '') {
          let fd = new FormData()
          fd.append('file', value[0], value[0].name.toLowerCase())
          api.post(this.urls.fileContractor, fd)
            .then((response) => {
              if (response.data.status === false) {
                alert(response.data.error.description)
                return
              }
              let jData = response.data
              let file = {
                id: jData.data.id,
                url: jData.data.url,
                ext: jData.data.ext,
                name: jData.data.name
              }
              this.documents.push(file)
              this.model.documentIds.push(file.id)
              document.getElementById('fileCont').value = ''
            })
            .then(() => {
              this.fileContractor = ''
            })
        }
      },
      fileMsg (value) {
        if (value !== '') {
          let fd = new FormData()
          fd.append('file', value)
          api.post(this.urls.file, fd).then((response) => {
            if (response.data.status === false) {
              alert(response.data.error.description)
              return
            }
            let jData = response.data
            let pict = {
              height: jData.data.height,
              id: jData.data.id,
              url: jData.data.url,
              width: jData.data.width
            }
            this.imagesMsg.push(pict.url)
            this.pictureMsg = pict.id
            this.msg = value.name
            this.msgInputDisabled = true
          })
        }
      }
    },
    mounted () {
      this.messages = []
      document.addEventListener('click', (e) => {
        let check = false
        let path = e.path || (e.composedPath && e.composedPath())
        path.forEach((item) => {
          if (String(item.className) === 'container__select') {
            check = true
          }
        })
        if (check && !this.focusSelect) {
          this.focusSelect = true
        } else {
          this.focusSelect = false
        }
      })
      api.get(this.urls.serviceRequest + '/' + this.$route.params.id).then((response) => {
        let jData = response.data
        if (jData.data) {
          this.model = jData.data
          this.$set(this.model, 'imageIds', [])
          this.$set(this.model, 'documentIds', [])
          if (this.model.userPhone) {
            this.phone = `+7 (${this.model.userPhone.substring(0, 3)}) ${this.model.userPhone.substring(3, 6)}-${this.model.userPhone.substring(6, 8)}-${this.model.userPhone.substring(8, 10)}`
          }
          jData.data.images.forEach((item) => {
            this.model.imageIds.push(item.id)
          })
          this.images.push(...jData.data.images)

          jData.data.documents.forEach((item) => {
            this.model.documentIds.push(item.id)
          })
          this.documents.push(...jData.data.documents)

          if (this.model.arrivalSpecialist !== null) {
            this.date.arrivalSpecialist = moment(this.model.arrivalSpecialist * 1000).format('DD.MM.YYYY HH:mm')
          }
          if (this.model.endTime !== null) {
            this.date.endTime = moment(this.model.endTime * 1000).format('DD.MM.YYYY HH:mm')
          }
          if (this.model.createdDate !== null) {
            this.date.createdDate = moment(this.model.createdDate * 1000).format('DD.MM.YYYY HH:mm')
          }
          if (this.model.serviceDate !== null) {
            this.date.serviceDate = moment(this.model.serviceDate * 1000).format('DD.MM.YYYY HH:mm')
          }
          if (this.model.requestClosed !== null) {
            this.date.requestClosed = moment(this.model.requestClosed * 1000).format('DD.MM.YYYY HH:mm')
          }
          if (this.model.termToWork !== null) {
            this.date.termToWork = moment(this.model.termToWork * 1000).format('DD.MM.YYYY HH:mm')
          }
        } else {
          this.model = jData.error
        }
      }).then(() => {
        if (!this.model.code) {
          this.getPremiseAddress()
          this.getRequestTypes()
          this.getRequestNatures()
          this.getCategories()
          this.getAdmins()
          this.getEmployee()
          this.getSpecialist()
          this.getStatuses()
          this.getInnerStatuses()
          this.getGroups()
          this.getProblemType()
        }
        console.log('fddffdfdf')
        this.$socket.emit('update', {chatId: this.model.chatId})
      })

      let chat = document.getElementById('chatBody')

      chat.addEventListener('scroll', (e) => {
        if (document.getElementById('chatBody').scrollTop === 0) {
          if (this.addLoadMessages) {
            this.lastScrollHeight = document.getElementById('chatBody').scrollHeight
            this.$socket.emit('loadMore', {
              chatId: this.model.chatId,
              messageId: this.messages[0].id
            })
          }
          this.addLoadMessages = false
        }
      })
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
