<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Диалоговое окно о доступности функционала</strong>
          </template>
          <b-form-group
            label="Заголовок"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadForm.title)"
            :state="leadForm_state1"
            description="Не более 40 символов включая пробельные символы и знаки препинания">
            <b-form-input id="id" type="text" :state="leadForm_state1" v-model="model.leadForm.title" :maxlength="40"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Содержание"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadForm.text)"
            :state="leadForm_state2"
            description="Не более 300 символов включая пробельные символы и знаки препинания">
            <b-form-input id="id" type="text" :state="leadForm_state2" v-model="model.leadForm.text" :maxlength="300"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка отмены"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadForm.cancelButton)"
            :state="leadForm_state3"
            description="Не более 15 символов включая пробельные символы и знаки препинания">
            <b-form-input id="id" type="text" :state="leadForm_state3" v-model="model.leadForm.cancelButton" :maxlength="15"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка подтверждения"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadForm.okButton)"
            :state="leadForm_state4"
            description="Не более 15 символов включая пробельные символы и знаки препинания">
            <b-form-input id="id" type="text" :state="leadForm_state4" v-model="model.leadForm.okButton" :maxlength="15"></b-form-input>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Лид-форма</strong>
          </template>
          <b-form-group
            label="Поле с заголовком"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormField.title)"
            :state="leadFormField_state4">
            <b-form-input id="id" type="text" :state="leadFormField_state4" v-model="model.leadFormField.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Поле с именем"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormField.name)"
            :state="leadFormField_state1">
            <b-form-input id="id" type="text" :state="leadFormField_state1" v-model="model.leadFormField.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Поле с телефоном"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormField.phone)"
            :state="leadFormField_state2">
            <b-form-input id="id" type="text" :state="leadFormField_state2" v-model="model.leadFormField.phone"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка отправки"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormField.button)"
            :state="leadFormField_state3">
            <b-form-input id="id" type="text" :state="leadFormField_state3" v-model="model.leadFormField.button"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка отмены"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormField.cancelButton)"
            :state="leadFormField_state5">
            <b-form-input id="id" type="text" :state="leadFormField_state5" v-model="model.leadFormField.cancelButton"></b-form-input>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Диалоговое окно о входе в демо-режим</strong>
          </template>
          <b-form-group
            label="Заголовок"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormStartpage.title)"
            :state="leadFormStartpage_state1">
            <b-form-input id="id" type="text" :state="leadFormStartpage_state1" v-model="model.leadFormStartpage.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Текст"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormStartpage.text)"
            :state="leadFormStartpage_state2">
            <b-form-input id="id" type="text" :state="leadFormStartpage_state2" v-model="model.leadFormStartpage.text"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка Продолжить"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormStartpage.nextButton)"
            :state="leadFormStartpage_state3">
            <b-form-input id="id" type="text" :state="leadFormStartpage_state3" v-model="model.leadFormStartpage.nextButton"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка Отмена"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormStartpage.cancelButton)"
            :state="leadFormStartpage_state4">
            <b-form-input id="id" type="text" :state="leadFormStartpage_state4" v-model="model.leadFormStartpage.cancelButton"></b-form-input>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Диалоговое окно о выходе из демо-режима</strong>
          </template>
          <b-form-group
            label="Текст"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormExitdialog.text)"
            :state="leadFormExitdialog_state1">
            <b-form-input id="id" type="text" :state="leadFormExitdialog_state1" v-model="model.leadFormExitdialog.text"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка Выйти"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormExitdialog.exitButton)"
            :state="leadFormExitdialog_state2">
            <b-form-input id="id" type="text" :state="leadFormExitdialog_state2" v-model="model.leadFormExitdialog.exitButton"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка Записаться"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.leadFormExitdialog.okButton)"
            :state="leadFormExitdialog_state3">
            <b-form-input id="id" type="text" :state="leadFormExitdialog_state3" v-model="model.leadFormExitdialog.okButton"></b-form-input>
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Сохранить изменения</strong>
          </template>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>

    <b-modal ref="myModalRef" hide-footer title="Поля лид-формы успешно обновлены">
      <p class="my-4">Поля лид-формы успешно обновлены</p>
    </b-modal>

    <b-modal ref="myModalRefError" hide-footer title="Поля лид-формы не удалось обновить">
      <p class="my-4">Ошибка! Неудачное обновление полей!</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      leadForm_state1 () {
        return validation.long(this.model.leadForm.title)
      },
      leadForm_state2 () {
        return validation.long(this.model.leadForm.text)
      },
      leadForm_state3 () {
        return validation.long(this.model.leadForm.cancelButton)
      },
      leadForm_state4 () {
        return validation.long(this.model.leadForm.okButton)
      },
      leadFormField_state1 () {
        return validation.long(this.model.leadFormField.name)
      },
      leadFormField_state2 () {
        return validation.long(this.model.leadFormField.phone)
      },
      leadFormField_state3 () {
        return validation.long(this.model.leadFormField.button)
      },
      leadFormField_state4 () {
        return validation.long(this.model.leadFormField.title)
      },
      leadFormField_state5 () {
        return validation.long(this.model.leadFormField.cancelButton)
      },
      leadFormStartpage_state1 () {
        return validation.long(this.model.leadFormStartpage.title)
      },
      leadFormStartpage_state2 () {
        return validation.long(this.model.leadFormStartpage.text)
      },
      leadFormStartpage_state3 () {
        return validation.long(this.model.leadFormStartpage.nextButton)
      },
      leadFormStartpage_state4 () {
        return validation.long(this.model.leadFormStartpage.cancelButton)
      },
      leadFormExitdialog_state1 () {
        return validation.long(this.model.leadFormExitdialog.text)
      },
      leadFormExitdialog_state2 () {
        return validation.long(this.model.leadFormExitdialog.exitButton)
      },
      leadFormExitdialog_state3 () {
        return validation.long(this.model.leadFormExitdialog.okButton)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        let correct = 4 // Число форм

        if ((_this.leadForm_state1 && _this.leadForm_state2 && _this.leadForm_state3 && _this.leadForm_state4) && (_this.leadFormField_state1 && _this.leadFormField_state2 && _this.leadFormField_state3 && _this.leadFormField_state4 && _this.leadFormField_state5) && (_this.leadFormStartpage_state1 && _this.leadFormStartpage_state2 && _this.leadFormStartpage_state3 && _this.leadFormStartpage_state4) && (_this.leadFormExitdialog_state1 && _this.leadFormExitdialog_state2 && _this.leadFormExitdialog_state3)) {
          _this.show = true
          api.put(_this.urls.dialog, _this.model.leadForm).then(function (response) {
            _this.show = false
            if (response.status) {
              correct--
              /* _this.$refs.myModalRefDialog.show()
              setTimeout(function () {
                _this.$refs.myModalRefDialog.hide()
                // _this.$router.push('/dashboard')
              }, 3000) */
            } else {
            }
          })

          api.put(_this.urls.fields, _this.model.leadFormField).then(function (response) {
            _this.show = false
            if (response.status) {
              correct--
            } else {
            }
          })

          api.put(_this.urls.startpage, _this.model.leadFormStartpage).then(function (response) {
            _this.show = false
            if (response.status) {
              correct--
            } else {
            }
          })

          api.put(_this.urls.exitdialog, _this.model.leadFormExitdialog).then(function (response) {
            _this.show = false
            if (response.status) {
              correct--
            } else {
            }
          })

          setTimeout(function () { // Если удачно - возвращается в Dashboard
            if (correct === 0) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/dashboard')
              }, 5000)
            } else {
              _this.$refs.myModalRefError.show() // Если ошибка - пишет об ошибке
              setTimeout(function () {
                _this.$refs.myModalRefError.hide()
              }, 3000)
            }
          }, 3000)
        }// Конец if ((_this.leadForm_state1
      },

      goBack () {
        this.$router.go(-1)
      }
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.dialog).then(function (response) {
        let jData = response.data
        _this.model.leadForm = jData.data
      })

      api.get(_this.urls.fields).then(function (response) {
        let jData = response.data
        _this.model.leadFormField = jData.data
      })

      api.get(_this.urls.startpage).then(function (response) {
        let jData = response.data
        _this.model.leadFormStartpage = jData.data
      })

      api.get(_this.urls.exitdialog).then(function (response) {
        let jData = response.data
        _this.model.leadFormExitdialog = jData.data
      })
    },
    watch: {
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'leadForm'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
          leadForm: {

          },
          leadFormField: {

          },
          leadFormStartpage: {

          },
          leadFormExitdialog: {

          }
        },
        date: '',
        accessList: {},
        posts: [],
        urls: {
          fields: 'demo/form', // Диалоговое окно о доступности функционала
          dialog: 'demo/dialog', // Лид-форма
          startpage: 'demo/startpage', // Диалоговое окно о входе в демо-режим
          exitdialog: 'demo/exitdialog' // Диалоговое окно о выходе из демо-режима
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
