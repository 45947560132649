<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Редактирование опроса</strong>
          </template>
          <b-form-group
            label="Заголовок"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(Model.title)"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="Model.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Текст"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(Model.text)"
            :state="state2">
            <b-form-input id="name" type="text" :state="state2" v-model="Model.text"></b-form-input>
          </b-form-group>
          <b-form-group
            v-for=" (n, item) in Model.answers"
            :key="item"
            label="Вариант ответа"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(Model.answers[item].value)"
            :state="Model.answersErrorState[item]"
          >
            <b-form-input id="name" type="text" :state="Model.answersErrorState[item]" v-model.trim="Model.answers[item].value"></b-form-input>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="addAnswer" text="Button" variant="success">Добавить вариант ответа</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Опрос успешно обновлён">
      <p class="my-4">Опрос успешно обновлён</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.Model.title)
      },
      state2 () {
        return validation.long(this.Model.text)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true && _this.Model.answersIsError === false) {
          _this.show = true
          let temp = []
          for (let i of _this.Model.answers) {
            i.value && temp.push(i)
          }
          _this.Model.answers = temp
          api.put(_this.urls.poll + '/' + _this.$route.params.id, _this.Model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/poll')
              }, 1000)
            }
          })
        }
      },
      addAnswer () {
        let _this = this
        let obj = {value: ''}
        _this.Model.answers.push(obj)
        _this.Model.answersErrorState.push('')
      },
      goBack () {
        this.$router.go(-1)
      }
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.poll + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data

        const answersErrorState = [];
        let answersIsError = false;
        if (jData.data.answers) {
          for (let elem in jData.data.answers) {
            const validationStatus = validation.long(jData.data.answers[elem].value)
            answersErrorState[elem] = validationStatus
            if (validationStatus === false) {
              answersIsError = true
            }
          }
        }

        _this.Model = {
          ...jData.data,
          answersIsError: answersIsError,
          answersErrorState: answersErrorState
        };

        _this.$watch(
          'Model.answers',
          function(arr) {
            let answersIsError = false;
            for (let elem in arr) {
              const validationStatus = validation.long(arr[elem].value)
              _this.Model.answersErrorState[elem] = validationStatus
              if (validationStatus === false) {
                answersIsError = true
              }
            }

            _this.Model.answersIsError = answersIsError

            _this.$forceUpdate();
          },
          {deep: true}
        );
      })
    },
    watch: {
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'poll'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        Model: {
          answers: [],
          answersErrorState: [],
          answersIsError: false
        },
        accessList: {},
        date: '',
        posts: [],
        urls: {
          poll: 'poll'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
