<template>
  <b-card :header="caption">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-col lg="12" class="d-flex justify-content-end">
      <b-btn variant="danger" @click="exit">Выйти</b-btn>
    </b-col>
    <b-col lg="12">
      <b-tabs card>
        <b-tab v-for="item in statusList" :title="`${item.name}`" :key="item.name" :active="isActive(item.name)" @click="changeTab(item.name)">
          <div class="spinner" v-if="!viewTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
          <div v-if="viewTable">
            <b-row>
              <b-col lg="4" class="justify-content-start">
                <label class="mt-0 d-inline-block">
                  Только просроченные
                </label>
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" v-model="expiredSwitch" class="switch-input" checked>
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </b-col>
              <b-col offset="5" lg="3">
                <div class="input-group mb-3">
                  <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
                  <div v-if="search.length > 0" class="input-group-append">
                    <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
                  </div>
                  <div v-else class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table_block">
            <b-table @row-dblclicked="rowClick" @row-clicked="rowTouch" @sort-changed="sortChange" v-if="viewTable" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
              <template slot="alert" slot-scope="data">
                <!-- <i class="fa fa-comment fa-2x">{{data.item.chat.count}}</i> -->
                <!-- <p v-if="data.item.chat && data.item.chat.count > 0" class="badge badge-info badge-pill">{{data.item.chat.count}}</p> -->
                <p v-if="data.item.chat && data.item.chat.count > 0" class="badge badge-warning badge-pill">{{data.item.chat.count}}</p>
              </template>
              <template slot="index" slot-scope="data">
                {{perPage * (currentPage - 1) + data.index + 1}}
              </template>
              <template slot="engineerName" slot-scope="data">
                  <b-badge v-if="data.item.engineer === 'Не назначен'" variant="danger">{{data.item.engineer}}</b-badge>
                  <b-badge v-else variant="info">{{data.item.engineer}}</b-badge>
              </template>
              <template slot="spec" slot-scope="data">
                  <b-badge v-if="data.item.specialist === 'Не назначен'" variant="danger">{{data.item.specialist}}</b-badge>
                  <b-badge v-else variant="info">{{data.item.specialist}}</b-badge>
              </template>
              <template slot="isDemo" slot-scope="data">
                <p v-if="data.item.isDemo === 1"><i class="fa fa-check fa-2x success"></i></p>
                <p v-else><i class="fa fa-close fa-2x danger"></i></p>
              </template>
              <template slot="buttons" slot-scope="data">
                <div class="table__one_buttons">
                  <b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn> 
                </div>
              </template>
            </b-table>
            <nav class="d-flex">
              <b-pagination v-if="viewTable" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
              <label class="d-flex justify-content-center align-items-center mb-3 ml-3" v-if="viewTable">
                Страница: 
                <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
              </label>
            </nav>
          </div>
        </b-tab>
      </b-tabs>
    </b-col>
    <b-modal ref="addPremise" hide-footer title="Выгрузить отчёт по заявкам">
      <b-form-group
        label="Начало периода:"
        label-for="serviceDate"
        :label-cols="4">
        <datetime type="date"
                  id="serviceDate"
                  v-model="date.from"
                  class="theme-orange dateTimePick__report_width"
                  input-class="dataTimePick__report"
                  placeholder="Дата"
                  moment-locale="ru"
                  :phrases="{ok: 'OK', cancel: 'Отменить'}"
                  v-bind:class="{invalid__dateTime: !state12, valid__dateTime: state12}"
                  :state="state12">
        </datetime>
      </b-form-group>
      <b-form-group
        label="Конец периода:"
        label-for="serviceDate"
        :label-cols="4">
        <datetime type="date"
                  id="serviceDate"
                  v-model="date.to"
                  class="theme-orange dateTimePick__report_width"
                  input-class="dataTimePick__report"
                  placeholder="Дата"
                  moment-locale="ru"
                  :phrases="{ok: 'OK', cancel: 'Отменить'}"
                  v-bind:class="{invalid__dateTime: !state13, valid__dateTime: state13}"
                  :state="state13">
        </datetime>
      </b-form-group>
      <b-form-group
        label="Вид отчёта"
        label-for="status"
        :label-cols="4"
        :horizontal="true"
        :invalid-feedback="validation.empty(downloadRoute)"
        :state="state10">
        <b-form-select id="status"
          :options="downloadRoutes"
          v-model="downloadRoute"
          :state="state10">
          <template slot="first">
            <option :value="null" disabled>Выберите вид отчёта</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="download">Выгрузить</b-btn>
      <b-btn class="mt-3" variant="outline-danger" block @click="hideModal">Отмена</b-btn>
    </b-modal>
  </b-card>
</template>

<script>
  import api from '../../../config/api'
  import moment from 'moment'
  import validation from '../../../config/validation'
  import consts from '../../../config/const'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import loading from 'vue-full-loading'
  import vueCookie from 'vue-cookie'
  import Vue from 'vue'

  Vue.use(vueCookie)

  export default {
    name: 'c-table',
    components: {
      datetime: Datetime,
      Datepicker,
      loading
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        isDemo: false,
        statusesList: [],
        sortBy: 'id',
        sortDesc: 1,
        searchTimer: null,
        search: '',
        statusList: [],
        tempItem: [],
        fields: [],
        downloadRoutes: [
          {
            value: 'report/request',
            text: 'Отчёт'
          },
          {
            value: 'report/request/history',
            text: 'История изменений'
          },
          {
            value: 'report/request/supervision',
            text: 'Отчёт технадзора'
          }
        ],
        textPage: '1',
        downloadRoute: '',
        currentPage: 1,
        lastPage: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        currentTab: 1,
        validation: validation,
        consts: consts,
        forMeSwitch: true,
        expiredSwitch: false,
        isTouchDevice: false,
        date: {
          from: '',
          to: ''
        },
        urls: {
          statuses: 'status',
          list: 'servicerequest',
          innerStatuses: 'status/inner',
          del: 'servicerequest'
        },
        show: false,
        label: 'Пожалуйста, подождите, отчёт формируется'
      }
    },
    computed: {
      state10 () {
        return validation.long(this.downloadRoute)
      },
      state12 () {
        return validation.long(this.date.from)
      },
      state13 () {
        return validation.long(this.date.to)
      }
    },
    methods: {
      exit () {
        let checkRole = vueCookie.get('role') === 'specialist'
        this.$cookie.delete('authToken')
        this.$cookie.delete('role')
        localStorage.removeItem('userId')
        localStorage.removeItem('userName')
        if (checkRole) {
          this.$router.push('/auth/login')
        } else {
          this.$router.push('/auth/contractor')
        }
      },
      view (id) {
        let _this = this
        _this.$router.push('/requests/chat/' + id)
      },
      rowClick (item) {
        if (!this.isTouchDevice) {
          this.detail(item.id)
        }
      },
      rowTouch (item) {
        if (this.isTouchDevice) {
          this.detail(item.id)
        }
      },
      sortChange (ctx) {
        this.viewTable = false
        this.sortBy = ctx.sortBy
        this.sortDesc = this.sortDesc === 1 ? 0 : 1
        this.getData()
      },
      download () {
        if (this.date.from !== '' && this.date.to !== '' && this.state10 && this.state12 && this.state13) {
          this.show = true
          this.viewEmpty = false
          let query = {
            from: moment(this.date.from).format('X'),
            to: parseInt(moment(this.date.to).format('X'), 10) + 86399
          }
          api.get(this.downloadRoute, query).then((response) => {
            location.href = consts.storageUrl + '/' + response.data.data.url
            this.show = false
            this.hideModal()
          })
        }
      },
      viewModal () {
        let _this = this
        _this.housingComplexId = 0
        _this.check = true
        _this.$refs.addPremise.show()
      },
      hideModal () {
        this.$refs.addPremise.hide()
      },
      clearSearch () {
        this.search = ''
      },
      async changeTab (name) {
        this.viewTable = false
        this.currentTab = (name === null ? 1 : name)
        if (!name) {
          await this.setCurrents()
        }
        this.getData()
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      detail (id) {
        this.$router.push({path: '/requests/edit/' + id, query: {currentPage: this.currentPage, currentTab: this.currentTab}})
      },
      del (id) {
        api.delete(this.urls.del + '/' + id).then((response) => {
          this.getData()
        })
      },
      getData () {
        this.textPage = String(this.currentPage)
        switch (this.isDemo) {
          case true:
            this.isDemo = 1
            break
          case false:
            this.isDemo = 0
            break
        }
        this.viewTable = false
        let status = null
        api.get(this.urls.innerStatuses).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.statusList = jData.data
            this.statusList.push({name: 'Все заявки'})
          }
          this.currentTab = (this.currentTab === 1 ? this.statusList[0].name : this.currentTab)
          jData.data.forEach((item) => {
            if (this.currentTab !== 'Все заявки') {
              if (this.currentTab === item.name) {
                status = item.id
              }
            } else {
              status = 'all'
            }
          })
          return status
        }).then((status) => {
          let query = {
            search: this.search,
            isDemo: this.isDemo,
            page: this.currentPage,
            limit: this.perPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            forMe: +this.forMeSwitch,
            expired: +this.expiredSwitch
          }
          if (status !== 'all') {
            query.innerStatusId = status
          }
          return api.get(this.urls.list, query)
        }).then((response) => {
          let jMeta = response.data.meta
          this.totalRows = jMeta.pages * this.perPage - 1
          this.lastPage = jMeta.pages
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              item.requestType = item.requestType !== null ? item.requestType.name : ''
              item.id = item.id
              item.street = item.premise.street
              item.building = item.premise.objectNumber
              item.premise = item.premise.premiseNum
              item.createdDate = moment(item.createdDate * 1000).format('DD.MM.YYYY HH:mm')
              item.serviceDate = item.serviceDate ? moment(item.serviceDate * 1000).format('DD.MM.YYYY HH:mm') : ''
              item.requestNat = item.requestNat !== null ? item.requestNat.name : ''
              item.category = item.category !== null ? item.category.name : ''
              item.status = item.status !== null ? item.status.name : ''
              if (item.userPhone) {
                item.phone = `+7 (${item.userPhone.substring(0, 3)}) ${item.userPhone.substring(3, 6)}-${item.userPhone.substring(6, 8)}-${item.userPhone.substring(8, 10)}`
              } else if (item.user) {
                item.phone = `+7 (${item.user.phone.substring(0, 3)}) ${item.user.phone.substring(3, 6)}-${item.user.phone.substring(6, 8)}-${item.user.phone.substring(8, 10)}`
              } else {
                item.phone = 'Не указан'
              }
              item.user = item.userName ? item.userName : 'Не указано'
              item.serviceDate = item.serviceDate ? item.serviceDate : 'Не назначена'
              item.engineer = item.engineer === null ? 'Не назначен' : item.engineer.lastName + ' ' + item.engineer.firstName
              item.specialist = item.specialist === null ? 'Не назначен' : item.specialist.lastName + ' ' + item.specialist.firstName
            })
            this.items = [...jData.data]
            this.viewTable = true
          }
          if (screen.width > 768) {
            this.fields = [
              {key: 'alert', label: ' ', 'class': 'text-center'},
              {key: 'id', label: '№', 'class': 'text-center', sortable: true},
              {key: 'createdDate', label: 'Дата создания', sortable: true},
              {key: 'requestNat', label: 'Характер обращения', sortable: true},
              {key: 'street', label: 'Улица', sortable: true},
              {key: 'building', label: 'Дом', sortable: true},
              {key: 'premise', label: 'Кв', sortable: true},
              {key: 'description', label: 'Описание', sortable: true, 'class': 'desc_table w_150'},
              {key: 'serviceDate', label: 'Назнач. дата', sortable: true},
              {key: 'engineerName', label: 'Постановщик', sortable: true},
              {key: 'spec', label: 'Исполнитель', sortable: true},
              {key: 'status', label: 'Статус', sortable: true},
              {key: 'user', label: 'Клиент', sortable: true},
              {key: 'phone', label: 'Телефон клиента'}
            ]
          } else {
            this.fields = [
              {key: 'alert', label: ' ', 'class': 'text-center'},
              {key: 'id', label: '№', 'class': 'text-center, px-0 pr-1 py-2 text-center'},
              {key: 'street', label: 'Ул', 'class': 'px-0 pr-1 py-2 text-center'},
              {key: 'building', label: 'Д', 'class': 'px-0 pr-1 py-2 text-center'},
              {key: 'premise', label: 'Кв', 'class': 'px-0 pr-1 py-2 text-center'},
              {key: 'serviceDate', label: 'Дата', 'class': 'px-0 pr-1 py-2 text-center'}
            ]
          }
        })
      },
      isActive (statusName) {
        return statusName === this.$route.query.currentTab
      },
      setCurrents () {
        if (this.$route.query.currentPage) {
          this.currentPage = parseInt(this.$route.query.currentPage)
        }
        if (this.$route.query.currentTab) {
          this.currentTab = this.$route.query.currentTab
        }
      }
    },
    watch: {
      search: function () {
        let _this = this
        clearTimeout(_this.searchTimer)
        _this.searchTimer = setTimeout(function () { _this.getData() }, 1000)
      },
      textPage (value) {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
        console.log('textPage', value)
      },
      currentPage (value) {
        console.log('currentPage', value)
      },
      isDemo: function () {
        this.getData()
      },
      forMeSwitch: function () {
        this.getData()
      }
    },
    mounted: function () {
      this.changeTab(null)
      this.isTouchDevice = 'ontouchstart' in document.documentElement || navigator.msMaxTouchPoints || navigator.maxTouchPoints
    }
  }
</script>
