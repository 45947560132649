<template>
  <b-card :header="caption">
    <b-row v-if="accessList.isWrite === true">
      <b-col lg="3">
        <b-btn class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Добавить техническую работу</b-btn>
      </b-col>
    </b-row>
    <b-table :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
      <template slot="index" slot-scope="data">
        {{perPage * (currentPage - 1) + data.index + 1}}
      </template>
      <template slot="buttons" slot-scope="data">
        <div class="table__two_buttons">
          <b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
          <b-btn size="sm" v-if="accessList.isDelete === true" class="change_size btn-danger"  @click.stop="showModal(data.item.id)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
        </div>
      </template>
    </b-table>
    <nav class="d-flex">
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
      <label class="d-flex justify-content-center align-items-center mb-3 ml-3">
        Страница: 
        <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
      </label>
    </nav>
    <b-modal ref="myModalRef" hide-footer title="Удаление версии">
      <div class="d-block text-center">
        <h3>Вы действительно хотите удалить запись?</h3>
      </div>
      <b-btn class="mt-3" variant="outline-danger" block @click="del()">Да</b-btn>
      <b-btn class="mt-3" variant="outline-danger" block @click="hideModal">Нет</b-btn>
    </b-modal>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        accessList: {},
        fields: [
          {key: 'index', label: '#'},
          {key: 'name', label: 'Наименование'},
          {key: 'dateBegin', label: 'Дата'},
          {key: 'buttons', label: ' '}
        ],
        textPage: '1',
        currentPage: 1,
        perPage: 20,
        lastPage: 1,
        totalRows: 1,
        delItem: 0,
        urls: {
          list: 'technicalservices'
        }
      }
    },
    methods: {
      showModal (id) {
        var _this = this
        this.delItem = id
        _this.$refs.myModalRef.show()
      },
      del () {
        var _this = this
        api.delete(_this.urls.list + '/' + _this.delItem).then(function (response) {
          _this.getData()
        })
        this.delItem = 0
        _this.hideModal()
      },
      hideModal () {
        this.$refs.myModalRef.hide()
      },
      clearSearch () {
        this.search = ''
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      newItem () {
        let _this = this
        _this.$router.push('new/')
      },
      detail (id) {
        var _this = this
        _this.$router.push('edit/' + id)
      },
      getData () {
        this.textPage = String(this.currentPage)
        let _this = this
        let query = {
          page: _this.currentPage,
          limit: _this.perPage
        }
        api.get(_this.urls.list, query).then(function (response) {
          let jMeta = response.data.meta
          _this.totalRows = jMeta.pages * _this.perPage - 1
          _this.lastPage = jMeta.pages
          let jData = response.data
          if (jData.status) {
            _this.items = jData.data
          }
        })
      }
    },
    watch: {
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'technicalServices'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getData()
    }
  }
</script>
