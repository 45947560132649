<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template>
            <loading
              :show="show"
              :label="label">
            </loading>
          </template>
          <template #header>
            <strong>Добавление версии</strong>
          </template>
          <b-form-group
            :label-cols="3"
            label="Платформа"
            label-for="housingComplex"
            :horizontal="true">
            <b-form-select id="housingComplex"
                          :options="platforms"
                          placeholder="Выберите платформу"
                          v-model="model.platform">
              <template slot="first">
                <option :value="null" disabled>Выберите платформу</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Номер версии"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isInt(model.versionNumber)"
            :state="state1">
            <b-form-input id="carNumber" type="text" :state="state1" v-model="model.versionNumber"></b-form-input>
          </b-form-group>
            
          <b-form-group
            label="Описание"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true">
            <b-textarea
              id="inlineFormInputName2"
              placeholder="Текст описания..."
              :rows="6"
              :max-rows="6"
              :no-resize="true"
              v-model="model.description"
            />
          </b-form-group>
          <b-form-group
            v-if="model.platform === 'android'"
            label="Ссылка на Google Play"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isGooglePlay(model.playLink)"
            :state="state2">
            <b-form-input id="carNumber" type="text" :state="state2" v-model="model.playLink"></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="model.platform === 'ios'"
            label="AppleID"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.storeLink)"
            :state="state3">
            <b-form-input id="carNumber" type="text" :state="state3" v-model="model.storeLink"></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="model.platform === 'android'"
            :invalid-feedback="validation.empty(apkLink)"
            label="Файл APK"
            label-for="file"
            :label-cols="3"
            :state="state4"
            :horizontal="true">
            <b-form-file id="file"
                         :class="{invalid__dateTime: !state4, valid__dateTime: state4 === true}"
                         :plain="true"
                         v-model="apkLink"
                         :state="state4"
                         :multiple="false"
                         accept=".apk"
                         ref="loadFile">
            </b-form-file>
            <div class="d-inline-block" v-if="checkLoader">Идёт загрузка файла, это может занять некоторое время <i class="fa fa-spinner fa-spin fa-3x"></i></div>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true && !checkLoader" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false && !checkLoader" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Версия успешно добавлена">
      <p class="my-4">Версия успешно добавлена</p>
    </b-modal>
  </div>
</template>


<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import consts from '../../config/const'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.isIntBool(this.model.versionNumber)
      },
      state2 () {
        return validation.isGooglePlayBool(this.model.playLink)
      },
      state3 () {
        return validation.long(this.model.storeLink)
      },
      state4 () {
        return validation.long(this.apkLink)
      }
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      sendForm () {
        let _this = this
        if (_this.state1 && ((_this.state2 && _this.state4) || _this.state3)) {
          _this.show = true
          api.post(_this.urls.version, _this.model).then(function (response) {
            if (response.status) {
              _this.show = false
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/version/list')
              }, 1000)
            }
          })
        }
      },
      getMaxVersion () {
        let _this = this
        let query = {
          platform: _this.model.platform
        }
        api.get(_this.urls.version + '/check', query).then(function (response) {
          if (response.status) {
            let jData = response.data
            _this.model.versionNumber = parseInt(jData.data.versionNumber) + 1
          }
        })
      }
    },
    mounted: function () {
    },
    watch: {
      'apkLink': function (newFile, oldFile) {
        let _this = this
        let fd = new FormData()
        fd.append('file', newFile)
        _this.checkLoader = true
        api.post(_this.urls.file, fd).then(function (response) {
          let jData = response.data
          _this.model.apkLink = consts.storageUrl + '/' + jData.data.fileUrl
          _this.checkLoader = false
        })
      },
      'model.platform': function () {
        if (this.model.platform === 'android') {
          this.model.storeLink = ''
        } else {
          this.model.playLink = ''
        }
        this.getMaxVersion()
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'version'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
          storeLink: '',
          playLink: '',
          versionNumber: ''
        },
        apkLink: '',
        checkLoader: false,
        platforms: [
          {text: 'Android', value: 'android'},
          {text: 'iOS', value: 'ios'}
        ],
        selectPlatform: 'all',
        accessList: {},
        urls: {
          version: 'version',
          file: 'file/apk'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        check: '',
        consts: consts,
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

