<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание заявки</strong>
          </template>
          <div class="container ml-0 mb-3 mt-3">
            <fieldset>
              <legend>Информация о клиенте</legend>
              <b-form-group
                label="Адрес"
                label-for="housingComplex"
                :label-cols="1"
                label-class="col-sm-2 col-lg-2 col-xl-1 text-left mt-xl-4"
                class="text-left mb-0"
                :horizontal="true">
                <b-form-group
                  label="Жилой комплекс:"
                  label-for="housingComplex"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0"
                  :state="state1">
                  <b-form-select id="housingComplex"
                    :options="housingComplex"
                    placeholder="Выберите ЖК"
                    v-model="housingComplexId"
                    :state="state1">
                    <template slot="first">
                      <option :value="null" disabled>Выберите ЖК</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Дом:"
                  label-for="building"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0"
                  :state="state3">
                  <b-form-select id="building"
                    :options="building"
                    placeholder="Выберите дом"
                    v-model="buildingId"
                    :state="state3">
                    <template slot="first">
                      <option :value="null" disabled>Выберите дом</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Квартира:"
                  label-for="premise"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0"
                  :state="state4">
                  <b-form-select id="premise"
                    :options="premise"
                    placeholder="Выберите квартиру"
                    v-model="model.premiseId"
                    :state="state4">
                    <template slot="first">
                      <option :value="null" disabled>Выберите квартиру</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form-group>
              <b-form-group
                label="Клиент"
                label-for="client"
                :label-cols="1"
                label-class="col-sm-2 col-lg-2 col-xl-1 text-left mt-xl-4"
                class="text-left mb-0"
                :horizontal="true">
                <b-form-group
                  label="ФИО:"
                  label-for="client"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left">
                  <label ref="containerSelect" class="mb-0">
                    <div class="container__select">
                      <input ref="selectInput" type="text" v-model="model.userName" class="custom_input_text">
                      <div class="select__select_items" :class="{'select__select_items--block': focusSelect}">
                        <div id="selectItem" class="select_item" @click="changeSelect(item)" v-for="item in filtredAcommodations" :key="item.id">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </label>
                </b-form-group>
                <b-form-group
                  label="Телефон:"
                  label-for="client"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left">
                  <masked-input
                    type="tel"
                    name="phone"
                    class="form-control"
                    v-model="phone"
                    :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
                    :guide="true"
                    :showMask="true"
                    :keepCharPositions="true"
                  ></masked-input>
                </b-form-group>
              </b-form-group>
            </fieldset>
            <fieldset>
              <legend>Информация о заявке</legend>
              <div class="row">
                <b-form-group
                  label="Заявление по гарантии"
                  label-for="userId"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-input id="id" type="text" class="w_160" v-model="model.warrantyStatement"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Тип заявки"
                  label-for="requestType"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-xl-5 col-lg-5 col-md-5 сol-12"
                  :horizontal="true"
                  :state="state6">
                  <b-form-select id="requestType"
                    :options="requestTypes"
                    v-model="model.requestTypeId"
                    :state="state6">
                    <template slot="first">
                      <option :value="null" disabled>Выберите тип</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Характер заявки"
                  label-for="requestType"
                  :label-cols="3"
                  label-class="text-left"
                  class="offset-md-1 col-xl-6 col-lg-6 col-md-6 сol-12"
                  :horizontal="true"
                  :state="state7">
                  <b-form-select id="requestType"
                    :options="requestNatures"
                    v-model="model.requestNatId"
                    :state="state7">
                    <template slot="first">
                      <option :value="null" disabled>Выберите характер</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Категория"
                  label-for="category"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-xl-5 col-lg-5 col-md-5 сol-12"
                  :horizontal="true"
                  :state="state8">
                  <b-form-select id="category"
                    :options="categories"
                    v-model="model.categoryId"
                    :state="state8">
                    <template slot="first">
                      <option :value="null" disabled>Выберите категорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Подкатегория"
                  label-for="subcategory"
                  :label-cols="3"
                  label-class="text-left"
                  class="offset-md-1 col-xl-6 col-lg-6 col-md-6 сol-12"
                  :horizontal="true"
                  :state="state9">
                  <b-form-select id="subcategory"
                    :options="subcategories"
                    placeholder="Выберите подкатегорию"
                    v-model="model.subcategoryId"
                    :state="state9">
                    <template slot="first">
                      <option :value="null" disabled>Выберите подкатегорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Описание заявки"
                  label-for="description"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-xl-12 col-lg-12 сol-12"
                  :horizontal="true"
                  :state="descriptionValidate"
                >
                  <b-textarea
                    id="inlineFormInputName2"
                    placeholder="Текст описания..."
                    :state="descriptionValidate"
                    :rows="3"
                    :max-rows="6"
                    :no-resize="true"
                    v-model="model.description"/>
                </b-form-group>
                <b-form-group
                  label="Изображения"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-xl-12 col-lg-12 сol-12"
                  :horizontal="true">
                  <label class="load-file__input">
                    <input id="file" type="file" accept="image/*" @change="loadIcon">
                    <b-btn size="sm" text="Button" variant="success">Добавить изображение</b-btn>
                  </label>
                  <div class="d-block">
                    <viewer :images="images" v-for="(i, index) in model.imageIds" :key="index" :options="{toolbar: false, navbar: false}" class="request_picture">
                      <img :src="consts.storageUrl + '/' + images[index].url"  alt="hex shorthand color" class="chat__picture">
                      <div class="del" @click="deletePicture(index)"><icon name="window-close"></icon></div>
                    </viewer>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Желаемое время прихода специалиста"
                  label-for="arrivalSpecialist"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-10 col-lg-10 col-md-10 сol-12"
                  :horizontal="true">
                  <label for="arrivalSpecialist" class="pr-2 pr-lg-0"> с</label>
                  <datetime type="datetime"
                    id="arrivalSpecialist"
                    v-model="date.arrivalSpecialist"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <br class="d-block d-lg-none">
                  <label for="endTime"> по</label>
                  <datetime type="datetime"
                    id="endTime"
                    v-model="date.endTime"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.arrivalSpecialist = ''; date.endTime = '';" text="Button" variant="light" class="mt-2" title="очистить">очистить</b-btn>
                </b-form-group>
                <b-form-group
                  label="Назначенное время прихода специалиста"
                  label-for="serviceDate"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true">
                  <datetime type="datetime"
                    id="serviceDate"
                    v-model="date.serviceDate"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.serviceDate = ''" text="Button" variant="light" class="mt-2" title="очистить">очистить</b-btn>
                </b-form-group>
                <b-form-group
                  label="Отложить заявку"
                  label-for="postpone"
                  label-cols="2"
                  label-class="text-left form-group-switch-label"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true"
                  v-if="allowPostponeService">
                  <label class="switch switch-3d switch-primary form-group-switch-vcentered">
                    <input type="checkbox" v-model="isPostponed" class="switch-input" checked>
                    <span class="switch-label" data-on="Да" data-off="Нет"></span>
                    <span class="switch-handle"></span>
                  </label>
                </b-form-group>
                <b-form-group
                  label="Отложить заявку до указанной даты"
                  label-for="postponedDate"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true"
                  :state="state16"
                  v-show="isPostponed"
                  v-if="allowPostponeService">
                  <datetime type="datetime"
                    id="postponedDate"
                    v-model="date.postponedDate"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}"
                    v-bind:class="{invalid__dateTime: !state16, valid__dateTime: state16 === true}"
                    :state="state16">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.postponedDate = ''" text="Button" variant="light" class="mt-2" title="очистить">очистить</b-btn>
                </b-form-group>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий для отложенной заявки"
                      label-for="postponedComment"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true"
                      v-show="isPostponed"
                      v-if="allowPostponeService">
                      <b-textarea
                        id="postponedComment"
                        placeholder="Текст комментария..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.postponedComment"
                        :state="statePostponedCommentRequired"/>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  label="Приемка-передача"
                  label-for="acceptanceTransfer"
                  label-cols="2"
                  label-class="text-left form-group-switch-label"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true">
                  <label class="switch switch-3d switch-primary form-group-switch-vcentered">
                    <input type="checkbox" v-model="model.acceptanceTransfer" class="switch-input" checked>
                    <span class="switch-label" data-on="Да" data-off="Нет"></span>
                    <span class="switch-handle"></span>
                  </label>
                </b-form-group>
                <b-form-group
                  label="Ошибка в проекте"
                  label-for="projectError"
                  label-cols="2"
                  label-class="text-left form-group-switch-label"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true">
                  <label class="switch switch-3d switch-primary form-group-switch-vcentered">
                    <input type="checkbox" v-model="model.projectError" class="switch-input" checked>
                    <span class="switch-label" data-on="Да" data-off="Нет"></span>
                    <span class="switch-handle"></span>
                  </label>
              </b-form-group>
              </div>
            </fieldset>
            <fieldset  v-if="myRole !== 'adminUK'">
              <legend>Администратор УК</legend>
              <div class="row">
                <div class="col-xl-5 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="ФИО"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-select id="userId"
                        :options="admins"
                        v-model="model.adminId">
                        <template slot="first">
                          <option :value="null" disabled>Выберите администратора</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Телефон"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-input id="id" plaintext type="text" v-model="adminPhone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий"
                      label-for="description"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-textarea
                        id="inlineFormInputName2"
                        placeholder="Текст описания..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.adminComment"/>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Постановщик</legend>
              <div class="row">
                <div class="col-xl-5 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="ФИО"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-select id="userId"
                        :options="employees"
                        v-model="model.engineerId">
                        <template slot="first">
                          <option :value="null" disabled>Выберите постановщика</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Телефон"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-input id="id" plaintext type="text" v-model="engineerPhone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий"
                      label-for="description"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-textarea
                        id="inlineFormInputName2"
                        placeholder="Текст описания..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.engineerComment"/>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Исполнитель</legend>
              <div class="row">
                <b-form-group
                  label="Категория исполнителя"
                  label-for="status"
                  :label-cols="5"
                  label-class="text-left"
                  class="col-xl-5 col-lg-8 col-md-10 col-12"
                  :class="{' mb-0' : isContractorGroup }"
                  :horizontal="true"
                  :state="validation.customNumId(model.statusId, model.groupId)">
                  <b-form-select id="status"
                    :options="groups"
                    v-model="model.groupId"
                    :state="validation.customNumId(model.statusId, model.groupId)">
                    <template slot="first">
                      <option :value="null" disabled>Выберите категорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="isContractorGroup"
                  label="Компания"
                  label-for="status"
                  :label-cols="5"
                  label-class="pr-xl-4 text-left"
                  class="col-xl-5 col-lg-8 col-md-9 col-12"
                  :horizontal="true"
                  :state="state11">
                  <b-form-select id="status"
                    :options="company"
                    v-model="companyName"
                    :state="state11">
                    <template slot="first">
                      <option :value="null" disabled>Выберите компанию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Исполнитель"
                  label-for="status"
                  :label-cols="5"
                  label-class="pr-xl-4 text-left"
                  class="col-xl-5 col-lg-8 col-md-9 col-12"
                  :class="{' mb-0' : isContractorGroup }"
                  :horizontal="true"
                  :state="state11">
                  <b-form-select id="status"
                    :options="specialists"
                    v-model="model.specialistId"
                    :state="state11">
                    <template slot="first">
                      <option :value="null" disabled>Выберите исполнителя</option>
                    </template>
                  </b-form-select>
                  <label v-if="!isContractorGroup" class="mb-5 mb-md-0 w_150 wspw">{{specialistGroup}}</label>
                </b-form-group>
                <b-form-group
                  v-if="isContractorGroup"
                  label="Исходящий номер предписания"
                  label-for="userId"
                  :label-cols="7"
                  label-class="text-left"
                  class="col-xl-5 col-lg-8 col-md-9 col-12"
                  :horizontal="true">
                  <b-form-input id="id" type="text" class="w_160" v-model="model.ordinanceNumber"></b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Срок устранения"
                  label-for="requestClosed"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <datetime type="datetime"
                    id="requestClosed"
                    v-model="date.termToWork"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.termToWork = ''" text="Button" variant="light" class="mt-2" title="очистить">очистить</b-btn>
                </b-form-group>
                <b-form-group
                  label="Файлы"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-xl-12 col-lg-12 сol-12"
                  :horizontal="true">
                  <label class="load-file__input">
                    <input id="fileCont" type="file" accept="image/*, .xlsx, .xls, .doc, .docx, .pdf" @change="loadFile">
                    <b-btn size="sm" text="Button" variant="success">Добавить файл</b-btn>
                  </label>
                  <div class="d-block">
                    <div v-for="(i, index) in model.documentIds" :key="index" class="request_picture">
                      <a :href="consts.storageUrl + '/' + documents[index].url" target="_blank">
                        <div v-if="documents[index].ext === 'doc' || documents[index].ext === 'docx'" class="file">
                          <img src="/img/doc.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <div v-else-if="documents[index].ext === 'xls' || documents[index].ext === 'xlsx'" class="file">
                          <img src="/img/xlsx.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <div v-else-if="documents[index].ext === 'pdf'" class="file">
                          <img src="/img/pdf.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <img v-else :src="consts.storageUrl + '/' + documents[index].url"  alt="hex shorthand color" class="chat__picture">
                      </a>
                      <div class="del" @click="deleteFile(index)"><icon name="window-close"></icon></div>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset>
              <legend>Информация о закрытии</legend>
              <div class="row">
                <b-form-group
                label="Коментарий при возврате"
                label-for="description"
                :label-cols="2"
                label-class="text-left"
                class="col-12"
                :horizontal="true">
                <b-textarea
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="3"
                  :max-rows="6"
                  :no-resize="true"
                  v-model="model.feedback"/>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                label="Коментарий при закрытии"
                label-for="description"
                :label-cols="2"
                label-class="text-left"
                class="col-12"
                :horizontal="true">
                <b-textarea
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="3"
                  :max-rows="6"
                  :no-resize="true"
                  v-model="model.comment"/>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Статус заявки"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12 mb-0"
                  :horizontal="true"
                  :state="state10">
                  <b-form-select id="status"
                    :options="statuses"
                    v-model="model.statusId"
                    :state="state10"
                    class="w_160">
                    <template slot="first">
                      <option :value="null" disabled>Выберите статус</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
              <div
                class="row"
                v-if="isContractorGroup && pickedInnerStatus === 'done'">
                <b-form-group
                  label="Причина неисправности"
                  label-for="requestClosed"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12 mt-3 mb-1"
                  :horizontal="true">
                    <multiselect v-model="model.requestProblems" :options="problemTypes"  tag-placeholder="Add this as new tag" placeholder="Укажите причину" label="name" track-by="code" :multiple="true" :taggable="true"></multiselect>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Дата и время закрытия заявки"
                  label-for="requestClosed"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true"
                  :state="state17">
                  <div class="d-flex align-items-center h-100">
                    <datetime type="datetime"
                      id="requestClosed"
                      v-model="date.requestClosed"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Дата и время"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state17, valid__dateTime: state17 === true}"
                      :state="state17">
                    </datetime>
                    <b-btn size="sm" @click.prevent="date.requestClosed = ''" text="Button" variant="light" class="mt-2" title="очистить">очистить</b-btn>
                  </div>
                </b-form-group>
              </div>
            </fieldset>
            <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success" class="mt-1">Сохранить</b-btn>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Заявка успешно добавлена">
      <p class="my-4">Заявка успешно добавлена</p>
    </b-modal>
  </div>
</template>

<script>
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import consts from '../../config/const'
  import api from '../../config/api'
  import MaskedInput from 'vue-text-mask'
  import Multiselect from 'vue-multiselect'
  import Viewer from 'v-viewer'
  import 'vue-awesome/icons/window-close'
  import Icon from 'vue-awesome/components/Icon'
  import Vue from 'vue'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import moment from 'moment'
  import vueCookie from 'vue-cookie'

  Vue.use(vueCookie)
  Vue.use(Viewer)

  export default {
    name: 'new',
    components: {
      datetime: Datetime,
      loading,
      MaskedInput,
      Icon,
      Multiselect
    },
    data () {
      return {
        model: {
          userName: '',
          userPhone: '',
          ordinanceNumber: '',
          warrantyStatement: '',
          premiseId: 0,
          requestTypeId: 0,
          requestNatId: 0,
          categoryId: 0,
          subcategoryId: 0,
          statusId: 1,
          adminId: 0,
          engineerId: 0,
          specialistId: 0,
          innerStatusId: 0,
          groupId: 0,
          imageIds: [],
          documentIds: [],
          requestProblems: [],
          postponedComment: '',
          acceptanceTransfer: false,
          projectError: false
        },
        isPostponed: false,
        housingComplex: [],
        street: [],
        building: [],
        premise: [],
        accommodation: [],
        filtredAcommodations: [],
        requestTypes: [],
        requestNatures: [],
        categories: [],
        subcategories: [],
        images: [],
        documents: [],
        attachments: [],
        admins: [],
        employees: [],
        specialists: [],
        statuses: [],
        innerStatuses: [],
        groups: [],
        company: [],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        companyId: 0,
        phone: '',
        companyName: '',
        adminPhone: 'Выберите контроллёра',
        engineerPhone: 'Выберите постановщика',
        specialistGroup: '',
        pickedInnerStatus: '',
        file: '',
        fileContractor: '',
        date: {
          arrivalSpecialist: '',
          endTime: '',
          serviceDate: '',
          requestClosed: '',
          termToWork: '',
          postponedDate: ''
        },
        urls: {
          serviceRequest: 'servicerequest',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          premise: 'premise',
          accommodation: 'accommodation/userlist',
          requesttype: 'requesttype',
          requestnature: 'requestnature',
          categories: 'category',
          subcategories: 'category/subcategories',
          users: 'users',
          admin: 'admin',
          employee: 'employee',
          statuses: 'status',
          innerStatuses: 'status/inner',
          group: 'contractor/group',
          specialist: 'contractor/all',
          problemType: 'problemtype',
          company: 'company',
          file: 'file',
          fileContractor: 'file/document'
        },
        problemTypes: [],
        focusSelect: false,
        show: false,
        isContractorGroup: false,
        accessList: {},
        label: 'Пожалуйста, подождите, заявка сохраняется',
        consts: consts,
        validation: validation,
        allowPostponeService: false,
        myRole: ''
      }
    },
    computed: {
      state1 () {
        return validation.numId(this.housingComplexId)
      },
      state3 () {
        return validation.numId(this.buildingId)
      },
      state4 () {
        return validation.numId(this.model.premiseId)
      },
      state6 () {
        return validation.numId(this.model.requestTypeId)
      },
      state7 () {
        return validation.numId(this.model.requestNatId)
      },
      state8 () {
        return validation.numId(this.model.categoryId)
      },
      state9 () {
        return validation.numId(this.model.subcategoryId)
      },
      state10 () {
        return validation.numId(this.model.statusId)
      },
      state11 () {
        return validation.customNumId(this.model.statusId, this.model.specialistId)
      },
      descriptionValidate () {
        if (!this.model?.description) return false
        return validation.long(this.model.description.trim())
      },
      state16 () {
        if (this.isPostponed) {
          return validation.dateSelectBool(this.date.postponedDate)
        }
      },
      state17 () {
        return validation.closableRequestBool(this.model.statusId, this.date.requestClosed)
      },
      statePostponedCommentRequired () {
        const comment = this.model.postponedComment
        return !!(comment && comment.trim() !== '')
      }
    },
    methods: {
      sendForm () {
        if (
          this.state1 && this.state3 && this.state4 && this.state6 && this.state7 && this.state8 && this.state9 &&
          this.state10 && this.state11 && this.descriptionValidate && this.state17
        ) {
          if (this.isPostponed && (!this.state16 || !this.statePostponedCommentRequired)) {
            return
          }
          this.model.isPostponed = this.isPostponed

          this.show = true
          if (this.date.arrivalSpecialist !== '') {
            this.model.arrivalSpecialist = moment(this.date.arrivalSpecialist).format('X')
          }
          if (this.date.endTime !== '') {
            this.model.endTime = moment(this.date.endTime).format('X')
          }
          if (this.date.serviceDate !== '') {
            this.model.serviceDate = moment(this.date.serviceDate).format('X')
          } else {
            this.model.serviceDate = null
          }
          if (this.date.requestClosed !== '') {
            this.model.requestClosed = moment(this.date.requestClosed).format('X')
          }
          if (this.date.termToWork !== '') {
            this.model.termToWork = moment(this.date.termToWork).format('X')
          }
          if (this.date.postponedDate !== '') {
            this.model.postponedDate = moment(this.date.postponedDate).format('X')
          }
          if (this.model.adminId === 0) {
            delete this.model.adminId
          }
          if (this.model.engineerId === 0) {
            delete this.model.engineerId
          }
          if (this.model.specialistId === 0) {
            delete this.model.specialistId
          }
          if (this.model.groupId === 0) {
            delete this.model.groupId
          }
          if (this.model.innerStatusId === 0) {
            delete this.model.innerStatusId
          }
          this.model.userPhone = this.phone.replace(/\D/g, '').replace('7', '')

          api.post(this.urls.serviceRequest, this.model)
            .then((response) => {
              this.show = false
              if (response.status) {
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.$router.push('/service_requests')
                }, 1000)
              }
            })
        }
      },
      loadIcon (e) {
        this.file = e.target.files || e.dataTransfer.files
      },
      loadFile (e) {
        this.fileContractor = e.target.files || e.dataTransfer.files
      },
      changeSelect (selectItem) {
        this.model.userName = selectItem.name.slice(0, -19)
        this.phone = `+7 (${selectItem.phone.substring(0, 3)}) ${selectItem.phone.substring(3, 6)}-${selectItem.phone.substring(6, 8)}-${selectItem.phone.substring(8, 10)}`
        this.focusSelect = false
      },
      getAdminPhone () {
        let findItem = this.admins.find(item => item.value === this.model.adminId)
        if (findItem) {
          this.adminPhone = findItem.phone
        }
      },
      getRoleContractor () {
        // this.companyId = 0
        // this.companyName = ''
        let findItem = this.groups.find(item => item.value === this.model.groupId)
        if (findItem) {
          this.isContractorGroup = findItem.key === 'contractor'
        }
      },
      getEngineerPhone () {
        let findItem = this.employees.find(item => item.value === this.model.engineerId)
        if (findItem) {
          this.engineerPhone = findItem.phone
        }
      },
      getSpecialistsTitle () {
        let findItem = this.specialists.find(item => item.value === this.model.specialistId)
        if (findItem) {
          if (this.isContractorGroup) {
            this.companyName = findItem.title
          } else {
            this.specialistGroup = findItem.title
          }
        } else {
          this.specialistGroup = ''
        }
      },
      getCompanyId () {
        let findItem = this.company.find(item => item.value === this.companyName)
        if (findItem) {
          this.companyId = findItem.id
        } else {
          this.companyId = ''
        }
      },
      getPickedInnerStatus () {
        let findItem = this.innerStatuses.find(item => item.value === this.model.innerStatusId)
        if (findItem) {
          this.pickedInnerStatus = findItem.key
        }
      },
      getHousingComplex () {
        api.get(this.urls.housingComplex).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.housingComplex.push(temp)
              }
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        api.get(this.urls.street).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        if (this.housingComplexId !== '') {
          this.street.forEach((street) => {
            let query = {
              hcId: this.housingComplexId,
              streetId: street.value
            }
            api.get(this.urls.building, query).then((response) => {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach((spec) => {
                  if (spec.id !== null && !spec.removalMark) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      },
      getPremise () {
        if ((this.housingComplexId !== '') && (this.streetId !== '') && (this.buildingId !== '')) {
          let query = {
            hcId: this.housingComplexId,
            streetId: this.streetId,
            buildingId: this.buildingId
          }
          api.get(this.urls.premise, query).then((response) => {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach((spec) => {
                if (!spec.removalMark) {
                  let temp = {}
                  temp.value = spec.id
                  temp.text = spec.premiseNum
                  this.premise.push(temp)
                }
              })
            }
          })
        }
      },
      getAccommodation () {
        let params = {
          premiseId: this.model.premiseId
        }
        api.get(this.urls.accommodation, params).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {
                  name: ''
                }
                if (spec.lastName) temp.name += spec.lastName + ' '
                if (spec.firstName) temp.name += spec.firstName + ' '
                if (spec.patronymicName) temp.name += spec.patronymicName + ' '
                if (spec.phone) temp.name += ` +7 (${spec.phone.substring(0, 3)}) ${spec.phone.substring(3, 6)}-${spec.phone.substring(6, 8)}-${spec.phone.substring(8, 10)}`
                temp.phone = spec.phone
                temp.id = spec.id
                this.accommodation.push(temp)
                this.filtredAcommodations.push(temp)
              }
            })
          }
        })
      },
      getRequestTypes () {
        api.get(this.urls.requesttype).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.requestTypes.push(temp)
              }
            })
          }
        })
      },
      getRequestNatures () {
        api.get(this.urls.requestnature).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.requestNatures.push(temp)
              }
            })
          }
        })
      },
      getCategories () {
        api.get(this.urls.categories).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.categories.push(temp)
              }
            })
          }
        })
      },
      getSubcategories () {
        let params = {
          id: this.model.categoryId
        }
        api.get(this.urls.subcategories, params).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.subcategories.push(temp)
              }
            })
          }
        })
      },
      deletePicture (index) {
        this.images.splice(index, 1)
        this.model.imageIds.splice(index, 1)
        document.getElementById('file').value = ''
      },
      deleteFile (index) {
        this.documents.splice(index, 1)
        this.model.documentIds.splice(index, 1)
        document.getElementById('fileCont').value = ''
      },
      getAdmins () {
        api.get(this.urls.admin, {role: 'adminUK'}).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((user) => {
              if (!user.removalMark) {
                let temp = {}
                temp.value = user.id
                temp.text = user.lastName.concat(' ', user.firstName, ' ', user.patronymicName === null ? '' : user.patronymicName + ' ')
                temp.phone = `+7 (${user.phone.substring(0, 3)}) ${user.phone.substring(3, 6)}-${user.phone.substring(6, 8)}-${user.phone.substring(8, 10)}`
                this.admins.push(temp)
              }
            })
          }
        })
      },
      getEmployee () {
        api.get(this.urls.admin).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((user) => {
              if (!user.removalMark) {
                let temp = {}
                temp.value = user.id
                temp.text = user.lastName.concat(' ', user.firstName, ' ', user.patronymicName === null ? '' : user.patronymicName + ' ')
                temp.phone = `+7 (${user.phone.substring(0, 3)}) ${user.phone.substring(3, 6)}-${user.phone.substring(6, 8)}-${user.phone.substring(8, 10)}`
                this.employees.push(temp)
              }
            })
          }
        })
      },
      getStatuses () {
        api.get(this.urls.statuses).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.statuses.push(temp)
              }
            })
          }
        })
      },
      getInnerStatuses () {
        api.get(this.urls.innerStatuses).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                temp.key = spec.keyWord
                this.innerStatuses.push(temp)
              }
            })
          }
        })
      },
      getGroups () {
        api.get(this.urls.group).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                temp.key = spec.keyWord
                this.groups.push(temp)
              }
            })
          }
        })
      },
      getSpecialist () {
        let query
        if (this.companyId && this.isContractorGroup) {
          query = {
            groupId: this.model.groupId,
            companyId: this.companyId
          }
        } else {
          query = {
            groupId: this.model.groupId
          }
        }
        api.get(this.urls.specialist, query)
          .then((response) => {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach((spec) => {
                if (!spec.removalMark) {
                  let temp = {}
                  temp.value = spec.id
                  temp.title = spec.title
                  temp.text = spec.lastName.concat(' ', spec.firstName, ' ', spec.patronymicName === null ? '' : spec.patronymicName + ' ')
                  this.specialists.push(temp)
                }
              })
            }
          })
      },
      getCompanies () {
        api.get(this.urls.company).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.name
              temp.id = spec.id
              temp.text = spec.name
              this.company.push(temp)
            })
          }
        }).then(() => {
          this.getCompanyId()
        })
      },
      getProblemType () {
        api.get(this.urls.problemType).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.id = spec.id
                temp.code = spec.id
                temp.name = spec.name
                this.problemTypes.push(temp)
              }
            })
          }
        })
      }
    },
    watch: {
      'model.userName' () {
        this.filtredAcommodations = this.accommodation.filter(item => item.name.indexOf(this.model.userName) !== -1)
      },
      'model.adminId' () {
        this.getAdminPhone()
      },
      'model.engineerId' () {
        this.getEngineerPhone()
      },
      'model.groupId' () {
        this.specialists = []
        this.specialistGroup = ''
        this.getSpecialist()
        this.getRoleContractor()
      },
      'model.specialistId' () {
        this.getSpecialistsTitle()
      },
      'model.innerStatusId' () {
        this.getPickedInnerStatus()
      },
      companyName () {
        this.specialists = []
        this.getCompanyId()
      },
      companyId () {
        this.specialists = []
        this.getSpecialist()
      },
      housingComplexId () {
        this.buildingId = 0
        this.model.premiseId = 0
        this.building = []
        this.premise = []
        this.getBuilding()
      },
      streetId () {
        this.building = []
        this.premise = []
      },
      buildingId () {
        this.premise = []
        this.model.premiseId = 0
        this.getPremise()
      },
      'model.premiseId' () {
        this.accommodation = []
        this.filtredAcommodations = []
        this.getAccommodation()
      },
      'model.categoryId' () {
        this.subcategories = []
        this.getSubcategories()
      },
      file (value) {
        if (value !== '') {
          let fd = new FormData()
          fd.append('file', value[0])
          api.post(this.urls.file, fd)
            .then((response) => {
              if (response.data.status === false) {
                alert(response.data.error.description)
                return
              }
              let jData = response.data
              let pict = {
                height: jData.data.height,
                id: jData.data.id,
                url: jData.data.url,
                width: jData.data.width
              }
              this.images.push(pict)
              this.model.imageIds.push(pict.id)
              document.getElementById('fileCont').value = ''
            })
            .then(() => {
              this.file = ''
            })
        }
      },
      fileContractor (value) {
        if (value !== '') {
          let fd = new FormData()
          fd.append('file', value[0])
          api.post(this.urls.fileContractor, fd)
            .then((response) => {
              if (response.data.status === false) {
                alert(response.data.error.description)
                return
              }
              let jData = response.data
              let file = {
                id: jData.data.id,
                url: jData.data.url,
                ext: jData.data.ext,
                name: jData.data.name
              }
              this.documents.push(file)
              this.model.documentIds.push(file.id)
              document.getElementById('fileCont').value = ''
            })
            .then(() => {
              this.fileContractor = ''
            })
        }
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'serviceRequest'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })

      api.get('role/components/access', {keyWord: 'postponeService'}).then((response) => {
        console.log(response.data.data.isRead)
        this.allowPostponeService = Boolean(response.data.data.isRead)
      })
    },
    mounted () {
      document.addEventListener('click', (e) => {
        let check = false
        let path = e.path || (e.composedPath && e.composedPath())
        path.forEach((item) => {
          if (String(item.className) === 'container__select') {
            check = true
          }
        })
        if (check && !this.focusSelect) {
          this.focusSelect = true
        } else {
          this.focusSelect = false
        }
      })
      this.myRole = vueCookie.get('role')
      this.getHousingComplex()
      this.getRequestTypes()
      this.getRequestNatures()
      this.getCategories()
      this.getAdmins()
      this.getEmployee()
      this.getStatuses()
      this.getInnerStatuses()
      this.getGroups()
      this.getProblemType()
      this.getCompanies()
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
