<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template>
            <loading
              :show="show"
              :label="label">
            </loading>
          </template>
          <template #header>
            <strong>Минимальная доступная версия</strong>
          </template>
          <b-form-group
            :label-cols="3"
            label="Adroind"
            label-for="housingComplex"
            :horizontal="true">
            <b-form-select id="housingComplex"
                          :options="androidVersion"
                          placeholder="Выберите версию Android"
                          v-model="modelVersion.android">
              <template slot="first">
                <option :value="null" disabled>Выберите версию Android</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            :label-cols="3"
            label="iOS"
            label-for="housingComplex"
            :horizontal="true">
            <b-form-select id="housingComplex"
                          :options="iOSVersion"
                          placeholder="Выберите версию iOS"
                          v-model="modelVersion.ios">
              <template slot="first">
                <option :value="null" disabled>Выберите версию iOS</option>
              </template>
            </b-form-select>
          </b-form-group>
          </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Настройка диалогового окна</strong>
          </template>
          <b-form-group
            label="Заголовок"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="carNumber" type="text" v-model="modelDialog.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Текст"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="carNumber" type="text" v-model="modelDialog.text"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка отмены"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="carNumber" type="text" v-model="modelDialog.cancelButton"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка согласия"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="carNumber" type="text" v-model="modelDialog.okButton"></b-form-input>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true && false" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Версия успешно изменена">
      <p class="my-4">Версия успешно изменена</p>
    </b-modal>
  </div>
</template>


<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import consts from '../../config/const'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.long(this.model.carNumber)
      },
      state2 () {
        return validation.long(this.value)
      },
      state3 () {
        return validation.phoneNumberBool(this.phone)
      },
      state4 () {
        return validation.numId(this.model.buildingId)
      },
      state5 () {
        return validation.dateSelectBool(this.date.time)
      }
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      sendForm () {
        let _this = this
        _this.show = true
        // if (_this.state1 === true && _this.state2 === true && _this.state3 === true && _this.state4 === true && (_this.state5 === true || !_this.check)) {
        api.put(_this.urls.version + '/dialog', _this.modelDialog).then(function (response) {
          return api.put(_this.urls.version + '/settings', _this.modelVersion)
        }).then(function (response) {
          if (response.status) {
            _this.show = false
            _this.$refs.myModalRef.show()
            setTimeout(function () {
              _this.$refs.myModalRef.hide()
              _this.$router.push('/version/list')
            }, 1000)
          }
        })
      // }
      },
      getVersion () {
        let _this = this
        api.get(_this.urls.version, { platform: 'android' }).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.androidVersion = jData.data.map(item => {
              return {
                text: item.versionNumber,
                value: item.id
              }
            })
          }
          return api.get(_this.urls.version, { platform: 'ios' })
        }).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.iOSVersion = jData.data.map(item => {
              return {
                text: item.versionNumber,
                value: item.id
              }
            })
          }
        })
      }
    },
    mounted () {
      let _this = this
      this.getVersion()
      api.get(_this.urls.version + '/settings').then(function (response) {
        let jData = response.data
        _this.modelVersion = jData.data
        return api.get(_this.urls.version + '/dialog')
      }).then((response) => {
        let jData = response.data
        _this.modelDialog = jData.data
      })
    },
    watch: {
    },
    created () {
      let _this = this
      let accsessKey = {
        keyWord: 'version'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        modelVersion: {
        },
        modelDialog: {
        },
        androidVersion: [],
        iOSVersion: [],
        accessList: {},
        urls: {
          version: 'version',
          file: 'file/apk'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        check: '',
        consts: consts,
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


