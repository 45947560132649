<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Изменение контакта</strong>
          </template>
          <b-form-group
            label="Адрес"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.address)"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="model.address"></b-form-input>
          </b-form-group>
          <div v-if="model.linkedObjects.length > 0" v-for="obj in model.linkedObjects">
            <b-form-group
              :label="`${obj.name}`"
              label-for="file"
              :label-cols="3"
              :horizontal="true">
              <div class="address_picker">
                <multiselect v-model="obj.buildings" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" :preserve-search="false" :searchable="false" placeholder="" label="name" track-by="name" :preselect-first="true">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.name }}</span><span class="custom__remove" @click="props.remove(props.option)" @click.prevent="getHousingComplex">❌</span></span></template>
                </multiselect>
                <!-- <b-btn size="sm" class="address_picker__button_add_before_remove" @click.prevent="viewModalBuild(obj.id)" text="Button" variant="success"><i class="fa fa-plus fa-2x"></i></b-btn> -->
                <b-btn size="sm" class="address_picker__button_remove" @click.prevent="delHC(obj.id)" text="Button" variant="danger"><i class="fa fa-close fa-2x"></i></b-btn>
              </div>
            </b-form-group>
          </div>
          <b-form-group
            label="ЖК"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-btn size="sm" @click.prevent="viewModalHC" text="Button" variant="success">Добавить ещё</b-btn>
          </b-form-group>
          <b-form-group
            label="Комментарий"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.comment)"
            :state="state3">
            <b-form-input id="id" type="text" :state="state3" v-model="model.comment"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Время работы"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.workTime)"
            :state="state4">
            <vue-editor v-model="model.workTime"
              :editorToolbar="editor"
              v-bind:class="{invalid__dateTime: !state4, valid__dateTime: state4 === true}"
              :state="state4">
            </vue-editor>
          </b-form-group>
          <b-form-group
            label="Широта"
            label-for="latitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.latitude)"
            :state="state5">
            <b-form-input id="latitude" type="text" :state="state5" v-model="model.latitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Долгота"
            label-for="longitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.longitude)"
            :state="state6">
            <b-form-input id="longitude" type="text" :state="state6" v-model="model.longitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label=""
            label-for="longitude"
            :label-cols="3"
            :horizontal="true">
            <yandex-map
              :coords="coords"
              zoom="18"
              style="width: 600px; height: 600px;"
              :cluster-options="{1: {clusterDisableClickZoom: true}}"
              :behaviors="['drag', 'scrollZoom', 'multiTouch']"
              v-model="yamap"
              :controls="['zoomControl', 'searchControl', 'fullscreenControl', 'typeSelector']"
              map-type="map"
              :placemarks="placemarks"
              @map-was-initialized="initHandler"
            >
            </yandex-map>
          </b-form-group>
          <b-form-group
            label="Описание"
            label-for="streamingUri"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.description)"
            :state="state7">
            <b-form-input id="image" type="text" :state="state7" v-model="model.description"></b-form-input>
          </b-form-group>
          <b-card>
            <template #header>
              <strong>Контактные данные</strong>
              <b-btn size="sm" @click.prevent="viewAddContactModal()" text="Button" variant="info" class="button__send-data">Добавить контакт</b-btn>
            </template>
            <b-table v-if="model.contacts.length > 0" :hover="true" :striped="false" :bordered="false" :small="false" :fixed="false" responsive="sm" :items="formatedContacts" :fields="fields">
              <template slot="buttons" slot-scope="data">
                <div class="table__two_buttons">
                  <b-btn size="sm" class="change_size btn-warning" @click.stop="detailContact(data.index)"><i class="fa fa-pencil fa-2x"></i></b-btn>
                  <b-btn size="sm" class="btn-danger" @click.stop="removeContact(data.index)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
                </div>
              </template>
            </b-table>
            <b-form-input v-else id="id" plaintext type="text" v-model="noContactText"></b-form-input>
          </b-card>
          <b-card>
            <template #header>
              <strong>Экстренные службы</strong>
              <b-btn size="sm" @click.prevent="viewAddESModal()" text="Button" variant="info" class="button__send-data">Добавить службу</b-btn>
            </template>
            <b-table v-if="model.emergencyServices.length > 0" :hover="true" :striped="false" :bordered="false" :small="false" :fixed="false" responsive="sm" :items="formatedES" :fields="fieldsES">
              <template slot="buttons" slot-scope="data">
                <div class="table__two_buttons">
                  <b-btn size="sm" class="change_size btn-warning" @click.stop="detailES(data.index)"><i class="fa fa-pencil fa-2x"></i></b-btn>
                  <b-btn size="sm" class="btn-danger" @click.stop="removeES(data.index)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
                </div>
              </template>
            </b-table>
            <b-form-input v-else id="id" plaintext type="text" v-model="noContactText"></b-form-input>
          </b-card>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Контакт успешно изменён">
      <p class="my-4">Контакт успешно изменён</p>
    </b-modal>
    <b-modal ref="addContactItemModal" hide-footer title="Добавление контактных данных">
      <div>
        <b-form-group
          label="Телефон"
          label-for="streamingUri"
          :label-cols="3"
          :horizontal="true"
          :invalid-feedback="validation.phoneNumber(newContact.phone)"
          :state="state11">
          <masked-input
            type="tel"
            name="phone"
            class="form-control"
            v-model="newContact.phone"
            :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
            :guide="true"
            :showMask="true"
            :keepCharPositions="true"
            v-bind:class="{invalid__dateTime: !state11, valid__dateTime: state11 === true}"
          ></masked-input>
        </b-form-group>
      </div>
      <b-form-group
        label="Должность"
        label-for="streamingUri"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.empty(newContact.hint)"
        :state="state12">
        <b-form-input id="hint" type="text" :state="state12" v-model="newContact.hint"></b-form-input>
      </b-form-group>
      <b-btn size="sm" @click.prevent="addContact()" text="Button" variant="info" class="ml-auto">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="addESItemModal" hide-footer title="Добавление экстренной службы">
      <b-form-group
        label="Телефон"
        label-for="streamingUri"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.phoneNumber(newES.phone)"
        :state="state14">
        <masked-input
          type="tel"
          name="phone"
          class="form-control"
          v-model="newES.phone"
          :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
          :guide="true"
          :showMask="true"
          :keepCharPositions="true"
          v-bind:class="{invalid__dateTime: !state14, valid__dateTime: state14 === true}"
        ></masked-input>
      </b-form-group>
      <b-form-group
        label="Должность"
        label-for="streamingUri"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.empty(newES.hint)"
        :state="state15">
        <b-form-input id="hint" type="text" :state="state15" v-model="newES.hint"></b-form-input>
      </b-form-group>
      <b-btn size="sm" @click.prevent="addES()" text="Button" variant="info" class="ml-auto">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="addPremise" hide-footer title="Добавить здание">
      <b-form-group
        label="Дом"
        label-for="building"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(buildingId)"
        :state="state18">
        <b-form-select id="building"
                       :options="building"
                       placeholder="Выберите дом"
                       v-model="buildingId"
                       :state="state18">
          <template slot="first">
            <option :value="null" disabled>Выберите дом</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="saveAddress">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="addHC" hide-footer title="Добавить ЖК">
      <b-form-group
        label="Жилищный комплекс"
        label-for="housingComplex"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(hcId)"
        :state="state19">
        <b-form-select id="housingComplex"
                       :options="housingComplex"
                       placeholder="Выберите ЖК"
                       v-model="hcId"
                       :state="state19">
          <template slot="first">
            <option :value="null" disabled>Выберите ЖК</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="addHC">Сохранить</b-btn>
    </b-modal>
  </div>
</template>
<script>
  import api from '../../config/api'
  import { yandexMap, ymapMarker } from 'vue-yandex-maps'
  import loading from 'vue-full-loading'
  import MaskedInput from 'vue-text-mask'
  import validation from '../../config/validation'
  import Multiselect from 'vue-multiselect'
  import { VueEditor, Quill } from 'vue2-editor'

  export default {
    name: 'New',
    components: {
      yandexMap,
      ymapMarker,
      loading,
      MaskedInput,
      Multiselect,
      VueEditor
    },
    data () {
      return {
        model: {
          linkedObjects: [],
          contacts: [],
          emergencyServices: [],
          main: false
        },
        newContact: {
          phone: '',
          email: '',
          extension: '',
          hint: 'phone',
          contactTypeId: 0
        },
        newES: {
          phone: '',
          extension: '',
          hint: '',
          description: ''
        },
        value: [],
        options: [],
        formatedContacts: [],
        formatedES: [],
        housingComplex: [],
        street: [],
        building: [],
        hcId: 0,
        streetId: 0,
        buildingId: 0,
        fields: [
          {key: 'value', label: 'Значение'},
          {key: 'extension', label: 'Добавочный номер'},
          {key: 'hint', label: 'Должность'},
          {key: 'contactTypeId', label: 'Тип'},
          {key: 'buttons', label: ' '}
        ],
        fieldsES: [
          {key: 'phone', label: 'Телефон'},
          {key: 'extension', label: 'Добавочный номер'},
          {key: 'hint', label: 'Служба'},
          {key: 'description', label: 'Описание'},
          {key: 'buttons', label: ' '}
        ],
        editor: [
          ['bold', 'italic', 'underline']
        ],
        accessList: {},
        houseComplex: [
        ],
        contacttype: [
        ],
        coords: [58.603542963107785, 49.66805437579303],
        date: '',
        placemarks: [
          {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение камеры на карте"</div>'
          }
        ],
        yamap: '',
        posts: [],
        urls: {
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          complexinfo: 'complexinfo',
          contacttype: 'contacttype',
          networktype: 'networktype'
        },
        show: false,
        editIndex: -1,
        label: 'Пожалуйста, подождите, операция выполняется',
        noContactText: 'Контакты пока не созданы',
        validation: validation
      }
    },
    computed: {
      state1 () {
        return validation.long(this.model.address)
      },
      state3 () {
        return validation.long(this.model.comment)
      },
      state4 () {
        return validation.long(this.model.workTime)
      },
      state5 () {
        return validation.isNumberBool(this.model.latitude)
      },
      state6 () {
        return validation.isNumberBool(this.model.longitude)
      },
      state7 () {
        return validation.long(this.model.description)
      },
      state11 () {
        return validation.phoneNumberBool(this.newContact.phone)
      },
      state12 () {
        return validation.long(this.newContact.hint)
      },
      state13 () {
        return validation.numId(this.newContact.contactTypeId)
      },
      state14 () {
        return validation.phoneNumberBool(this.newES.phone)
      },
      state15 () {
        return validation.long(this.newES.hint)
      },
      state16 () {
        return validation.long(this.newES.description)
      },
      state17 () {
        return validation.emailBool(this.newContact.email)
      },
      state18 () {
        return validation.numId(this.buildingId)
      },
      state19 () {
        return validation.numId(this.hcId)
      },
      state20 () {
        return validation.isIntOrEmptyBool(this.newContact.extension)
      },
      state21 () {
        return validation.isIntOrEmptyBool(this.newES.extension)
      }
    },
    methods: {
      getContactTypeKey (id) {
        return this.contacttype.find(item => item.value === id).keyWord
      },
      viewAddESModal () {
        this.newES.phone = ''
        this.newES.hint = ''
        this.newES.extension = ''
        this.newES.description = ''
        this.editIndex = -1
        this.$refs.addESItemModal.show()
      },
      viewAddContactModal () {
        this.newContact.phone = ''
        this.newContact.email = ''
        this.newContact.extension = ''
        this.newContact.hint = ''
        this.newContact.contactTypeId = 0
        this.editIndex = -1
        this.$refs.addContactItemModal.show()
      },
      detailES (index) {
        this.newES.phone = `+7 (${this.model.emergencyServices[index].phone.substring(0, 3)}) ${this.model.emergencyServices[index].phone.substring(3, 6)}-${this.model.emergencyServices[index].phone.substring(6, 8)}-${this.model.emergencyServices[index].phone.substring(8, 10)}`
        this.newES.hint = this.model.emergencyServices[index].hint
        this.newES.extension = this.model.emergencyServices[index].extension
        this.newES.description = this.model.emergencyServices[index].description
        this.editIndex = index
        this.$refs.addESItemModal.show()
      },
      detailContact (index) {
        this.newContact.phone = `+7 (${this.model.contacts[index].value.substring(0, 3)}) ${this.model.contacts[index].value.substring(3, 6)}-${this.model.contacts[index].value.substring(6, 8)}-${this.model.contacts[index].value.substring(8, 10)}`
        this.newContact.email = ''
        this.newContact.extension = this.model.contacts[index].extension
        this.newContact.hint = this.model.contacts[index].hint
        this.newContact.contactTypeId = this.model.contacts[index].contactTypeId
        this.editIndex = index
        this.$refs.addContactItemModal.show()
      },
      addContact () {
        if (this.state12 && this.state11) {
          if (this.editIndex === -1) {
            this.model.contacts.push({
              value: this.newContact.phone.replace(/\D/g, '').replace('7', ''),
              extension: this.newContact.extension,
              hint: this.newContact.hint,
              contactTypeId: this.newContact.contactTypeId
            })
          } else {
            this.model.contacts[this.editIndex].value = this.newContact.phone.replace(/\D/g, '').replace('7', '')
            this.model.contacts[this.editIndex].hint = this.newContact.hint
            this.model.contacts[this.editIndex].extension = this.newContact.extension
            this.model.contacts[this.editIndex].contactTypeId = this.newContact.contactTypeId
          }
          this.$refs.addContactItemModal.hide()
        }
      },
      addES () {
        if (this.state14 && this.state15) {
          if (this.editIndex === -1) {
            this.model.emergencyServices.push({
              phone: this.newES.phone.replace(/\D/g, '').replace('7', ''),
              hint: this.newES.hint,
              description: this.newES.description,
              extension: this.newES.extension
            })
          } else {
            this.model.emergencyServices[this.editIndex].phone = this.newES.phone.replace(/\D/g, '').replace('7', '')
            this.model.emergencyServices[this.editIndex].hint = this.newES.hint
            this.model.emergencyServices[this.editIndex].description = this.newES.description
            this.model.emergencyServices[this.editIndex].extension = this.newES.extension
          }
          this.$refs.addESItemModal.hide()
        }
      },
      removeES (id) {
        this.model.emergencyServices.splice(id, 1)
      },
      removeContact (id) {
        this.model.contacts.splice(id, 1)
      },
      initHandler (obj) {
        obj.events.add('click', (e) => {
          let mark = {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение камеры на карте"</div>'
          }
          this.placemarks = []
          mark.coords = e.get('coords')
          this.model.latitude = mark.coords[0]
          this.model.longitude = mark.coords[1]
          this.coords = mark.coords
          this.placemarks.push(mark)
        })
      },
      sendForm () {
        if (this.state1 && this.state3 && this.state4 && this.state5 && this.state6 && this.state7) {
          this.show = true
          let contacts = []
          let emergencyServices = []
          this.model.contacts.forEach((item) => {
            let data = {
              value: item.value.replace(/\D/g, ''),
              extension: item.extension,
              hint: item.hint,
              contactTypeId: item.contactTypeId
            }
            contacts.push(data)
          })
          this.model.contacts = contacts
          this.model.emergencyServices.forEach((item) => {
            let data = {
              phone: item.phone.replace(/\D/g, ''),
              extension: item.extension,
              hint: item.hint,
              description: item.description
            }
            emergencyServices.push(data)
          })
          this.model.emergencyServices = emergencyServices
          this.model.workTime = this.model.workTime.slice(0, -4).replace(/<p>/gi, '').replace(/<br><\/p>/ig, '<br>').replace(/<\/p>/ig, '<br>')
          while (this.model.workTime.slice(-4, this.model.workTime.length) === '<br>') {
            this.model.workTime = this.model.workTime.slice(0, -4)
          }
          api.put(this.urls.complexinfo + '/' + this.$route.params.id, this.model).then((response) => {
            this.show = false
            if (response.status) {
              this.$refs.myModalRef.show()
              setTimeout(() => {
                this.$refs.myModalRef.hide()
                this.$router.push('/complexinfo/list')
              }, 1000)
            }
          })
        }
      },
      getContactType () {
        api.get(this.urls.contacttype).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              temp.keyWord = spec.keyWord
              this.contacttype.push(temp)
            })
          }
        }).then(() => {
          this.formatContacts()
          this.formatES()
        })
      },
      goBack () {
        this.$router.go(-1)
      },
      formatContacts () {
        this.formatedContacts = this.model.contacts.map(item => {
          return {
            value: `+7 (${item.value.substring(0, 3)}) ${item.value.substring(3, 6)}-${item.value.substring(6, 8)}-${item.value.substring(8, 10)}`,
            hint: item.hint,
            extension: item.extension,
            contactTypeId: this.contacttype.find(cont => item.contactTypeId === cont.value) ? this.contacttype.find(cont => item.contactTypeId === cont.value).text : ''
          }
        })
      },
      formatES () {
        this.formatedES = this.model.emergencyServices.map(item => {
          return {
            phone: `+7 (${item.phone.substring(0, 3)}) ${item.phone.substring(3, 6)}-${item.phone.substring(6, 8)}-${item.phone.substring(8, 10)}`,
            extension: item.extension,
            hint: item.hint,
            description: item.description
          }
        })
      },
      delHC (id) {
        this.model.linkedObjects.forEach((item, index) => {
          if (item.id === id) {
            this.model.linkedObjects.splice(index, 1)
          }
        })
        this.getHousingComplex()
      },
      viewModalHC () {
        this.hcId = 0
        this.$refs.addHC.show()
      },
      viewModalBuild (id) {
        this.hcId = id
        this.buildingId = 0
        this.building = []
        this.street = []
        this.getStreet()
        this.$refs.addPremise.show()
      },
      addHC () {
        if (this.state19) {
          let hComplex = {}
          let checkHC = false
          this.model.linkedObjects.forEach((item) => {
            if (item.id === this.hcId) {
              checkHC = true
            }
          })
          if (!checkHC) {
            this.housingComplex.forEach((item, index) => {
              if (item.value === this.hcId) {
                hComplex.name = item.text
                hComplex.id = item.value
              }
            })
            let linkedObject = {
              name: hComplex.name,
              id: hComplex.id,
              buildings: []
            }
            this.model.linkedObjects.push(linkedObject)
          }
        }
        this.housingComplex = []
        this.getHousingComplex()
        this.hideModalHC()
      },
      saveAddress () {
        if (this.state18) {
          let fullAddress = null
          let hComplex = {}
          this.model.linkedObjects.forEach((item) => {
            if (item.id === this.hcId) {
              hComplex.name = item.name
              hComplex.id = item.id
              this.building.forEach((building, index) => {
                if (building.value === this.buildingId) {
                  fullAddress = building.text
                }
              })
              let checkBuilding = false
              item.buildings.forEach((building) => {
                if (building.id === this.buildingId) {
                  checkBuilding = true
                }
              })
              if (!checkBuilding) {
                let building = {
                  name: fullAddress,
                  id: this.buildingId
                }
                item.buildings.push(building)
              }
            }
          })
          this.hideModalPremise()
        }
      },
      hideModalPremise () {
        this.buildingId = 0
        this.$refs.addPremise.hide()
      },
      hideModalHC () {
        this.buildingId = 0
        this.$refs.addHC.hide()
      },
      getHousingComplex () {
        api.get(this.urls.housingComplex).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let checkHC = false
              this.model.linkedObjects.forEach((houseComplex) => {
                if (houseComplex.id === spec.id) {
                  checkHC = true
                }
              })
              if (!checkHC) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.housingComplex.push(temp)
              }
            })
          }
        })
      },
      getStreet () {
        api.get(this.urls.street).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        this.street.forEach((street) => {
          let query = {
            hcId: this.hcId,
            streetId: street.value
          }
          api.get(this.urls.building, query).then((response) => {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach((spec) => {
                let checkBuilding = false
                if (spec.id !== null) {
                  this.model.linkedObjects.forEach((item) => {
                    item.buildings.forEach((building) => {
                      if (building.id === spec.id) {
                        checkBuilding = true
                      }
                    })
                  })
                  if (!checkBuilding) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    this.building.push(temp)
                  }
                }
              })
            }
          })
        })
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'complexInfo'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },
    mounted () {
      api.get(this.urls.complexinfo + '/' + this.$route.params.id).then((response) => {
        let jData = response.data
        this.model = jData.data
        this.model.workTime = '<p>' + this.model.workTime.replace(/<br>/gi, '<p>') + '</p>'
        if (this.model.latitude !== null && this.model.longitude !== null) {
          this.coords = [this.model.latitude, this.model.longitude]
          let mark = {
            coords: [this.model.latitude, this.model.longitude],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение камеры на карте"</div>'
          }
          this.placemarks = []
          mark.coords = [this.model.latitude, this.model.longitude]
          this.placemarks.push(mark)
        }
      })
      this.getHousingComplex()
      this.getContactType()
    },
    watch: {
      'model.latitude' (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let mark = this.placemarks[0]
          this.placemarks = []
          mark.coords = [n, this.model.longitude]
          this.coords = [n, this.model.longitude]
          this.placemarks.push(mark)
        }
      },
      'model.longitude' (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let mark = this.placemarks[0]
          this.placemarks = []
          mark.coords = [this.model.latitude, n]
          this.coords = [this.model.latitude, n]
          this.placemarks.push(mark)
        }
      },
      'model.contacts': {
        handler () {
          this.formatContacts()
        },
        deep: true
      },
      'model.emergencyServices': {
        handler () {
          this.formatES()
        },
        deep: true
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
