<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Изменение сотрудника</strong>
            <div class="float-right">
              <b-link :to="{ path: '/employee/login-log/' + $route.params.id }">История рабочих сессий</b-link>
            </div>
          </template>
          <b-form-group
            label="Имя"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.firstName)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.firstName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="lastName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.lastName)"
            :state="state5">
            <b-form-input id="lastName" type="text" :state="state5" v-model="model.lastName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Отчество"
            label-for="patronymicName"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="patronymicName" type="text" v-model="model.patronymicName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Роль сотрудника"
            label-for="role"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.roleId)"
            :state="state9">
            <b-form-select id="role"
                           :options="role"
                           placeholder="Выберите роль"
                           v-model="model.roleId"
                           :state="state9">
              <template slot="first">
                <option :value="null" disabled>Выберите роль</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="getRoleKey === 'specialist'"
            label="Должность сотрудника"
            label-for="role"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.postId)"
            :state="state3">
            <b-form-select id="role"
                           :options="post"
                           placeholder="Выберите должность"
                           v-model="model.postId"
                           :state="state3">
              <template slot="first">
                <option :value="null" disabled>Выберите должность</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.email(model.email)"
            :state="state6">
            <b-form-input id="email" type="text" :state="state6" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state7">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
            ></masked-input>
          </b-form-group>
          <b-form-group
            label=""
            label-for="basicCustomCheckboxes"
            :label-cols="3"
            :horizontal="true">
            <b-form-checkbox-group stacked id="basicCustomCheckboxes">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" :disabled="getRoleKey === 'admin'" v-model="isAccess" id="published" value="1" true-value="1" falser-value="0">
                <label class="custom-control-label" for="published">Имеет доступ к ИС</label>
              </div>
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group
            label="Время последнего входа"
            label-for="lastLoginAt"
            :label-cols="3"
            :horizontal="true"
            v-show="showLastLoginAt">
            <span>{{ lastLoginAt }}</span>
          </b-form-group>
          <b-form-group
            v-if="isAccess"
            label="Пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.editPass(password, sPassword)"
            :state="state8">
            <b-form-input id="pass" type="password" :state="state8" v-model="password"></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="isAccess"
            label="Повторите пароль"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.editPass(password, sPassword)"
            :state="state8">
            <b-form-input id="twinpass" type="password" :state="state8" v-model="sPassword"></b-form-input>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Сотрудник успешно изменён">
      <p class="my-4">Сотрудник успешно изменён</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import loading from 'vue-full-loading'
  import moment from 'moment'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput
    },
    computed: {
      state3 () {
        return validation.numId(this.model.postId)
      },
      state4 () {
        return validation.long(this.model.firstName)
      },
      state5 () {
        return validation.long(this.model.lastName)
      },
      state6 () {
        return validation.emailBool(this.model.email)
      },
      state7 () {
        return validation.phoneNumberBool(this.phone)
      },
      state8 () {
        return validation.editPassBool(this.password, this.sPassword)
      },
      state9 () {
        return validation.numId(this.model.roleId)
      },
      getRoleKey () {
        let findItem = this.role.find(item => item.value === this.model.roleId)
        if (findItem) {
          if (findItem.key === 'admin') {
            this.isAccess = '1'
            this.model.access = true
          } else {
            this.model.access = false
          }
          return findItem.key
        } else {
          return ''
        }
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if ((_this.state3 || _this.getRoleKey !== 'specialist') && _this.state4 && _this.state5 && _this.state6 && _this.state7 && (_this.state8 || !_this.isAccess) && _this.state9) {
          _this.show = true
          if (!_this.isAccess) {
            delete this.model.password
          }
          if (_this.password !== '') {
            _this.model.password = _this.password
          }
          if (_this.model.patronymicName === null || _this.model.patronymicName === '') {
            delete _this.model.patronymicName
          }
          delete _this.model.photo
          _this.model.phone = _this.phone.replace(/\D/g, '').replace('7', '')
          api.put(_this.urls.employee + '/' + _this.$route.params.id, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({ path: '/employee/list', query: { currentPage: _this.$route.query.currentPage } })
              }, 1000)
            }
          })
        }
      },
      getRole () {
        let _this = this
        api.get(_this.urls.role).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              temp.key = spec.keyWord
              _this.role.push(temp)
            })
          }
        })
      },
      getPost () {
        let _this = this
        api.get(_this.urls.post).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.post.push(temp)
            })
          }
        })
      }
    },
    watch: {
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.employee + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
        _this.phone = `+7 (${_this.model.phone.substring(0, 3)}) ${_this.model.phone.substring(3, 6)}-${_this.model.phone.substring(6, 8)}-${_this.model.phone.substring(8, 10)}`
        if (_this.model.access) {
          _this.isAccess = '1'
        } else {
          _this.isAccess = false
        }
        _this.showLastLoginAt = Boolean(jData.data.lastLoginAt)
        if (_this.showLastLoginAt) {
          _this.lastLoginAt = moment(jData.data.lastLoginAt * 1000).format('DD.MM.YYYY HH:mm:ss')
        }
      })
      _this.getRole()
      _this.getPost()
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'admin'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false || response.data.data.isWrite === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
          email: '',
          roleId: 0,
          password: ''
        },
        accessList: {},
        date: '',
        role: [
        ],
        isAccess: false,
        post: [],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        urls: {
          employee: 'employee',
          role: 'role',
          post: 'post'
        },
        password: '',
        sPassword: '',
        check: 'true',
        phone: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation,
        showLastLoginAt: false,
        lastLoginAt: null
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
