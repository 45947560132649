<template>
  <b-card :header="caption">
    <b-col lg="12">
      <b-tabs card>
        <b-tab v-for=" (item, n) in hcList" :title="`${item.name}`" :active="isActive(item.name)" @click="changeTab(item.name)">
          <div class="spinner" v-if="!viewTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
          <div v-if="viewTable">
            <b-row>
              <b-col offset="9" lg="3">
                <div class="input-group mb-3">
                  <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
                  <div v-if="search.length > 0" class="input-group-append">
                    <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
                  </div>
                  <div v-else class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table v-if="viewTable" :sort-by.sync="sortBy" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
            <template slot="index" slot-scope="data">
              {{perPage * (currentPage - 1) + data.index + 1}}
            </template>
            <template slot="isDemo" slot-scope="data">
              <p v-if="data.item.isDemo === 1"><i class="fa fa-check fa-2x success"></i></p>
              <p v-else><i class="fa fa-close fa-2x danger"></i></p>
            </template>
            <template slot="buttons" slot-scope="data">
              <div class="table__one_buttons">
                <b-btn size="sm" class="change_size btn-info" @click.stop="detail(data.item.id)"><i class="fa fa-eye fa-2x"></i></b-btn>
              </div>
            </template>
          </b-table>
          <nav class="d-flex">
            <b-pagination v-if="viewTable" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
            <label class="d-flex justify-content-center align-items-center mb-3 ml-3" v-if="viewTable">
              Страница:
              <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
            </label>
          </nav>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        tempItem: [],
        hcList: [],
        fields: [
          {key: 'index', label: '#'},
          {key: 'account', label: 'Номер ЛС'},
          {key: 'unifiedAccount', label: 'Номер ЕЛС'},
          {key: 'address', label: 'Адрес'},
          {key: 'description', label: 'Описание'},
          {key: 'buttons', label: ' '}
        ],
        accessList: {},
        sortBy: 'premiseNum',
        isDemo: false,
        searchTimer: null,
        search: '',
        textPage: '1',
        currentPage: 1,
        lastPage: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        currentTab: 1,
        delItem: 0,
        urls: {
          list: 'paymentDocument',
          hc: 'housingcomplex'
        }
      }
    },
    methods: {
      clearSearch () {
        this.search = ''
      },
      setCurrents () {
        if (this.$route.query.tab) {
          this.currentTab = this.$route.query.tab
        }
        if (this.$route.query.currentPage) {
          this.currentPage = parseInt(this.$route.query.currentPage)
        }
      },
      async changeTab (name) {
        this.viewTable = false
        this.currentTab = (name === null ? 1 : name)
        if (!name) {
          await this.setCurrents()
        }
        this.getData()
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      detail (id) {
        console.log('/list/' + id)
        this.$router.push({path: '/paymentdocument/list/' + id})
      },
      getData () {
        this.textPage = String(this.currentPage)
        this.viewTable = false
        let hcId = null
        api.get(this.urls.hc).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.hcList = jData.data
          }
          this.currentTab = (this.currentTab === 1 ? this.hcList[0].name : this.currentTab)
          jData.data.forEach((item) => {
            if (item.name === this.currentTab) {
              hcId = item.id
            }
          })
          return hcId
        }).then((idHc) => {
          let query = {
            search: this.search,
            // isDemo: this.isDemo,
            hcId: idHc,
            page: this.currentPage,
            limit: this.perPage
          }
          return api.get(this.urls.list, query)
        }).then((response) => {
          let jMeta = response.data.meta
          this.lastPage = jMeta.pages
          this.totalRows = jMeta.pages * this.perPage - 1
          this.items = response.data.data
          this.viewTable = true
        })
      },
      isActive (hcName) {
        return hcName === this.$route.query.tab
      },
      replaceRouterQuery () {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            tab: this.currentTab,
            currentPage: this.currentPage
          }
        })
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'payment'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },
    watch: {
      search () {
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => { this.getData() }, 1000)
      },
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      },
      isDemo (newVal, lastVal) {
        if (lastVal === 0 || lastVal === 1) {
          this.getData()
        }
      },
      currentPage (val) {
        this.replaceRouterQuery()
      },
      currentTab () {
        this.replaceRouterQuery()
      }
    },
    mounted () {
      this.changeTab(null)
    }
  }
</script>
