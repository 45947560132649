<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="12">
        <b-card>
          <template #header>
            <strong>Social Media Button</strong> <small>Usage ex.</small><code style="text-transform:lowercase">&lt;b-button variant="facebook"&gt;&lt;span&gt;Facebook&lt;/span&gt;&lt;/b-button&gt;</code>
          </template>
          <h6>Size Small <small>Add this <code>size="sm"</code></small></h6>
          <p>
            <b-button size="sm" variant="facebook"><span>Facebook</span></b-button>
            <b-button size="sm" variant="twitter"><span>Twitter</span></b-button>
            <b-button size="sm" variant="linkedin"><span>LinkedIn</span></b-button>
            <b-button size="sm" variant="flickr"><span>Flickr</span></b-button>
            <b-button size="sm" variant="tumblr"><span>Tumblr</span></b-button>
            <b-button size="sm" variant="xing"><span>Xing</span></b-button>
            <b-button size="sm" variant="github"><span>Github</span></b-button>
            <b-button size="sm" variant="html5"><span>HTML5</span></b-button>
            <b-button size="sm" variant="openid"><span>OpenID</span></b-button>
            <b-button size="sm" variant="stack-overflow"><span>StackOverflow</span></b-button>
            <b-button size="sm" variant="css3"><span>CSS3</span></b-button>
            <b-button size="sm" variant="youtube"><span>YouTube</span></b-button>
            <b-button size="sm" variant="dribbble"><span>Dribbble</span></b-button>
            <b-button size="sm" variant="google-plus"><span>Google+</span></b-button>
            <b-button size="sm" variant="instagram"><span>Instagram</span></b-button>
            <b-button size="sm" variant="pinterest"><span>Pinterest</span></b-button>
            <b-button size="sm" variant="vk"><span>VK</span></b-button>
            <b-button size="sm" variant="yahoo"><span>Yahoo</span></b-button>
            <b-button size="sm" variant="behance"><span>Behance</span></b-button>
            <b-button size="sm" variant="dropbox"><span>Dropbox</span></b-button>
            <b-button size="sm" variant="reddit"><span>Reddit</span></b-button>
            <b-button size="sm" variant="spotify"><span>Spotify</span></b-button>
            <b-button size="sm" variant="vine"><span>Vine</span></b-button>
            <b-button size="sm" variant="foursquare"><span>Forsquare</span></b-button>
            <b-button size="sm" variant="vimeo"><span>Vimeo</span></b-button>
          </p>
          <h6>Size Normal</h6>
          <p>
            <b-button variant="facebook"><span>Facebook</span></b-button>
            <b-button variant="twitter"><span>Twitter</span></b-button>
            <b-button variant="linkedin"><span>LinkedIn</span></b-button>
            <b-button variant="flickr"><span>Flickr</span></b-button>
            <b-button variant="tumblr"><span>Tumblr</span></b-button>
            <b-button variant="xing"><span>Xing</span></b-button>
            <b-button variant="github"><span>Github</span></b-button>
            <b-button variant="html5"><span>HTML5</span></b-button>
            <b-button variant="openid"><span>OpenID</span></b-button>
            <b-button variant="stack-overflow"><span>StackOverflow</span></b-button>
            <b-button variant="css3"><span>CSS3</span></b-button>
            <b-button variant="youtube"><span>YouTube</span></b-button>
            <b-button variant="dribbble"><span>Dribbble</span></b-button>
            <b-button variant="google-plus"><span>Google+</span></b-button>
            <b-button variant="instagram"><span>Instagram</span></b-button>
            <b-button variant="pinterest"><span>Pinterest</span></b-button>
            <b-button variant="vk"><span>VK</span></b-button>
            <b-button variant="yahoo"><span>Yahoo</span></b-button>
            <b-button variant="behance"><span>Behance</span></b-button>
            <b-button variant="dropbox"><span>Dropbox</span></b-button>
            <b-button variant="reddit"><span>Reddit</span></b-button>
            <b-button variant="spotify"><span>Spotify</span></b-button>
            <b-button variant="vine"><span>Vine</span></b-button>
            <b-button variant="foursquare"><span>Forsquare</span></b-button>
            <b-button variant="vimeo"><span>Vimeo</span></b-button>
          </p>
          <h6>Size Large <small>Add this <code>size="lg"</code></small></h6>
          <p>
            <b-button size="lg" variant="facebook"><span>Facebook</span></b-button>
            <b-button size="lg" variant="twitter"><span>Twitter</span></b-button>
            <b-button size="lg" variant="linkedin"><span>LinkedIn</span></b-button>
            <b-button size="lg" variant="flickr"><span>Flickr</span></b-button>
            <b-button size="lg" variant="tumblr"><span>Tumblr</span></b-button>
            <b-button size="lg" variant="xing"><span>Xing</span></b-button>
            <b-button size="lg" variant="github"><span>Github</span></b-button>
            <b-button size="lg" variant="html5"><span>HTML5</span></b-button>
            <b-button size="lg" variant="openid"><span>OpenID</span></b-button>
            <b-button size="lg" variant="stack-overflow"><span>StackOverflow</span></b-button>
            <b-button size="lg" variant="css3"><span>CSS3</span></b-button>
            <b-button size="lg" variant="youtube"><span>YouTube</span></b-button>
            <b-button size="lg" variant="dribbble"><span>Dribbble</span></b-button>
            <b-button size="lg" variant="google-plus"><span>Google+</span></b-button>
            <b-button size="lg" variant="instagram"><span>Instagram</span></b-button>
            <b-button size="lg" variant="pinterest"><span>Pinterest</span></b-button>
            <b-button size="lg" variant="vk"><span>VK</span></b-button>
            <b-button size="lg" variant="yahoo"><span>Yahoo</span></b-button>
            <b-button size="lg" variant="behance"><span>Behance</span></b-button>
            <b-button size="lg" variant="dropbox"><span>Dropbox</span></b-button>
            <b-button size="lg" variant="reddit"><span>Reddit</span></b-button>
            <b-button size="lg" variant="spotify"><span>Spotify</span></b-button>
            <b-button size="lg" variant="vine"><span>Vine</span></b-button>
            <b-button size="lg" variant="foursquare"><span>Forsquare</span></b-button>
            <b-button size="lg" variant="vimeo"><span>Vimeo</span></b-button>
          </p>
        </b-card>
      </b-col><!--/.col-->
      <b-col cols="12">
        <b-card>
          <template #header>
            <strong>Social Media Button</strong> <small>Only icons. Usage ex.</small> <code style="text-transform:lowercase">&lt;b-button variant="facebook icon"&gt;&lt;span&gt;Facebook&lt;/span&gt;&lt;/b-button&gt;</code>
          </template>
          <h6>Size Small <small>Add this <code>size="sm"</code></small></h6>
          <p>
            <b-button size="sm" variant="facebook icon"></b-button>
            <b-button size="sm" variant="twitter icon"></b-button>
            <b-button size="sm" variant="linkedin icon"></b-button>
            <b-button size="sm" variant="flickr icon"></b-button>
            <b-button size="sm" variant="tumblr icon"></b-button>
            <b-button size="sm" variant="xing icon"></b-button>
            <b-button size="sm" variant="github icon"></b-button>
            <b-button size="sm" variant="html5 icon"></b-button>
            <b-button size="sm" variant="openid icon"></b-button>
            <b-button size="sm" variant="stack-overflow icon"></b-button>
            <b-button size="sm" variant="css3 icon"></b-button>
            <b-button size="sm" variant="youtube icon"></b-button>
            <b-button size="sm" variant="dribbble icon"></b-button>
            <b-button size="sm" variant="google-plus icon"></b-button>
            <b-button size="sm" variant="instagram icon"></b-button>
            <b-button size="sm" variant="pinterest icon"></b-button>
            <b-button size="sm" variant="vk icon"></b-button>
            <b-button size="sm" variant="yahoo icon"></b-button>
            <b-button size="sm" variant="behance icon"></b-button>
            <b-button size="sm" variant="dropbox icon"></b-button>
            <b-button size="sm" variant="reddit icon"></b-button>
            <b-button size="sm" variant="spotify icon"></b-button>
            <b-button size="sm" variant="vine icon"></b-button>
            <b-button size="sm" variant="foursquare icon"></b-button>
            <b-button size="sm" variant="vimeo icon"></b-button>
          </p>
          <h6>Size Normal</h6>
          <p>
            <b-button variant="facebook icon"></b-button>
            <b-button variant="twitter icon"></b-button>
            <b-button variant="linkedin icon"></b-button>
            <b-button variant="flickr icon"></b-button>
            <b-button variant="tumblr icon"></b-button>
            <b-button variant="xing icon"></b-button>
            <b-button variant="github icon"></b-button>
            <b-button variant="html5 icon"></b-button>
            <b-button variant="openid icon"></b-button>
            <b-button variant="stack-overflow icon"></b-button>
            <b-button variant="css3 icon"></b-button>
            <b-button variant="youtube icon"></b-button>
            <b-button variant="dribbble icon"></b-button>
            <b-button variant="google-plus icon"></b-button>
            <b-button variant="instagram icon"></b-button>
            <b-button variant="pinterest icon"></b-button>
            <b-button variant="vk icon"></b-button>
            <b-button variant="yahoo icon"></b-button>
            <b-button variant="behance icon"></b-button>
            <b-button variant="dropbox icon"></b-button>
            <b-button variant="reddit icon"></b-button>
            <b-button variant="spotify icon"></b-button>
            <b-button variant="vine icon"></b-button>
            <b-button variant="foursquare icon"></b-button>
            <b-button variant="vimeo icon"></b-button>
          </p>
          <h6>Size Large <small>Add this <code>size="lg"</code></small></h6>
          <p>
            <b-button size="lg" variant="facebook icon"><span>Facebook</span></b-button>
            <b-button size="lg" variant="twitter icon"><span>Twitter</span></b-button>
            <b-button size="lg" variant="linkedin icon"><span>LinkedIn</span></b-button>
            <b-button size="lg" variant="flickr icon"><span>Flickr</span></b-button>
            <b-button size="lg" variant="tumblr icon"><span>Tumblr</span></b-button>
            <b-button size="lg" variant="xing icon"><span>Xing</span></b-button>
            <b-button size="lg" variant="github icon"><span>Github</span></b-button>
            <b-button size="lg" variant="html5 icon"><span>HTML5</span></b-button>
            <b-button size="lg" variant="openid icon"><span>OpenID</span></b-button>
            <b-button size="lg" variant="stack-overflow icon"><span>StackOverflow</span></b-button>
            <b-button size="lg" variant="css3 icon"><span>CSS3</span></b-button>
            <b-button size="lg" variant="youtube icon"><span>YouTube</span></b-button>
            <b-button size="lg" variant="dribbble icon"><span>Dribbble</span></b-button>
            <b-button size="lg" variant="google-plus icon"><span>Google+</span></b-button>
            <b-button size="lg" variant="instagram icon"><span>Instagram</span></b-button>
            <b-button size="lg" variant="pinterest icon"><span>Pinterest</span></b-button>
            <b-button size="lg" variant="vk icon"><span>VK</span></b-button>
            <b-button size="lg" variant="yahoo icon"><span>Yahoo</span></b-button>
            <b-button size="lg" variant="behance icon"><span>Behance</span></b-button>
            <b-button size="lg" variant="dropbox icon"><span>Dropbox</span></b-button>
            <b-button size="lg" variant="reddit icon"><span>Reddit</span></b-button>
            <b-button size="lg" variant="spotify icon"><span>Spotify</span></b-button>
            <b-button size="lg" variant="vine icon"><span>Vine</span></b-button>
            <b-button size="lg" variant="foursquare icon"><span>Forsquare</span></b-button>
            <b-button size="lg" variant="vimeo icon"><span>Vimeo</span></b-button>
          </p>
        </b-card>
      </b-col><!--/.col-->

      <b-col cols="12">
        <b-card>
          <template #header>
            <strong>Social Media Button</strong> <small>Only text. Usage ex.</small> <code style="text-transform:lowercase">&lt;b-button variant="facebook text"&gt;&lt;span&gt;Facebook&lt;/span&gt;&lt;/b-button&gt;</code>
          </template>
          <h6>Size Small <small>Add this <code>size="sm"</code></small></h6>
          <p>
            <b-button size="sm" variant="facebook text"><span>Facebook</span></b-button>
            <b-button size="sm" variant="twitter text"><span>Twitter</span></b-button>
            <b-button size="sm" variant="linkedin text"><span>LinkedIn</span></b-button>
            <b-button size="sm" variant="flickr text"><span>Flickr</span></b-button>
            <b-button size="sm" variant="tumblr text"><span>Tumblr</span></b-button>
            <b-button size="sm" variant="xing text"><span>Xing</span></b-button>
            <b-button size="sm" variant="github text"><span>Github</span></b-button>
            <b-button size="sm" variant="html5 text"><span>HTML5</span></b-button>
            <b-button size="sm" variant="openid text"><span>OpenID</span></b-button>
            <b-button size="sm" variant="stack-overflow text"><span>StackOverflow</span></b-button>
            <b-button size="sm" variant="css3 text"><span>CSS3</span></b-button>
            <b-button size="sm" variant="youtube text"><span>YouTube</span></b-button>
            <b-button size="sm" variant="dribbble text"><span>Dribbble</span></b-button>
            <b-button size="sm" variant="google-plus text"><span>Google+</span></b-button>
            <b-button size="sm" variant="instagram text"><span>Instagram</span></b-button>
            <b-button size="sm" variant="pinterest text"><span>Pinterest</span></b-button>
            <b-button size="sm" variant="vk text"><span>VK</span></b-button>
            <b-button size="sm" variant="yahoo text"><span>Yahoo</span></b-button>
            <b-button size="sm" variant="behance text"><span>Behance</span></b-button>
            <b-button size="sm" variant="dropbox text"><span>Dropbox</span></b-button>
            <b-button size="sm" variant="reddit text"><span>Reddit</span></b-button>
            <b-button size="sm" variant="spotify text"><span>Spotify</span></b-button>
            <b-button size="sm" variant="vine text"><span>Vine</span></b-button>
            <b-button size="sm" variant="foursquare text"><span>Forsquare</span></b-button>
            <b-button size="sm" variant="vimeo text"><span>Vimeo</span></b-button>
          </p>
          <h6>Size Normal</h6>
          <p>
            <b-button variant="facebook text"><span>Facebook</span></b-button>
            <b-button variant="twitter text"><span>Twitter</span></b-button>
            <b-button variant="linkedin text"><span>LinkedIn</span></b-button>
            <b-button variant="flickr text"><span>Flickr</span></b-button>
            <b-button variant="tumblr text"><span>Tumblr</span></b-button>
            <b-button variant="xing text"><span>Xing</span></b-button>
            <b-button variant="github text"><span>Github</span></b-button>
            <b-button variant="html5 text"><span>HTML5</span></b-button>
            <b-button variant="openid text"><span>OpenID</span></b-button>
            <b-button variant="stack-overflow text"><span>StackOverflow</span></b-button>
            <b-button variant="css3 text"><span>CSS3</span></b-button>
            <b-button variant="youtube text"><span>YouTube</span></b-button>
            <b-button variant="dribbble text"><span>Dribbble</span></b-button>
            <b-button variant="google-plus text"><span>Google+</span></b-button>
            <b-button variant="instagram text"><span>Instagram</span></b-button>
            <b-button variant="pinterest text"><span>Pinterest</span></b-button>
            <b-button variant="vk text"><span>VK</span></b-button>
            <b-button variant="yahoo text"><span>Yahoo</span></b-button>
            <b-button variant="behance text"><span>Behance</span></b-button>
            <b-button variant="dropbox text"><span>Dropbox</span></b-button>
            <b-button variant="reddit text"><span>Reddit</span></b-button>
            <b-button variant="spotify text"><span>Spotify</span></b-button>
            <b-button variant="vine text"><span>Vine</span></b-button>
            <b-button variant="foursquare text"><span>Forsquare</span></b-button>
            <b-button variant="vimeo text"><span>Vimeo</span></b-button>
          </p>
          <h6>Size Large <small>Add this <code>size="lg"</code></small></h6>
          <p>
            <b-button size="lg" variant="facebook text"><span>Facebook</span></b-button>
            <b-button size="lg" variant="twitter text"><span>Twitter</span></b-button>
            <b-button size="lg" variant="linkedin text"><span>LinkedIn</span></b-button>
            <b-button size="lg" variant="flickr text"><span>Flickr</span></b-button>
            <b-button size="lg" variant="tumblr text"><span>Tumblr</span></b-button>
            <b-button size="lg" variant="xing text"><span>Xing</span></b-button>
            <b-button size="lg" variant="github text"><span>Github</span></b-button>
            <b-button size="lg" variant="html5 text"><span>HTML5</span></b-button>
            <b-button size="lg" variant="openid text"><span>OpenID</span></b-button>
            <b-button size="lg" variant="stack-overflow text"><span>StackOverflow</span></b-button>
            <b-button size="lg" variant="css3 text"><span>CSS3</span></b-button>
            <b-button size="lg" variant="youtube text"><span>YouTube</span></b-button>
            <b-button size="lg" variant="dribbble text"><span>Dribbble</span></b-button>
            <b-button size="lg" variant="google-plus text"><span>Google+</span></b-button>
            <b-button size="lg" variant="instagram text"><span>Instagram</span></b-button>
            <b-button size="lg" variant="pinterest text"><span>Pinterest</span></b-button>
            <b-button size="lg" variant="vk text"><span>VK</span></b-button>
            <b-button size="lg" variant="yahoo text"><span>Yahoo</span></b-button>
            <b-button size="lg" variant="behance text"><span>Behance</span></b-button>
            <b-button size="lg" variant="dropbox text"><span>Dropbox</span></b-button>
            <b-button size="lg" variant="reddit text"><span>Reddit</span></b-button>
            <b-button size="lg" variant="spotify text"><span>Spotify</span></b-button>
            <b-button size="lg" variant="vine text"><span>Vine</span></b-button>
            <b-button size="lg" variant="foursquare text"><span>Forsquare</span></b-button>
            <b-button size="lg" variant="vimeo text"><span>Vimeo</span></b-button>
          </p>
        </b-card>
      </b-col><!--/.col-->
    </b-row><!--/.row-->
  </div>
</template>

<script>
export default {
  name: 'social-buttons'
}
</script>

<style scoped lang="css">
.btn {
  margin-bottom: 4px;
}
</style>
