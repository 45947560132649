<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Конфигурация электронной почты</strong>
          </template>
          <b-form-group
            label="Отправитель"
            label-for="name"
            :label-cols="2"
            :horizontal="true">
            <b-form-group
              label="E-mail"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              label-class="text-sm-right pr-4"
              :invalid-feedback="validation.email(model.sender)"
              :state="state5">
              <b-form-input id="id" type="text" :state="state5" v-model="model.sender"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Хост"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              label-class="text-sm-right pr-4"
              :invalid-feedback="validation.empty(model.host)"
              :state="state1">
              <b-form-input id="id" type="text" :state="state1" v-model="model.host"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Порт"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              label-class="text-sm-right pr-4"
              :invalid-feedback="validation.empty(model.port)"
              :state="state2">
              <b-form-input id="id" type="text" :state="state2" v-model="model.port"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Логин"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              label-class="text-sm-right pr-4"
              :invalid-feedback="validation.email(model.user)"
              :state="state3">
              <b-form-input id="id" type="text" :state="state3" v-model="model.user"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Пароль"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              label-class="text-sm-right pr-4"
              :invalid-feedback="validation.empty(model.password)"
              :state="state4">
              <b-form-input id="id" type="text" :state="state4" v-model="model.password"></b-form-input>
            </b-form-group>
          </b-form-group>
          <hr>
          <b-form-group
          label="Получатель"
          label-for="name"
          :label-cols="2"
          :horizontal="true">
            <b-form-group
              label="E-mail"
              label-for="name"
              :label-cols="3"
              :horizontal="true"
              label-class="text-sm-right pr-4"
              :invalid-feedback="validation.email(model.receiver)"
              :state="state6">
              <b-form-input id="id" type="text" :state="state6" v-model="model.receiver"></b-form-input>
            </b-form-group>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Конфигурация успешно обновлена">
      <p class="my-4">Конфигурация успешно обновлена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.model.host)
      },
      state2 () {
        return validation.long(this.model.port)
      },
      state3 () {
        return validation.emailBool(this.model.user)
      },
      state4 () {
        return validation.long(this.model.password)
      },
      state5 () {
        return validation.emailBool(this.model.sender)
      },
      state6 () {
        return validation.emailBool(this.model.receiver)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true && _this.state3 === true && _this.state4 === true && _this.state5 === true && _this.state6 === true) {
          _this.show = true
          api.put(_this.urls.mail, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/dashboard')
              }, 1000)
            }
          })
        }
      },
      goBack () {
        this.$router.go(-1)
      }
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.mail).then(function (response) {
        let jData = response.data
        _this.model = jData.data
      })
    },
    watch: {
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'mail'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
        },
        date: '',
        accessList: {},
        posts: [],
        urls: {
          mail: 'mail/demo'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
