<template>
  <b-card header="Платёжные документы">
    <b-row>
      <b-col v-if="accessList.isWrite" lg="3">
        <b-btn class="mb-3 btn-success model_btn--add_new" @click.stop="createPaymentDocument">Добавить документ</b-btn>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="11">
        <b-form-group
          label="Год"
          label-for="date"
          :label-cols="1"
          :horizontal="true"
        >
          <datepicker
            :minimumView="'year'"
            :maximumView="'year'"
            :initialView="'year'"
            v-model="date"
            :format="customFormatter"
            input-class="data_picker__input"
            language="ru"
            placeholder="Выберите год"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-table
      :hover="true"
      :striped="striped"
      :bordered="bordered"
      :small="small"
      :fixed="fixed"
      responsive="sm"
      :items="items"
      :fields="fields"
      :current-page="1"
      :per-page="perPage"
    >
      <template slot="index" slot-scope="data">
        {{perPage * (currentPage - 1) + data.index + 1}}
      </template>
      <template slot="buttons" slot-scope="data">
        <div class="table__one_buttons">
          <b-btn size="sm" class="change_size btn-info" @click.stop="detail(data.item.id)">
            <i class="fa fa-eye fa-2x"></i>
          </b-btn>

          <b-btn v-if="data.item.isManual && accessList.isUpdate" size="sm" class="change_size btn-warning" @click.stop="edit(data.item.id)">
            <i class="fa fa-pencil fa-2x"></i>
          </b-btn>

          <b-btn size="sm" v-if="accessList.isDelete" class="change_size btn-danger" @click.stop="remove(data.item.id)">
            <i class="fa fa-trash-o fa-2x"></i>
          </b-btn>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'

  export default {
    name: 'c-table',
    components: {
      datetime: Datetime,
      Datepicker
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [],
        accessList: {},
        fields: [
          {key: 'index', label: '#'},
          {key: 'month', label: 'Месяц'},
          {key: 'accrued', label: 'Начислено'},
          {key: 'total', label: 'С учетом задолженности'},
          {key: 'paid', label: 'Оплачено'},
          {key: 'buttons', label: ' '}
        ],
        searchTimer: null,
        search: '',
        textPage: '1',
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        totalRows: 1,
        date: new Date(),
        urls: {
          list: 'paymentDocument',
          remove: 'paymentDocument/remove'
        }
      }
    },
    methods: {
      customFormatter (date) {
        moment.locale('ru')
        return moment(date).format('YYYY')
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      detail (id) {
        this.$router.push({
          path: '/paymentdocument/detail/' + id,
          query: {
            readonly: true
          }
        })
      },
      edit (id) {
        this.$router.push({
          path: '/paymentdocument/detail/' + id,
          query: {
            accountId: this.$route.params.id
          }
        })
      },
      remove (id) {
        return api.post(this.urls.remove + '/' + id)
          .then(() => {
            this.getData()
          })
      },
      getData () {
        this.textPage = String(this.currentPage)
        let query = {
          timestamp: moment(this.date).format('X')
        }
        api.get(this.urls.list + `/${this.$route.params.id}`, query).then((response) => {
          // let jMeta = response.data.meta
          // this.lastPage = jMeta.pages
          // this.totalRows = jMeta.pages * this.perPage - 1
          let jData = response.data
          if (jData.status) {
            this.items = jData.data
          }
        })
      },
      createPaymentDocument () {
        this.$router.push({
          path: '/paymentdocument/detail',
          query: {
            accountId: this.$route.params.id
          }
        })
      }
    },
    watch: {
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      },
      date () {
        this.getData()
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'payment'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },
    mounted () {
      this.getData()
    }
  }
</script>

