<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template>
            <loading
              :show="show"
              :label="label">
            </loading>
          </template>
          <template #header>
            <strong>Изменение версии</strong>
          </template>
          <b-form-group
            label="Наименование"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state1">
            <b-form-input id="carNumber" type="text" :state="state1" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Начало технических работ"
            label-for="installationDate"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.dateBegin)"
            :state="state3">
            <datetime type="datetime"
                      id="installationDate"
                      v-model="date.dateBegin"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Укажите дату и время"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state3, valid__dateTime: state3 === true}"
                      :state="state3">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Окончание технических работ"
            label-for="installationDate"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.dateEnd)"
            :state="state4">
            <datetime type="datetime"
                      id="installationDate"
                      v-model="date.dateEnd"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Укажите дату и время"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state4, valid__dateTime: state4 === true}"
                      :state="state4">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Текст описания"
            label-for="description"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.description)"
            :state="state2">
            <vue-editor :content="model.description"
                        v-bind:class="{invalid__dateTime: !state2, valid__dateTime: state2 === true}"
                        :state="state2"
                        v-model="model.description"
                        :editorToolbar="customToolbar">
            </vue-editor>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Версия успешно изменена">
      <p class="my-4">Версия успешно изменена</p>
    </b-modal>
  </div>
</template>


<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import validation from '../../config/validation'
  import consts from '../../config/const'
  import { VueEditor } from 'vue2-quill-editor'
  import customToolbar from '../../config/editor'

  export default {
    name: 'New',
    components: {
      VueEditor,
      loading,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.long(this.model.name)
      },
      state2 () {
        return validation.long(this.model.description)
      },
      state3 () {
        return validation.dateSelectBool(this.date.dateBegin)
      },
      state4 () {
        return validation.dateSelectBool(this.date.dateEnd)
      }
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      sendForm () {
        let _this = this
        _this.show = true
        _this.model.dateBegin = moment(_this.date.dateBegin).format('X')
        _this.model.dateEnd = moment(_this.date.dateEnd).format('X')
        if (_this.state1 && _this.state2 && _this.state3 && _this.state4) {
          api.put(_this.urls.version + '/' + _this.$route.params.id, _this.model).then(function (response) {
            if (response.status) {
              _this.show = false
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/technicalservices/list')
              }, 1000)
            }
          })
        }
      }
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.version + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
        _this.date.dateBegin = moment(_this.model.dateBegin * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
        _this.date.dateEnd = moment(_this.model.dateEnd * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
      })
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'technicalServices'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
        },
        accessList: {},
        urls: {
          version: 'technicalservices'
        },
        date: {
          dateBegin: '',
          dateEnd: ''
        },
        show: false,
        checkLoader: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        check: '',
        customToolbar: customToolbar,
        consts: consts,
        validation: validation
      }
    }
  }
</script>


