<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>
          <b-form-group
            label="Демо режим"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isDemo" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Город"
            label-for="cities"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.housingComplex.cityId)"
            :state="stateCity">
            <b-form-select id="cities"
                           :options="cities"
                           v-model="model.housingComplex.cityId"
                           :state="stateCity">
              <template slot="first">
                <option :value="0" disabled>Выберите город</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Жилищный комплекс"
            label-for="hc"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.hcId)"
            :state="state1">
            <b-form-select id="hc"
                           :options="hcList"
                           placeholder="Выберите ЖК"
                           v-model="model.hcId"
                           :state="state1">
              <template slot="first">
                <option :value="null" disabled>Выберите ЖК</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Улица"
            label-for="Street"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.streetId)"
            :state="state2">
            <b-form-select id="Street"
                           :options="streetList"
                           placeholder="Выберите улицу"
                           v-model="model.streetId"
                           :state="state2">
              <template slot="first">
                <option :value="null" disabled>Выберите улицу</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Номер дома"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.objectNumber)"
            :state="state3">
            <b-form-input id="id" type="text" :state="state3" v-model="model.objectNumber"></b-form-input>
          </b-form-group>
          <!-- <b-form-group
            label="Количество квартир"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.premiseCount)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.premiseCount"></b-form-input>
          </b-form-group> -->
          <b-form-group
            label="IP АТС"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="name" type="text" v-model="model.asteriskGateway.ip"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Порт АТС"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="id" type="text" v-model="model.asteriskGateway.port"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Количество подъездов"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isIntEntrance(model.entranceCount)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.entranceCount"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Код диспетчерской"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.dispatchCode)"
            :state="state5">
            <b-form-input id="id" type="text" :state="state5" v-model="model.dispatchCode"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Идентификационный код адреса"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="name" type="text" v-model="model.gisCode"></b-form-input>
            <span>Информацию можно получить: <a href="https://dom.gosuslugi.ru/#!/houses" target="_blank">https://dom.gosuslugi.ru/#!/houses</a></span>
          </b-form-group>
          <b-form-group
            label="Общая площадь"
            label-for="totalArea"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.totalArea)"
            :state="stateTotalArea">
            <b-form-input id="totalArea" type="text" :state="stateTotalArea" v-model="model.totalArea"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Жилая площадь"
            label-for="livingArea"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.livingArea)"
            :state="stateLivingArea">
            <b-form-input id="livingArea" type="text" :state="stateLivingArea" v-model="model.livingArea"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Количество жилых помещений"
            label-for="livingPremises"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.livingPremises)"
            :state="stateLivingPremises">
            <b-form-input id="livingPremises" type="text" :state="stateLivingPremises" v-model="model.livingPremises"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Количество нежилых помещений"
            label-for="nonLivingPremises"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.nonLivingPremises)"
            :state="stateNonLivingPremises">
            <b-form-input id="nonLivingPremises" type="text" :state="stateNonLivingPremises" v-model="model.nonLivingPremises"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Доступ к оплатам"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.paymentAccess" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Отопительный сезон"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <div class="d-flex">
              <label @click.prevent="viewSetSeasonModal()" class="switch switch-3d switch-primary mb-0 mr-3 align-self-center">
                <input type="checkbox" v-model="model.heatingSeason" class="switch-input" checked>
                <span class="switch-label" data-on="Да" data-off="Нет"></span>
                <span class="switch-handle"></span>
              </label>
              <b-form-input id="id" type="text" plaintext v-model="model.seasonDate"></b-form-input>
              <b-btn size="sm" class="btn-success" @click.stop="viewHistory()">История</b-btn>
            </div>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Здание успешно сохранено">
      <p class="my-4">Здание успешно сохранено</p>
    </b-modal>
    <b-modal size="lg" ref="historyModal" hide-footer title='История отопительных сезонов'>
      <b-form-group
        label="Период:"
        label-for="link"
        :label-cols="3"
        class="col-4 d-inline-block"
        :horizontal="true">
        <datepicker :minimumView="'year'" :maximumView="'year'" :initialView="'year'" v-model="date.seasonsHistory" :format="customFormatter" input-class="data_picker__input" language="ru" placeholder="Выберите временной промежуток"></datepicker>
      </b-form-group>
      <b-form-group
        label="История"
        label-for=""
        :label-cols="3"
        :horizontal="false"
        label-size="lg">
        <b-table v-if="seasonsHistory.length > 0" :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="seasonsHistory" :fields="fieldsHistory">
        </b-table>
        <p v-else>Данные отсутствуют</p>
      </b-form-group>
    </b-modal>
    <b-modal size="md" ref="setSeasonModal" centered hide-footer title='Управление отопительным сезоном'>
      <b-form-group
        label="Дата"
        label-for="requestClosed"
        :label-cols="2"
        label-class="text-left"
        class="col-12"
        :horizontal="true"
        :state="state6">
        <datetime type="date"
          id="requestClosed"
          v-model="date.seasonActDate"
          class="theme-orange"
          input-class="dataTimePick"
          placeholder="Дата"
          moment-locale="ru"
          :phrases="{ok: 'OK', cancel: 'Отменить'}"
          v-bind:class="{invalid__dateTime: !state6, valid__dateTime: state6 === true}"
          :state="state6">
        </datetime>
      </b-form-group>
      <b-btn size="sm" class="btn-success" @click.stop="setSeasonModal()">Сохранить</b-btn>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import 'vue-datetime/dist/vue-datetime.css'

  export default {
    name: 'Item',
    data () {
      return {
        buildingId: this.$route.params.id,
        model: {
          isDemo: false,
          hcId: 0,
          streetId: 0,
          objectNumber: '',
          premiseCount: '',
          entranceCount: 1,
          asteriskGateway: {
            ip: '',
            port: ''
          },
          housingComplex: {
            cityId: 0
          }
        },
        cities: [],
        accessList: {},
        posts: [],
        seasonsHistory: [],
        streetList: [],
        hcList: [],
        fieldsHistory: [
          {key: 'timestamp', label: 'Дата'},
          {key: 'active', label: 'Действие'}
        ],
        date: {
          seasonsHistory: moment().format('YYYY'),
          seasonActDate: moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
        },
        urls: {
          building: 'building',
          street: 'street',
          housingcomplex: 'housingcomplex',
          seasonsHistory: 'season',
          city: 'city'
        },
        hComplex: '',
        heatingSeasonStatus: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    },
    computed: {
      pageTitle () {
        return this.buildingId ? 'Редактировать дом' : 'Добавить дом'
      },
      stateCity () {
        return validation.numId(this.model.housingComplex.cityId)
      },
      state1 () {
        return validation.numId(this.model.hcId)
      },
      state2 () {
        return validation.numId(this.model.streetId)
      },
      state3 () {
        return validation.long(this.model.objectNumber)
      },
      state4 () {
        return validation.isIntEntranceBool(this.model.entranceCount)
      },
      state5 () {
        return validation.isNumberBool(this.model.dispatchCode)
      },
      state6 () {
        return validation.dateSelectBool(this.date.seasonActDate)
      },
      stateTotalArea () {
        return validation.isNumberBool(this.model.totalArea)
      },
      stateLivingArea () {
        return validation.isNumberBool(this.model.livingArea)
      },
      stateLivingPremises () {
        return validation.isNumberBool(this.model.livingPremises)
      },
      stateNonLivingPremises () {
        return validation.isNumberBool(this.model.nonLivingPremises)
      }
    },
    components: {
      loading,
      datetime: Datetime,
      Datepicker
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      updateMessageSeason () {
        api.get(this.urls.building + '/' + this.$route.params.id).then((response) => {
          let jData = response.data
          this.model.seasonDate = jData.data.seasonDate
          this.model.heatingSeason = jData.data.heatingSeason
        })
      },
      customFormatter (date) {
        moment.locale('ru')
        return moment(date).format('YYYY')
      },
      getSeasonsHistory () {
        let query = {
          buildingId: this.$route.params.id,
          timestamp: moment(this.date.seasonsHistory).format('X'),
          limit: 'YEAR'
        }
        api.get(this.urls.seasonsHistory, query).then((response) => {
          let jData = response.data
          this.seasonsHistory = jData.data
          this.seasonsHistory.forEach(item => {
            item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY')
          })
        })
      },
      setSeasonModal () {
        if (this.state6) {
          let query = {
            buildingId: this.$route.params.id,
            timestamp: moment(this.date.seasonActDate).format('X'),
            active: !this.model.heatingSeason
          }
          api.post(this.urls.seasonsHistory, query).then((response) => {
            this.updateMessageSeason()
            this.$refs.setSeasonModal.hide()
            this.date.seasonActDate = moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
          })
        }
      },
      viewSetSeasonModal () {
        this.$refs.setSeasonModal.show()
      },
      viewHistory () {
        this.seasonsHistory = []
        this.getSeasonsHistory()
        this.$refs.historyModal.show()
      },
      whenTabInFocus () {
        let _this = this
        api.get(_this.urls.housingcomplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.id === _this.model.hcId) {
                _this.hComplex = item.name
              }
            })
          }
        })
      },
      sendForm () {
        let _this = this
        if (
          _this.stateCity && _this.state1 && _this.state2 && _this.state3 && _this.state4 && _this.state5 &&
          _this.stateTotalArea && _this.stateLivingArea && _this.stateLivingPremises && _this.stateNonLivingPremises
        ) {
          _this.whenTabInFocus()
          _this.show = true

          const removeEmpty = (obj) => {
            Object.keys(obj).forEach(key => {
              if (obj[key] && typeof obj[key] === 'object') {
                removeEmpty(obj[key])
              } else if (obj[key] == null || obj[key] === '' || obj[key] === undefined) {
                delete obj[key]
              }
            })
          }
          removeEmpty(this.model)

          let promise = null
          if (this.buildingId) {
            promise = api.put(_this.urls.building + '/' + _this.$route.params.id, _this.model)
          } else {
            promise = api.post(_this.urls.building, _this.model)
          }
          promise.then(function (response) {
            _this.show = false
            setTimeout(function () {
            }, 1000)
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({ path: '/building/list', query: { tab: _this.hComplex, currentPage: _this.$route.query.currentPage } })
              }, 1000)
            }
          })
        }
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.streetList.push(temp)
            })
          }
        })
      },
      getHousingComplex () {
        let _this = this
        let query = {}
        if (_this.model.housingComplex.cityId > 0) {
          query.cityId = this.model.housingComplex.cityId
        }

        api.get(this.urls.housingcomplex, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.hcList = []
            let isIssetHcId = false
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.hcList.push(temp)
              if (spec.id === _this.model.hcId) {
                isIssetHcId = true
              }
            })
            if (!isIssetHcId) {
              _this.model.hcId = 0
            }
          }
        })
      },
      getCities () {
        api.get(this.urls.city).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name + ' [' + spec.utc + ']'
                this.cities.push(temp)
              }
            })
          }
        })
      }
    },
    watch: {
      'date.seasonsHistory' () {
        this.seasonsHistory = []
        this.getSeasonsHistory()
      },
      'model.housingComplex.cityId' () {
        this.getHousingComplex()
      }
    },
    mounted: function () {
      this.getCities()
      this.getStreet()
      if (this.buildingId) {
        this.getSeasonsHistory()
        api.get(this.urls.building + '/' + this.$route.params.id).then((response) => {
          let jData = response.data
          this.model = jData.data
          if (this.model.asteriskGateway === null) {
            this.model.asteriskGateway = {
              ip: '',
              port: ''
            }
          }
        })
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'building'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false || response.data.data.isWrite === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    }
  }
</script>
