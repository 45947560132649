<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template>
            <loading
              :show="show"
              :label="label">
            </loading>
          </template>
          <template #header>
            <strong>Изменение автомобиля</strong>
          </template>
          <b-form-group
            label="Номер автомобиля"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.carNumber)"
            :state="state1">
            <b-form-input id="carNumber" type="text" :state="state1" v-model="model.carNumber"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Владелец автомобиля"
            label-for="file"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.emptyObj(value)"
            :state="state2">
            <multiselect track-by="id" v-model="value" v-bind:class="{invalid__dateTime: !state2, valid__dateTime: state2 === true}" :options="options" :custom-label="nameWithLang" :show-labels="false" placeholder="Выберите владельца" label="name">
              <span slot="noResult">Совпадений не найдено!</span>
            </multiselect>
          </b-form-group>
          <!--<b-form-group-->
            <!--label="Клиент"-->
            <!--label-for="userId"-->
            <!--:label-cols="3"-->
            <!--:horizontal="true">-->
            <!--<b-form-select id="userId"-->
                           <!--:options="clients"-->
                           <!--v-model="model.userId">-->
              <!--<template slot="first">-->
                <!--<option :value="null" disabled>Выберите клиента</option>-->
              <!--</template>-->
            <!--</b-form-select>-->
          <!--</b-form-group>-->
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state3">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state3, valid__dateTime: state3 === true}"
            ></masked-input>
          </b-form-group>
          <b-form-group
            label="Адрес"
            label-for="premise"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.premiseId)"
            :state="state4">
            <b-form-select id="premise"
                           :options="accommodations"
                           placeholder="Выберите адрес"
                           v-model="model.premiseId"
                           :state="state4">
              <template slot="first">
                <option :value="null" disabled>Выберите адрес</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Ограничение парковки"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="check" class="switch-input" checked>
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Время окончания парковки"
            v-if="check"
            label-for="time"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.time)"
            :state="state5">
            <datetime type="datetime"
                      id="time"
                      v-model="date.time"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Выберите время окончания парковки"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state5, valid__dateTime: state5 === true}">
            </datetime>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Автомобиль успешно изменён">
      <p class="my-4">Автомобиль успешно изменён</p>
    </b-modal>
  </div>
</template>


<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.long(this.model.carNumber)
      },
      state2 () {
        return validation.longObj(this.value)
      },
      state3 () {
        return validation.phoneNumberBool(this.phone)
      },
      state4 () {
        return validation.numId(this.model.premiseId)
      },
      state5 () {
        return validation.dateSelectBool(this.date.time)
      }
    },
    methods: {
      nameWithLang ({ name, id }) {
        return `${name}`
      },
      goBack () {
        this.$router.go(-1)
      },
      sendForm () {
        this.show = true
        if (this.state1 && this.state2 && this.state3 && this.state4 && (this.state5 || !this.check)) {
          this.model.userId = this.value.id
          this.model.phone = this.phone.replace(/\D/g, '').replace('7', '')
          if (this.check) {
            this.model.time = parseInt(moment(this.date.time).format('X'))
          } else {
            this.model.time = 0
          }
          api.put(this.urls.car + '/' + this.$route.params.id, this.model).then((response) => {
            if (response.status) {
              this.show = false
              this.$refs.myModalRef.show()
              setTimeout(() => {
                this.$refs.myModalRef.hide()
                this.$router.push({ path: '/car/list', query: { currentPage: this.$route.query.currentPage } })
              }, 1000)
            }
          })
        }
      },
      getClients () {
        let _this = this
        api.get(_this.urls.users, {role: 'user'}).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (user) {
              let temp = {}
              temp.id = user.id
              temp.name = '';
              temp.name += user.lastName || '';
              temp.name += ` ${user.firstName}` || '';
              temp.name += ` ${user.patronymicName}` || '';
              _this.options.push(temp)
            })
          }
        })
      },
      getAccomodation () {
        api.get(this.urls.accommodation, {userId: this.value.id}).then((response) => {
          this.accommodations = []
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              let temp = {}
              temp.value = item.premiseId
              temp.text = item.address
              this.accommodations.push(temp)
            })
          }
        }).then(() => {
          this.firstLoadForm = false
        })
      }
    },
    mounted () {
      api.get(this.urls.car + '/' + this.$route.params.id).then((response) => {
        let jData = response.data
        this.model = jData.data
        this.phone = `+7 (${this.model.phone.substring(0, 3)}) ${this.model.phone.substring(3, 6)}-${this.model.phone.substring(6, 8)}-${this.model.phone.substring(8, 10)}`
        this.value = {
          name: this.model.user.lastName.concat(' ', this.model.user.firstName, ' ', this.model.user.patronymicName === null ? '' : this.model.user.patronymicName),
          id: this.model.userId
        }
        if (this.model.time === 0) {
          this.date.time = 0
          this.check = false
        } else {
          this.check = true
          this.date.time = moment(this.model.time * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
        }
      }).then(() => {
        this.getClients()
        this.getAccomodation()
      })
    },
    watch: {
      value () {
        if (this.value !== null && this.value !== undefined) {
          this.getAccomodation()
        }
        if (!this.firstLoadForm) {
          this.model.premiseId = 0
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'car'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
        },
        value: {},
        options: [
        ],
        accommodations: [],
        date: {
          time: 0
        },
        accessList: {},
        posts: [],
        urls: {
          car: 'car',
          users: 'users',
          accommodation: 'accommodation'
        },
        phone: '',
        show: false,
        firstLoadForm: true,
        label: 'Пожалуйста, подождите, операция выполняется',
        check: '',
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

