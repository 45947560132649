<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template>
            <loading
              :show="show"
              :label="label">
            </loading>
          </template>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>
          <b-form-group
            :label-cols="3"
            label="Тип домофона"
            label-for="housingComplex"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.intercomTypeId)"
            :state="state6">
            <b-form-select id="housingComplex"
                          :options="intercomTypeList"
                          placeholder="Выберите тип домофона"
                          v-model="model.intercomTypeId"
                          :state="state6">
              <template slot="first">
                <option :value="null" disabled>Выберите тип домофона</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Наименование домофона"
            label-for="intercomName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state5">
            <b-form-input id="intercomName" type="text" :state="state5" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Комментарий"
            label-for="commentId"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="commentId" type="text" v-model="model.comment"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Логин домофона"
            label-for="intercomLogin"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.login)"
            :state="state1">
            <b-form-input id="intercomLogin" type="text" :state="state1" v-model="model.login"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Широта"
            label-for="latitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.latitude)"
            :state="state7">
            <b-form-input id="latitude" type="text" :state="state7" v-model="model.latitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Долгота"
            label-for="longitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.longitude)"
            :state="state8">
            <b-form-input id="longitude" type="text" :state="state8" v-model="model.longitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label=""
            label-for="longitude"
            :label-cols="3"
            :horizontal="true">
            <yandex-map
              :coords="coords"
              zoom="18"
              style="width: 600px; height: 600px;"
              :cluster-options="{1: {clusterDisableClickZoom: true}}"
              :behaviors="['drag', 'scrollZoom', 'multiTouch']"
              v-model="yamap"
              :controls="['zoomControl', 'searchControl', 'fullscreenControl', 'typeSelector']"
              map-type="map"
              :placemarks="placemarks"
              @map-was-initialized="initHandler"
            >
            </yandex-map>
          </b-form-group>
          <b-form-group
            label="IP АТС"
            label-for="ipAtsId"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.host)"
            :state="state9">
            <b-form-input id="ipAtsId" type="text" :state="state9" v-model="model.host"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Порт АТС"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.port)"
            :state="state10">
            <b-form-input id="name" type="text" :state="state10" v-model="model.port"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Жилищный комплекс"
            label-for="housingComplex"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.hcId)"
            :state="state4">
            <b-form-select id="housingComplex"
                           :options="housingComplex"
                           placeholder="Выберите ЖК"
                           v-model="model.hcId"
                           :state="state4">
              <template slot="first">
                <option :value="null" disabled>Выберите ЖК</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Видеопоток для мобильного приложения"
            label-for="streamId"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="streamId" type="text" v-model="model.stream"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Видеопоток для мобильного приложения HD"
            label-for="streamHdId"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="streamHdId" type="text" v-model="model.streamHd"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Видеопоток для административной панели"
            label-for="streamAdminId"
            :label-cols="3"
            :horizontal="true"
            description="Ссылка должна быть HTTP/HTTPS">
            <b-form-input id="streamAdminId" type="text" v-model="model.adminStream"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Точка доступа"
            label-for="premise"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.doorId)"
            :state="state3">
            <b-form-select id="premise"
                           :options="barrier"
                           placeholder="Выберите точку доступа"
                           v-model="model.doorId"
                           :state="state3">
              <template slot="first">
                <option :value="null" disabled>Выберите точку доступа</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="selectedType === 'gate'"
            label="Здания"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <div class="address_picker">
              <multiselect v-model="value" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" :preserve-search="false" :searchable="false" placeholder="" label="name" track-by="name" :preselect-first="true">
                <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.name }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
              </multiselect>
              <b-btn size="sm" class="address_picker__button_add" @click.prevent="viewModal" text="Button" variant="success"><i class="fa fa-plus fa-2x"></i></b-btn>
            </div>
          </b-form-group>
          <b-form-group
            v-if="selectedType === 'entrance' || selectedType === 'premise'"
            label="Дом"
            label-for="building"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(buildingId)"
            :state="state12">
            <b-form-select id="building"
                          :options="building"
                          placeholder="Выберите дом"
                          v-model="buildingId"
                          :state="state12">
              <template slot="first">
                <option :value="null" disabled>Выберите дом</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="selectedType === 'entrance'"
            label="Подъезд"
            label-for="entrance"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(entranceId)"
            :state="state13">
            <b-form-select id="entrance"
                           :options="entrance"
                           placeholder="Выберите подъезд"
                           v-model="entranceId"
                           :state="state13">
              <template slot="first">
                <option :value="null" disabled>Выберите подъезд</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="selectedType === 'premise'"
            label="Квартира"
            label-for="premise"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(premiseId)"
            :state="state14">
            <b-form-select id="premise"
                          :options="premise"
                          placeholder="Выберите квартиру"
                          v-model="premiseId"
                          :state="state14">
              <template slot="first">
                <option :value="null" disabled>Выберите квартиру</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Активна"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.active" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Превью"
            label-for="status"
            :label-cols="3"
            :horizontal="true">
            <b-form-file :plain="true" id="file" v-model="file"></b-form-file>
            <div v-if="model.image !== null && model.image !== undefined" v-viewer="{toolbar: false, navbar: false}" class="request_picture">
              <img v-if="model.image !== null" :src="model.image" alt="hex shorthand color" fluid class="chat__picture m-1 pb-3">
              <div v-if="model.image !== null" class="del" @click="deletePicture('file')"><icon name="window-close"></icon></div>
            </div>
          </b-form-group>
          <b-form-group
            label="Иконка"
            label-for="status"
            :label-cols="3"
            :horizontal="true">
            <b-form-file :plain="true" id="icon" v-model="fileIcon"></b-form-file>
            <div v-if="model.icon !== null && model.icon !== undefined" v-viewer="{toolbar: false, navbar: false}" class="request_picture">
              <img v-if="model.icon !== null" :src="model.icon" alt="hex shorthand color" fluid class="chat__picture m-1 pb-3">
              <div v-if="model.icon !== null" class="del" @click="deletePicture('icon')"><icon name="window-close"></icon></div>
            </div>
          </b-form-group>
          <b-form-group
            label="Иконка для избранного"
            label-for="status"
            :label-cols="3"
            :horizontal="true">
            <b-form-file :plain="true" id="favouriteIcon" v-model="fileFavouriteIcon"></b-form-file>
            <div v-if="model.favouriteIcon !== null && model.favouriteIcon !== undefined" v-viewer="{toolbar: false, navbar: false}" class="request_picture">
              <img v-if="model.favouriteIcon !== null" :src="model.favouriteIcon" alt="hex shorthand color" fluid class="chat__picture m-1 pb-3">
              <div v-if="model.favouriteIcon !== null" class="del" @click="deletePicture('favouriteIcon')"><icon name="window-close"></icon></div>
            </div>
          </b-form-group>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="addPremise" hide-footer title="Добавить здание">
      <b-form-group
        label="Дом"
        label-for="building"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(buildingId)"
        :state="state11">
        <b-form-select id="building"
                       :options="building"
                       placeholder="Выберите дом"
                       v-model="buildingId"
                       :state="state11">
          <template slot="first">
            <option :value="null" disabled>Выберите дом</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="saveAddress">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="myModalRef" hide-footer title="Домофон успешно сохранен">
      <p class="my-4">Домофон успешно сохранен</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import { yandexMap, ymapMarker } from 'vue-yandex-maps'
  import Viewer from 'v-viewer'
  import Icon from 'vue-awesome/components/Icon'
  import consts from '../../config/const'

  export default {
    name: 'Item',
    components: {
      loading,
      yandexMap,
      ymapMarker,
      Multiselect,
      MaskedInput,
      datetime: Datetime,
      Icon,
      Viewer
    },
    computed: {
      state1 () {
        return validation.long(this.model.login)
      },
      state3 () {
        return validation.numId(this.model.doorId)
      },
      state4 () {
        return validation.numId(this.model.hcId)
      },
      state5 () {
        return validation.long(this.model.name)
      },
      state6 () {
        return validation.numId(this.model.intercomTypeId)
      },
      state7 () {
        return validation.isNumberBool(this.model.latitude)
      },
      state8 () {
        return validation.isNumberBool(this.model.longitude)
      },
      state9 () {
        return validation.long(this.model.host)
      },
      state10 () {
        return validation.isNumberBool(this.model.port)
      },
      state11 () {
        return validation.numId(this.buildingId)
      },
      state12 () {
        return validation.numId(this.buildingId)
      },
      state13 () {
        return validation.numId(this.entranceId)
      },
      state14 () {
        return validation.numId(this.premiseId)
      },
      pageTitle () {
        return this.intercomId ? 'Редактирование домофона' : 'Создание домофона'
      }
    },
    methods: {
      deletePicture () {
        let _this = this
        _this.model.image = null
        document.getElementById('file').value = ''
      },
      goBack () {
        this.$router.go(-1)
      },
      viewModal () {
        let _this = this
        _this.check = true
        _this.$refs.addPremise.show()
      },
      saveAddress () {
        let _this = this
        if (_this.state11 === true) {
          let fullAddress = null
          _this.housingComplex.forEach((item) => {
            if (item.value === _this.model.hcId) {
              fullAddress = item.text
            }
          })
          _this.building.forEach((item) => {
            if (item.value === _this.buildingId) {
              fullAddress = fullAddress + ' ' + item.text
            }
          })
          let addressData = {
            name: fullAddress,
            id: _this.buildingId
          }
          _this.value.push(addressData)
          _this.hideModal()
        }
      },
      hideModal () {
        this.buildingId = 0
        this.$refs.addPremise.hide()
      },
      getHousingComplex () {
        let _this = this
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.housingComplex.push(temp)
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this
        if (_this.model.hcId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.model.hcId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    _this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      },
      getEntrance () {
        let _this = this
        let query = {
          buildingId: _this.buildingId
        }
        api.get(_this.urls.entrance, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.number
              _this.entrance.push(temp)
            })
          }
        })
      },
      getPremise () {
        let _this = this
        if ((_this.housingComplexId !== '') && (_this.streetId !== '') && (_this.buildingId !== '')) {
          let query = {
            hcId: _this.model.hcId,
            streetId: _this.streetId,
            buildingId: _this.buildingId
          }
          api.get(_this.urls.premise, query).then(function (response) {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach(function (spec) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.premiseNum
                _this.premise.push(temp)
              })
            }
          })
        }
      },
      getIntercom () {
        let _this = this
        api.get(_this.urls.intercomType).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.key = spec.keyWord
              temp.text = spec.name
              _this.intercomTypeList.push(temp)
            })
          }
        })
      },
      whenTabInFocus () {
        let _this = this
        api.get(_this.urls.housingComplex + '/' + _this.model.hcId)
          .then(function (response) {
            let jData = response.data
            _this.hComplex = jData.data.name
          })
      },
      initHandler (obj) {
        let _this = this
        obj.events.add('click', function (e) {
          let mark = {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение домофона на карте"</div>'
          }
          _this.placemarks = []
          mark.coords = e.get('coords')
          _this.model.latitude = mark.coords[0]
          _this.model.longitude = mark.coords[1]
          _this.coords = mark.coords
          _this.placemarks.push(mark)
        })
      },
      sendForm () {
        let _this = this
        if (_this.state1 && _this.state3 && _this.state4 && _this.state5 && _this.state6 && _this.state7 && _this.state8 && _this.state9 && _this.state10 && ((_this.selectedType === 'entrance' && _this.state13) || (_this.selectedType === 'premise' && _this.state14) || _this.selectedType === 'gate')) {
          _this.show = true
          _this.whenTabInFocus()
          const removeEmpty = (obj) => {
            Object.keys(obj).forEach(key => {
              if (obj[key] && typeof obj[key] === 'object') {
                removeEmpty(obj[key])
              } else if (obj[key] == null || obj[key] === undefined) {
                delete obj[key]
              }
            })
          }
          removeEmpty(_this.model)
          if (_this.selectedType === 'premise') {
            _this.model.premiseId = _this.premiseId
            delete _this.model.buildings
            delete _this.model.entranceId
          } else if (_this.selectedType === 'entrance') {
            _this.model.entranceId = _this.entranceId
            delete _this.model.buildings
            delete _this.model.premiseId
          } else {
            _this.model.buildings = []
            _this.value.forEach((item) => {
              _this.model.buildings.push(item.id)
            })
            delete _this.model.entranceId
            delete _this.model.premiseId
          }
          if (_this.model.adminStream === null) {
            delete _this.model.adminStream
          }
          if (_this.model.stream === null) {
            delete _this.model.stream
          }
          if (_this.model.streamHd === null) {
            delete _this.model.streamHd
          }

          let promise = null
          if (_this.intercomId) {
            promise = api.put(_this.urls.intercom + '/' + _this.$route.params.id, _this.model)
          } else {
            promise = api.post(_this.urls.intercom, _this.model)
          }

          promise.then(function (response) {
            if (response.status) {
              _this.show = false
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({
                  path: '/intercom/list',
                  query: { tab: _this.hComplex, currentPage: _this.$route.query.currentPage }
                })
              }, 1000)
            }
          })
        }
      },
      getBarrier () {
        let _this = this
        let query = {
          hcId: this.model.hcId,
          barrierType: 'door'
        }
        api.get(_this.urls.barrier, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.barrier.push(temp)
            })
          }
        })
      },
      getPickedBuildings () {
        let _this = this
        let query = {
          doorId: _this.model.doorId
        }
        api.get(_this.urls.buildings, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              let addressData = {
                name: item.address,
                id: item.id
              }
              _this.value.push(addressData)
            })
          }
        })
      }
    },
    watch: {
      'model.hcId': function () {
        this.barrier = []
        this.getBarrier()
        this.building = []
        this.getBuilding()
      },
      file (newFile, oldFile) {
        let _this = this
        let fd = new FormData()
        fd.append('file', newFile)
        api.post(_this.urls.file, fd).then(function (response) {
          if (response.data.status === false) {
            alert(response.data.error.description)
            return
          }
          let jData = response.data
          _this.model.image = consts.storageUrl + '/' + jData.data.url
        })
      },
      fileIcon (newFile, oldFile) {
        let fd = new FormData()
        fd.append('file', newFile)
        api.post(this.urls.file, fd).then((response) => {
          if (response.data.status === false) {
            alert(response.data.error.description)
            return
          }
          let jData = response.data
          this.model.icon = consts.storageUrl + '/' + jData.data.url
        })
      },
      fileFavouriteIcon (newFile, oldFile) {
        let fd = new FormData()
        fd.append('file', newFile)
        api.post(this.urls.file, fd).then((response) => {
          if (response.data.status === false) {
            alert(response.data.error.description)
            return
          }
          let jData = response.data
          this.model.favouriteIcon = consts.storageUrl + '/' + jData.data.url
        })
      },
      'model.intercomTypeId': function () {
        this.intercomTypeList.forEach((item) => {
          if (item.value === this.model.intercomTypeId) {
            this.selectedType = item.key
          }
        })
      },
      'model.doorId': function () {
        this.getPickedBuildings()
      },
      streetId: function () {
        this.building = []
      },
      buildingId: function () {
        this.entrance = []
        this.getEntrance()
        this.premise = []
        this.getPremise()
      },
      'model.latitude': function (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let _this = this
          let mark = _this.placemarks[0]
          _this.placemarks = []
          mark.coords = [n, _this.model.longitude]
          _this.coords = [n, _this.model.longitude]
          _this.placemarks.push(mark)
        }
      },
      'model.longitude': function (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let _this = this
          let mark = _this.placemarks[0]
          _this.placemarks = []
          mark.coords = [_this.model.latitude, n]
          _this.coords = [_this.model.latitude, n]
          _this.placemarks.push(mark)
        }
      }
    },
    mounted: function () {
      let _this = this

      if (this.intercomId) {
        api.get(_this.urls.intercom + '/' + _this.$route.params.id).then(function (response) {
          let jData = response.data
          _this.model = jData.data

          if (_this.model.stream === null) {
            _this.model.stream = ''
          }
          if (_this.model.streamHd === null) {
            _this.model.streamHd = ''
          }
          if (_this.model.adminStream === null) {
            _this.model.adminStream = ''
          }
          if (_this.model.entranceId !== null) {
            _this.selectedType = 'entrance'
            _this.buildingId = _this.model.buildingId
            _this.entranceId = _this.model.entranceId
          } else if (_this.model.premiseId !== null) {
            _this.selectedType = 'premise'
            _this.buildingId = _this.model.buildingId
            _this.premiseId = _this.model.premiseId
          } else {
            _this.selectedType = 'gate'
          }
          if (_this.model.latitude !== null && _this.model.longitude !== null) {
            _this.coords = [_this.model.latitude, _this.model.longitude]
            let mark = {
              coords: [_this.model.latitude, _this.model.longitude],
              properties: {}, // define properties hereasd
              clusterName: '1',
              balloonTemplate: '<div>"Положение домофона на карте"</div>'
            }
            _this.placemarks = []
            mark.coords = [_this.model.latitude, _this.model.longitude]
            _this.placemarks.push(mark)
          }
          _this.getIntercom()
          _this.getHousingComplex()
        })
      } else {
        _this.getIntercom()
        _this.getHousingComplex()
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'intercom'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        intercomId: this.$route.params.id,
        model: {
          image: null,
          icon: null,
          favouriteIcon: null,
          active: true,
          doorId: 0,
          hcId: 0,
          latitude: 58.603542963107785,
          longitude: 49.66805437579303,
          adminStream: '',
          stream: '',
          streamHd: '',
          intercomTypeId: 0,
          comment: ''
        },
        coords: [58.603542963107785, 49.66805437579303],
        placemarks: [
          {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение домофона на карте"</div>'
          }
        ],
        file: '',
        fileFavouriteIcon: '',
        fileIcon: '',
        yamap: '',
        accessList: {},
        date: {
          time: 0
        },
        intercomTypeList: [],
        barrier: [],
        value: [],
        selectedType: '',
        options: [
        ],
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        entrance: [
        ],
        premise: [
        ],
        streetId: 0,
        buildingId: 0,
        entranceId: 0,
        premiseId: 0,
        hComplex: '',
        hComplexId: 0,
        urls: {
          file: 'file',
          intercom: 'intercom/data',
          housingComplex: 'housingComplex',
          intercomType: 'intercomtype',
          barrier: 'barrier',
          street: 'street',
          building: 'building',
          entrance: 'entrance',
          premise: 'premise',
          buildings: 'barrier/buildings',
          buildingAddress: 'building/buildingaddress'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        check: '',
        consts: consts,
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

