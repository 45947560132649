<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Изменение настроек крон</strong>
          </template>
          <b-form-group
            label="Частота обовления"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <b-form inline>
              <label class="mr-sm-1" for="inlineFormCustomSelectPref">Каждые</label>
              <b-form-select id="premise"
                          :options="date.hh"
                          placeholder="Часы"
                          v-model="model.hours">
                <template slot="first">
                  <option :value="null" disabled>Часы</option>
                </template>
              </b-form-select>
              <label class="mr-sm-1 ml-sm-2" for="inlineFormCustomSelectPref">часов </label>
              <b-form-select id="premise"
                            :options="date.mm"
                            placeholder="Минуты"
                            v-model="model.minutes">
                <template slot="first">
                  <option :value="null" disabled>Минуты</option>
                </template>
              </b-form-select>
              <label class="mr-sm-1 ml-sm-2" for="inlineFormCustomSelectPref">минут </label>
            </b-form>
          </b-form-group>
          <!--<b-form-group
            label="Дни недели"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
              <b-row v-for="day in weekDay">
                <b-col xl="2">
                  <label class="mr-sm-1" for="inlineFormCustomSelectPref">{{day.name}}</label>
                </b-col>  
                <b-col xl="2">
                  <label class="switch switch-3d switch-primary">
                    <input type="checkbox" v-model="day.picked" class="switch-input" checked>
                    <span class="switch-label" data-on="Да" data-off="Нет"></span>
                    <span class="switch-handle"></span>
                  </label>
                </b-col> 
              </b-row>
          </b-form-group>-->
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Настройки крон успешно изменены">
      <p class="my-4">Настройки крон успешно изменены</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import moment from 'moment'

  export default {
    name: 'New',
    components: {
      loading,
      MaskedInput
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      sendForm () {
        let _this = this
        _this.show = true
        // _this.model.dayOfWeek = []
        // _this.weekDay.forEach((item) => {
        //   if (item.picked === true) {
        //     _this.model.dayOfWeek.push(item.key)
        //   }
        // })
        api.put(_this.urls.cronsetting + '/' + _this.$route.params.id, _this.model).then(function (response) {
          _this.show = false
          if (response.status) {
            _this.$refs.myModalRef.show()
            setTimeout(function () {
              _this.$refs.myModalRef.hide()
              _this.$router.push('/cron/list')
            }, 1000)
          }
        })
      }
    },
    mounted: function () {
      let _this = this
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          _this.date.hh.push({value: '0' + i, text: '0' + i})
        } else {
          _this.date.hh.push({value: i, text: i})
        }
      }
      for (let i = 0; i < 60; i++) {
        if (i < 10) {
          _this.date.mm.push({value: '0' + i, text: '0' + i})
        } else {
          _this.date.mm.push({value: i, text: i})
        }
      }
      api.get(_this.urls.cronsetting + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
        // if (_this.model.dayOfWeek.length > 0) {
        //   _this.weekDay.forEach((item) => {
        //     if (_this.model.dayOfWeek.indexOf(item.key) !== -1) {
        //       item.picked = true
        //     }
        //   })
        // }
      })
    },
    watch: {
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'cron'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
        },
        accessList: {},
        date: {
          hh: [],
          mm: []
        },
        // weekDay: [
        //   {key: 1, picked: false, name: 'Понедельник'},
        //   {key: 2, picked: false, name: 'Вторник'},
        //   {key: 3, picked: false, name: 'Среда'},
        //   {key: 4, picked: false, name: 'Четверг'},
        //   {key: 5, picked: false, name: 'Пятница'},
        //   {key: 6, picked: false, name: 'Суббота'},
        //   {key: 7, picked: false, name: 'Воскресенье'}
        // ],
        posts: [],
        urls: {
          cronsetting: 'cronsetting'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
