<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание уведомления</strong>
          </template>
          <b-form-group
            label="Наименование"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.title)"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="model.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Текст"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.text)"
            :state="state2">
            <b-form-input id="name" type="text" :state="state2" v-model="model.text"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Опрос"
            label-for="Poll"
            :label-cols="3"
            :horizontal="true">
            <b-form-select id="Poll"
                           :options="pollList"
                           placeholder="Выберите опрос"
                           v-model="model.pollId">
              <template slot="first">
                <option :value="null" disabled>Выберите опрос</option>
              </template>
            </b-form-select>
          </b-form-group>
          <div v-if="model.linkedObjects.length > 0" v-for="obj in model.linkedObjects">
            <b-form-group
              :label="`${obj.name}`"
              label-for="file"
              :label-cols="3"
              :horizontal="true">
              <div class="address_picker">
                <multiselect v-model="obj.buildings" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" :preserve-search="false" :searchable="false" placeholder="" label="name" track-by="name" :preselect-first="true">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.name }}</span><span class="custom__remove" @click="props.remove(props.option)" @click.prevent="getHousingComplex">❌</span></span></template>
                </multiselect>
                <b-btn size="sm" class="address_picker__button_add_before_remove" @click.prevent="viewModalPremise(obj.id)" text="Button" variant="success"><i class="fa fa-plus fa-2x"></i></b-btn>
                <b-btn size="sm" class="address_picker__button_remove" @click.prevent="delHC(obj.id)" text="Button" variant="danger"><i class="fa fa-close fa-2x"></i></b-btn>
              </div>
            </b-form-group>
          </div>
          <b-form-group
            label="ЖК"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-btn size="sm" @click.prevent="viewModalHC" text="Button" variant="success">Добавить ещё</b-btn>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="addHC" hide-footer title="Добавить ЖК">
      <b-form-group
        label="Жилищный комплекс"
        label-for="housingComplex"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(hcId)"
        :state="state5">
        <b-form-select id="housingComplex"
                        :options="housingComplex"
                        placeholder="Выберите ЖК"
                        v-model="hcId"
                        :state="state5">
          <template slot="first">
            <option :value="null" disabled>Выберите ЖК</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="addHC">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="addPremise" hide-footer title="Добавить здание">
      <b-form-group
        label="Дом"
        label-for="building"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(buildingId)"
        :state="state11">
        <b-form-select id="building"
                       :options="building"
                       placeholder="Выберите дом"
                       v-model="buildingId"
                       :state="state11">
          <template slot="first">
            <option :value="null" disabled>Выберите дом</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="saveAddress">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="myModalRef" hide-footer title="Уведомление успешно добавлено">
      <p class="my-4">Уведомление успешно добавлено</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect
    },
    computed: {
      state1 () {
        return validation.long(this.model.title)
      },
      state2 () {
        return validation.long(this.model.text)
      },
      state11 () {
        return validation.numId(this.buildingId)
      },
      state5 () {
        return validation.numId(this.hcId)
      }
    },
    methods: {
      delHC (id) {
        this.model.linkedObjects.forEach((item, index) => {
          if (item.id === id) {
            this.model.linkedObjects.splice(index, 1)
          }
        })
        this.getHousingComplex()
      },
      viewModalHC () {
        let _this = this
        this.hcId = 0
        _this.$refs.addHC.show()
      },
      viewModalPremise (id) {
        let _this = this
        this.hcId = id
        this.buildingId = 0
        this.building = []
        this.street = []
        this.getHousingComplex()
        _this.$refs.addPremise.show()
      },
      addHC () {
        let _this = this
        if (_this.state5) {
          let hComplex = {}
          let checkHC = false
          _this.model.linkedObjects.forEach((item) => {
            if (item.id === _this.hcId) {
              checkHC = true
            }
          })
          if (!checkHC) {
            _this.housingComplex.forEach((item, index) => {
              if (item.value === _this.hcId) {
                hComplex.name = item.text
                hComplex.id = item.value
              }
            })
            let linkedObject = {
              name: hComplex.name,
              id: hComplex.id,
              buildings: []
            }
            _this.model.linkedObjects.push(linkedObject)
          }
        }
        _this.getHousingComplex()
        _this.hideModalHC()
      },
      saveAddress () {
        let _this = this
        if (_this.state11) {
          let fullAddress = null
          let hComplex = {}
          _this.model.linkedObjects.forEach((item) => {
            if (item.id === _this.hcId) {
              hComplex.name = item.name
              hComplex.id = item.id
              _this.building.forEach((building, index) => {
                if (building.value === _this.buildingId) {
                  fullAddress = building.text
                }
              })
              let checkBuilding = false
              item.buildings.forEach((building) => {
                if (building.id === _this.buildingId) {
                  checkBuilding = true
                }
              })
              if (!checkBuilding) {
                let building = {
                  name: fullAddress,
                  id: _this.buildingId
                }
                item.buildings.push(building)
              }
            }
          })
          _this.getHousingComplex()
          _this.hideModalPremise()
        }
      },
      hideModalPremise () {
        this.buildingId = 0
        this.$refs.addPremise.hide()
      },
      hideModalHC () {
        this.buildingId = 0
        this.$refs.addHC.hide()
      },
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true) {
          _this.show = true
          if (_this.model.pollId === 0) {
            delete _this.model.pollId
          }
          api.post(_this.urls.notification, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/notice/list')
              }, 1000)
            }
          })
        }
      },
      getPoll () {
        let _this = this
        api.get(_this.urls.poll).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.title
              _this.pollList.push(temp)
            })
          }
        })
      },
      getHousingComplex () {
        let _this = this
        _this.housingComplex = []
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let checkHC = false
              _this.model.linkedObjects.forEach((houseComplex) => {
                if (houseComplex.id === spec.id) {
                  checkHC = true
                }
              })
              if (!checkHC) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                _this.housingComplex.push(temp)
              }
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this
        _this.building = []
        if (_this.model.hcId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.hcId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  let checkBuilding = false
                  if (spec.id !== null) {
                    _this.model.linkedObjects.forEach((item) => {
                      item.buildings.forEach((building) => {
                        if (building.id === spec.id) {
                          checkBuilding = true
                        }
                      })
                    })
                    if (!checkBuilding) {
                      let temp = {}
                      temp.value = spec.id
                      temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                      _this.building.push(temp)
                    }
                  }
                })
              }
            })
          })
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'notification'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getPoll()
      _this.getHousingComplex()
    },
    data () {
      return {
        model: {
          title: '',
          text: '',
          pollId: 0,
          linkedObjects: []
        },
        accessList: {},
        date: '',
        file: '',
        value: [],
        options: [
        ],
        posts: [],
        pollList: [
        ],
        urls: {
          notification: 'notification',
          poll: 'poll',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building'
        },
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        hcId: 0,
        streetId: 0,
        buildingId: 0,
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
