<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Редактирование получателя</strong>
          </template>
          <!-- <b-form-group
            label="Идентификатор типа комнат"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="id" type="text" v-model="model.id" readonly></b-form-input>
          </b-form-group> -->
          <b-form-group
            label="Наименование получателя"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state1">
            <b-form-input id="name" type="text" :state="state1" v-model="model.name" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="Короткое наименование"
            label-for="shortName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.shortName)"
            :state="state1">
            <b-form-input id="shortName" type="text" :state="state1" v-model="model.shortName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="ИНН"
            label-for="inn"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.INN)"
            :state="state1">
            <b-form-input id="inn" type="text" :state="state1" v-model="model.INN" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="КПП"
            label-for="kpp"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.KPP)"
            :state="state1">
            <b-form-input id="kpp" type="text" :state="state1" v-model="model.KPP" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="Наименование банка"
            label-for="bankName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.bankName)"
            :state="state1">
            <b-form-input id="bankName" type="text" :state="state1" v-model="model.bankName" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="БИК"
            label-for="bankBIK"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.bankBIK)"
            :state="state1">
            <b-form-input id="bankBIK" type="text" :state="state1" v-model="model.bankBIK" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер расчетного счета"
            label-for="operating"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.operatingAccountNumber)"
            :state="state1">
            <b-form-input id="operating" type="text" :state="state1" v-model="model.operatingAccountNumber" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="Корреспондентский счет банка"
            label-for="correspondent"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.correspondentBankAccount)"
            :state="state1">
            <b-form-input id="correspondent" type="text" :state="state1" v-model="model.correspondentBankAccount" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="КБК"
            label-for="KBK"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.KBK)"
            :state="state1">
            <b-form-input id="KBK" type="text" :state="state1" v-model="model.KBK" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label="ОКТМО"
            label-for="OKTMO"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.OKTMO)"
            :state="state1">
            <b-form-input id="OKTMO" type="text" :state="state1" v-model="model.OKTMO" readonly></b-form-input>
          </b-form-group>
          <b-form-group
            label='Идентификатор в платежной системе "QR Оплата"'
            label-for="QROplataId"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.QROplataId)"
            :state="state1">
            <b-form-input id="QROplataId" type="text" :state="state1" v-model="model.QROplataId"></b-form-input>
          </b-form-group>
          <!-- <b-form-group
            label=""
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-img-lazy blank width="175" height="175" fluid blank-color="#777" :src="model.icon" alt="hex shorthand color" class="m-1" />
          </b-form-group> -->
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Получатель успешно изменён">
      <p class="my-4">Получатель успешно изменён</p>
    </b-modal>
  </div>
</template>

<script>
    import api from '../../config/api'
    import loading from 'vue-full-loading'
    import validation from '../../config/validation'

    export default {
      name: 'New',
      components: {
        loading
      },
      computed: {
        state1 () {
          return validation.long(this.model.name)
        }
      },
      methods: {
        sendForm () {
          let _this = this
          if (_this.state1) {
            _this.show = true
            api.put(_this.urls.paymentrecipient + '/' + _this.$route.params.id, _this.model).then(function (response) {
              _this.show = false
              if (response.status) {
                _this.$refs.myModalRef.show()
                setTimeout(function () {
                  _this.$refs.myModalRef.hide()
                  _this.$router.push('/paymentrecipient/list')
                }, 1000)
              }
            })
          }
        },
        goBack () {
          this.$router.go(-1)
        }
      },
      mounted: function () {
        let _this = this
        api.get(_this.urls.paymentrecipient + '/' + _this.$route.params.id).then(function (response) {
          let jData = response.data
          _this.model = jData.data
        })
      },
      created: function () {
        let _this = this
        let accsessKey = {
          keyWord: 'payment'
        }
        api.get('role/components/access', accsessKey).then((response) => {
          if (response.data.data.isRead === false) {
            _this.$router.push('/pages/403')
          } else {
            _this.accessList = response.data.data
          }
        })
      },
      watch: {
      },
      data () {
        return {
          model: {
          },
          accessList: {},
          date: '',
          posts: [],
          urls: {
            paymentrecipient: 'paymentrecipient'
          },
          show: false,
          label: 'Пожалуйста, подождите, операция выполняется',
          validation: validation
        }
      }
    }
</script>
