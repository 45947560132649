<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>
          <b-form-group
            label="Демо режим"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isDemo" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Наименование"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="stateName">
            <b-form-input id="id" type="text" :state="stateName" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Широта"
            label-for="latitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.latitude)"
            :state="state2">
            <b-form-input id="latitude" type="text" :state="state2" v-model="model.latitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Долгота"
            label-for="longitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.longitude)"
            :state="state3">
            <b-form-input id="longitude" type="text" :state="state3" v-model="model.longitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label=""
            label-for="longitude"
            :label-cols="3"
            :horizontal="true">
            <yandex-map
              :coords="coords"
              zoom="18"
              style="width: 600px; height: 600px;"
              :cluster-options="{1: {clusterDisableClickZoom: true}}"
              :behaviors="['drag', 'scrollZoom', 'multiTouch']"
              v-model="yamap"
              :controls="['zoomControl', 'searchControl', 'fullscreenControl', 'typeSelector']"
              map-type="map"
              :placemarks="placemarks"
              @map-was-initialized="initHandler"
            >
            </yandex-map>
          </b-form-group>
          <b-form-group
            v-if="this.cameraId"
            label="Изображения"
            label-for="status"
            :label-cols="3"
            :horizontal="true">
            <div v-if="model.image !== null" v-viewer="{toolbar: false, navbar: false}" class="request_picture">
              <b-img-lazy blank width="175" height="175" fluid blank-color="#777" :src="model.image" alt="hex shorthand color" class="m-1 pb-3" />
              <div class="del" @click="deletePicture()" title="Удалить">
                <icon name="window-close"></icon>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            label="Видеопоток для мобильного приложения"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="id" type="text" v-model="model.streamingUri"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Видеопоток для мобильного приложения HD"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="id" type="text" v-model="model.streamingUriHd"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Видеопоток для административной панели"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            description="Ссылка должна быть HTTP/HTTPS">
            <b-form-input id="id" type="text" v-model="model.adminStream"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Привязанное здание"
            label-for="buildingId"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.buildingId)"
            :state="state6">
            <b-form-select id="status"
                           :options="buildings"
                           v-model="model.buildingId"
                           :state="state6">
              <template slot="first">
                <option :value="null" disabled>Выберите статус</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Камера успешно сохранена">
      <p class="my-4">Камера успешно сохранена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import { yandexMap, ymapMarker } from 'vue-yandex-maps'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import consts from '../../config/const'
  import Icon from 'vue-awesome/components/Icon'

export default {
    name: 'Item',
    components: {
      yandexMap,
      ymapMarker,
      loading,
      Icon
    },
    computed: {
      stateName () {
        return validation.long(this.model.name)
      },
      state2 () {
        return validation.isNumberBool(this.model.latitude)
      },
      state3 () {
        return validation.isNumberBool(this.model.longitude)
      },
      state6 () {
        return validation.numId(this.model.buildingId)
      },
      pageTitle () {
        return this.cameraId ? 'Редактирование камеры' : 'Создание камеры'
      }
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      initHandler (obj) {
        let _this = this
        obj.events.add('click', function (e) {
          let mark = {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение камеры на карте"</div>'
          }
          _this.placemarks = []
          mark.coords = e.get('coords')
          _this.model.latitude = mark.coords[0]
          _this.model.longitude = mark.coords[1]
          _this.coords = mark.coords
          _this.placemarks.push(mark)
        })
      },
      whenTabInFocus () {
        let _this = this
        let query = {
          id: _this.model.buildingId
        }
        api.get(_this.urls.buildingAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.hComplexId = jData.data.housingComplexId
          }
          return api.get(_this.urls.housingComplex)
        }).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.id === _this.hComplexId) {
                _this.hComplex = item.name
              }
            })
          }
        })
      },
      sendForm () {
        let _this = this
        if (_this.stateName === true && _this.state2 === true && _this.state3 === true && _this.state6 === true) {
          _this.show = true
          _this.whenTabInFocus()
          delete _this.model.image

          let promise = null
          if (this.cameraId) {
            promise = api.put(_this.urls.camera + '/' + _this.$route.params.id, _this.model)
          } else {
            promise = api.post(_this.urls.camera, _this.model)
          }

          promise.then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({
                  path: '/cameras/list',
                  query: {tab: _this.hComplex, currentPage: _this.$route.query.currentPage}
                })
              }, 1000)
            }
          })
        }
      },
      getBuildings () {
        let _this = this
        api.get(_this.urls.building).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.housingComplex + ', ' + spec.street + ', д. ' + spec.objectNumber
              _this.buildings.push(temp)
            })
          }
        })
      },
      deletePicture () {
        let _this = this
        _this.model.image = null
        document.getElementById('file').value = ''
      }
    },
    mounted: function () {
      this.getBuildings()
      let _this = this
      if (this.cameraId) {
        api.get(_this.urls.camera + '/' + _this.$route.params.id).then(function (response) {
          let jData = response.data
          _this.model = jData.data
          if (_this.model.streamingUri === null) {
            _this.model.streamingUri = ''
          }
          if (_this.model.streamingUriHd === null) {
            _this.model.streamingUriHd = ''
          }
          if (_this.model.adminStream === null) {
            _this.model.adminStream = ''
          }
          if (_this.model.latitude !== null && _this.model.longitude !== null) {
            _this.coords = [_this.model.latitude, _this.model.longitude]
            let mark = {
              coords: [_this.model.latitude, _this.model.longitude],
              properties: {}, // define properties hereasd
              clusterName: '1',
              balloonTemplate: '<div>"Положение камеры на карте"</div>'
            }
            _this.placemarks = []
            mark.coords = [_this.model.latitude, _this.model.longitude]
            _this.placemarks.push(mark)
          }
        })
      }
    },
    watch: {
      'model.latitude': function (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let _this = this
          let mark = _this.placemarks[0]
          _this.placemarks = []
          mark.coords = [n, _this.model.longitude]
          _this.coords = [n, _this.model.longitude]
          _this.placemarks.push(mark)
        }
      },
      'model.longitude': function (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let _this = this
          let mark = _this.placemarks[0]
          _this.placemarks = []
          mark.coords = [_this.model.latitude, n]
          _this.coords = [_this.model.latitude, n]
          _this.placemarks.push(mark)
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'camera'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        cameraId: this.$route.params.id,
        model: {
          name: '',
          image: '',
          latitude: 58.603542963107785,
          longitude: 49.66805437579303,
          streamingUri: '',
          streamingUriHd: '',
          buildingId: 0,
          isDemo: false
        },
        buildings: [
        ],
        coords: [58.603542963107785, 49.66805437579303],
        date: '',
        placemarks: [
          {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение камеры на карте"</div>'
          }
        ],
        yamap: '',
        file: '',
        posts: [],
        urls: {
          file: 'file',
          camera: 'camera',
          buildingAddress: 'building/buildingaddress',
          housingComplex: 'housingComplex',
          building: 'building'
        },
        show: false,
        accessList: {},
        hComplex: '',
        hComplexId: 0,
        label: 'Пожалуйста, подождите, операция выполняется',
        consts: consts,
        validation: validation
      }
    }
  }
</script>
