<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Редактирование новости</strong>
          </template>
          <b-form-group
            label="Статус"
            label-for="status"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.statusId)"
            :state="stateStatusId">
            <b-form-select
              id="status"
              placeholder="Выберите статус новости"
              :options="statuses"
              v-model="model.statusId"
              :state="stateStatusId">
              <template slot="first">
                <option :value="null" disabled>Выберите статус новости</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            v-if="model.npTypeId !== 3"
            label="Название новости"
            label-for="title"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.title)"
            :state="state1">
            <b-form-input id="title" type="text" :state="state1" v-model="model.title"></b-form-input>
          </b-form-group>
          <b-form-group
            v-if="model.npTypeId === 3"
            label="Опрос"
            label-for="poll"
            :label-cols="3"
            :horizontal="true">
            <b-form-select
              id="poll"
              placeholder="Выберите опрос"
              :options="polls"
              v-model="model.pollId">
              <template slot="first">
                <option :value="null" disabled>Выберите опрос</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Текст новости"
            label-for="description"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.content)"
            :state="state2">
            <vue-editor v-model="model.content"
                        :editorToolbar="customToolbar"
                        :useCustomImageHandler="true"
                        @imageAdded="handleImageAdded"
                        v-bind:class="{invalid__dateTime: !state2, valid__dateTime: state2 === true}"
                        :state="state2">
            </vue-editor>
          </b-form-group>
          <b-form-group
            label="Изображение новости"
            label-for="file"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(file)"
            :state="state3">
            <b-form-file id="file"
                         v-bind:class="{invalid__dateTime: !state3, valid__dateTime: state3 === true}"
                         :plain="true"
                         :state="state3"
                         v-model="file"
                         :multiple="false">
            </b-form-file>
          </b-form-group>
          <b-form-group
            v-if="model.image !== undefined"
            label=""
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-img-lazy blank width="175" height="175" fluid blank-color="#777" :src="model.image" alt="hex shorthand color" class="m-1" />
          </b-form-group>
          <div v-if="model.linkedObjects.length > 0" v-for="obj in model.linkedObjects">
            <b-form-group
              :label="`${obj.name}`"
              label-for="file"
              :label-cols="3"
              :horizontal="true">
              <div class="address_picker">
                <div v-if="obj.buildings.length === 0" class="sendToWholeHC">Если не указаны здания, будет уведомлен весь ЖК</div>
                <multiselect v-model="obj.buildings" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" :preserve-search="false" :searchable="false" placeholder="" label="name" track-by="name" :preselect-first="true">
                  <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.name }}</span><span class="custom__remove" @click="props.remove(props.option)" @click.prevent="getHousingComplex">❌</span></span></template>
                </multiselect>
                <b-btn size="sm" class="address_picker__button_add_before_remove" @click.prevent="viewModalPremise(obj.id)" text="Button" variant="success"><i class="fa fa-plus fa-2x"></i></b-btn>
                <b-btn size="sm" class="address_picker__button_remove" @click.prevent="delHC(obj.id)" text="Button" variant="danger"><i class="fa fa-close fa-2x"></i></b-btn>
              </div>
            </b-form-group>
          </div>
          <b-form-group
            label="ЖК"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-btn size="sm" @click.prevent="viewModalHC" text="Button" variant="success">Добавить ещё</b-btn>
          </b-form-group>
          <b-form-group
            label="Период публикации"
            label-for="publishesAt"
            :label-cols="3"
            :horizontal="true">
            <label for="publishesAt" class="pr-2 pr-lg-0">с</label>
            <datetime type="datetime"
              id="publishesAt"
              v-model="date.publishesAt"
              class="theme-orange"
              input-class="dataTimePick"
              placeholder="Дата и время"
              moment-locale="ru"
              :phrases="{ok: 'OK', cancel: 'Отменить'}">
            </datetime>
            <br class="d-block d-lg-none">
            <label for="endsAt"> по</label>
            <datetime type="datetime"
              id="endsAt"
              v-model="date.endsAt"
              class="theme-orange"
              input-class="dataTimePick"
              placeholder="Дата и время"
              moment-locale="ru"
              :phrases="{ok: 'OK', cancel: 'Отменить'}">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Тип новости"
            label-for="housingComplex"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.npTypeId)"
            :state="state6">
            <b-form-select id="housingComplex"
                            :options="npTypes"
                            placeholder="Выберите тип новости"
                            v-model="model.npTypeId"
                            :state="state6">
              <template slot="first">
                <option :value="null" disabled>Выберите тип новости</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" @click.prevent="publish" text="Button" variant="info"
                 title="Сохранить и опубликовать с отправкой пуш-уведомления" v-b-tooltip.hover>Опубликовать</b-btn>
          <b-btn size="sm" @click.prevent="goBack" text="Button">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="addHC" hide-footer title="Добавить ЖК">
      <b-form-group
        label="Жилищный комплекс"
        label-for="housingComplex"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(hcIds)"
        :state="state5">
        <b-form-select
          id="housingComplex"
          v-model="hcIds"
          :options="housingComplex"
          placeholder="Выберите ЖК"
          multiple>
          <template slot="first">
            <option :value="null" disabled>Выберите ЖК</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="addHCs">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="addPremise" hide-footer title="Добавить здание">
      <b-form-group
        label="Дом"
        label-for="building"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(buildingIds)"
        :state="state11">
        <b-form-select id="building"
                       :options="building"
                       :multiple="true"
                       placeholder="Выберите дом"
                       v-model="buildingIds"
                       :select-size="10"
                       :state="state11">
          <template slot="first">
            <option :value="null" disabled>Выберите дом</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="saveAddress">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="myModalRef" hide-footer title="Новость успешно изменена">
      <p class="my-4">Новость успешно изменена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import { VueEditor } from 'vue2-editor'
  import customToolbar from '../../config/editor'
  import consts from '../../config/const'
  import validation from '../../config/validation'
  import Multiselect from 'vue-multiselect'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import moment from 'moment'

  moment.locale('ru')

  export default {
    name: 'New',
    components: {
      VueEditor,
      loading,
      Multiselect,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.long(this.model.title)
      },
      state2 () {
        return validation.long(this.model.content)
      },
      state3 () {
        return validation.long(this.file)
      },
      state11 () {
        return validation.numIds(this.buildingIds)
      },
      state5 () {
        return validation.numIds(this.hcIds)
      },
      state6 () {
        return validation.numId(this.model.npTypeId)
      },
      stateStatusId () {
        return validation.numId(this.model.statusId)
      }
    },
    methods: {
      getNPTypes () {
        let _this = this
        api.get(_this.urls.nptype).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.npTypes.push(temp)
            })
          }
        })
      },
      async getPolls () {
        const response = await api.get(this.urls.polls)
        const jsonData = response.data
        if (jsonData && jsonData.status) {
          for (const value of jsonData.data) {
            const status = {}
            status.value = value.id
            status.text = value.title
            this.polls.push(status)
          }
        }
      },
      handleImageAdded (file, Editor, cursorLocation, resetUploader) {
        let formData = new FormData()
        formData.append('file', file)

        api.post(this.urls.file, formData).then((response) => {
          if (response.data.status === false) {
            alert(response.data.error.description)
            return
          }
          let jData = response.data
          Editor.insertEmbed(cursorLocation, 'image', consts.storageUrl + '/' + jData.data.url)
          resetUploader()
        })
      },
      delHC (id) {
        this.model.linkedObjects.forEach((item, index) => {
          if (item.id === id) {
            this.model.linkedObjects.splice(index, 1)
          }
        })
        this.getHousingComplex()
      },
      viewModalHC () {
        let _this = this
        this.hcIds = []
        _this.$refs.addHC.show()
        this.isModalClosed = false
      },
      viewModalPremise (id) {
        let _this = this
        this.hcId = id
        this.buildingIds = []
        this.building = []
        this.street = []
        this.getHousingComplex()
        _this.$refs.addPremise.show()
      },
      goBack () {
        this.$router.push({path: '/news', query: {currentPage: this.$route.query.currentPage}})
      },
      async addHCs () {
        let _this = this
        this.buildingIds = []
        this.$refs.addHC.hide()
        this.isModalClosed = true
        await api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          for (let i = 0; i < _this.hcIds.length; i++) {
            let isFound = false
            _this.model.linkedObjects.forEach((item) => {
              if (item.id === parseInt(_this.hcIds[i])) {
                isFound = true
              }
            })
            if (!isFound) {
              jData.data.forEach(function (spec) {
                if (spec.id === parseInt(_this.hcIds[i])) {
                  const linkedObj = {
                    id: spec.id,
                    name: spec.name,
                    buildings: []
                  }
                  _this.model.linkedObjects.push(linkedObj)
                }
              })
            }
          }
        })
      },
      saveAddress () {
        let _this = this
        if (_this.state11) {
          let fullAddress = null
          let hComplex = {}
          _this.model.linkedObjects.forEach((item) => {
            if (item.id === _this.hcId) {
              hComplex.name = item.name
              hComplex.id = item.id
              _this.buildingIds.forEach((bId) => {
                _this.building.forEach((building, index) => {
                  if (building.value === bId) {
                    fullAddress = building.text
                  }
                })
                let checkBuilding = false
                item.buildings.forEach((building) => {
                  if (building.id === bId) {
                    checkBuilding = true
                  }
                })
                if (!checkBuilding) {
                  item.buildings.push({
                    name: fullAddress,
                    id: bId
                  })
                }
              })
            }
          })
          _this.getHousingComplex()
          _this.hideModalPremise()
        }
      },
      hideModalPremise () {
        this.buildingIds = []
        this.$refs.addPremise.hide()
      },
      async saveHCs () {
        let _this = this
        this.hideModalPremise()
        this.isModalClosed = true
        await api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          console.log(_this.hcIds)
          for (let i = 0; i < _this.hcIds.length; i++) {
            let isFound = false
            _this.model.linkedObjects.forEach((item) => {
              if (item.id === parseInt(_this.hcIds[i])) {
                isFound = true
              }
            })
            if (!isFound) {
              jData.data.forEach(function (spec) {
                if (spec.id === parseInt(_this.hcIds[i])) {
                  const linkedObj = {
                    id: spec.id,
                    name: spec.name,
                    buildings: []
                  }
                  _this.model.linkedObjects.push(linkedObj)
                }
              })
            }
          }
        })
      },
      sendForm () {
        if (this.state1 && this.state2 && this.state3 && this.state6 && this.stateStatusId) {
          this.show = true
          if (this.model.image === 'https://openclipart.org/image/2400px/svg_to_png/194077/Placeholder.png&disposition=attachment') {
            this.model.image = null
          }
          if (this.date.publishesAt) {
            this.model.publishesAt = moment(this.date.publishesAt).format('X')
          }
          if (this.date.endsAt) {
            this.model.endsAt = moment(this.date.endsAt).format('X')
          }
          if (this.forcePublish) {
            this.model.forcePublish = true
          }
          delete this.model.hcId
          delete this.model.buildingIds
          delete this.model.url
          if (this.model.pollId === 0) delete this.model.pollId
          console.log('MODEL', this.model)
          api.put(this.urls.news + '/' + this.$route.params.id, this.model).then(response => {
            this.show = false
            if (response.status) {
              this.$refs.myModalRef.show()
              setTimeout(() => {
                this.$refs.myModalRef.hide()
                this.$router.push('/news')
              }, 1000)
            }
          })
        }
      },
      publish () {
        this.forcePublish = true
        this.sendForm()
      },
      async getNPStatuses () {
        const response = await api.get(this.urls.npstatus)
        const jsonData = response.data
        if (jsonData && jsonData.status) {
          for (const value of jsonData.data) {
            const status = {}
            status.value = value.id
            status.text = value.name
            this.statuses.push(status)
          }
        }
      },
      getHousingComplex () {
        let _this = this
        _this.housingComplex = []
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            console.log('jData.data', jData.data)
            jData.data.forEach(function (spec) {
              let checkHC = false
              _this.model.linkedObjects.forEach((houseComplex) => {
                if (houseComplex.id === spec.id) {
                  checkHC = true
                }
              })
              if (!checkHC) {
                const temp = {
                  value: spec.id,
                  text: spec.name
                }
                _this.housingComplex.push(temp)
              }
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this
        _this.building = []
        if (_this.model.hcId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.hcId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  let checkBuilding = false
                  if (spec.id !== null) {
                    _this.model.linkedObjects.forEach((item) => {
                      item.buildings.forEach((building) => {
                        if (building.id === spec.id) {
                          checkBuilding = true
                        }
                      })
                    })
                    if (!checkBuilding) {
                      let temp = {}
                      temp.value = spec.id
                      temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                      _this.building.push(temp)
                    }
                  }
                })
              }
            })
          })
        }
      },
      formatDate (timestamp) {
        return moment(timestamp * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'news'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false || response.data.data.isWrite === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      api.get(this.urls.news + '/' + this.$route.params.id, {notCountThisView: true}).then(response => {
        let jData = response.data
        this.model = jData.data
        console.log(this.model)
        this.file = this.model.image
        if (this.model.publishesAt) {
          this.date.publishesAt = this.formatDate(this.model.publishesAt)
        }
        if (this.model.endsAt) {
          this.date.endsAt = this.formatDate(this.model.endsAt)
        }
      })
      this.getHousingComplex()
      this.getNPTypes()
      this.getNPStatuses()
      this.getPolls()
    },
    watch: {
      'file': function (newFile, oldFile) {
        if (typeof newFile === 'object') {
          let _this = this
          let fd = new FormData()
          fd.append('file', newFile)
          _this.show = true
          api.post(_this.urls.file, fd).then(function (response) {
            _this.show = false
            if (response.data.status === false) {
              alert(response.data.error.description)
              return
            }
            let jData = response.data
            _this.model.image = consts.storageUrl + '/' + jData.data.url
          })
        }
      }
    },
    data () {
      return {
        model: {
          linkedObjects: []
        },
        accessList: {},
        value: [],
        npTypes: [],
        options: [
        ],
        file: '',
        posts: [],
        urls: {
          file: 'file',
          news: 'news',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          nptype: 'nptype',
          npstatus: 'npstatus',
          polls: 'poll'
        },
        date: {
          publishesAt: '',
          endsAt: ''
        },
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        statuses: [],
        hcId: 0,
        hcIds: [],
        pollId: 0,
        polls: [],
        streetId: 0,
        buildingIds: [],
        consts: consts,
        customToolbar: customToolbar,
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation,
        isModalClosed: true
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.sendToWholeHC {
  margin-top: 10px;
  margin-left: 10px;
  position: absolute;
  z-index: 1;
}
</style>
