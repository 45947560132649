<template>
  <b-card :header="caption">
    <b-row v-if="accessList.isWrite === true">
      <b-col lg="3">
        <b-btn class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Добавить чат</b-btn>
      </b-col>
    </b-row>
    <b-col lg="12">
      <b-tabs card>
        <b-tab v-for=" (item, n) in typeList" :title="`${item.count === 0 ? `Чаты ${item.name}` : `Чаты ${item.name} <span class=\'badge badge-info badge-pill\'>${item.count}</span>`}` " :active="isActive(item.name)" @click="changeTab(item.name)">
          <div class="spinner" v-if="!viewTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
          <div v-if="viewTable">
            <b-row>
              <b-col offset="9" lg="3">
                <div class="input-group mb-3">
                  <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
                  <div v-if="search.length > 0" class="input-group-append">
                    <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
                  </div>
                  <div v-else class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table v-if="viewTable" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
            <template slot="index" slot-scope="data">
              {{perPage * (currentPage - 1) + data.index + 1}}
            </template>
            <template slot="buttons" slot-scope="data">
              <div class="table__three_buttons">
                <b-btn size="sm" class="change_size btn-info" @click.stop="view(data.item.id)"><i class="fa fa-comment-o fa-2x"></i></b-btn>
                <b-btn size="sm" class="change_size btn-success change__font_size" @click.stop="userList(data.item.id)"><i class="icon-people"></i></b-btn>
                <b-btn size="sm" class="change_size btn-warning" v-if="currentTab === 'тематические'" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
                <b-btn size="sm" v-if="accessList.isDelete === true && currentTab === 'тематические'" class="change_size btn-danger" @click.stop="del(data.item.id)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
              </div>
            </template>
          </b-table>
          <nav class="d-flex">
            <b-pagination v-if="viewTable" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
            <label class="d-flex justify-content-center align-items-center mb-3 ml-3" v-if="viewTable">
              Страница:
              <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
            </label>
          </nav>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import consts from '../../config/const'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        searchTimer: null,
        firstView: true,
        search: '',
        typeList: [],
        accessList: {},
        tempItem: [],
        fields: [
          {key: 'index', label: '#'},
          {key: 'name', label: 'Наименование'},
          {key: 'count', label: 'Новых сообщений'},
          {key: 'usersCount', label: 'Пользователей'},
          {key: 'buttons', label: ' '}
        ],
        textPage: '1',
        lastPage: 1,
        currentPage: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        currentTab: 1,
        consts: consts,
        urls: {
          del: 'chat/theme',
          list: 'chat/list',
          chat: 'chat',
          userlist: 'userlist',
          chattype: 'chattype'
        }
      }
    },
    sockets: {
      adminAllUnread (msg) {
        this.typeList.forEach(item => {
          if (item.keyWord === msg.type) {
            item.count = msg.countAllUnread
          }
        })
      },
      message (msg) {
        console.log('msg.chatKeyWord', msg.chatKeyWord)
        this.$socket.emit('adminAllUnread', {type: msg.chatKeyWord})
        this.items.forEach(item => {
          if (item.id === msg.chatId) {
            item.count++
            item._rowVariant = 'info'
          }
        })
      }
    },
    methods: {
      userList (id) {
        this.$router.push(this.urls.userlist + '/' + id)
      },
      clearSearch () {
        this.search = ''
      },
      setCurrents () {
        if (this.$route.query.tab) {
          this.currentTab = this.$route.query.tab
        }
        if (this.$route.query.currentPage) {
          this.currentPage = parseInt(this.$route.query.currentPage)
        }
      },
      async changeTab (name) {
        this.viewTable = false
        this.currentTab = (name === null ? 1 : name)
        if (!name) {
          await this.setCurrents()
        }
        this.getData()
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      view (id) {
        this.$router.push('/chats/chat/' + id)
      },
      detail (id) {
        this.$router.push('/chats/edit/' + id)
      },
      del (id) {
        api.delete(this.urls.del + '/' + id).then((response) => {
          this.getData()
        })
      },
      newItem () {
        this.$router.push('new/')
      },
      getData () {
        this.textPage = String(this.currentPage)
        this.viewTable = false
        let typeKey = null
        let cashTypeList = [...this.typeList]
        api.get(this.urls.chattype).then((response) => {
          let jData = response.data
          this.typeList = []
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.keyWord !== 'request' && item.keyWord !== 'premise') {
                item.name = item.name.toLowerCase()
                if (!this.firstView) {
                  cashTypeList.forEach((type) => {
                    if (type.keyWord === item.keyWord) {
                      item.count = type.count
                    }
                  })
                } else {
                  item.count = 0
                }
                this.typeList.push(item)
              }
            })
          }
          this.currentTab = (this.currentTab === 1 ? this.typeList[0].name : this.currentTab)
          jData.data.forEach((item) => {
            if (item.name === this.currentTab) {
              typeKey = item.keyWord
            }
          })
          let query = {
            search: this.search,
            type: typeKey,
            page: this.currentPage,
            limit: this.perPage
          }
          return api.get(this.urls.list, query)
        }).then((response) => {
          let jMeta = response.data.meta
          let jData = response.data
          if (jData.status) {
            this.totalRows = jMeta.pages * this.perPage - 1
            this.lastPage = jMeta.pages
            this.items = jData.data
            this.items.forEach((item) => {
              if (item.count > 0) {
                item._rowVariant = 'info'
              }
            })
            this.viewTable = true
            this.typeList.forEach(item => {
              // console.log('fdffddffddf', item.keyWord)
              this.$socket.emit('adminAllUnread', {type: item.keyWord})
            })
            this.firstView = false
          }
        })
      },
      isActive (hcName) {
        let _this = this
        return hcName === _this.$route.query.tab
      },
      replaceRouterQuery () {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            tab: this.currentTab,
            currentPage: this.currentPage
          }
        })
      }
    },
    watch: {
      search: function () {
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => { this.getData() }, 1000)
      },
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      },
      currentPage (val) {
        this.replaceRouterQuery()
      },
      currentTab () {
        this.replaceRouterQuery()
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'chat'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted () {
      this.changeTab(null)
    },
    beforeDestroy () {
      this.$socket.removeListener('adminAllUnread')
      this.$socket.removeListener('message')
    }
  }
</script>
