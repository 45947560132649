<template>
  <b-card :header="caption">
    <b-row v-if="accessList.isWrite === true">
      <b-col lg="3">
        <b-btn class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Создать помещение</b-btn>
      </b-col>
    </b-row>
    <b-col lg="12">
      <b-tabs card>
        <b-tab v-for=" (item, n) in hcList" :title="`${item.name}`" :active="isActive(item.name)" @click="changeTab(item.name)">
          <div class="spinner" v-if="!viewTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
          <div v-if="viewTable">
            <b-row>
              <b-col lg="2" class="justify-content-start">
                <label class="mt-1 d-inline-block">
                  Демо
                </label>
                </b-form-group>
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" v-model="isDemo" class="switch-input" checked>
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </b-col>
              <b-col offset="7" lg="3">
                <div class="input-group mb-3">
                  <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
                  <div v-if="search.length > 0" class="input-group-append">
                    <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
                  </div>
                  <div v-else class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table v-if="viewTable" :sort-by.sync="sortBy" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
            <template slot="index" slot-scope="data">
              {{perPage * (currentPage - 1) + data.index + 1}}
            </template>
            <template slot="isDemo" slot-scope="data">
              <p v-if="data.item.isDemo === 1"><i class="fa fa-check fa-2x success"></i></p>
              <p v-else><i class="fa fa-close fa-2x danger"></i></p>
            </template>
            <template slot="buttons" slot-scope="data">
              <div class="table__two_buttons">
                <b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
                <b-btn size="sm" v-if="accessList.isDelete === true" class="change_size btn-danger" @click.stop="showModal(data.item.id)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
              </div>
            </template>
          </b-table>
          <nav class="d-flex">
            <b-pagination v-if="viewTable" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
            <label class="d-flex justify-content-center align-items-center mb-3 ml-3" v-if="viewTable">
              Страница:
              <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
            </label>
          </nav>
        </b-tab>
      </b-tabs>
    </b-col>
    <b-modal ref="myModalRef" hide-footer title="Удаление квартиры">
      <div class="d-block text-center">
        <h3>Вы действительно хотите удалить запись?</h3>
      </div>
      <b-btn class="mt-3" variant="outline-danger" block @click="del()">Да</b-btn>
      <b-btn class="mt-3" variant="outline-danger" block @click="hideModal">Нет</b-btn>
    </b-modal>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        tempItem: [],
        hcList: [],
        fields: [
          {key: 'index', label: '#'},
          {key: 'address', label: 'Адрес'},
          {key: 'entrance', label: 'Подъезд'},
          {key: 'floor', label: 'Этаж'},
          {key: 'premiseNum', label: 'Номер квартиры'},
          {key: 'premiseType', label: 'Тип помещения'},
          {key: 'totalArea', label: 'Общая площадь'},
          {key: 'livingArea', label: 'Жилая площадь'},
          {key: 'gisCode', label: 'Код ГИС ЖКХ'},
          {key: 'dispatchCode', label: 'Код диспетчерской'},
          {key: 'buttons', label: ' '}
        ],
        accessList: {},
        sortBy: 'premiseNum',
        isDemo: false,
        searchTimer: null,
        search: '',
        textPage: '1',
        currentPage: 1,
        lastPage: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        currentTab: 1,
        delItem: 0,
        urls: {
          list: 'premise',
          hc: 'housingcomplex',
          buildingAddress: 'building/buildingaddress'
        }
      }
    },
    methods: {
      clearSearch () {
        this.search = ''
      },
      setCurrents () {
        if (this.$route.query.tab) {
          this.currentTab = this.$route.query.tab
        }
        if (this.$route.query.currentPage) {
          this.currentPage = parseInt(this.$route.query.currentPage)
        }
      },
      async changeTab (name) {
        this.viewTable = false
        this.currentTab = (name === null ? 1 : name)
        if (!name) {
          await this.setCurrents()
        }
        this.getData()
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      newItem () {
        let _this = this
        _this.$router.push('/premise/new/')
      },
      detail (id) {
        this.$router.push({path: '/premise/edit/' + id, query: {currentPage: this.currentPage}})
      },
      showModal (id) {
        var _this = this
        this.delItem = id
        _this.$refs.myModalRef.show()
      },
      del () {
        var _this = this
        api.delete(_this.urls.list + '/' + _this.delItem).then(function (response) {
          _this.getData()
        })
        this.delItem = 0
        _this.hideModal()
      },
      hideModal () {
        this.$refs.myModalRef.hide()
      },
      getData () {
        this.textPage = String(this.currentPage)
        let _this = this
        switch (_this.isDemo) {
          case true:
            _this.isDemo = 1
            break
          case false:
            _this.isDemo = 0
            break
        }
        this.viewTable = false
        let hcId = null
        api.get(_this.urls.hc).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.hcList = jData.data
          }
          _this.currentTab = (_this.currentTab === 1 ? _this.hcList[0].name : _this.currentTab)
          jData.data.forEach((item) => {
            if (item.name === _this.currentTab) {
              hcId = item.id
            }
          })
          return hcId
        }).then((idHc) => {
          let query = {
            search: _this.search,
            isDemo: _this.isDemo,
            hcId: idHc,
            page: _this.currentPage,
            limit: _this.perPage
          }
          return api.get(_this.urls.list, query)
        }).then((response) => {
          let jMeta = response.data.meta
          _this.lastPage = jMeta.pages
          _this.totalRows = jMeta.pages * _this.perPage - 1
          _this.items = response.data.data
          _this.viewTable = true
        })
      },
      isActive (hcName) {
        let _this = this
        return hcName === _this.$route.query.tab
      },
      replaceRouterQuery () {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            tab: this.currentTab,
            currentPage: this.currentPage
          }
        })
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'premise'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    watch: {
      search: function () {
        let _this = this
        clearTimeout(_this.searchTimer)
        _this.searchTimer = setTimeout(function () { _this.getData() }, 1000)
      },
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      },
      isDemo (newVal, lastVal) {
        if (lastVal === 0 || lastVal === 1) {
          this.getData()
        }
      },
      currentPage (val) {
        this.replaceRouterQuery()
      },
      currentTab () {
        this.replaceRouterQuery()
      }
    },
    mounted: function () {
      this.changeTab(null)
    }
  }
</script>
