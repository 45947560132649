<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>

    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
            <b-btn
              size="sm"
              text="Button"
              variant="info"
              class="button__send-data"
              title="Загрузка платежных документов по данной квартире из ГИС ЖКХ"
              v-b-tooltip.hover
              @click.prevent="fetchPayments"
            >
              Получить платежи
            </b-btn>
          </template>

          <b-form-group
            label="Демо режим"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isDemo" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>

          <b-form-group
            label="Режим отопления"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="id" plaintext readonly type="text" v-model="model.heatingMode"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Тип помещения"
            label-for="status"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.premiseTypeId)"
            :state="state1">
            <b-form-select id="status"
                           :options="premisetype"
                           v-model="model.premiseTypeId"
                           :state="state1">
              <template slot="first">
                <option :value="null" disabled>Выберите тип помещения</option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Город"
            label-for="cities"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(location.cityId)"
            :state="stateCity">
            <b-form-select id="cities"
                           :options="cities"
                           v-model="location.cityId"
                           :state="stateCity">
              <template slot="first">
                <option :value="null" disabled>Выберите город</option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Жилищный комплекс"
            label-for="housingComplex"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(location.hcId)"
            :state="stateHcId">
            <b-form-select id="housingComplex"
                           :options="housingComplex"
                           placeholder="Выберите ЖК"
                           v-model="location.hcId"
                           :state="stateHcId">
              <template slot="first">
                <option :value="null" disabled>Выберите ЖК</option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Дом"
            label-for="building"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(location.buildingId)"
            :state="stateBuildingId">
            <b-form-select id="building"
                           :options="building"
                           placeholder="Выберите дом"
                           v-model="location.buildingId"
                           :state="stateBuildingId">
              <template slot="first">
                <option :value="null" disabled>Выберите здание</option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Подъезд"
            label-for="entrance"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelectWithPremiseType(location.entranceId, selectPremiseTypeKey)"
            :state="stateEntranceId">
            <b-form-select id="entrance"
                           :options="entrance"
                           placeholder="Выберите подъезд"
                           v-model="location.entranceId"
                           :state="stateEntranceId">
              <template slot="first">
                <option :value="null" disabled>Выберите подъезд</option>
              </template>
            </b-form-select>
          </b-form-group>

          <b-form-group
            label="Номер помещения"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.premiseNum)"
            :state="state7">
            <b-form-input id="id" type="number" :state="state7" v-model="model.premiseNum"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Код диспетчерской"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="id" type="text" v-model="model.dispatchCode"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Код ГИС ЖКХ"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="name" type="text" v-model="model.gisCode"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Общая площадь"
            label-for="totalArea"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.totalArea)"
            :state="state10">
            <b-form-input id="totalArea" type="text" :state="state10" v-model="model.totalArea"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Жилая площадь"
            label-for="livingArea"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumberWithPremiseType(model.livingArea, selectPremiseTypeKey)"
            :state="state11">
            <b-form-input id="livingArea" type="text" :state="state11" v-model="model.livingArea"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Этаж"
            label-for="floor"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.floor)"
            :state="state12">
            <b-form-input id="livingArea" type="text" :state="state12" v-model="model.floor"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Группа вызова ATC"
            label-for="name"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="id" type="text" readonly v-model="model.callingGroup"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Контроллер умного дома"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.smartHome" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>

          <b-form-group
            label="Дата передачи"
            label-for="keysAcceptanceDate"
            :label-cols="3"
            label-class="text-left"
            :horizontal="true"
            :state="state17"
          >
            <div class="d-flex align-items-center h-100">
              <datetime 
                type="date"
                id="keysAcceptanceDate"
                v-model="date.keysAcceptanceDate"
                class="theme-orange"
                input-class="dataTimePick"
                placeholder="Дата"
                moment-locale="ru"
                :phrases="{ok: 'OK', cancel: 'Отменить'}"
                v-bind:class="{invalid__dateTime: !state17, valid__dateTime: state17 === true}"
                :state="state17"
              >
              </datetime>
              <b-btn size="sm" @click.prevent="date.keysAcceptanceDate = ''" text="Button" variant="light" class="mt-2" title="Очистить">очистить</b-btn>
            </div>
          </b-form-group>

          <b-form-group
            label="Чистовая отделка"
            label-for="isRoughFinishing"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isRoughFinishing" class="switch-input">
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>

          <b-card v-if="model.smartHome">
            <template #header>
              <strong>Комнаты</strong>
            </template>
            <b-btn size="sm" class="mb-3" @click.prevent="viewNewRoomModal(false)" text="Button" variant="success">Добавить комнату</b-btn>
            <b-table v-if="rooms.length > 0" :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="rooms" :fields="roomsFields" :current-page="1" :per-page="100">
              <template slot="lights" slot-scope="data">
                <div v-for="(light, i) in data.item.lights" :key="i" class="text-center">
                  <i v-if="!light.removalMark" class="fa fa-1x fa-check color-green"></i>
                </div>
              </template>
              <template slot="heatings" slot-scope="data">
                <div v-for="(heat, i) in data.item.heatings" :key="i" class="text-center">
                  <i v-if="!heat.removalMark" class="fa fa-1x fa-check color-green"></i>
                </div>
              </template>
              <template slot="waterSensors" slot-scope="data">
                <div v-for="(waterSensor, i) in data.item.waterSensors" :key="i" class="text-center">
                  <i v-if="!waterSensor.removalMark" class="fa fa-1x fa-check color-green"></i>
                </div>
              </template>
              <template slot="buttons" slot-scope="data">
                <div class="table__two_buttons">
                  <b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item)"><i class="fa fa-pencil fa-2x"></i></b-btn>
                  <b-btn size="sm" v-if="accessList.isDelete === true" class="change_size btn-danger" @click.stop="del(data.item)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
                </div>
              </template>
            </b-table>
            <b-form-input v-else id="id" plaintext type="text" v-model="noRoomsText"></b-form-input>
          </b-card>

          <b-card>
            <template #header>
              <strong>Пользователи</strong>
            </template>
            <b-table v-if="model.users && model.users.length > 0" :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="model.users" :fields="usersFields" :current-page="1" :per-page="100">
              <template slot="buttons" slot-scope="data">
                <div class="table__one_buttons">
                  <b-btn size="sm" class="change_size btn-info" @click.stop="detailUser(data.item.id)"><i class="fa fa-info fa-2x"></i></b-btn>
                </div>
              </template>
            </b-table>
            <b-form-input v-else id="id" plaintext type="text" v-model="noUserText"></b-form-input>
          </b-card>

          <b-card>
            <template #header>
              <strong>Счётчики</strong>
              <b-btn size="sm" @click.prevent="getMeasurement()" text="Button" variant="info" class="button__send-data">Передать показания</b-btn>
            </template>
            <b-table v-if="items.length > 0" :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields">
              <template slot="buttons" slot-scope="data">
                <div class="table__two_buttons">
                  <b-btn size="sm" class="change_size btn-success" @click.stop="view(data.item.id)"><i class="fa fa-sliders fa-2x"></i></b-btn>
                  <b-btn size="sm" class="change_size btn-info" @click.stop="viewHistory(data.item)"><i class="fa fa-info fa-2x"></i></b-btn>
                </div>
              </template>
              <template slot="auto" slot-scope="data">
                {{ data.item.isAuto ? 'A' : 'P' }}
              </template>
            </b-table>
            <b-form-input v-else id="id" plaintext type="text" v-model="noMeterText"></b-form-input>
          </b-card>

          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>

    <b-modal size="lg" ref="roomModal" hide-footer title="Настройка комнаты">
      <b-form-group
        label="Тип комнаты"
        label-for="category"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(tempItem.roomTypeId)"
        :state="state13">
        <b-form-select id="category"
                        :options="roomtypes"
                        :state="state13"
                        v-model="tempItem.roomTypeId">
          <template slot="first">
            <option :value="null" disabled>Выберите тип комнаты</option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Наименование комнаты"
        label-for="category"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(tempItem.roomNameId)"
        :state="state14">
        <b-form-select id="category"
                        :options="roomnames"
                        :state="state14"
                        v-model="tempItem.roomNameId">
          <template slot="first">
            <option :value="null" disabled>Выберите наименование комнаты</option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group
        v-if="tempItem.currentTemp !== ''"
        label="Текущая температура:"
        label-for="name"
        :label-cols="3"
        :horizontal="true">
        <label class="m-0 pt-7px" v-if="tempItem.currentTemp !== null">{{tempItem.currentTemp}} &#8451;</label>
        <label class="m-0 pt-7px" v-else>{{noDataText}}</label>
      </b-form-group>

      <b-form-group
        v-if="tempItem.presetTemp !== ''"
        label="Заданная температура:"
        label-for="name"
        :label-cols="3"
        :horizontal="true">
        <label class="m-0 pt-7px" v-if="tempItem.presetTemp !== null">{{tempItem.presetTemp}} &#8451;</label>
        <label class="m-0 pt-7px" v-else>{{noDataText}}</label>
      </b-form-group>

      <b-form-group
        :label="`Свет (устройство ${room.device})`"
        label-for=""
        :label-cols="3"
        :horizontal="false"
        label-size="md"
        class="posrel"
        label-class="minus-mb pb-0"
        v-for="(room, i) in tempItem.lights"
        :key="room.id"
        v-if="!room.removalMark">
        <b-btn size="sm" class="change_size btn-delete-device" @click.stop="delDevice('lights', i)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
        <hr>
        <b-form-group
          label="Номер устройства"
          label-for="link"
          :label-cols="3"
          class="col-3 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.device)">
          <b-form-input id="link" type="text" :state="validation.long(room.device)" v-model="room.device"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Хост"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.host)">
          <b-form-input id="link" type="text" :state="validation.long(room.host)" v-model="room.host"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Порт"
          label-for="link"
          :label-cols="3"
          class="col-3 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.port)">
          <b-form-input id="link" type="text" :state="validation.long(room.port)" v-model="room.port"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Логин"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false">
          <b-form-input id="link" type="text" v-model="room.login"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Пароль"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false">
          <b-form-input id="link" type="text" v-model="room.password"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Топик управления светом"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.setValueTopic)">
          <b-form-input id="link" type="text" :state="validation.long(room.setValueTopic)" v-model="room.setValueTopic"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Топик текущего значения света"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.currentValueTopic)">
          <b-form-input id="link" type="text" :state="validation.long(room.currentValueTopic)" v-model="room.currentValueTopic"></b-form-input>
        </b-form-group>
      </b-form-group>

      <b-btn v-if="lightsLength < 3" class="mt-3 mb-3" variant="success" block @click="addControl('lights')">Добавить устройство(свет)</b-btn>

      <b-form-group
        :label="`Тепло (устройство ${room.device})`"
        label-for=""
        :label-cols="3"
        :horizontal="false"
        label-size="md"
        class="posrel"
        label-class="minus-mb pb-0"
        v-for="(room, i) in tempItem.heatings"
        :key="room.id"
        v-if="!room.removalMark">
        <b-btn size="sm" class="change_size btn-delete-device" @click.stop="delDevice('heatings', i)"><i class="fa fa-trash-o fa-2x"></i></b-btn>

        <hr>

        <b-form-group
          label="Номер устройства"
          label-for="link"
          :label-cols="3"
          class="col-3 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.device)">
          <b-form-input id="link" type="text" :state="validation.long(room.device)" v-model="room.device"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Хост"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.host)">
          <b-form-input id="link" type="text" :state="validation.long(room.host)" v-model="room.host"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Порт"
          label-for="link"
          :label-cols="3"
          class="col-3 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.port)">
          <b-form-input id="link" type="text" :state="validation.long(room.port)" v-model="room.port"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Логин"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false">
          <b-form-input id="link" type="text" v-model="room.login"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Пароль"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false">
          <b-form-input id="link" type="text" v-model="room.password"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Топик включения управления отоплением"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.controlTopic)">
          <b-form-input id="link" type="text" :state="validation.long(room.controlTopic)" v-model="room.controlTopic"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Топик текущей температуры"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.currentTempTopic)">
          <b-form-input id="link" type="text" :state="validation.long(room.currentTempTopic)" v-model="room.currentTempTopic"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Топик установки желаемой температуры"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.setPresetTempTopic)">
          <b-form-input id="link" type="text" :state="validation.long(room.setPresetTempTopic)" v-model="room.setPresetTempTopic"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Топик получения желаемой температуры"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.getPresetTempTopic)">
          <b-form-input id="link" type="text" :state="validation.long(room.getPresetTempTopic)" v-model="room.getPresetTempTopic"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Положение реле"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.getStateTopic)">
          <b-form-input id="link" type="text" :state="validation.long(room.getStateTopic)" v-model="room.getStateTopic"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Инверсия значения"
          label-for="premise"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false">
          <label class="switch switch-3d switch-primary">
            <input type="checkbox" v-model="room.stateValueInverted" class="switch-input" checked>
            <span class="switch-label" data-on="Да" data-off="Нет"></span>
            <span class="switch-handle"></span>
          </label>
        </b-form-group>
      </b-form-group>

      <b-btn v-if="heatingsLength < 1" class="mt-3" variant="success" block @click="addControl('heatings')">Добавить устройство(тепло)</b-btn>

      <b-form-group
        :label="`Датчик протечки (устройство ${room.device})`"
        label-for=""
        :label-cols="3"
        :horizontal="false"
        label-size="md"
        class="posrel"
        label-class="minus-mb pb-0"
        v-for="(room, i) in tempItem.waterSensors"
        :key="room.id"
        v-if="!room.removalMark">

        <b-btn size="sm" class="change_size btn-delete-device" @click.stop="delDevice('waterSensors', i)"><i class="fa fa-trash-o fa-2x"></i></b-btn>

        <hr>

        <b-form-group
          label="Номер устройства"
          label-for="link"
          :label-cols="3"
          class="col-3 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.device)">
          <b-form-input id="link" type="text" :state="validation.long(room.device)" v-model="room.device"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Хост"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.host)">
          <b-form-input id="link" type="text" :state="validation.long(room.host)" v-model="room.host"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Порт"
          label-for="link"
          :label-cols="3"
          class="col-3 d-inline-block"
          :horizontal="false"
          :state="validation.long(room.port)">
          <b-form-input id="link" type="text" :state="validation.long(room.port)" v-model="room.port"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Логин"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false">
          <b-form-input id="link" type="text" v-model="room.login"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Пароль"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false">
          <b-form-input id="link" type="text" v-model="room.password"></b-form-input>
        </b-form-group>

      </b-form-group>

      <b-btn v-if="waterSensorsLength < 1" class="mt-3" variant="success" block @click="addControl('waterSensors')">Добавить устройство(датчик протечки)</b-btn>
      <b-btn class="mt-3" variant="outline-success" block @click="editRoom()">Применить</b-btn>
    </b-modal>

    <b-modal size="lg" v-if="tempSetMeasure.length > 0 || tempSetMeasure.code === 471" ref="setMeasurementModal" hide-footer :title="`Форма передачи показаний счётчиков ${tempSetMeasure.code === 471 ? `(${tempSetMeasure.content.title})` : ''}`">
      <div v-if="tempSetMeasure.code === 471">
        <p class="text-center">{{tempSetMeasure.content.text}}</p>
        <b-btn class="mt-3" variant="outline-success" block @click="setMeasurement()">{{tempSetMeasure.content.button}}</b-btn>
      </div>

      <div v-else>
        <b-form-group
          :label="`${item.name}`"
          label-for=""
          :label-cols="3"
          :horizontal="false"
          label-size="md"
          label-class="minus-mb pb-0"
          v-for="(item, i) in tempSetMeasure"
          :key="i">

          <hr>

          <div v-for="(measure, i) in item.measurements" :key="i">
            <b-form-group
              label="Номер устройства"
              label-for="link"
              :label-cols="3"
              class="col-5 d-inline-block"
              :horizontal="false">
              <b-form-input id="link" plaintext type="text" v-model="measure.meterNumber"></b-form-input>
            </b-form-group>

            <b-form-group
              label="Значение"
              label-for="link"
              :label-cols="3"
              class="col-5 d-inline-block"
              :horizontal="false"
              :state="validation.isNumberBool(measure.value)">
              <b-form-input id="link" type="text" :state="validation.isNumberBool(measure.value)" v-model="measure.value"></b-form-input>
            </b-form-group>
          </div>
        </b-form-group>

        <b-btn class="mt-3" variant="outline-success" block @click="setMeasurement()">Применить</b-btn>
      </div>
    </b-modal>

    <b-modal size="lg" ref="historyModal" hide-footer :title='`Счётчик "${selectMeter.meterType}"`'>
      <b-form-group
        label="Номер счётчика:"
        label-for="link"
        :label-cols="4"
        class="col-6 d-inline-block"
        :horizontal="true">
        <b-form-input id="link" plaintext type="text" v-model="selectMeter.meterNumber"></b-form-input>
      </b-form-group>

      <b-form-group
        label="Период:"
        label-for="link"
        :label-cols="3"
        class="col-4 d-inline-block"
        :horizontal="true">
        <datepicker :minimumView="'month'" :maximumView="'month'" :initialView="'month'" v-model="date.materHistory" :format="customFormatter" input-class="data_picker__input" language="ru" placeholder="Выберите временной промежуток"></datepicker>
      </b-form-group>

      <b-form-group
        :label="`${item.name}`"
        label-for=""
        :label-cols="3"
        :horizontal="false"
        label-size="lg"
        v-for="(item, i) in meterHistory"
        :key="i">

        <b-table v-if="item.history.length > 0" :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="item.history" :fields="fieldsHistory">
        </b-table>

        <p v-else>Данные отсутствуют</p>
      </b-form-group>
    </b-modal>

    <b-modal ref="myModalRef" hide-footer title="Помещение успешно сохранено">
      <p class="my-4">Помещение успешно сохранено</p>
    </b-modal>

    <b-modal ref="privacyAccountsModal" hide-footer title="Лицевые счета пользователя">
      <b-form-group
        :label="`Лицевой счёт № ${i + 1}`"
        label-for=""
        :label-cols="3"
        :horizontal="false"
        label-size="md"
        class="posrel mt-0"
        label-class="minus-mb pb-0"
        v-for="(paymentAccount, i) in tempPrivacyAccount.paymentAccounts"
        :key="paymentAccount.id"
        v-if="!paymentAccount.removalMark">
        <b-btn size="sm" class="change_size btn-delete-privacy-account" @click.stop="delPaymentAccount(paymentAccount)"><i class="fa fa-trash-o fa-2x"></i></b-btn>

        <hr>

        <b-form-group
          label="Аккаунт"
          label-for="link"
          :label-cols="3"
          class="col-5 d-inline-block"
          :horizontal="false"
          :state="validation.long(paymentAccount.account)">
          <b-form-input id="link" type="text" :state="validation.long(paymentAccount.account)" v-model="paymentAccount.account"></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание"
          label-for="link"
          :label-cols="3"
          class="col-6 d-inline-block"
          :horizontal="false"
          :state="validation.long(paymentAccount.description)">
          <b-form-input id="link" type="text" :state="validation.long(paymentAccount.description)" v-model="paymentAccount.description"></b-form-input>
        </b-form-group>
      </b-form-group>

      <div class="d-flex justify-content-between">
        <b-btn size="sm" class="mt-3 w-40px" variant="success" block @click="addPrivacyAccount()"><i class="fa fa-plus fa-2x"></i></b-btn>
        <b-btn size="sm" class="mt-3 w-100px" variant="outline-success" block @click="setPrivacyAccounts()">Применить</b-btn>
      </div>
    </b-modal>

    <b-modal ref="paymentsModal" hide-footer title="Обновление платежных документов">
      <p>В ГИС ЖКХ отправлен запрос на получение платежных документов для лицевых счетов:</p>
      <ul>
        <li v-for="account in accounts">
          <router-link :to="`/${urls.paymentDocumentList}/${account.id}`" target="_blank">
            ЕЛС {{ account.unifiedAccountNumber }}, ЛС {{ account.accountNumber }}
          </router-link>
        </li>
      </ul>
      <p class="danger">Обработка запроса может занять несколько минут, в редких случаях -
        несколько часов или даже дней, что зависит от доступности и работоспособности сервиса ГИС ЖКХ</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import Datepicker from 'vuejs-datepicker'
  import validation from '../../config/validation'

  export default {
    name: 'Item',
    components: {
      loading,
      datetime: Datetime,
      Datepicker
    },

    props: {
      caption: {
        type: String,
        default: 'Table'
      },

      hover: {
        type: Boolean,
        default: false
      },

      striped: {
        type: Boolean,
        default: false
      },

      bordered: {
        type: Boolean,
        default: false
      },

      small: {
        type: Boolean,
        default: false
      },

      fixed: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        premiseId: this.$route.params.id,

        premisetype: [],
        cities: [],
        housingComplex: [],
        building: [],
        street: [],
        entrance: [],

        location: {
          cityId: null,
          hcId: null,
          buildingId: null,
          entranceId: null
        },
        model: {
          isDemo: false,
          premiseTypeId: 0,
          building: {
            id: 0,
            hcId: 0,
            streetId: 0,
            entranceId: 0,
            housingComplex: {
              id: 0,
              cityId: 0,
              name: ''
            }
          },
          buildingId: 0,
          entranceId: 0,
          smartHome: false,
          rooms: []
        },
        isRoughFinishing: false,
        tempItem: {
          roomTypeId: 0,
          roomNameId: 0,
          lights: [],
          heatings: [],
          waterSensors: []
        },

        selectMeter: 0,
        localIncrement: 0,
        items: [],
        roomtypes: [],
        roomnames: [],
        setMeasure: [],
        tempSetMeasure: [],
        meterHistory: [],

        housingComplexId: '',
        streetId: '',

        fields: [
          {key: 'auto', label: 'Авто/Руч', 'class': 'text-center'},
          {key: 'meterType', label: 'Тип', 'class': 'text-center'},
          {key: 'meterNumber', label: 'Номер', 'class': 'text-center'},
          {key: 'autoLastTimestamp', label: 'Дата автоматического показания', 'class': 'text-center'},
          {key: 'autoLastValue', label: 'Значение автоматического показания', 'class': 'text-center'},
          {key: 'manualLastTimestamp', label: 'Дата ручного показания', 'class': 'text-center'},
          {key: 'manualLastValue', label: 'Значение ручного показания', 'class': 'text-center'},
          {key: 'difference', label: 'Разница', 'class': 'text-center'},
          {key: 'buttons', label: ' '}
        ],

        usersFields: [
          {key: 'firstName', label: 'Имя'},
          {key: 'lastName', label: 'Фамилия'},
          {key: 'phone', label: ' Номер телефона'},
          {key: 'buttons', label: ' '}
        ],

        roomsFields: [
          {key: 'roomName', label: 'Наименование'},
          {key: 'roomType', label: 'Тип'},
          {key: 'lights', label: 'Датчики света', 'class': 'text-center'},
          {key: 'heatings', label: 'Датчик температуры', 'class': 'text-center'},
          {key: 'waterSensors', label: 'Датчик протечки', 'class': 'text-center'},
          {key: 'buttons', label: ' '}
        ],

        fieldsHistory: [
          {key: 'timestamp', label: 'Дата'},
          {key: 'measurement', label: 'Показание'},
          {key: 'user', label: 'Пользователь который передал показания', 'class': 'text-center'}
        ],

        date: {
          installationDate: '',
          lastInspection: '',
          materHistory: moment().valueOf(),
          history: moment().valueOf(),
          keysAcceptanceDate: '',
        },

        urls: {
          city: 'city',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          buildingAddress: 'building/buildingaddress',
          premise: 'premise',
          entrance: 'entrance',
          premisetype: 'premisetype',
          meter: 'meter/info',
          roomname: 'roomname',
          roomtype: 'roomtype',
          measurement: 'measurement',
          meterHistory: 'measurement/history',
          payments: 'payments',
          paymentDocumentList: 'paymentdocument/list'
        },

        accessList: {},
        tempPrivacyAccount: {},
        firstLoadForm: true,
        hComplex: '',
        hComplexId: 0,
        openedUser: null,
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        noMeterText: 'Счётчики ещё не добавлены',
        noRoomsText: 'Комнаты ещё не добавлены',
        noDataText: 'Нет данных',
        noUserText: 'Пользователи не добавлены',
        validation: validation,

        accounts: []
      }
    },

    computed: {
      pageTitle () {
        return this.premiseId ? 'Редактировать помещение' : 'Добавить помещение'
      },

      stateCity () {
        return validation.numId(this.location.cityId)
      },

      state1 () {
        return validation.numId(this.model.premiseTypeId)
      },

      stateHcId () {
        return validation.numId(this.location.hcId)
      },

      stateBuildingId () {
        return validation.numId(this.location.buildingId)
      },

      stateEntranceId () {
        return validation.numIdWithPremiseType(this.location.entranceId, this.selectPremiseTypeKey)
      },

      state7 () {
        return validation.long(this.model.premiseNum)
      },

      state10 () {
        return validation.isNumberBool(this.model.totalArea)
      },

      state11 () {
        return validation.isNumberBool(this.model.livingArea)
      },

      state12 () {
        return validation.isNumberBool(this.model.floor)
      },

      state13 () {
        return validation.numId(this.tempItem.roomTypeId)
      },

      state14 () {
        return validation.numId(this.tempItem.roomNameId)
      },
      state17 () {
        return validation.dateSelectBool(this.date.keysAcceptanceDate)
      },

      selectPremiseTypeKey () {
        let premiseType = this.premisetype.find(item => item.value === this.model.premiseTypeId)
        if (premiseType) {
          return premiseType.key
        } else {
          return ''
        }
      },

      rooms () {
        let tempArray = this.model.rooms.filter(item => !item.removalMark)
        return tempArray
      },

      heatingsLength () {
        let tempArray = []
        tempArray = this.tempItem.heatings.filter(item => !item.removalMark)
        return tempArray.length
      },

      lightsLength () {
        let tempArray = []
        tempArray = this.tempItem.lights.filter(item => !item.removalMark)
        return tempArray.length
      },

      waterSensorsLength () {
        let tempArray = []
        tempArray = this.tempItem.waterSensors.filter(item => !item.removalMark)
        return tempArray.length
      }
    },

    methods: {
      getCities () {
        api.get(this.urls.city).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name + ' [' + spec.utc + ']'
                this.cities.push(temp)
              }
            })
          }
        })
      },

      viewPrivacyAccounts (id) {
        this.openedUser = id
        this.setTempPrivacyAccounts()
        this.$refs.privacyAccountsModal.show()
      },

      setTempPrivacyAccounts () {
        let id = this.searchUserIndex()
        // console.log(Object.assign({}, this.model.users[id]))
        this.tempPrivacyAccount = JSON.parse(JSON.stringify(this.model.users[id]))

        if (this.tempPrivacyAccount.paymentAccounts.length === 0) {
          this.tempPrivacyAccount.paymentAccounts.push({
            account: '',
            description: '',
            removalMark: false
          })
        }
      },

      searchUserIndex () {
        let id = null
        this.model.users.forEach((item, index) => {
          if (String(item.id) === String(this.openedUser)) {
            id = index
          }
        })
        return id
      },

      setPrivacyAccounts () {
        let checkValid = true

        this.tempPrivacyAccount.paymentAccounts.forEach(item => {
          if ((!item.account || !item.description) && !item.removalMark) {
            checkValid = false
          }
        })
        if (checkValid) {
          let id = this.searchUserIndex()
          this.model.users[id] = JSON.parse(JSON.stringify(this.tempPrivacyAccount))
          this.$refs.privacyAccountsModal.hide()
        }
      },

      addPrivacyAccount () {
        this.tempPrivacyAccount.paymentAccounts.push({
          account: '',
          description: '',
          removalMark: false
        })
      },

      delPaymentAccount (paymentAccount) {
        paymentAccount.removalMark = true
      },

      getMeterHistory (data) {
        let id = 0
        if (data) {
          this.selectMeter = data
          id = data.id
        } else {
          id = this.selectMeter.id
        }
        let query = {
          meterId: id,
          timestamp: moment(this.date.materHistory).format('X'),
          limit: 'month'
        }
        api.get(this.urls.meterHistory, query).then((response) => {
          let jData = response.data
          this.meterHistory = jData.data
          this.meterHistory.forEach((data) => {
            data.history.forEach(item => {
              item.user = item.user ? item.user.lastName.concat(' ', item.user.firstName) : 'Передано автоматически'
              item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY HH:mm')
            })
          })
        })
      },

      customFormatter (date) {
        moment.locale('ru')
        return moment(date).format('MMMM')
      },

      viewHistory (data) {
        this.getMeterHistory(data)
        this.$refs.historyModal.show()
      },

      async getMeasurement () {
        api.get(this.urls.measurement, {premiseId: this.$route.params.id}).then((response) => {
          if (response.data.data) {
            this.setMeasure = response.data.data
          } else if (response.data.error) {
            this.setMeasure = response.data.error
          }
          return this.setMeasure
        }).then((setMeasure) => {
          this.tempSetMeasure = JSON.parse(JSON.stringify(this.setMeasure))
        }).then(() => {
          this.$refs.setMeasurementModal.show()
        })
      },

      setMeasurement () {
        if (this.tempSetMeasure.code === 471) {
          this.$refs.setMeasurementModal.hide()
        } else {
          let tempMeasure = {
            measurements: []
          }
          let checkValid = true
          this.tempSetMeasure.forEach(item => {
            item.measurements.forEach(measure => {
              if (!validation.isNumberBool(measure.value)) {
                checkValid = false
              } else {
                measure.value = String(measure.value).replace(/,/, '.')
              }
            })
            tempMeasure.measurements.push(...item.measurements)
          })
          if (checkValid) {
            this.setMeasure = this.tempSetMeasure
            api.post(`${this.urls.measurement}?premiseId="${this.$route.params.id}"`, tempMeasure).then((response) => {
              this.getMeter()
              this.$refs.setMeasurementModal.hide()
            })
          }
        }
      },

      detail (room) {
        let tempRoom = JSON.parse(JSON.stringify(room))
        this.tempItem = {
          roomTypeId: tempRoom.roomTypeId,
          roomNameId: tempRoom.roomNameId,
          roomType: tempRoom.roomType,
          roomName: tempRoom.roomName,
          currentTemp: tempRoom.currentTemp,
          presetTemp: tempRoom.presetTemp,
          lights: tempRoom.lights,
          heatings: tempRoom.heatings,
          waterSensors: tempRoom.waterSensors,
          localId: tempRoom.localId,
          id: tempRoom.id,
          removalMark: tempRoom.removalMark || false
        }
        this.viewNewRoomModal(true)
      },

      detailUser (userId) {
        this.$router.push('/users/edit/' + userId)
      },

      addControl (key) {
        if (key === 'lights') {
          this.tempItem.lights.push(
            {
              device: '',
              host: '',
              port: '',
              login: '',
              password: '',
              setValueTopic: '',
              currentValueTopic: '',
              removalMark: false
            }
          )
        } else if (key === 'heatings') {
          this.tempItem.heatings.push(
            {
              device: '',
              host: '',
              port: '',
              login: '',
              password: '',
              controlTopic: '',
              currentTempTopic: '',
              setPresetTempTopic: '',
              getPresetTempTopic: '',
              getStateTopic: '',
              stateValueInverted: false,
              removalMark: false
            }
          )
        } else {
          this.tempItem.waterSensors.push(
            {
              device: '',
              host: '',
              port: '',
              login: '',
              password: '',
              currentValueTopic: '',
              statusValueTopic: '',
              removalMark: false
            }
          )
        }
      },

      del (room) {
        let id = -1
        for (let i = 0; i < this.model.rooms.length; i++) {
          if (this.model.rooms[i].localId === room.localId) {
            this.model.rooms[i].removalMark = true
          }
        }
      },

      delDevice (key, id) {
        this.tempItem[key][id].removalMark = true
      },

      editRoom () {
        let checkValid = true
        let tempLights = this.tempItem.lights.filter(item => !item.removalMark)
        tempLights.forEach(item => {
          if (item.device === '' || item.host === '' || item.port === '' || item.setValueTopic === '' || item.currentValueTopic === '') {
            checkValid = false
          }
        })
        let tempHeatings = this.tempItem.heatings.filter(item => !item.removalMark)
        tempHeatings.forEach(item => {
          if (item.device === '' || item.host === '' || item.port === '' || item.setValueTopic === '' || item.currentValueTopic === '') {
            checkValid = false
          }
        })
        let tempWaterSensors = this.tempItem.waterSensors.filter(item => !item.removalMark)
        tempWaterSensors.forEach(item => {
          if (item.device === '' || item.host === '' || item.port === '') {
            checkValid = false
          }
        })
        if (this.state13 && this.state14 && checkValid) {
          let tempArray = [...this.model.rooms]
          this.model.rooms = []
          let id = null
          for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].localId === this.tempItem.localId) {
              id = i
            }
          }
          if (id !== null) {
            tempArray[id] = {
              roomTypeId: this.tempItem.roomTypeId,
              roomNameId: this.tempItem.roomNameId,
              roomType: this.tempItem.roomType,
              roomName: this.tempItem.roomName,
              currentTemp: this.tempItem.currentTemp,
              presetTemp: this.tempItem.presetTemp,
              lights: this.tempItem.lights,
              heatings: this.tempItem.heatings,
              waterSensors: this.tempItem.waterSensors,
              localId: this.tempItem.localId,
              id: this.tempItem.id,
              removalMark: this.tempItem.removalMark
            }
          } else {
            tempArray.push({
              roomTypeId: this.tempItem.roomTypeId,
              roomNameId: this.tempItem.roomNameId,
              roomType: this.tempItem.roomType,
              roomName: this.tempItem.roomName,
              currentTemp: this.tempItem.currentTemp,
              presetTemp: this.tempItem.presetTemp,
              lights: this.tempItem.lights,
              heatings: this.tempItem.heatings,
              waterSensors: this.tempItem.waterSensors,
              localId: this.localIncrement,
              removalMark: this.tempItem.removalMark
            })
          }
          this.model.rooms = [...tempArray]
          this.hideNewRoomModal()
          this.localIncrement++
        }
      },

      viewNewRoomModal (edit) {
        if (edit) {
          this.$refs.roomModal.show()
        } else {
          this.tempItem.roomTypeId = -1
          this.tempItem.roomNameId = 0
          this.tempItem.localId = -1
          this.tempItem.roomType = ''
          this.tempItem.roomName = ''
          this.tempItem.currentTemp = ''
          this.tempItem.presetTemp = ''
          this.tempItem.lights = []
          this.tempItem.heatings = []
          this.tempItem.waterSensors = []
          this.$refs.roomModal.show()
        }
      },

      hideNewRoomModal () {
        this.$refs.roomModal.hide()
      },

      whenTabInFocus () {
        let query = {
          id: this.location.buildingId
        }
        api.get(this.urls.buildingAddress, query).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.hComplexId = jData.data.housingComplexId
          }
          return api.get(this.urls.housingComplex)
        }).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.id === this.hComplexId) {
                this.hComplex = item.name
              }
            })
          }
        })
      },

      sendForm () {
        if (this.stateCity && this.state1 && this.stateHcId && this.stateBuildingId && this.state7 && this.state10 &&
          ((this.state11 && this.stateEntranceId) || this.selectPremiseTypeKey === 'pantry' || this.selectPremiseTypeKey === 'сommercial') &&
          this.state12 && this.state17) {
          this.whenTabInFocus()
          this.show = true
          this.model.rooms.forEach(room => {
            if (!room.heatings) {
              room.heatings = []
            }
            if (!room.lights) {
              room.lights = []
            }
            if (!room.waterSensors) {
              room.waterSensors = []
            }
          })
          if (String(this.model.totalArea).indexOf(',') !== -1) {
            this.model.totalArea = this.model.totalArea.replace(',', '.')
          }
          if (String(this.model.livingArea).indexOf(',') !== -1) {
            this.model.livingArea = this.model.livingArea.replace(',', '.')
          }
          if (this.model.livingArea === '') {
            delete this.model.livingArea
          }
          this.model.building.hcId = this.location.hcId
          this.model.building.housingComplex.cityId = this.location.cityId
          this.model.buildingId = this.location.buildingId
          this.model.entranceId = this.location.entranceId
          if (this.model.entranceId === 0) {
            delete this.model.entranceId
          }
          if (this.model.dispatchCode === null || this.model.dispatchCode === '') {
            delete this.model.dispatchCode
          }
          if (this.model.gisCode === null || this.model.gisCode === '') {
            delete this.model.gisCode
          }
          if (this.model.callingGroup === null || this.model.callingGroup === '') {
            delete this.model.callingGroup
          }
          if (this.model.account === null || this.model.account === '') {
            delete this.model.account
          }

          if (this.date.keysAcceptanceDate) {
            this.model.keysAcceptanceDate = this.date.keysAcceptanceDate
          }

          let promise = null

          const modifiedParams = Object.assign({}, this.model)
          modifiedParams.isRoughFinishing = !modifiedParams.isRoughFinishing

          if (this.premiseId) {
            promise = api.put(this.urls.premise + '/' + this.$route.params.id, modifiedParams)
          } else {
            promise = api.post(this.urls.premise, modifiedParams)
          }

          promise.then((response) => {
            this.show = false
            if (response.status) {
              this.$refs.myModalRef.show()
              setTimeout(() => {
                this.$refs.myModalRef.hide()
                this.$router.push({ path: '/premise/list', query: { tab: this.hComplex, currentPage: this.$route.query.currentPage } })
              }, 1000)
            }
          }).catch((error) => {
            this.show = false
            alert('Произошла ошибка при отправке.')
            console.error(error)
          })
        }
      },

      getTypes () {
        api.get(this.urls.premisetype).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              temp.key = spec.keyWord
              this.premisetype.push(temp)
            })
          }
        })
      },

      getHousingComplex () {
        let query = {
          cityId: this.location.cityId
        }
        api.get(this.urls.housingComplex, query).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.housingComplex = []
            this.location.hcId = null
            this.building = []
            this.location.buildingId = null
            this.entrance = []
            this.location.entranceId = null
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.housingComplex.push(temp)
            })
          }
        }).then(() => {
          if (this.location.hcId === null && this.model.building.hcId > 0) {
            this.location.hcId = this.model.building.hcId
            this.model.building.hcId = 0
          }
        })
      },

      getStreet () {
        api.get(this.urls.street).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.street.push(temp)
            })
          }
        })
      },

      getBuilding () {
        if (this.location.hcId > 0) {
          this.building = []
          this.location.buildingId = null
          this.entrance = []
          this.location.entranceId = null
          this.street.forEach((street) => {
            let query = {
              hcId: this.location.hcId,
              streetId: street.value
            }
            api.get(this.urls.building, query).then((response) => {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach((spec) => {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    this.building.push(temp)
                  }
                })
              }
            })
          })
          if (this.location.buildingId === null && this.model.buildingId > 0) {
            this.location.buildingId = this.model.buildingId
            this.model.buildingId = 0
          }
        }
      },

      getEntrance () {
        if (this.location.buildingId > 0) {
          let query = {
            buildingId: this.location.buildingId
          }
          api.get(this.urls.entrance, query).then((response) => {
            let jData = response.data
            if (jData.status) {
              this.entrance = []
              this.location.entranceId = null
              jData.data.forEach((spec) => {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.number
                this.entrance.push(temp)
              })
            }
          }).then(() => {
            if (this.location.entranceId === null && this.model.entranceId > 0) {
              this.location.entranceId = this.model.entranceId
              this.model.entranceId = 0
            }
          })
        }
      },

      getMeter () {
        let query = {
          premiseId: this.$route.params.id
        }
        api.get(this.urls.meter, query).then((response) => {
          let jData = response.data
          jData.data.forEach((item) => {
            item.autoLastTimestamp = item.autoLastTimestamp ? moment(item.autoLastTimestamp * 1000).format('DD.MM.YYYY HH:mm') : ''
            item.manualLastTimestamp = item.manualLastTimestamp ? moment(item.manualLastTimestamp * 1000).format('DD.MM.YYYY HH:mm') : ''
          })
          this.items = jData.data
        })
      },

      view (id) {
        this.$router.push('/meterRoom/edit/' + id)
      },

      goBack () {
        this.$router.go(-1)
      },

      getRoomTypes () {
        api.get(this.urls.roomtype).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.roomtypes.push(temp)
            })
          }
        })
      },

      getRoomNames () {
        api.get(this.urls.roomname, {roomTypeId: this.tempItem.roomTypeId}).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.roomnames.push(temp)
            })
          }
        })
      },

      fetchPayments () {
        api.post(this.urls.premise + `/${this.$route.params.id}/` + this.urls.payments, {}).then(response => {
          if (response.data.status === false) {
            alert('При отправке запроса на получение платежных документов возникла ошибка. ' +
              'Пожалуйста, повторите запрос позднее или обратитесь к главному администратору!')

            return
          }

          this.accounts = response.data.data
          if (this.accounts && this.accounts.length) {
            this.$refs.paymentsModal.show()
          }
        })
      }
    },

    mounted () {
      this.getTypes()
      this.getCities()
      this.getStreet()

      if (this.premiseId) {
        api.get(this.urls.premise + '/' + this.$route.params.id).then((response) => {
          let jData = response.data
          this.model = jData.data
          this.model.isRoughFinishing = !this.model.isRoughFinishing
          this.model.rooms.forEach(item => {
            item.lights.forEach(light => {
              light.removalMark = false
            })
            item.heatings.forEach(heat => {
              heat.removalMark = false
            })
            item.localId = this.localIncrement
            this.localIncrement++
          })
          this.location.cityId = this.model.building.housingComplex.cityId
          if (jData.data.keysAcceptanceDate) {
            this.date.keysAcceptanceDate = jData.data.keysAcceptanceDate
          }
        }).then(() => {
          this.housingComplexId = this.building.hcId
          this.streetId = this.building.streetId
          this.getMeter()
        })
      }

      this.getRoomTypes()
    },

    created () {
      let accsessKey = {
        keyWord: 'premise'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },

    watch: {
      'location.cityId' () {
        this.getHousingComplex()
      },

      'location.hcId' () {
        this.getBuilding()
      },

      'location.buildingId' () {
        this.getEntrance()
      },

      'tempItem.roomNameId' (value) {
        if (value !== 0 && value !== -1) {
          let tempObj = this.roomnames.find(item => item.value === value)
          if (tempObj) {
            this.tempItem.roomName = tempObj.text
          }
        }
      },

      'tempItem.roomTypeId' (value) {
        if (value !== 0 && value !== -1) {
          let tempObjType = this.roomtypes.find(item => item.value === value)
          if (tempObjType) {
            this.tempItem.roomType = tempObjType.text
          }
          let tempObjName = this.roomnames.find(item => parseInt(item.value) === parseInt(this.tempItem.roomNameId))
          if (tempObjName) {
            this.tempItem.roomNameId = 0
          }
          this.roomnames = []
        }
        this.getRoomNames()
      },

      'date.materHistory' (value) {
        this.getMeterHistory()
      }
    }
  }
</script>
