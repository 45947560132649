<template>
  <b-card :header="caption">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row v-if="accessList.isWrite === true">
      <b-col lg="3">
        <b-btn class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Добавить точку доступа</b-btn>
      </b-col>
      <b-col v-if="account === 'admin'" offset="6" lg="3" class="d-flex justify-content-end">
        <b-btn class="mb-3 btn-info model_btn--add_new" @click.stop="updatePreview">Обновить превью</b-btn>
      </b-col>
    </b-row>
    <b-col lg="12">
      <b-tabs card>
        <b-tab v-for=" (item, n) in hcList" :title="`${item.name}`" :active="isActive(item.name)" @click="changeTab(item.name)">
          <div class="spinner" v-if="!viewTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
          <div v-if="viewTable">
            <b-row>
              <b-col lg="2" class="justify-content-start">
                <label class="mt-1 d-inline-block">
                  Демо
                </label>
                </b-form-group>
                <label class="switch switch-3d switch-primary">
                  <input type="checkbox" v-model="isDemo" class="switch-input" checked>
                  <span class="switch-label"></span>
                  <span class="switch-handle"></span>
                </label>
              </b-col>
              <b-col lg="3">
                <b-form-group
                  :label-cols="5"
                  label="Тип точки доступа"
                  label-for="housingComplex"
                  :horizontal="true">
                  <b-form-select id="housingComplex"
                                :options="barrierTypeList"
                                placeholder="Выберите тип точки доступа"
                                v-model="barrierType">
                    <template slot="first">
                      <option :value="null" disabled>Выберите тип точки доступа</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col offset="4" lg="3">
                <div class="input-group mb-3">
                  <input v-model="search" type="text" class="form-control" placeholder="Введите строку поиска" aria-label="Recipient's username">
                  <div v-if="search.length > 0" class="input-group-append">
                    <button @click="clearSearch" class="btn btn-outline-secondary" type="button"><i class="fa fa-close danger"></i></button>
                  </div>
                  <div v-else class="input-group-append">
                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table v-if="viewTable" @sort-changed="sortChange" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
            <template slot="index" slot-scope="data">
              {{perPage * (currentPage - 1) + data.index + 1}}
            </template>
            <template slot="images" slot-scope="data">
              <b-img-lazy blank width="175" height="175" fluid blank-color="#777" v-if="data.item.image !== null" :src="data.item.image" alt="hex shorthand color" class="m-1 pre_img" />
            </template>
            <template slot="isDemo" slot-scope="data">
              <p v-if="data.item.isDemo === 1"><i class="fa fa-check fa-2x success"></i></p>
              <p v-else><i class="fa fa-close fa-2x danger"></i></p>
            </template>
            <template slot="buttons" slot-scope="data">
              <div class="table__four_buttons">
                <b-btn size="sm" class="change_size btn-info" @click.stop="updateSelectedPreview(data.item.id)"><i class="fa fa-refresh fa-2x"></i></b-btn>
                <b-btn size="sm" class="change_size btn-success" @click.stop="viewVideo(data.item.adminStream)"><i class="fa fa-play-circle-o fa-2x"></i></b-btn>
                <b-btn size="sm" class="change_size btn-info" v-if="openId.indexOf(data.item.id) === -1" @click.stop="barrierOpen(data.item.id)"><i class="fa fa-lock fa-2x"></i></b-btn>
                <b-btn size="sm" class="change_size btn-success" v-if="openId.indexOf(data.item.id) !== -1" @click.stop="barrierOpen(data.item.id)"><i class="fa fa-unlock fa-2x"></i></b-btn>
                <b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
                <b-btn size="sm" v-if="accessList.isDelete === true" class="change_size btn-danger" @click.stop="del(data.item.id)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
              </div>
            </template>
            <template slot="addresses" slot-scope="data">
              <p v-for="address in data.item.buildings">{{address.address}}</p>
            </template>
            <template slot="buildings" slot-scope="data">
              <p v-for="building in data.item.buildings">{{building.objectNumber}}</p>
            </template>
          </b-table>
          <nav class="d-flex">
            <b-pagination v-if="viewTable" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
            <label class="d-flex justify-content-center align-items-center mb-3 ml-3" v-if="viewTable">
              Страница:
              <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
                из {{lastPage}}
            </label>
          </nav>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-card>
</template>

<script>
  import api from '../../config/api'
  import consts from '../../config/const'
  import loading from 'vue-full-loading'
  import vueCookie from 'vue-cookie'

  export default {
    name: 'c-table',
    components: {
      loading
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        isDemo: false,
        searchTimer: null,
        search: '',
        hcList: [],
        accessList: {},
        tempItem: [],
        fields: [
          {key: 'id', label: 'ID', 'class': 'box__id', sortable: true},
          {key: 'images', label: 'Превью', 'class': 'box__image'},
          {key: 'name', label: 'Наименование', sortable: true},
          {key: 'addresses', label: 'Адрес', class: 'nowrap', sortable: true},
          {key: 'buildings', label: 'Дом'},
          {key: 'buttons', label: ' '}
        ],
        paginateTimer: null,
        textPage: '1',
        lastPage: 1,
        currentPage: 1,
        barrierType: 0,
        openId: [],
        barrierTypeList: [],
        sortBy: 'id',
        sortDesc: 1,
        perPage: 20,
        totalRows: 1,
        viewTable: false,
        show: false,
        label: 'Идёт обновление превью, это может занять длительное время, пожалуйста, подождите',
        currentTab: 1,
        account: vueCookie.get('isAdmin'),
        consts: consts,
        urls: {
          list: 'barrier',
          del: 'barrier',
          hc: 'housingcomplex',
          barrierType: 'barriertype',
          updatePreview: 'barrier/updatepreview',
          open: 'barrier/controls'
        }
      }
    },
    methods: {
      viewVideo (url) {
        window.open(url)
      },
      sortChange (ctx) {
        this.viewTable = false
        this.sortBy = ctx.sortBy
        this.sortDesc = this.sortDesc === 1 ? 0 : 1
        this.getData()
      },
      barrierOpen (id) {
        let query = {
          doorId: id
        }
        api.post(this.urls.open, query).then(response => {
          this.openId.push(id)
          setTimeout(() => {
            for (let i = 0; i < this.openId.length; i++) {
              if (String(this.openId[i]) === String(id)) {
                this.openId.splice(i, 1)
              }
            }
          }, 5000)
        })
      },
      updateSelectedPreview (id) {
        let query = {
          id: id
        }
        this.show = true
        api.get(this.urls.updatePreview, query).then((response) => {
          if (response.data.status === true) {
            this.getData()
          }
        })
      },
      updatePreview () {
        this.show = true
        api.get(this.urls.updatePreview).then((response) => {
          if (response.data.status === true) {
            this.getData()
          }
        })
      },
      getBarrier () {
        api.get(this.urls.barrierType).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.barrierTypeList.push(temp)
            })
            this.barrierTypeList.unshift({value: 0, text: 'Все точки доступа'})
          }
        })
      },
      clearSearch () {
        this.search = ''
      },
      setCurrents () {
        if (this.$route.query.tab) {
          this.currentTab = this.$route.query.tab
        }
        if (this.$route.query.currentPage) {
          this.currentPage = parseInt(this.$route.query.currentPage)
        }
      },
      async changeTab (name) {
        this.viewTable = false
        this.currentTab = (name === null ? 1 : name)
        if (!name) {
          await this.setCurrents()
        }
        this.getData()
      },
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      detail (id) {
        this.$router.push({path: '/barrier/edit/' + id, query: {currentPage: this.currentPage}})
      },
      del (id) {
        api.delete(this.urls.del + '/' + id).then((response) => {
          this.getData()
        })
      },
      newItem () {
        this.$router.push('new/')
      },
      getData () {
        this.textPage = String(this.currentPage)
        let scrollXOffset = window.pageXOffset
        let scrollYOffset = window.pageYOffset
        switch (this.isDemo) {
          case true:
            this.isDemo = 1
            break
          case false:
            this.isDemo = 0
            break
        }
        this.viewTable = false
        let hcId = null
        api.get(this.urls.hc).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.hcList = jData.data
          }
          this.currentTab = (this.currentTab === 1 ? this.hcList[0].name : this.currentTab)
          jData.data.forEach((item) => {
            if (item.name === this.currentTab) {
              hcId = item.id
            }
          })
          return hcId
        }).then((idHc) => {
          let query = {
            search: this.search,
            isDemo: this.isDemo,
            hcId: idHc,
            page: this.currentPage,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            limit: this.perPage
          }
          if (this.barrierType !== 0) {
            query.barrierTypeId = this.barrierType
          }
          return api.get(this.urls.list, query)
        }).then((response) => {
          let jMeta = response.data.meta
          this.totalRows = jMeta.pages * this.perPage - 1
          this.lastPage = jMeta.pages
          this.items = response.data.data
          this.items.forEach((item) => {
            item.open = false
          })
          this.viewTable = true
          setTimeout(() => {
            scrollTo(scrollXOffset, scrollYOffset)
            this.show = false
          }, 2000)
        })
      },
      isActive (hcName) {
        return hcName === this.$route.query.tab
      },
      replaceRouterQuery () {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            tab: this.currentTab,
            currentPage: this.currentPage
          }
        })
      }
    },
    watch: {
      search () {
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => { this.getData() }, 1000)
      },
      textPage (val) {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      },
      isDemo (newVal, lastVal) {
        if (lastVal === 0 || lastVal === 1) {
          this.getData()
        }
      },
      barrierType () {
        this.getData()
      },
      currentPage (val) {
        this.replaceRouterQuery()
      },
      currentTab () {
        this.replaceRouterQuery()
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'barrier'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },
    mounted () {
      this.getBarrier()
      this.changeTab(null)
    }
  }
</script>
