<template>
  <b-card :header="caption">
    <template #header>
      <strong>Участники чата</strong>
    </template>
    <b-table :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
      <template slot="index" slot-scope="data">
        {{perPage * (currentPage - 1) + data.index + 1}}
      </template>
      <template slot="buttons" slot-scope="data">
        <div class="table__one_buttons">
          <b-btn size="sm" v-if="data.item.isBlocked === true" class="change_size btn-danger" @click.stop="unblock(data.item.id)"><i class="fa fa-ban fa-2x"></i></b-btn>
          <b-btn size="sm" v-if="data.item.isBlocked === false" class="change_size btn-success" @click.stop="block(data.item.id)"><i class="fa fa-check fa-2x"></i></b-btn>
          <!--<b-btn size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>-->
        </div>
      </template>
    </b-table>
    <nav class="d-flex">
      <b-pagination v-if="viewTable" v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="getData()" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
      <label class="d-flex justify-content-center align-items-center mb-3 ml-3" v-if="viewTable">
        Страница: 
        <input type="number" class="form-control ml-2 mr-2 pagination_input" v-model="textPage">
          из {{lastPage}}
      </label>
    </nav>
  </b-card>
</template>

<script>
  import api from '../../config/api'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        fields: [
          {key: 'index', label: '#'},
          {key: 'lastName', label: 'Фамилия'},
          {key: 'firstName', label: 'Имя'},
          {key: 'phone', label: 'Телефон'},
          {key: 'buttons', label: ' '}
        ],
        paginateTimer: null,
        accessList: {},
        sortBy: 'id',
        sortDesc: 1,
        perPage: 20,
        textPage: '1',
        lastPage: 1,
        currentPage: 1,
        viewTable: true,
        urls: {
          list: 'chat/users',
          chat: 'chat',
          blacklist: 'blacklist'
        }
      }
    },
    methods: {
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      getRowCount (items) {
        return items.length
      },
      block (userId) {
        let _this = this
        let query = {
          userId: userId,
          chatId: _this.$route.params.id
        }
        api.post(_this.urls.blacklist, query).then(function (response) {
          let jData = response.data
          _this.items = jData.data
        }).then(() => {
          _this.getData()
        })
      },
      unblock (userId) {
        let _this = this
        let query = {
          userId: userId,
          chatId: _this.$route.params.id
        }
        api.post(_this.urls.blacklist + '/unblock', query).then(function (response) {
          let jData = response.data
          _this.items = jData.data
        }).then(() => {
          _this.getData()
        })
      },
      getData () {
        this.textPage = String(this.currentPage)
        let _this = this
        _this.items = []
        let query = {
          chatId: _this.$route.params.id,
          page: _this.currentPage,
          limit: _this.perPage
        }
        api.get(_this.urls.list, query).then(function (response) {
          let jData = response.data
          let jMeta = response.data.meta
          _this.totalRows = jMeta.pages * _this.perPage - 1
          _this.lastPage = jMeta.pages
          _this.items = response.data.data
          jData.data.forEach((item) => {
            item.phone = `+7 (${item.phone.substring(0, 3)}) ${item.phone.substring(3, 6)}-${item.phone.substring(6, 8)}-${item.phone.substring(8, 10)}`
          })
          _this.items = jData.data
        })
      }
    },
    watch: {
      textPage () {
        clearTimeout(this.paginateTimer)
        if (/^\d{1,}$/.test(this.textPage) && this.currentPage !== this.textPage && parseInt(this.textPage) > 0) {
          this.paginateTimer = setTimeout(() => {
            this.currentPage = parseInt(this.textPage)
          }, 1000)
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'chat'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getData()
    }
  }
</script>
