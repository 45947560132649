import vueCookie from 'vue-cookie'

export default {
  /**
   * Проверяет нужно ли заблокировать сервисные заявки
   * @returns {Boolean}
   */
  checkServiceRequestIsBlocked () {
    const role = vueCookie.get('role')
    if (!role) return false

    const blockedRoleListForServiceRequest = ['adminUK', 'admin_Inzhener']
    if (blockedRoleListForServiceRequest.includes(role)) return true
    return false
  }
}