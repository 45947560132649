// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import routes from './router'
import App from './App'
import Constants from './config/const'
import Vuex from 'vuex'
import axios from 'axios'
import vueCookie from 'vue-cookie'
import io from 'socket.io-client'
import VueSocketio from 'vue-socket.io'
import accessService from '/src/assets/scripts/services/access.js'

if (vueCookie.get('authToken') !== null) {
  if (!vueCookie.get('guid')) {
    localStorage.clear()
    vueCookie.delete('authToken')
    vueCookie.delete('role')
    vueCookie.delete('guid')
    vueCookie.delete('isAdmin')
    location.href = '/auth/login'
  }
  
  global.socketObj = io(Constants.storageUrl, {
    query: {auth_token: vueCookie.get('authToken').slice(4)}
  })
} else {
  global.socketObj = io(Constants.storageUrl, {
    query: {auth_token: ''}
  })
}

Vue.use(VueRouter)
Vue.use(VueSocketio, global.socketObj)
Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(vueCookie)

const router = new VueRouter({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})

window.constants = Constants

router.beforeEach((to, from, next) => {
  const token = vueCookie.get('authToken')
  const role = vueCookie.get('role')

  if (to.path === '/requests/list' && token === null) {
    next({path: '/auth/contractor'})
  } else if (to.path !== '/auth/login' && to.path !== '/auth/contractor' && to.matched[1].path !== '/auth/contractor/:id' && to.matched[1].path !== '/auth/login/:id' && token === null) {
    next({path: '/auth/login'})
  } else if (to.path === '/auth/login' && token !== null) {
    next({path: '/'})
  } else if (to.path === '/auth/contractor' && token !== null) {
    next({path: '/requests'})
  } else if (to.matched[1].path === '/auth/contractor/:id' && token !== null) {
    next({path: `/requests/edit/${to.params.id}`})
  } else if (to.matched[1].path === '/auth/login/:id' && token !== null) {
    next({path: `/service_requests/edit/${to.params.id}`})
  } else if ((role === 'contractor' || role === 'specialist') && to.matched[1].path !== '/requests/edit/:id' && to.matched[1].path !== '/requests/chat/:id' && to.path !== '/requests/list') {
    next({path: '/requests'})
  }

  if (to.path.match(/\/(service_requests\/((list)|(new)|(edit)))|(requests\/((list)|(new)|(edit)))/gi) && accessService.checkServiceRequestIsBlocked()) {
    next({path: '/dashboard'})
  }
  
  if (to.path.match(/\/(intercom|barrier)\/((list)|(new)|(edit))/gi)) {
    next({path: '/dashboard'})
  }

  const allowedRoles = [
    'admin',
    'contractor',
    'specialist',
    'admin_Inzhener',
    'adminUK',
    'admin_Administratorzastroyshchika',
    'admin_Specialistpoplatezham'
  ]
  if (allowedRoles.includes(role) || token === null) {
    next()
  }
})

router.afterEach((to, from) => {
  // do something
})

const store = new Vuex.Store({
  state: {
    user: {}
  },
  mutations: {
  }
})

Vue.mixin({
  methods: {
    apiGet (url, params) {
      var token = vueCookie.get('authToken')
      var esc = encodeURIComponent
      var query = Object.keys(params).map(k => esc(k) + '=' + esc(params[k])).join('&')
      var headers = {}
      if (token !== null) {
        headers.Authorization = token
      }
      return axios.get(window.apiBaseUrl + url + '?' + query, {
        headers: headers
      })
    }
  },
  apiPost (url, params) {
    var token = vueCookie.get('authToken')
    var headers = {}
    if (token !== null) {
      headers.Authorization = token
    }
    return axios.post(window.apiBaseUrl + url + '?', {
      headers: headers,
      params
    })
  },
  apiPut (url, params) {
    var token = vueCookie.get('authToken')
    var headers = {}
    if (token !== null) {
      headers.Authorization = token
    }
    return axios.put(window.apiBaseUrl + url + '?', {
      headers: headers
    }, params)
  }
})

const app = new Vue({
  router,
  vueCookie,
  store,
  render: h => h(App)
}).$mount('#app')