<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание подкатегории</strong>
          </template>
          <b-form-group
            label="Наименование подкатегории"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Категория"
            label-for="category"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.categoryId)"
            :state="state2">
            <b-form-select id="category"
                           :options="categories"
                           v-model="model.categoryId"
                           :state="state2">
              <template slot="first">
                <option :value="null" disabled>Выберите категорию</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Подкатегория успешно добавлена">
      <p class="my-4">Подкатегория успешно добавлена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../../config/api'
  import validation from '../../../config/validation'
  import loading from 'vue-full-loading'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.model.name)
      },
      state2 () {
        return validation.numId(this.model.categoryId)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true) {
          _this.show = true
          api.post(_this.urls.categories, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/service_requests/subcategories/list')
              }, 1000)
            }
          })
        }
      },
      getCategories () {
        let _this = this
        api.get(_this.urls.categories).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.categories.push(temp)
            })
          }
        })
      }
    },
    mounted: function () {
      this.getCategories()
    },
    watch: {
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'subcategory'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        model: {
          name: '',
          categoryId: 0
        },
        accessList: {},
        date: '',
        posts: [],
        categories: [
        ],
        urls: {
          categories: 'category'
        },
        validation: validation,
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется'
      }
    }
  }
</script>
