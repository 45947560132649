<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>{{ pageTitle }}</strong>
          </template>
          <b-form-group
            label="Демо режим"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isDemo" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Наименование"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="stateName">
            <b-form-input id="id" type="text" :state="stateName" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Город"
            label-for="cities"
            :label-cols="3"
            label-size="sm"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.cityId)"
            :state="stateCity">
            <b-form-select id="cities"
                           :options="cities"
                           v-model="model.cityId"
                           :state="stateCity">
              <template slot="first">
                <option :value="0" disabled>Выберите город</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Широта"
            label-for="latitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.latitude)"
            :state="state2">
            <b-form-input id="latitude" type="text" :state="state2" v-model="model.latitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Долгота"
            label-for="longitude"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isNumber(model.longitude)"
            :state="state3">
            <b-form-input id="longitude" type="text" :state="state3" v-model="model.longitude"></b-form-input>
          </b-form-group>
          <b-form-group
            label=""
            label-for="longitude"
            :label-cols="3"
            :horizontal="true">
            <yandex-map
              :coords="coords"
              zoom="18"
              style="width: 600px; height: 600px;"
              :cluster-options="{1: {clusterDisableClickZoom: true}}"
              :behaviors="['drag', 'scrollZoom', 'multiTouch']"
              v-model="yamap"
              :controls="['zoomControl', 'searchControl', 'fullscreenControl', 'typeSelector']"
              map-type="map"
              :placemarks="placemarks"
              @map-was-initialized="initHandler"
            >
            </yandex-map>
          </b-form-group>

          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="ЖК успешно сохранен">
      <p class="my-4">ЖК успешно сохранен</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import { yandexMap, ymapMarker } from 'vue-yandex-maps'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'Item',
    components: {
      yandexMap,
      ymapMarker,
      loading
    },
    computed: {
      stateCity () {
        return validation.numId(this.model.cityId)
      },
      stateName () {
        return validation.long(this.model.name)
      },
      state2 () {
        return validation.isNumberBool(this.model.latitude)
      },
      state3 () {
        return validation.isNumberBool(this.model.longitude)
      }
    },
    methods: {
      initHandler (obj) {
        let _this = this
        obj.events.add('click', function (e) {
          let mark = {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение камеры на карте"</div>'
          }
          _this.placemarks = []
          mark.coords = e.get('coords')
          _this.model.latitude = mark.coords[0]
          _this.model.longitude = mark.coords[1]
          _this.coords = mark.coords
          _this.placemarks.push(mark)
        })
      },
      sendForm () {
        let _this = this
        if (_this.stateName === true && _this.state2 === true && _this.state3 === true) {
          _this.show = true

          let promise = null

          if (this.hcId) {
            promise = api.put(_this.urls.housingcomplex + '/' + _this.$route.params.id, _this.model)
          } else {
            promise = api.post(_this.urls.housingcomplex, _this.model)
          }

          promise.then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/housingcomplex/list')
              }, 1000)
            }
          })
        }
      },
      goBack () {
        this.$router.go(-1)
      },
      getCities () {
        api.get(this.urls.city).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name + ' [' + spec.utc + ']'
                this.cities.push(temp)
              }
            })
          }
        })
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'housingComplex'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getCities()
      if (this.hcId) {
        api.get(_this.urls.housingcomplex + '/' + _this.$route.params.id).then(function (response) {
          let jData = response.data
          _this.model = jData.data
          if (_this.model.latitude !== null && _this.model.longitude !== null) {
            _this.coords = [_this.model.latitude, _this.model.longitude]
            let mark = {
              coords: [_this.model.latitude, _this.model.longitude],
              properties: {}, // define properties hereasd
              clusterName: '1',
              balloonTemplate: '<div>"Положение камеры на карте"</div>'
            }
            _this.placemarks = []
            mark.coords = [_this.model.latitude, _this.model.longitude]
            _this.placemarks.push(mark)
          }
        })
      }
    },
    watch: {
      'model.latitude': function (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let _this = this
          let mark = _this.placemarks[0]
          _this.placemarks = []
          mark.coords = [n, _this.model.longitude]
          _this.coords = [n, _this.model.longitude]
          _this.placemarks.push(mark)
        }
      },
      'model.longitude': function (n, o) {
        if (this.model.longitude !== null && this.model.latitude !== null && parseFloat(n) !== 0) {
          let _this = this
          let mark = _this.placemarks[0]
          _this.placemarks = []
          mark.coords = [_this.model.latitude, n]
          _this.coords = [_this.model.latitude, n]
          _this.placemarks.push(mark)
        }
      }
    },
    data () {
      return {
        pageTitle: '',
        hcId: this.$route.params.id,
        cities: [],
        model: {
          name: '',
          cityId: '',
          latitude: '',
          longitude: ''
        },
        accessList: {},
        buildings: [
        ],
        coords: [58.603542963107785, 49.66805437579303],
        date: '',
        placemarks: [
          {
            coords: [58.603542963107785, 49.66805437579303],
            properties: {}, // define properties hereasd
            clusterName: '1',
            balloonTemplate: '<div>"Положение камеры на карте"</div>'
          }
        ],
        yamap: '',
        posts: [],
        urls: {
          housingcomplex: 'housingcomplex',
          city: 'city'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
