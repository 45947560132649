<template>
  <div class="animated fadeIn">
    <div class="chat__button" :class="{ 'chat__button_active': !chatIsOpen && chatIsLoaded }" @click="controlChat(true)">
      <i class="fa fa-comments fa-3x"></i>
      <div class="badge-new">
        <b-badge pill variant="warning">{{ model.count }}</b-badge>
      </div>
    </div>
    <div class="modal-chat" :class="{ 'modal-chat_active': chatIsOpen }">
      <div class="header">
        <div class="chat__close" @click="controlChat(false)">
          <div class="cross">
            <div class="cross_horizontal"></div>
            <div class="cross_vertical"></div>
          </div>
        </div>
        <div class="header__title"> Чат с клиентом </div>
      </div>
      <div class="wrapper" id="chatBody">
        <div v-for="(message, i) in messages" :key="i">
          <div class="message__time" :class="{'text-right': message.user !== null && isUser(message.user.id) }">
            {{ message.timestamp }}
            <i
              v-if="accessList.isUpdate && (message.contentText !== '' || message.attachment !== null)"
              @click="showModal(message.id)"
              class="delete_message__btn fa fa-trash-o">
            </i>
          </div>
<!--          <div class="d-flex"-->
<!--             :class="{'justify-content-end': message.user !== null && isUser(message.user.id), 'justify-content-start': message.user !== null && !isUser(message.user.id)}">-->
<!--            <i-->
<!--              v-if="message.contentText !== '' || message.attachment !== null"-->
<!--              @click="showModal(message.id)"-->
<!--              class="delete_message__btn fa fa-trash-o"-->
<!--              :class="{'pr-2 pl-0': message.user !== null && isUser(message.user.id), 'order-3 pr-0 pl-2': message.user === null || !isUser(message.user.id)}">-->
<!--            </i>-->
            <div
              v-if="message.user !== null && message.contentText !== '' && message.contentText !== null"
              class="message"
              :class="{'my-message': isUser(message.user.id), 'them-message': !isUser(message.user.id)}">
              <div class="user_name">
                {{ message.user.firstName }} {{ message.user.lastName }}
              </div>
              {{ message.contentText }}
            </div>
            <div
              v-if="message.user === null && message.contentText !== '' && message.contentText !== null"
              class="message them-message">
              {{ message.contentText }}
            </div>
            <div
              v-if="message.attachment !== null"
              class="message"
              :class="{'my-message': isUser(message.user.id), 'them-message': !isUser(message.user.id)}">
              <viewer :images="imagesMsg" :options="{toolbar: false, navbar: false, zoom: 4}" class="request_picture">
                <img :src="consts.storageUrl + '/' + message.attachment.url"  alt="hex shorthand color" class="chat__picture">
              </viewer>
            </div>
          </div>
<!--        </div>-->
      </div>
      <div v-if="accessList.isUpdate" class="message-input">
        <b-form inline @keyup.ctrl.enter.exact="addBreak()" @keyup.enter.exact.capture="sendMessage()">
          <b-textarea
            class="textarea mb-0"
            :disabled="msgInputDisabled"
            id="chatInput"
            placeholder="Текст сообщения..."
            :rows="1"
            :max-rows="1"
            :no-resize="true"
            v-model="msg"
            onkeydown="if(event.keyCode == 13){ return false; }"
          />
          <label for="upload-photo" class="btn-primary fileInput"><i class="icon-paper-clip"></i></label>
          <b-form-file accept="image/jpg,image/jpeg,image/png" id="upload-photo" :plain="true" v-model="fileMsg"></b-form-file>
          <b-button class="sendMessageButton" variant="success" @click.stop="sendMessage()" :disabled="msgButtonDisabled">
            Отправить
          </b-button>
          <label v-if="msgInputDisabled" @click.stop="cancelPicture()" class="btn-primary fileInput"><i class="icon-close"></i></label>
          <img src="/img/loading-green.gif" class="image-loader" v-if="chatViewLoader" alt="">
        </b-form>
      </div>
    </div>
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-card>
      <template #header>
        <strong>Изменение заявки</strong>
        <div class="float-right">
          <b-link :to="{ path: '/service_requests/log/' + $route.params.id }">История изменений заявки</b-link>
        </div>
      </template>
      <div class="container ml-0 mb-3 mt-3">
        <b-row>
          <b-col md="12">
            <b-alert :show="model.desiredFlag && accessRequest" variant="danger">
              <h4 class="alert-heading">Запрос на изменение срока устранения!</h4>
              <p>
                Подрядчик отправил запрос на изменение срока устранения причины заявки. Запрошенная дата: {{ date.desiredFlag }}
              </p>
              <hr>
              <p class="mb-0">
                <b-btn size="sm" @click="acceptDesired(true)" text="Button" variant="success">Принять</b-btn>
                <b-btn size="sm" @click="acceptDesired(false)" text="Button" variant="danger">Отказать</b-btn>
              </p>
            </b-alert>
            <b-form-group
              label="Номер заявки"
              label-for="id"
              :label-cols="2"
              label-class="text-left pb-0"
              class="mb-1"
              :horizontal="true">
              <b-form-input id="id" class="pb-0" plaintext type="text" v-model="model.id"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Дата создания"
              label-for="id"
              :label-cols="2"
              label-class="text-left pb-0"
              class="mb-1"
              :horizontal="true">
              <b-form-input id="id" class="pb-0" plaintext type="text" v-model="date.createdDate"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Автор"
              label-for="id"
              :label-cols="2"
              label-class="text-left pb-0"
              class="mb-1"
              :horizontal="true">
              <b-form-input id="id" class="pb-0" plaintext type="text" v-model="model.author"></b-form-input>
            </b-form-group>
            <fieldset>
              <legend>Информация о клиенте</legend>
              <b-form-group
                label="Адрес"
                label-for="housingComplex"
                :label-cols="1"
                label-class="col-sm-2 col-lg-2 col-xl-1 text-left mt-xl-4"
                class="text-left mb-0"
                :horizontal="true">
                <b-form-group
                  label="Жилой комплекс:"
                  label-for="housingComplex"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0"
                  :state="state1">
                  <b-form-select id="housingComplex"
                    :options="housingComplex"
                    placeholder="Выберите ЖК"
                    v-model="housingComplexId"
                    :state="state1"
                    :disabled="!hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите ЖК</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Дом:"
                  label-for="building"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0"
                  :state="state3">
                  <b-form-select id="building"
                    :options="building"
                    placeholder="Выберите дом"
                    v-model="buildingId"
                    :state="state3"
                    :disabled="!hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите дом</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Квартира:"
                  label-for="premise"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left"
                  :state="state4">
                  <b-form-select id="premise"
                    :options="premise"
                    placeholder="Выберите квартиру"
                    v-model="model.premiseId"
                    :state="state4"
                    :disabled="!hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите квартиру</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-form-group>
              <b-form-group
                label="Клиент"
                label-for="client"
                :label-cols="1"
                label-class="col-sm-2 col-lg-2 col-xl-1 text-left mt-xl-4"
                class="text-left"
                :horizontal="true">
                <b-form-group
                  label="ФИО:"
                  label-for="client"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0">
                  <label ref="containerSelect" class="mb-0">
                    <div class="container__select">
                      <input ref="selectInput" type="text" :readonly="!hasAdminAccess()" v-model="model.userName" class="custom_input_text">
                      <div v-if="hasAdminAccess()" class="select__select_items" :class="{'select__select_items--block': focusSelect}">
                        <div id="selectItem" class="select_item" @click="changeSelect(item)" v-for="item in filtredAcommodations" :key="item.id">
                          {{item.name}}
                        </div>
                      </div>
                    </div>
                  </label>
                </b-form-group>
                <b-form-group
                  label="Телефон:"
                  label-for="client"
                  :label-cols="3"
                  label-size="sm"
                  :horizontal="false"
                  class="inline-select text-left mb-0">
                  <b-form-input id="id" class="pb-0 w_180" v-if="!hasAdminAccess()" plaintext type="text" v-model="phone"></b-form-input>
                  <masked-input
                    v-else
                    type="tel"
                    name="phone"
                    class="form-control"
                    v-model="phone"
                    :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
                    :guide="true"
                    :showMask="true"
                    :keepCharPositions="true"
                  ></masked-input>
                </b-form-group>
              </b-form-group>
            </fieldset>
            <fieldset>
              <legend>Информация о заявке</legend>
              <div class="row">
                <b-form-group
                  label="Заявление по гарантии"
                  label-for="userId"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-input id="id" type="text" :plaintext="!hasAdminAccess()" class="w_160" v-model="model.warrantyStatement"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Тип заявки"
                  label-for="requestType"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-xl-5 col-lg-5 col-md-5 сol-12"
                  :horizontal="true"
                  :state="state6">
                  <b-form-select id="requestType"
                    :options="requestTypes"
                    v-model="model.requestTypeId"
                    :state="state6"
                    :disabled="!hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите тип</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Характер заявки"
                  label-for="requestType"
                  :label-cols="3"
                  label-class="text-left"
                  class="offset-md-1 col-xl-6 col-lg-6 col-md-6 сol-12"
                  :horizontal="true"
                  :state="state7">
                  <b-form-select id="requestType"
                    :options="requestNatures"
                    v-model="model.requestNatId"
                    :state="state7"
                    :disabled="!hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите характер</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Категория"
                  label-for="category"
                  :label-cols="3"
                  label-class="text-left"
                  class="col-xl-5 col-lg-5 col-md-5 сol-12"
                  :horizontal="true"
                  :state="state8">
                  <b-form-select id="category"
                    :options="categories"
                    v-model="model.categoryId"
                    :state="state8"
                    :disabled="!hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите категорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Подкатегория"
                  label-for="subcategory"
                  :label-cols="3"
                  label-class="text-left"
                  class="offset-md-1 col-xl-6 col-lg-6 col-md-6 сol-12"
                  :horizontal="true"
                  :state="state9">
                  <b-form-select id="subcategory"
                    :options="subcategories"
                    placeholder="Выберите подкатегорию"
                    v-model="model.subcategoryId"
                    :state="state9"
                    :disabled="!hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите подкатегорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Описание заявки"
                  label-for="description"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-textarea
                    id="inlineFormInputName2"
                    placeholder="Текст описания..."
                    :rows="3"
                    :max-rows="6"
                    :no-resize="true"
                    v-model="model.description"
                    :plaintext="!hasAdminAccess()"/>
                </b-form-group>
                <b-form-group
                  label="Изображения"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <label v-if="hasAdminAccess()" class="load-file__input">
                    <input id="file" type="file" accept="image/*" @change="loadIcon">
                    <b-btn size="sm" text="Button" variant="success">Добавить изображение</b-btn>
                  </label>
                  <img src="/img/loading-green.gif" class="image-loader" v-if="viewLoader" style="left: 165px;">
                  <div class="d-block">
                    <viewer :images="images" v-for="(i, index) in images" :key="index" :options="{toolbar: false, navbar: false}" class="request_picture">
                      <img :src="consts.storageUrl + '/' + images[index].url"  alt="hex shorthand color" class="chat__picture">
                      <div v-if="hasAdminAccess()" class="del" @click="deletePicture(index)"><icon name="window-close"></icon></div>
                    </viewer>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Желаемое время прихода специалиста"
                  label-for="arrivalSpecialist"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-10 col-lg-10 col-md-10 сol-12"
                  :horizontal="true">
                  <label for="arrivalSpecialist" class="pr-2 pr-lg-0"> с</label>
                  <b-form-input id="id" class="pb-0 w_180" plaintext v-if="!isEngineer && !hasAdminAccess()" type="text" v-model="date.arrivalSpecialist"></b-form-input>
                  <datetime type="datetime"
                            v-else
                            id="arrivalSpecialist"
                            v-model="date.arrivalSpecialist"
                            class="theme-orange"
                            input-class="dataTimePick"
                            placeholder="Дата и время"
                            moment-locale="ru"
                            :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <br class="d-block d-lg-none">
                  <label for="endTime"> по</label>
                  <b-form-input id="id" class="pb-0 w_180" plaintext v-if="!isEngineer && !hasAdminAccess()" type="text" v-model="date.endTime"></b-form-input>
                  <datetime type="datetime"
                    v-else
                    id="endTime"
                    v-model="date.endTime"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.arrivalSpecialist = ''; date.endTime = '';" text="Button" variant="light" class="mt-2" title="очистить">очистить</b-btn>
                </b-form-group>
                <b-form-group
                  label="Назначенное время прихода специалиста"
                  label-for="serviceDate"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true">
                  <b-form-input id="id" class="pb-0 w_180" plaintext v-if="!isEngineer && !hasAdminAccess()" type="text" v-model="date.serviceDate"></b-form-input>
                  <datetime type="datetime"
                    v-else
                    id="serviceDate"
                    v-model="date.serviceDate"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.serviceDate = ''" text="Button" variant="light" class="mt-2" title="Очистить">очистить</b-btn>
                </b-form-group>
                <b-form-group
                  label="Отложить заявку"
                  label-for="postpone"
                  label-cols="2"
                  label-class="text-left form-group-switch-label"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true"
                  v-if="allowPostponeService">
                  <label class="switch switch-3d switch-primary form-group-switch-vcentered">
                    <input type="checkbox" v-model="isPostponed" class="switch-input" checked>
                    <span class="switch-label" data-on="Да" data-off="Нет"></span>
                    <span class="switch-handle"></span>
                  </label>
                </b-form-group>
                <b-form-group
                  label="Отложить заявку до указанной даты"
                  label-for="postponedDate"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true"
                  :state="state16"
                  v-show="isPostponed"
                  v-if="allowPostponeService">
                  <datetime type="datetime"
                    id="postponedDate"
                    v-model="date.postponedDate"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}"
                    v-bind:class="{invalid__dateTime: !state16, valid__dateTime: state16 === true}"
                    :state="state16">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.postponedDate = ''" text="Button" variant="light" class="mt-2" title="Очистить">очистить</b-btn>
                </b-form-group>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий для отложенной заявки"
                      label-for="postponedComment"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true"
                      v-show="isPostponed"
                      v-if="allowPostponeService">
                      <b-textarea
                        id="postponedComment"
                        placeholder="Текст комментария..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.postponedComment"
                        :state="statePostponedCommentRequired"/>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  label="Заявка отложена до указанной даты"
                  label-for="postponedDate"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true"
                  v-if="isPostponed && !isEngineer && !hasAdminAccess()">
                  <b-form-input id="postponedDate" class="pb-0 w_180" plaintext
                                type="text" v-model="date.postponedDate"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Комментарий"
                  label-for="postponedComment"
                  :label-cols="4"
                  label-class="text-left"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true"
                  v-if="isPostponed && !isEngineer && !hasAdminAccess()">
                  <b-textarea id="postponedComment" plaintext
                              :rows="3" :max-rows="6"
                              :no-resize="true"
                              v-model="model.postponedComment"/>
                </b-form-group>
                <b-form-group
                  label="Приемка-передача"
                  label-for="acceptanceTransfer"
                  label-cols="2"
                  label-class="text-left form-group-switch-label"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true">
                  <label class="switch switch-3d switch-primary form-group-switch-vcentered">
                    <input type="checkbox" v-model="model.acceptanceTransfer" class="switch-input" checked>
                    <span class="switch-label" data-on="Да" data-off="Нет"></span>
                    <span class="switch-handle"></span>
                  </label>
                </b-form-group>
                <b-form-group
                  label="Ошибка в проекте"
                  label-for="projectError"
                  label-cols="2"
                  label-class="text-left form-group-switch-label"
                  class="col-xl-11 col-lg-11 col-md-11 сol-12"
                  :horizontal="true">
                  <label class="switch switch-3d switch-primary form-group-switch-vcentered">
                    <input type="checkbox" v-model="model.projectError" class="switch-input" checked>
                    <span class="switch-label" data-on="Да" data-off="Нет"></span>
                    <span class="switch-handle"></span>
                  </label>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset  v-if="myRole !== 'adminUK'">
              <legend>Администратор УК</legend>
              <div class="row">
                <div class="col-xl-5 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="ФИО"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-select id="userId"
                        :options="admins"
                        v-model="model.adminId"
                        :disabled="!hasAdminAccess()">
                        <template slot="first">
                          <option :value="null" disabled>Выберите администратора</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Телефон"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-input id="id" plaintext type="text" v-model="adminPhone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий"
                      label-for="description"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-textarea
                        :plaintext="!hasAdminAccess()"
                        id="inlineFormInputName2"
                        placeholder="Текст описания..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.adminComment"/>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Постановщик</legend>
              <div class="row">
                <div class="col-xl-5 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="ФИО"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-select id="userId"
                        :options="employees"
                        v-model="model.engineerId"
                        :disabled="!hasAdminAccess()">
                        <template slot="first">
                          <option :value="null" disabled>Выберите постановщика</option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Телефон"
                      label-for="userId"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-form-input id="id" plaintext type="text" v-model="engineerPhone"></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-xl-7 col-lg-12 col-12">
                  <div class="row">
                    <b-form-group
                      label="Комментарий"
                      label-for="description"
                      :label-cols="3"
                      label-class="text-left"
                      class="col-xl-12 col-lg-12 col-12"
                      :horizontal="true">
                      <b-textarea
                        :plaintext="!isEngineer && !hasAdminAccess()"
                        id="inlineFormInputName2"
                        placeholder="Текст описания..."
                        :rows="3"
                        :max-rows="6"
                        :no-resize="true"
                        v-model="model.engineerComment"/>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset>
              <legend>Исполнитель</legend>
              <div class="row">
                <b-form-group
                  label="Категория исполнителя"
                  label-for="status"
                  :label-cols="5"
                  label-class="text-left"
                  class="col-xl-5 col-lg-8 col-md-10 col-12"
                  :class="{' mb-0' : isContractorGroup }"
                  :horizontal="true"
                  :state="validation.customNumId(model.statusId, model.groupId)">
                  <b-form-select id="status"
                    :options="groups"
                    v-model="model.groupId"
                    :state="validation.customNumId(model.statusId, model.groupId)"
                    :disabled="!isEngineer && !hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите категорию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="isContractorGroup"
                  label="Компания"
                  label-for="status"
                  :label-cols="5"
                  label-class="pr-xl-4 text-left"
                  class="col-xl-5 col-lg-8 col-md-9 col-12"
                  :horizontal="true"
                  :state="state11">
                  <b-form-select id="status"
                    :options="company"
                    v-model="companyName"
                    :state="state11"
                    :disabled="!isEngineer && !hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите компанию</option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  label="Исполнитель"
                  label-for="status"
                  :label-cols="5"
                  label-class="pr-xl-4 text-left"
                  class="col-xl-5 col-lg-8 col-md-9 col-12"
                  :class="{' mb-0' : isContractorGroup }"
                  :horizontal="true"
                  :state="state11">
                  <b-form-select id="status"
                    :options="specialists"
                    v-model="model.specialistId"
                    :state="state11"
                    :disabled="!isEngineer && !hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите исполнителя</option>
                    </template>
                  </b-form-select>
                  <label v-if="!isContractorGroup" class="mb-5 mb-md-0 w_150 wspw">{{specialistGroup}}</label>
                </b-form-group>
                <b-form-group
                  v-if="isContractorGroup"
                  label="Исходящий номер предписания"
                  label-for="userId"
                  :label-cols="7"
                  label-class="text-left"
                  class="col-xl-5 col-lg-8 col-md-9 col-12"
                  :horizontal="true">
                  <b-form-input id="id" type="text" :plaintext="!isEngineer && !hasAdminAccess()" class="w_160" v-model="model.ordinanceNumber"></b-form-input>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                label="Коментарий"
                label-for="description"
                :label-cols="2"
                label-class="text-left"
                class="col-12"
                :horizontal="true">
                <b-textarea
                  :plaintext="!isContractor && !isEngineer && !hasAdminAccess()"
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="3"
                  :max-rows="6"
                  :no-resize="true"
                  v-model="model.contractorComment"/>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Внутенний статус"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-select id="status"
                    :options="innerStatuses"
                    v-model="model.innerStatusId"
                    class="w_160"
                    :disabled="!isContractor && !isEngineer && !hasAdminAccess()">
                    <template slot="first">
                      <option :value="null" disabled>Выберите статус</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Срок устранения"
                  label-for="termToWork"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true">
                  <b-form-input id="id" class="pb-0 w_180" plaintext v-if="!isEngineer && !hasAdminAccess()" type="text" v-model="date.termToWork"></b-form-input>
                  <datetime type="datetime"
                    v-else
                    id="termToWork"
                    v-model="date.termToWork"
                    class="theme-orange"
                    input-class="dataTimePick"
                    placeholder="Дата и время"
                    moment-locale="ru"
                    :phrases="{ok: 'OK', cancel: 'Отменить'}">
                  </datetime>
                  <b-btn size="sm" @click.prevent="date.termToWork = ''" text="Button" variant="light" class="mt-2" title="Очистить">очистить</b-btn>
                  <!-- <div @click="showDesiredTimeForm()" class="desiredterm">
                    <i  class="fa fa-warning fa-2x"></i>
                  </div> -->
                </b-form-group>
                <b-form-group
                  label="Файлы"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-xl-12 col-lg-12 сol-12"
                  :horizontal="true">
                  <label v-if="isContractor || isEngineer || hasAdminAccess()" class="load-file__input">
                    <input id="fileCont" type="file" accept="image/*, .xlsx, .xls, .doc, .docx, .pdf" @change="loadFile">
                    <b-btn size="sm" text="Button" variant="success">Добавить файл</b-btn>
                  </label>
                  <div class="d-block">
                    <div v-for="(i, index) in model.documentIds" :key="index" class="request_picture">
                      <a :href="consts.storageUrl + '/' + documents[index].url" target="_blank">
                        <div v-if="documents[index].ext === 'doc' || documents[index].ext === 'docx'" class="file">
                          <img src="/img/doc.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <div v-else-if="documents[index].ext === 'xls' || documents[index].ext === 'xlsx'" class="file">
                          <img src="/img/xlsx.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <div v-else-if="documents[index].ext === 'pdf'" class="file">
                          <img src="/img/pdf.png"  alt="hex shorthand color" class="chat__picture">
                          <p class="file-name">{{ documents[index].name }}</p>
                        </div>
                        <img v-else :src="consts.storageUrl + '/' + documents[index].url"  alt="hex shorthand color" class="chat__picture">
                      </a>
                      <div v-if="isContractor || isEngineer || hasAdminAccess()" class="del" @click="deleteFile(index)"><icon name="window-close"></icon></div>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </fieldset>
            <fieldset>
              <legend>Информация о закрытии</legend>
              <div class="row">
                <b-form-group
                label="Коментарий при возврате"
                label-for="description"
                :label-cols="2"
                label-class="text-left"
                class="col-12"
                :horizontal="true">
                <b-textarea
                  :plaintext="!isEngineer && !hasAdminAccess()"
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="3"
                  :max-rows="6"
                  :no-resize="true"
                  v-model="model.feedback"/>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                label="Коментарий при выполнении"
                label-for="description"
                :label-cols="2"
                label-class="text-left"
                class="col-12"
                :horizontal="true">
                <b-textarea
                  :plaintext="!isEngineer && !hasAdminAccess()"
                  id="inlineFormInputName2"
                  placeholder="Текст описания..."
                  :rows="3"
                  :max-rows="6"
                  :no-resize="true"
                  v-model="model.comment"/>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Статус заявки"
                  label-for="status"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12 mb-0"
                  :horizontal="true"
                  :state="state10">
                  <b-form-select id="status"
                    :options="statuses"
                    v-model="model.statusId"
                    :state="state10"
                    class="w_160"
                    :disabled="!isEngineer && !hasAdminAccess()"
                  >
                    <template slot="first">
                      <option :value="null" disabled>Выберите статус</option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
              <div
                class="row"
                v-if="isContractorGroup && pickedInnerStatus === 'done'">
                <b-form-group
                  label="Причина неисправности"
                  label-for="requestClosed"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12 mt-3 mb-1"
                  :horizontal="true">
                    <multiselect :disabled="!isEngineer && !hasAdminAccess()" v-model="model.requestProblems" :options="problemTypes" tag-placeholder="Add this as new tag" placeholder="Укажите причину" label="name" track-by="code" :multiple="true" :taggable="true"></multiselect>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Дата и время закрытия заявки"
                  label-for="requestClosed"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true"
                  :state="state17">
                  <div class="d-flex align-items-center h-100">
                    <b-form-input id="id" class="pb-0 w_180" plaintext v-if="!isEngineer && !hasAdminAccess()" type="text" v-model="date.requestClosed"></b-form-input>
                    <datetime type="datetime"
                      v-else
                      id="requestClosed"
                      v-model="date.requestClosed"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Дата и время"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state17, valid__dateTime: state17 === true}"
                      :state="state17">
                    </datetime>
                    <b-btn size="sm" @click.prevent="date.requestClosed = ''" text="Button" variant="light" class="mt-2" title="Очистить">очистить</b-btn>
                  </div>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  label="Рейтинг"
                  label-for="rating"
                  :label-cols="2"
                  label-class="text-left"
                  class="col-12"
                  :horizontal="true"
                  :state="state17">
                  <star-rating id="rating"
                    v-model="model.rating"
                    :increment="1"
                    :max-rating="5"
                    :read-only="true"
                    :border-width="3"
                    :star-size="20"
                    text-class="starText">
                  </star-rating>
                </b-form-group>
              </div>
            </fieldset>
            <b-btn size="sm" v-if="accessList.isUpdate === true && accessRequest" @click.prevent="sendForm"
                   text="Button" variant="success" class="mt-2">Сохранить</b-btn>
            <b-btn size="sm" @click.prevent="goBack" text="Button" variant="success" class="mt-2">Назад</b-btn>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="myModalRef" hide-footer title="Заявка успешно изменена">
      <p class="my-4">Заявка успешно изменена</p>
    </b-modal>
    <b-modal ref="deleteModal" hide-footer title="Удаление сообщения">
      <div class="d-block text-center">
        <h3>Вы действительно хотите удалить сообщение?</h3>
      </div>
      <b-btn class="mt-3" variant="outline-danger" block @click="deleteMessage()">Да</b-btn>
      <b-btn class="mt-3" variant="outline-danger" block @click="hideModal">Нет</b-btn>
    </b-modal>
    <b-modal ref="desiredTimeForm" centered hide-footer title="Отправить запрос на изменение сроков">
      <b-form-group
        label="Дата и время закрытия заявки"
        label-for="requestClosed"
        :label-cols="3"
        label-class="text-left"
        class="col-12"
        :horizontal="true"
        :state="state18">
        <datetime type="datetime"
          id="requestClosed"
          v-model="date.desiredTermToWork"
          class="theme-orange"
          input-class="dataTimePick"
          placeholder="Дата и время"
          moment-locale="ru"
          :phrases="{ok: 'OK', cancel: 'Отменить'}"
          v-bind:class="{invalid__dateTime: !state18, valid__dateTime: state18 === true}"
          :state="state18">
        </datetime>
        <b-btn size="sm" @click.prevent="date.desiredTermToWork = ''" text="Button" variant="light" class="mt-2" title="Очистить">очистить</b-btn>
      </b-form-group>
      <b-btn size="sm" @click.prevent="desiredterm" text="Button" variant="success">Отправить запрос</b-btn>
    </b-modal>
  </div>
</template>

<script>
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import consts from '../../config/const'
  import api from '../../config/api'
  import MaskedInput from 'vue-text-mask'
  import Multiselect from 'vue-multiselect'
  import StarRating from 'vue-star-rating'
  import Viewer from 'v-viewer'
  import 'vue-awesome/icons/window-close'
  import Icon from 'vue-awesome/components/Icon'
  import Vue from 'vue'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import moment from 'moment'
  import vueCookie from 'vue-cookie'

  Vue.use(vueCookie)
  Vue.use(Viewer)

  export default {
    name: 'new',
    components: {
      datetime: Datetime,
      loading,
      MaskedInput,
      StarRating,
      Icon,
      Multiselect
    },
    data () {
      return {
        myIdIs: localStorage.getItem('userId'),
        pictureMsg: null,
        model: {
          imageIds: [],
          documentIds: [],
          requestProblems: [],
          postponedComment: '',
          acceptanceTransfer: false,
          projectError: false
        },
        isPostponed: false,
        housingComplex: [],
        street: [],
        building: [],
        premise: [],
        accommodation: [],
        filtredAcommodations: [],
        requestTypes: [],
        requestNatures: [],
        categories: [],
        subcategories: [],
        images: [],
        documents: [],
        imagesMsg: [],
        attachments: [],
        admins: [],
        employees: [],
        specialists: [],
        statuses: [],
        innerStatuses: [],
        groups: [],
        messages: [],
        company: [],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        companyId: 0,
        lastScrollHeight: 0,
        unreadMessage: 0,
        delItem: '',
        myRole: '',
        companyName: '',
        phone: '',
        adminPhone: 'Выберите контроллёра',
        engineerPhone: 'Выберите постановщика',
        specialistGroup: '',
        pickedInnerStatus: '',
        file: '',
        fileContractor: '',
        fileMsg: '',
        msg: '',
        date: {
          arrivalSpecialist: '',
          endTime: '',
          createdDate: '',
          serviceDate: '',
          requestClosed: '',
          termToWork: '',
          desiredTermToWork: '',
          desiredFlag: '',
          postponedDate: ''
        },
        urls: {
          serviceRequest: 'servicerequest',
          premiseAddress: 'premise/premiseaddress',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          premise: 'premise',
          accommodation: 'accommodation/userlist',
          requesttype: 'requesttype',
          requestnature: 'requestnature',
          categories: 'category',
          subcategories: 'category/subcategories',
          users: 'users',
          admin: 'admin',
          employee: 'employee',
          statuses: 'status',
          innerStatuses: 'status/inner',
          group: 'contractor/group',
          specialist: 'contractor/all',
          desiredterm: 'servicerequest/desiredterm',
          problemType: 'problemtype',
          company: 'company',
          file: 'file',
          fileContractor: 'file/document'
        },
        problemTypes: [],
        focusSelect: false,
        show: false,
        firstLoadForm: true,
        firstLoadFormCategory: true,
        firstLoadFormCompanyName: true,
        accessRequest: false,
        isEngineer: false,
        isContractor: false,
        isContractorGroup: false,
        chatIsOpen: false,
        chatIsLoaded: false,
        msgInputDisabled: false,
        msgButtonDisabled: false,
        addLoadMessages: true,
        accessList: {},
        label: 'Пожалуйста, подождите, заявка сохраняется',
        consts: consts,
        validation: validation,
        allowPostponeService: false,
        allowedRoles: [
          'admin',
          'adminUK',
          'admin_Administratorzastroyshchika',
          'admin_Specialistpoplatezham'
        ],
        viewLoader: false,
        chatViewLoader: false,
        stateUploadFile: 0
      }
    },
    computed: {
      state1 () {
        return validation.numId(this.housingComplexId)
      },
      state3 () {
        return validation.numId(this.buildingId)
      },
      state4 () {
        return validation.numId(this.model.premiseId)
      },
      state6 () {
        return validation.numId(this.model.requestTypeId)
      },
      state7 () {
        return validation.numId(this.model.requestNatId)
      },
      state8 () {
        return validation.numId(this.model.categoryId)
      },
      state9 () {
        return validation.numId(this.model.subcategoryId)
      },
      state10 () {
        return validation.numId(this.model.statusId)
      },
      state11 () {
        return validation.customNumId(this.model.statusId, this.model.specialistId)
      },
      state16 () {
        if (this.isPostponed) {
          return validation.dateSelectBool(this.date.postponedDate)
        }
      },
      state17 () {
        return validation.closableRequestBool(this.model.statusId, this.date.requestClosed)
      },
      state18 () {
        return validation.dateSelectBool(this.date.desiredTermToWork)
      },
      statePostponedCommentRequired () {
        const comment = this.model.postponedComment
        return !!(comment && comment.trim() !== '')
      }
    },
    sockets: {
      message (msg) {
        if (!msg) return
        this.addMessageInList(msg)
        let readMsg = {
          chatId: this.model.chatId,
          messageId: this.messages[this.messages.length - 1].id
        }
        if (String(this.myIdIs) !== String(msg.messages[0].user.id)) {
          if (this.chatIsOpen) {
            this.model.count = 0
            this.$socket.emit('read', readMsg)
          } else {
            this.model.count++
          }
        }
        this.$nextTick(() => {
          this.scrollToEnd()
        })
      },
      deleteMessage (msg) {
        for (let i = 0; i < this.messages.length; i++) {
          if (String(this.messages[i].id) === String(msg.messageId)) {
            this.messages.splice(i, 1)
            break
          }
        }
      },
      loadMore (msg) {
        console.log('loadMore', msg)
        msg.messages.forEach((item) => {
          item.timestamp = this.printDate(item.timestamp)
          if (msg.deletedMessage.indexOf(item.id) === -1) {
            if (item.contentText !== null) {
              item.contentText = item.contentText.replace('<a>Детали заявки</a>', '')
            }
            this.messages.unshift(item)
          }
        })
        if (msg.messages.length === 0) {
          this.lastScrollHeight = 0
        } else {
          this.$nextTick(() => {
            document.getElementById('chatBody').scrollTop = document.getElementById('chatBody').scrollHeight - this.lastScrollHeight
          })
        }
        this.addLoadMessages = true
      },
      adminAllUnread (msg) {
        console.log('adminAllUnread', msg)
      },
      update (msg) {
        if (!msg) return
        console.log('update', msg)
        this.updateMessageInList(msg)
        let readMsg = {
          chatId: this.model.chatId,
          messageId: this.messages[this.messages.length - 1].id
        }
        if (msg.messages[this.messages.length - 1].user && String(this.myIdIs) !== String(msg.messages[this.messages.length - 1].user.id)) {
          this.$socket.emit('read', readMsg)
        }
        this.$nextTick(() => {
          this.scrollToEnd()
          this.chatIsLoaded = true
        })
      }
    },
    methods: {
      changeInnerIdIfNeededForStatus (val) {
        const modelStatus = this.statuses.find(el => el.value === val)
        const inWorkInnerStatus = this.innerStatuses.find(el => el.key === 'new')

        if (!modelStatus || !inWorkInnerStatus) {
          return
        }

        if (modelStatus.text === 'Возвращена' || modelStatus.text === 'В работе') {
          this.model.innerStatusId = inWorkInnerStatus.value
        }
      },
      hasAdminAccess () {
        return this.allowedRoles.includes(this.myRole)
      },
      showModal (id) {
        this.delItem = id
        this.$refs.deleteModal.show()
      },
      hideModal () {
        this.$refs.deleteModal.hide()
      },
      deleteMessage () {
        let msgData = {
          messageId: this.delItem
        }
        this.$socket.emit('deleteMessage', msgData)
        this.hideModal()
      },
      updateMessageInList (msg) {
        if (!msg) return
        if (msg.messages.length > 1) {
          if (!((parseInt(msg.messages[0].id, 10)) < parseInt(msg.messages[1].id, 10))) {
            msg.messages.reverse()
          }
        }
        msg.messages.forEach((item) => {
          item.timestamp = this.printDate(item.timestamp)
          if (item.attachment !== null) {
            this.imagesMsg.push(item.attachment.url)
          }
          if (msg.deletedMessage.indexOf(item.id) === -1) {
            if (item.contentText !== null) {
              item.contentText = item.contentText.replace('<a>Детали заявки</a>', '')
            }
            this.messages.push(item)
          }
        })
      },
      addMessageInList (msg) {
        console.log('add', msg)
        if (String(msg.chatId) === String(this.model.chatId)) {
          msg.messages[0].timestamp = this.printDate(msg.messages[0].timestamp)
          this.messages.push(msg.messages[0])
        }
      },
      isUser (id) {
        if (String(this.myIdIs) === String(id)) {
          return true
        } else {
          return false
        }
      },
      scrollToEnd () {
        let chatInput = document.getElementById('chatBody')
        chatInput.scrollTop = chatInput.scrollHeight
      },
      addBreak () {
        this.msg += '\r\n'
      },
      cancelPicture () {
        this.imagesMsg.splice(-1, 1)
        this.pictureMsg = 0
        this.msg = ''
        this.fileMsg = ''
        this.msgInputDisabled = false
        this.msgButtonDisabled = false
        this.chatViewLoader = false
        this.stateUploadFile = 0
        document.getElementById('upload-photo').value = ''
      },
      loadMore () {
        let msgData = {
          chatId: this.model.chatId,
          messageId: this.messages[0].id
        }
        this.$socket.emit('loadMore', msgData)
      },
      sendMessage () {
        let nonSpaceMsg = this.msg.replace(/\s/g, '')
        if (this.pictureMsg !== 0 || nonSpaceMsg.length > 0) {
          this.myIdIs = localStorage.getItem('userId')
          let msgData = {}
          if (this.pictureMsg) {
            msgData = {
              chatId: this.model.chatId,
              contentImage: this.pictureMsg
            }
          } else {
            msgData = {
              chatId: this.model.chatId,
              contentText: this.msg
            }
          }
          this.$socket.emit('message', msgData)
          this.pictureMsg = 0
          this.msgInputDisabled = false
          this.msg = ''
          this.fileMsg = ''
          document.getElementById('upload-photo').value = ''
          this.$nextTick(() => {
            this.scrollToEnd()
          })
        }
      },
      controlChat (active) {
        let readMsg = {
          chatId: this.model.chatId,
          messageId: this.messages[this.messages.length - 1].id
        }
        this.$socket.emit('read', readMsg)
        this.model.count = 0
        this.$nextTick(async () => {
          await this.scrollToEnd()
        })
        this.chatIsOpen = active
        if (this.chatIsOpen && window.screen.width < 576) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      },
      goBack () {
        this.$router.push({
          path: '/service_requests',
          query: {
            currentPage: this.$route.query.currentPage,
            currentTab: this.$route.query.currentTab,
            sortBy: this.$route.query.sortBy,
            sortDesc: this.$route.query.sortDesc
          }
        })
      },
      acceptDesired (flag) {
        if (flag) {
          this.model.termToWork = this.model.desiredFlag
          this.date.termToWork = this.formatDateForWidget(this.model.desiredFlag)
          this.model.approve = true
        } else {
          this.model.approve = false
        }
        this.model.desiredTermToWork = null
        this.model.desiredFlag = null
      },
      showDesiredTimeForm () {
        this.$refs.desiredTimeForm.show()
      },
      desiredterm () {
        if (this.state18) {
          let query = {
            requestId: this.$route.params.id,
            desiredTermToWork: this.toTimestamp(this.date.desiredTermToWork)
          }
          api.post(this.urls.desiredterm, query).then((response) => {
            this.date.desiredTermToWork = ''
            this.$refs.desiredTimeForm.hide()
          })
        }
      },
      sendForm () {
        if (
          this.state1 &&
          this.state3 &&
          this.state4 &&
          this.state6 &&
          this.state7 &&
          this.state8 &&
          this.state9 &&
          this.state10 &&
          this.state11 &&
          this.state17
        ) {
          if (
            this.isPostponed &&
            (!this.state16 || !this.statePostponedCommentRequired)
          ) {
            window.scrollTo({
              top: 380,
              behavior: 'smooth'
            })
            return
          }

          this.show = true
          this.model.isPostponed = this.isPostponed

          if (this.hasAdminAccess() || this.isEngineer) {
            if (this.date.arrivalSpecialist) {
              this.model.arrivalSpecialist = this.toTimestamp(this.date.arrivalSpecialist)
            }

            if (this.date.endTime) {
              this.model.endTime = this.toTimestamp(this.date.endTime)
            }

            if (this.date.serviceDate) {
              this.model.serviceDate = this.toTimestamp(this.date.serviceDate)
            } else {
              this.model.serviceDate = null
            }

            if (this.date.requestClosed) {
              this.model.requestClosed = this.toTimestamp(this.date.requestClosed)
            }

            if (this.date.termToWork) {
              this.model.termToWork = this.toTimestamp(this.date.termToWork)
            }
          }

          if (!this.date.desiredTermToWork) {
            delete this.model.desiredTermToWork
          }

          if (this.date.createdDate) {
            delete this.model.createdDate
          }

          if (this.model.adminId === 0) {
            delete this.model.adminId
          }

          if (this.model.engineerId === 0) {
            delete this.model.engineerId
          }

          if (this.model.specialistId === 0) {
            delete this.model.specialistId
          }

          if (this.model.groupId === 0) {
            delete this.model.groupId
          }

          if (this.model.innerStatusId === 0) {
            delete this.model.innerStatusId
          }

          if (!this.isContractorGroup || !this.pickedInnerStatus !== 'done') {
            this.model.requestProblems = []
          }

          if (this.date.postponedDate) {
            this.model.postponedDate = this.toTimestamp(this.date.postponedDate)
          }

          delete this.model.images
          delete this.model.documents

          this.model.userPhone = this.phone.replace(/\D/g, '').replace('7', '')

          api.put(this.urls.serviceRequest + '/' + this.$route.params.id, this.model)
            .then((response) => {
              this.show = false
              if (response.status) {
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.$router.push({
                    path: '/service_requests',
                    query: {
                      currentPage: this.$route.query.currentPage,
                      currentTab: this.$route.query.currentTab,
                      sortBy: this.$route.query.sortBy,
                      sortDesc: this.$route.query.sortDesc
                    }
                  })
                }, 1000)
              }
            })
        } else {
          window.scrollTo({
            top: 380,
            behavior: 'smooth'
          })
        }
      },
      loadIcon (e) {
        this.file = e.target.files || e.dataTransfer.files
      },
      loadFile (e) {
        this.fileContractor = e.target.files || e.dataTransfer.files
      },
      changeSelect (selectItem) {
        this.model.userName = selectItem.name.slice(0, -19)
        this.phone = `+7 (${selectItem.phone.substring(0, 3)}) ${selectItem.phone.substring(3, 6)}-${selectItem.phone.substring(6, 8)}-${selectItem.phone.substring(8, 10)}`
        this.focusSelect = false
      },
      getAdminPhone () {
        let findItem = this.admins.find(item => item.value === this.model.adminId)
        if (findItem) {
          this.adminPhone = findItem.phone
        }
      },
      getEngineerPhone () {
        let findItem = this.employees.find(item => item.value === this.model.engineerId)
        if (findItem) {
          this.engineerPhone = findItem.phone
        }
      },
      getSpecialistsTitle () {
        let findItem = this.specialists.find(item => item.value === this.model.specialistId)
        if (findItem) {
          if (this.isContractorGroup) {
            this.companyName = findItem.title
          } else {
            this.specialistGroup = findItem.title
          }
        } else {
          this.specialistGroup = ''
        }
      },
      getCompanyId () {
        let findItem = this.company.find(item => item.value === this.companyName)
        if (findItem) {
          this.companyId = findItem.id
        } else {
          this.companyId = ''
        }
      },
      getPickedInnerStatus () {
        let findItem = this.innerStatuses.find(item => item.value === this.model.innerStatusId)
        if (findItem) {
          this.pickedInnerStatus = findItem.key
        }
      },
      getRoleContractor () {
        // this.companyId = 0
        // this.companyName = ''
        let findItem = this.groups.find(item => item.value === this.model.groupId)
        if (findItem) {
          this.isContractorGroup = findItem.key === 'contractor'
        }
      },
      getPremiseAddress () {
        let query = {
          id: this.model.premiseId
        }
        api.get(this.urls.premiseAddress, query).then((response) => {
          let jData = response.data
          if (jData.status) {
            this.housingComplexId = jData.data.housingComplexId
            this.streetId = jData.data.streetId
            this.buildingId = jData.data.buildingId
            this.premiseId = jData.data.premiseId
          }
        }).then(() => {
          this.getHousingComplex()
        })
      },
      getHousingComplex () {
        api.get(this.urls.housingComplex).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.housingComplex.push(temp)
              }
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        api.get(this.urls.street).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        if (this.housingComplexId !== '') {
          this.street.forEach((street) => {
            let query = {
              hcId: this.housingComplexId,
              streetId: street.value
            }
            api.get(this.urls.building, query).then((response) => {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach((spec) => {
                  if (spec.id !== null && !spec.removalMark) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      },
      getPremise () {
        if ((this.housingComplexId !== '') && (this.streetId !== '') && (this.buildingId !== '')) {
          let query = {
            hcId: this.housingComplexId,
            streetId: this.streetId,
            buildingId: this.buildingId
          }
          api.get(this.urls.premise, query).then((response) => {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach((spec) => {
                if (!spec.removalMark) {
                  let temp = {}
                  temp.value = spec.id
                  temp.text = spec.premiseNum
                  this.premise.push(temp)
                }
              })
            }
          }).then(() => {
            this.firstLoadForm = false
          })
        }
      },
      getAccommodation () {
        let params = {
          premiseId: this.model.premiseId
        }
        api.get(this.urls.accommodation, params).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {
                  name: ''
                }
                if (spec.lastName) temp.name += spec.lastName + ' '
                if (spec.firstName) temp.name += spec.firstName + ' '
                if (spec.patronymicName) temp.name += spec.patronymicName + ' '
                if (spec.phone) temp.name += ` +7 (${spec.phone.substring(0, 3)}) ${spec.phone.substring(3, 6)}-${spec.phone.substring(6, 8)}-${spec.phone.substring(8, 10)}`
                temp.phone = spec.phone
                temp.id = spec.id
                this.accommodation.push(temp)
                this.filtredAcommodations.push(temp)
              }
            })
          }
        })
      },
      getRequestTypes () {
        api.get(this.urls.requesttype).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.requestTypes.push(temp)
              }
            })
          }
        })
      },
      getRequestNatures () {
        api.get(this.urls.requestnature).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.requestNatures.push(temp)
              }
            })
          }
        })
      },
      getCategories () {
        api.get(this.urls.categories).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.categories.push(temp)
              }
            })
          }
        })
      },
      getSubcategories () {
        let params = {
          id: this.model.categoryId
        }
        api.get(this.urls.subcategories, params).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                this.subcategories.push(temp)
              }
            })
          }
        }).then(() => {
          this.firstLoadFormCategory = false
        })
      },
      deletePicture (index) {
        this.images.splice(index, 1)
        this.model.imageIds.splice(index, 1)
        document.getElementById('file').value = ''
      },
      deleteFile (index) {
        this.documents.splice(index, 1)
        this.model.documentIds.splice(index, 1)
        document.getElementById('fileCont').value = ''
      },
      getAdmins () {
        api.get(this.urls.admin, {role: 'adminUK'}).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((user) => {
              if (!user.removalMark) {
                let temp = {}
                temp.value = user.id
                temp.text = user.lastName.concat(' ', user.firstName, ' ', user.patronymicName === null ? '' : user.patronymicName + ' ')
                temp.phone = `+7 (${user.phone.substring(0, 3)}) ${user.phone.substring(3, 6)}-${user.phone.substring(6, 8)}-${user.phone.substring(8, 10)}`
                this.admins.push(temp)
              }
            })
          }
        }).then(() => {
          this.getAdminPhone()
        })
      },
      getEmployee () {
        api.get(this.urls.admin).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((user) => {
              if (!user.removalMark) {
                let temp = {}
                temp.value = user.id
                temp.text = user.lastName.concat(' ', user.firstName, ' ', user.patronymicName === null ? '' : user.patronymicName + ' ')
                temp.phone = `+7 (${user.phone.substring(0, 3)}) ${user.phone.substring(3, 6)}-${user.phone.substring(6, 8)}-${user.phone.substring(8, 10)}`
                this.employees.push(temp)
              }
            })
          }
        }).then(() => {
          this.getEngineerPhone()
        })
      },
      getStatuses () {
        api.get(this.urls.statuses).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                if (spec.keyWord === 'closed') {
                  temp.disabled = true
                }
                this.statuses.push(temp)
              }
            })
          }
        })
      },
      getInnerStatuses () {
        api.get(this.urls.innerStatuses).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                temp.key = spec.keyWord
                this.innerStatuses.push(temp)
              }
            })
          }
        }).then(() => {
          this.getPickedInnerStatus()
        })
      },
      getGroups () {
        api.get(this.urls.group).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.name
                temp.key = spec.keyWord
                this.groups.push(temp)
              }
            })
          }
        }).then(() => {
          this.getRoleContractor()
        })
      },
      getSpecialist () {
        let query
        if (this.companyId && this.isContractorGroup) {
          query = {
            groupId: this.model.groupId,
            companyId: this.companyId
          }
        } else {
          query = {
            groupId: this.model.groupId
          }
        }
        api.get(this.urls.specialist, query)
          .then((response) => {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach((spec) => {
                if (!spec.removalMark) {
                  let temp = {}
                  temp.value = spec.id
                  temp.title = spec.title
                  temp.text = spec.lastName.concat(' ', spec.firstName, ' ', spec.patronymicName === null ? '' : spec.patronymicName + ' ')
                  this.specialists.push(temp)
                }
              })
            }
          }).then(() => {
            this.getSpecialistsTitle()
          })
      },
      getCompanies () {
        api.get(this.urls.company).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              let temp = {}
              temp.value = spec.name
              temp.id = spec.id
              temp.text = spec.name
              this.company.push(temp)
            })
          }
        }).then(() => {
          this.getCompanyId()
        })
      },
      getProblemType () {
        api.get(this.urls.problemType).then((response) => {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((spec) => {
              if (!spec.removalMark) {
                let temp = {}
                temp.id = spec.id
                temp.code = spec.id
                temp.name = spec.name
                this.problemTypes.push(temp)
              }
            })
          }
        })
      },
      printDate (timestamp) {
        return moment(timestamp * 1000).format('DD.MM.YYYY HH:mm')
      },
      formatDateForWidget (timestamp) {
        return moment(timestamp * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
      },
      convertDateStringToTimestamp (date) {
        return this.toTimestamp(moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS').toDate())
      },
      toTimestamp (date) {
        return moment(date).format('X')
      }
    },
    watch: {
      'model.statusId' (val) {
        this.changeInnerIdIfNeededForStatus(val)
      },
      'model.userName' () {
        this.filtredAcommodations = this.accommodation.filter(item => item.name.indexOf(this.model.userName) !== -1)
      },
      'model.adminId' () {
        this.getAdminPhone()
      },
      'model.engineerId' () {
        this.getEngineerPhone()
      },
      'model.groupId' () {
        this.specialists = []
        if (!this.firstLoadFormCategory) {
          this.specialistGroup = ''
          this.getRoleContractor()
          this.getSpecialist()
        }
      },
      'model.specialistId' () {
        this.getSpecialistsTitle()
      },
      'model.innerStatusId' () {
        this.getPickedInnerStatus()
      },
      companyName () {
        this.specialists = []
        if (!this.firstLoadFormCompanyName) {
          this.getCompanyId()
        } else {
          this.firstLoadFormCompanyName = false
        }
      },
      companyId () {
        this.specialists = []
        if (!this.firstLoadFormCategory) {
          this.getSpecialist()
        }
      },
      housingComplexId () {
        this.street = []
        this.building = []
        this.premise = []
        this.accommodation = []
        if (this.firstLoadForm === false && this.firstLoadForm !== undefined && this.firstLoadForm !== null) {
          this.buildingId = 0
        }
        this.getStreet()
      },
      buildingId () {
        this.premise = []
        this.accommodation = []
        if (this.firstLoadForm === false && this.firstLoadForm !== undefined && this.firstLoadForm !== null) {
          this.model.premiseId = 0
        }
        this.getPremise()
      },
      'model.premiseId' () {
        this.accommodation = []
        this.filtredAcommodations = []
        if (this.firstLoadForm === false && this.firstLoadForm !== undefined && this.firstLoadForm !== null) {
          this.model.userName = ''
          this.phone = ''
        }
        this.getAccommodation()
      },
      'model.categoryId' () {
        this.subcategories = []
        if (!this.firstLoadFormCategory) {
          this.model.subcategoryId = 0
        }
        this.getSubcategories()
      },
      file (value) {
        if (value !== '') {
          let fd = new FormData()
          fd.append('file', value[0])
          this.viewLoader = true
          api.post(this.urls.file, fd)
            .then((response) => {
              if (response.data.status === false) {
                alert(response.data.error.description)
                return
              }
              this.viewLoader = false
              let jData = response.data
              let pict = {
                height: jData.data.height,
                id: jData.data.id,
                url: jData.data.url,
                width: jData.data.width
              }
              this.images.push(pict)
              this.model.imageIds.push(pict.id)
              document.getElementById('fileCont').value = ''
            })
            .then(() => {
              this.viewLoader = false
              this.file = ''
            })
        }
      },
      fileContractor (value) {
        if (value !== '') {
          let fd = new FormData()
          fd.append('file', value[0], value[0].name.toLowerCase())
          api.post(this.urls.fileContractor, fd)
            .then((response) => {
              if (response.data.status === false) {
                alert(response.data.error.description)
                return
              }
              let jData = response.data
              let file = {
                id: jData.data.id,
                url: jData.data.url,
                ext: jData.data.ext,
                name: jData.data.name
              }
              this.documents.push(file)
              this.model.documentIds.push(file.id)
              document.getElementById('fileCont').value = ''
            })
            .then(() => {
              this.fileContractor = ''
            })
        }
      },
      fileMsg (value) {
        if (value !== '') {
          let fd = new FormData()
          fd.append('file', value)
          this.msg = 'Идет загрузка...'
          this.msgInputDisabled = true
          this.msgButtonDisabled = true
          this.chatViewLoader = true
          this.stateUploadFile = 1
          api.post(this.urls.file, fd).then((response) => {
            if (response.data.status === false) {
              this.msg = ''
              this.chatViewLoader = false
              this.msgInputDisabled = false
              this.msgButtonDisabled = false
              this.stateUploadFile = 0
              alert(response.data.error.description)
              return
            }
            if (this.stateUploadFile === 1) { // Проверка, был ли вызов cancelPicture ()
              const jData = response.data
              const pict = {
                height: jData.data.height,
                id: jData.data.id,
                url: jData.data.url,
                width: jData.data.width
              }
              this.imagesMsg.push(pict.url)
              this.pictureMsg = pict.id
              this.chatViewLoader = false
              this.msg = value.name
              this.chatViewLoader = false
              this.msgButtonDisabled = false
              this.msgInputDisabled = true
              this.stateUploadFile = 0
            }
          }).catch((err) => {
            this.msg = ''
            this.chatViewLoader = false
            this.msgInputDisabled = false
            this.msgButtonDisabled = false
            this.stateUploadFile = 0
            console.error(err)
          })
        }
      }
    },
    created () {
      let accsessKey = {
        keyWord: 'serviceRequest'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })

      api.get('role/components/access', {keyWord: 'postponeService'}).then((response) => {
        this.allowPostponeService = Boolean(response.data.data.isRead)
      })
    },
    mounted () {
      this.messages = []
      api.get(this.urls.serviceRequest + '/' + this.$route.params.id).then((response) => {
        let jData = response.data
        this.model = jData.data
        this.$set(this.model, 'imageIds', [])
        this.$set(this.model, 'documentIds', [])
        if (this.model.userPhone) {
          this.phone = `+7 (${this.model.userPhone.substring(0, 3)}) ${this.model.userPhone.substring(3, 6)}-${this.model.userPhone.substring(6, 8)}-${this.model.userPhone.substring(8, 10)}`
        }

        jData.data.images.forEach((item) => {
          this.model.imageIds.push(item.id)
        })
        this.images.push(...jData.data.images)

        jData.data.documents.forEach((item) => {
          this.model.documentIds.push(item.id)
        })
        this.documents.push(...jData.data.documents)

        if (this.model.desiredFlag !== null) {
          this.date.desiredFlag = this.printDate(this.model.desiredFlag)
        }
      }).then(() => {
        const allowedUsers = [
          this.model.specialistId,
          this.model.engineerId,
          this.model.adminId
        ]

        const userId = Number(localStorage.getItem('userId'))
        const userRole = vueCookie.get('role')

        if (allowedUsers.includes(userId) || this.allowedRoles.includes(userRole)) {
          this.accessRequest = true
        }

        this.isEngineer = userId === this.model.engineerId
        this.isContractor = userId === this.model.specialistId
        this.myRole = userRole

        // console.log(userId)
        // console.log(this.model.engineerId)
        // console.log(userRole)
        // console.log(this.isEngineer)

        this.getPremiseAddress()
        this.getRequestTypes()
        this.getRequestNatures()
        this.getCategories()
        this.getAdmins()
        this.getEmployee()
        this.getStatuses()
        this.getInnerStatuses()
        this.getGroups()
        this.getSpecialist()
        this.getProblemType()
        this.getCompanies()

        if (this.model.postponedDate) {
          this.isPostponed = true
        }

        if (this.hasAdminAccess()) {
          document.addEventListener('click', (e) => {
            let check = false
            let path = e.path || (e.composedPath && e.composedPath())
            path.forEach((item) => {
              if (String(item.className) === 'container__select') {
                check = true
              }
            })
            this.focusSelect = check && !this.focusSelect
          })
        }
        if (this.hasAdminAccess() || this.isEngineer) {
          if (this.model.arrivalSpecialist) {
            this.date.arrivalSpecialist = this.formatDateForWidget(this.model.arrivalSpecialist)
          }

          if (this.model.endTime) {
            this.date.endTime = this.formatDateForWidget(this.model.endTime)
          }

          if (this.model.createdDate) {
            this.date.createdDate = this.formatDateForWidget(this.model.createdDate)
          }

          if (this.model.serviceDate) {
            this.date.serviceDate = this.formatDateForWidget(this.model.serviceDate)
          }

          if (this.model.requestClosed) {
            this.date.requestClosed = this.formatDateForWidget(this.model.requestClosed)
          }

          if (this.model.termToWork) {
            this.date.termToWork = this.formatDateForWidget(this.model.termToWork)
          }

          if (this.model.postponedDate) {
            this.date.postponedDate = this.formatDateForWidget(this.model.postponedDate)
          }
        } else {
          if (this.model.arrivalSpecialist) {
            this.date.arrivalSpecialist = this.printDate(this.model.arrivalSpecialist)
          }

          if (this.model.endTime) {
            this.date.endTime = this.printDate(this.model.endTime)
          }

          if (this.model.createdDate) {
            this.date.createdDate = this.printDate(this.model.createdDate)
          }

          if (this.model.serviceDate) {
            this.date.serviceDate = this.printDate(this.model.serviceDate)
          }

          if (this.model.requestClosed) {
            this.date.requestClosed = this.printDate(this.model.requestClosed)
          }

          if (this.model.termToWork) {
            this.date.termToWork = this.printDate(this.model.termToWork)
          }

          if (this.model.postponedDate) {
            this.date.postponedDate = this.printDate(this.model.postponedDate)
          }
        }

        this.$socket.emit('update', {chatId: this.model.chatId})
      })

      let chat = document.getElementById('chatBody')

      chat.addEventListener('scroll', () => {
        if (document.getElementById('chatBody').scrollTop === 0) {
          if (this.addLoadMessages) {
            this.lastScrollHeight = document.getElementById('chatBody').scrollHeight
            this.$socket.emit('loadMore', {
              chatId: this.model.chatId,
              messageId: this.messages[0].id
            })
          }
          this.addLoadMessages = false
        }
      })
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
