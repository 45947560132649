<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Редактирование типа заявки</strong>
          </template>
          <b-form-group
            label="Название типа"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Тип заявки успешно обновлён">
      <p class="my-4">Тип заявки успешно обновлён</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../../config/validation'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.model.name)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true) {
          _this.show = true
          api.put(_this.urls.requesttype + '/' + _this.$route.params.id, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/service_requests/types/list')
              }, 1000)
            }
          })
        }
      }
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.requesttype + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
      })
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'requestType'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    watch: {
    },
    data () {
      return {
        model: {
        },
        accessList: {},
        date: '',
        posts: [],
        urls: {
          requesttype: 'requesttype'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
