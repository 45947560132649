<template>
  <footer class="app-footer">

  </footer>
</template>
<script>
export default {
  name: 'c-footer'
}
</script>
