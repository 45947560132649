<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <template>
          <loading
            :show="show"
            :label="label">
          </loading>
        </template>
        <b-card>
          <template #header>
            <strong>Технические работы</strong>
          </template>
          <b-card>
            <template #header>
              <strong>Настройка технической работы</strong>
            </template>
            <b-form-group
              :label-cols="3"
              label="Техническая работа"
              label-for="housingComplex"
              :horizontal="true">
              <b-form-select id="housingComplex"
                            :options="settingsList"
                            placeholder="Выберите версию Android"
                            v-model="modelSettings.alertId">
                <template slot="first">
                  <option :value="null" disabled>Выберите техническую работу</option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group
              :label-cols="3"
              label="Активна"
              label-for="housingComplex"
              :horizontal="true">
              <label class="switch switch-3d switch-primary">
                <input type="checkbox" v-model="modelSettings.activate" class="switch-input" checked>
                <span class="switch-label"></span>
                <span class="switch-handle"></span>
              </label>
            </b-form-group>
            <b-btn size="sm" @click.prevent="goHistory" text="Button" variant="info">Посмотреть историю</b-btn>
          </b-card>
          <b-card>
            <template #header>
              <strong>Диалоговое окно</strong>
            </template>
            <b-form-group
              label="Заголовок"
              label-for="carNumber"
              :label-cols="3"
              :horizontal="true"
              :invalid-feedback="validation.empty(modelDialog.title)"
              :state="state1">
              <b-form-input id="carNumber" type="text" :state="state1" v-model="modelDialog.title"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Текст"
              label-for="carNumber"
              :label-cols="3"
              :horizontal="true"
              :invalid-feedback="validation.empty(modelDialog.text)"
              :state="state2">
              <b-form-input id="carNumber" type="text" :state="state2" v-model="modelDialog.text"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Кнопка согласия"
              label-for="carNumber"
              :label-cols="3"
              :horizontal="true"
              :invalid-feedback="validation.empty(modelDialog.button)"
              :state="state3">
              <b-form-input id="carNumber" type="text" :state="state3" v-model="modelDialog.button"></b-form-input>
            </b-form-group>
          </b-card>
          <b-btn size="sm" v-if="accessList.isUpdate === true && false" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
        <b-card>
          <template #header>
            <strong>Настройка периода передачи показаний счетчиков</strong>
          </template>
          <b-form-group
            label="Заголовок"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(modelMeasurement.title)"
            :state="state4">
            <b-form-input id="carNumber" type="text" :state="state4" v-model="modelMeasurement.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Начало периода"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isInt(modelMeasurement.from)"
            :state="state5">
            <b-form-input id="carNumber" type="text" :state="state5" v-model="modelMeasurement.from"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Конец периода"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.isInt(modelMeasurement.to)"
            :state="state6">
            <b-form-input id="carNumber" type="text" :state="state6" v-model="modelMeasurement.to"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка закрытия"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(modelMeasurement.button)"
            :state="state7">
            <b-form-input id="carNumber" type="text" :state="state6" v-model="modelMeasurement.button"></b-form-input>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true && false" @click.prevent="sendFormMeasurement" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
        <b-card>
          <template #header>
            <strong>Настройка алерта об отсутствии счетчиков</strong>
          </template>
          <b-form-group
            label="Заголовок"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(modelMeter.title)"
            :state="state8">
            <b-form-input id="carNumber" type="text" :state="state8" v-model="modelMeter.title"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Текст"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(modelMeter.text)"
            :state="state9">
            <b-form-input id="carNumber" type="text" :state="state9" v-model="modelMeter.text"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Кнопка закрытия"
            label-for="carNumber"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(modelMeter.button)"
            :state="state10">
            <b-form-input id="carNumber" type="text" :state="state10" v-model="modelMeter.button"></b-form-input>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true && false" @click.prevent="sendFormMeter" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Версия успешно изменена">
      <p class="my-4">Версия успешно изменена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import { Datetime } from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import consts from '../../config/const'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.long(this.modelDialog.title)
      },
      state2 () {
        return validation.long(this.modelDialog.text)
      },
      state3 () {
        return validation.long(this.modelDialog.button)
      },
      state4 () {
        return validation.long(this.modelMeasurement.title)
      },
      state5 () {
        return validation.isIntBool(this.modelMeasurement.from)
      },
      state6 () {
        return validation.isIntBool(this.modelMeasurement.to)
      },
      state7 () {
        return validation.long(this.modelMeasurement.button)
      },
      state8 () {
        return validation.long(this.modelMeter.title)
      },
      state9 () {
        return validation.long(this.modelMeter.text)
      },
      state10 () {
        return validation.long(this.modelMeter.button)
      }
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      goHistory () {
        this.$router.push('/technicalservices/list')
      },
      sendForm () {
        let _this = this
        if (_this.state1 && _this.state2 && _this.state3) {
          _this.show = true
          api.put(_this.urls.technicalservices + '/dialog', _this.modelDialog).then(function (response) {
            return api.put(_this.urls.technicalservices + '/settings', _this.modelSettings)
          }).then(function (response) {
            if (response.status) {
              _this.show = false
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
              }, 1000)
            }
          })
        }
      },
      sendFormMeasurement () {
        let _this = this
        if (_this.state4 && _this.state5 && _this.state6 && _this.state7) {
          _this.show = true
          api.put(_this.urls.measurement, _this.modelMeasurement).then(function (response) {
            if (response.status) {
              _this.show = false
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
              }, 1000)
            }
          })
        }
      },
      sendFormMeter () {
        let _this = this
        if (_this.state8 && _this.state9 && _this.state10) {
          _this.show = true
          api.put(_this.urls.meter, _this.modelMeter).then(function (response) {
            if (response.status) {
              _this.show = false
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
              }, 1000)
            }
          })
        }
      },
      getSettings () {
        let _this = this
        api.get(_this.urls.technicalservices).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.settingsList = jData.data.map(item => {
              return {
                text: item.name,
                value: item.id
              }
            })
          }
        })
      },
      getFormMeasurement () {
        api.get(this.urls.measurement).then((response) => {
          let jData = response.data
          this.modelMeasurement = jData.data
        })
      },
      getFormMeter () {
        api.get(this.urls.meter).then((response) => {
          let jData = response.data
          this.modelMeter = jData.data
        })
      }
    },
    mounted: function () {
      let _this = this
      this.getSettings()
      this.getFormMeasurement()
      this.getFormMeter()
      api.get(_this.urls.technicalservices + '/settings').then(function (response) {
        let jData = response.data
        _this.modelSettings = jData.data
        return api.get(_this.urls.technicalservices + '/dialog')
      }).then((response) => {
        let jData = response.data
        _this.modelDialog = jData.data
      })
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'technicalServices'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    data () {
      return {
        modelSettings: {
        },
        modelDialog: {
        },
        modelMeasurement: {
        },
        modelMeter: {
        },
        settingsList: [],
        accessList: {},
        urls: {
          technicalservices: 'technicalservices',
          measurement: 'measurement/dialog',
          meter: 'meter/dialog',
          file: 'file/apk'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        check: '',
        consts: consts,
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


