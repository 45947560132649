<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Импорт квартир</strong>
          </template>
          <b-form-group
            label="Файл(.xlsx)"
            label-for="file"
            :label-cols="3"
            :horizontal="true"
            :description="errorDescription">
            <b-form-file id="file"
                  @click="resetInput()"
                  :plain="true"
                  v-model="file"
                  :multiple="false"
                  accept=".xlsx">
            </b-form-file>
            <b-btn size="sm" text="Button" variant="success" class="posabs" @click="downloadTemplate()">Скачать шаблон</b-btn>
          </b-form-group>
          <b-form-group
            label="Ошибки"
            label-for="file"
            :label-cols="3"
            :horizontal="true"
            v-if="error"
            label-class="text-danger">
            <ol>
              <li class="text-danger" v-for="(error, i) in errorList" :key="i">{{ error }}</li>
            </ol>
          </b-form-group>
          <b-form-group
            label="Успешно"
            label-for="file"
            :label-cols="3"
            :horizontal="true"
            v-if="successView"
            label-class="text-success">
          </b-form-group>
          <b-btn size="sm" text="Button" variant="success" v-if="viewButton" @click="saveAddress()">Импортировать</b-btn>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import consts from '../../config/const'
  import api from '../../config/api'
  import validation from '../../config/validation'
  import loading from 'vue-full-loading'

export default {
    components: {
      loading
    },
    data () {
      return {
        file: '',
        fileName: '',
        error: false,
        successView: false,
        errorList: [],
        errorDescription: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        urls: {
          file: 'file/loadpremise',
          objectCreator: 'import/objectCreator'
        },
        viewButton: false,
        consts: consts,
        validation: validation
      }
    },
    watch: {
      'file' (newFile, oldFile) {
        if (newFile) {
          this.successView = false
          this.error = false
          this.errorList = []
          this.errorDescription = ''
          this.show = true
          let fd = new FormData()
          fd.append('file', newFile)
          api.post(this.urls.file, fd).then((response) => {
            let jData = response.data
            if (jData.status) {
              this.fileName = jData.data.filename
              this.viewButton = true
            } else {
              this.error = true
              this.errorList = jData.error.content
              this.errorDescription = jData.error.description
            }
            this.show = false
          })
        }
      },
      successView (nValue, oValue) {
        if (nValue === true) {
          setTimeout(() => {
            this.successView = false
          }, 3000)
        }
      }
    },
    mounted () {
      document.getElementById('file').onclick = () => {
        document.getElementById('file').value = ''
        this.error = false
        this.errorList = []
        this.errorDescription = ''
      }
    },
    methods: {
      async saveAddress () {
        this.show = true

        const query = {
          fileName: this.fileName
        }

        let response = await api.post(this.urls.objectCreator, query)

        let interval = setInterval(async () => {
          let checkReady = await api.get(this.urls.objectCreator, { guid: response.data.data.guid })
          if (checkReady.data.data.success) {
            this.show = false
            clearInterval(interval)
            this.successView = true
            this.resetInput()
          }
        }, 10000)
      },
      resetInput () {
        // this.file = ''
        document.getElementById('file').value = ''
      },
      downloadTemplate () {
        location.href = consts.storageUrl + '/' + consts.templatePremise
      }
    }
  }
</script>



