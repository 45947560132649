<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <template #header>
            <strong>{{ chatName }}</strong>
          </template>

          <b-card-body
            id="nav-scroller"
            ref="content"
            :offset="200"
            style="position:relative; height:60vh; overflow-y:scroll; display: flex; flex-direction: column;"
          >
            <b-link href="" class="middle" @click.prevent="loadMore()">Загрузить ещё</b-link>

            <div v-viewer="{toolbar: false, navbar: false}" v-for="i in messages" class="leftUser">
              <h4
                v-if="i.user !== null && (i.contentText !== '' || i.attachment !== null)"
                v-bind:class="{rightUser__header: isUser(i.user.id)}"
              >
                {{i.user.firstName.concat(' ' + i.user.lastName).concat()}}
              </h4>

              <h4 v-if="i.user === null && (i.contentText !== '' || i.attachment !== null)">
                Системное сообщение:
              </h4>

              <p class="m-1 textMessage" v-if="i.contentText !== ''">{{ i.contentText }}</p>

              <img
                v-if="i.attachment !== null"
                :src="config.storageUrl + '/' + i.attachment.url"
                alt="hex shorthand color"
                class="chat__picture m-1 pb-3"
              />

              <p
                class="dateStamp"
                v-if="i.contentText !== '' || i.attachment !== null">
                {{ i.timestamp }}
              </p>

              <i @click="showModal(i.id)" class="delete_message__btn fa fa-trash-o"></i>

              <hr class="chat__delimeter" v-if="i.contentText !== '' || i.attachment !== null">
            </div>
          </b-card-body>

          <b-form inline class="mt-4" @keyup.ctrl.enter.exact="addBreak()" @keyup.enter.exact.capture="sendMessage()">
            <b-textarea
              class="textarea mb-2 mb-sm-0"
              :disabled="msgInputDisabled"
              id="inlineFormInputName2"
              placeholder="Текст сообщения..."
              :rows="1"
              :max-rows="1"
              :no-resize="true"
              v-model="msg"
              onkeydown="if (event.keyCode === 13) { return false; }"
            />

            <label for="upload-photo" class="btn-primary fileInput"><i class="icon-paper-clip"></i></label>
            <b-form-file accept="image/jpg,image/jpeg,image/png" id="upload-photo" :plain="true" v-model="file"></b-form-file>

            <b-button class="sendMessageButton" variant="success" @click.stop="sendMessage()" :disabled="msgButtonDisabled">Отправить</b-button>

            <label v-if="msgInputDisabled" @click.stop="cancelPicture()" class="btn-primary fileInput">
              <i class="icon-close"></i>
            </label>
            <img src="/img/loading-green.gif" class="image-loader" v-if="chatViewLoader" alt="">
          </b-form>

          <b-modal ref="myModalRef" hide-footer title="Удаление сообщения">
            <div class="d-block text-center">
              <h3>Вы действительно хотите удалить сообщение?</h3>
            </div>
            <b-btn class="mt-3" variant="outline-danger" block @click="deleteMessage()">Да</b-btn>
            <b-btn class="mt-3" variant="outline-danger" block @click="hideModal">Нет</b-btn>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import api from '../../config/api'
  import config from '../../config/const'
  import Viewer from 'v-viewer'
  import Vue from 'vue'
  import moment from 'moment'

  Vue.use(Viewer)

  export default {
    name: 'Chat',

    data () {
      return {
        isConnected: false,
        chatName: '',
        socketMessage: '',
        myIdIs: localStorage.getItem('userId'),
        accessList: {},
        messages: [],
        msg: '',
        delItem: '',
        msgInputDisabled: false,
        msgButtonDisabled: false,
        pictureMsg: 0,
        urls: {
          list: 'chat/list',
          file: 'file'
        },
        header: '',
        config: config,
        images: [],
        file: '',
        chatViewLoader: false,
        stateUploadFile: 0
      }
    },

    sockets: {
      async message (msg) {
        console.log('message', msg)
        if (!msg) return

        await this.addMessageInList(msg)
        this.scrollToEnd()

        if (String(this.myIdIs) !== String(msg.messages[0].user.id)) {
          this.$socket.emit('read', {
            chatId: this.$route.params.id,
            messageId: this.messages[this.messages.length - 1].id
          })
        }
      },

      loadMore (msg) {
        console.log('loadMore', msg)

        msg.messages.forEach((item) => {
          item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY HH:mm')

          if (msg.deletedMessage.indexOf(item.id) === -1) {
            this.messages.unshift(item)
          }
        })
      },

      deleteMessage (msg) {
        console.log('deleteMessage', msg)

        for (let i = 0; i < this.messages.length; i++) {
          if (String(this.messages[i].id) === String(msg.messageId)) {
            this.messages.splice(i, 1)
          }
        }
      },

      async update (msg) {
        if (!msg) return
        console.log('update', msg)

        this.chatName = msg.name

        await this.updateMessageInList(msg)
        this.scrollToEnd()

        if (String(this.myIdIs) !== String(msg.messages[this.messages.length - 1].user.id)) {
          this.$socket.emit('read', {
            chatId: this.$route.params.id,
            messageId: this.messages[this.messages.length - 1].id
          })
        }
      }
    },

    methods: {
      updateMessageInList (msg) {
        if (!msg) return
        if (msg.messages.length > 1) {
          if (!((parseInt(msg.messages[0].id, 10)) < parseInt(msg.messages[1].id, 10))) {
            msg.messages.reverse()
          }
        }

        msg.messages.forEach((item) => {
          item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY HH:mm')

          if (item.attachment !== null) {
            this.images.push(item.attachment.url)
          }

          if (msg.deletedMessage.indexOf(item.id) === -1) {
            this.messages.push(item)
          }
        })
      },

      addMessageInList (msg) {
        if (String(msg.chatId) === String(this.$route.params.id)) {
          msg.messages[0].timestamp = moment(msg.messages[0].timestamp * 1000).format('DD.MM.YYYY HH:mm')
          this.messages.push(msg.messages[0])
        }
      },

      addBreak () {
        this.msg += '\r\n'
      },

      showModal (id) {
        this.delItem = id
        this.$refs.myModalRef.show()
      },

      hideModal () {
        this.$refs.myModalRef.hide()
      },

      deleteMessage () {
        this.$socket.emit('deleteMessage', {
          messageId: this.delItem
        })

        this.hideModal()
      },

      isUser (id) {
        return String(this.myIdIs) === String(id)
      },

      cancelPicture () {
        this.images.splice(-1, 1)
        this.pictureMsg = 0
        this.msg = ''
        this.file = ''
        this.msgInputDisabled = false
        this.msgButtonDisabled = false
        this.chatViewLoader = false
        this.stateUploadFile = 0
        document.getElementById('upload-photo').value = ''
      },

      loadMore () {
        this.$socket.emit('loadMore', {
          chatId: this.$route.params.id,
          messageId: this.messages[0].id
        })
      },

      sendMessage () {
        const nonSpaceMsg = this.msg.replace(/\s/g, '')
        if (this.pictureMsg !== 0 || nonSpaceMsg.length > 0) {
          this.myIdIs = localStorage.getItem('userId')

          let msgData = {}
          if (this.pictureMsg !== 0) {
            msgData = {
              chatId: this.$route.params.id,
              contentImage: this.pictureMsg
            }
          } else {
            msgData = {
              chatId: this.$route.params.id,
              contentText: this.msg
            }
          }

          this.$socket.emit('message', msgData)

          this.pictureMsg = 0
          this.msgInputDisabled = false
          this.msg = ''
          this.file = ''
          document.getElementById('upload-photo').value = ''

          setTimeout(() => {
            this.scrollToEnd()
          }, 200)
        }
      },

      scrollToEnd () {
        const container = this.$el.querySelector('#nav-scroller')
        container.scrollTop = container.scrollHeight
      }
    },

    watch: {
      file (value) {
        if (value !== '') {
          const fd = new FormData()
          fd.append('file', value)
          this.msg = 'Идет загрузка...'
          this.msgInputDisabled = true
          this.msgButtonDisabled = true
          this.chatViewLoader = true
          this.stateUploadFile = 1
          api.post(this.urls.file, fd).then((response) => {
            if (response.data.status === false) {
              this.msg = ''
              this.chatViewLoader = false
              this.msgInputDisabled = false
              this.msgButtonDisabled = false
              this.stateUploadFile = 0
              alert(response.data.error.description)
              return
            }
            if (this.stateUploadFile === 1) { // Проверка, был ли вызов cancelPicture ()
              const jData = response.data
              const pict = {
                height: jData.data.height,
                id: jData.data.id,
                url: jData.data.url,
                width: jData.data.width
              }
              this.images.push(pict.url)
              this.pictureMsg = pict.id
              this.msg = value.name
              this.chatViewLoader = false
              this.msgButtonDisabled = false
              this.msgInputDisabled = true
              this.stateUploadFile = 0
            }
          }).catch(
            (err) => {
              this.msg = ''
              this.chatViewLoader = false
              this.msgInputDisabled = false
              this.msgButtonDisabled = false
              this.stateUploadFile = 0
              console.error(err)
            }
          )
        }
      }
    },

    created: function () {
      const accsessKey = {
        keyWord: 'chat'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          this.$router.push('/pages/403')
        } else {
          this.accessList = response.data.data
        }
      })
    },

    mounted () {
      this.$nextTick(() => {
        this.$socket.emit('update', {chatId: this.$route.params.id})
      })
    },

    beforeDestroy () {
      this.$socket.removeListener('update')
      this.$socket.removeListener('message')
      this.$socket.removeListener('loadMore')
      this.$socket.removeListener('deleteMessage')
    }
  }
</script>

