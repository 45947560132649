<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание пользователя</strong>
          </template>
          <b-form-group
            label="Имя"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.firstName)"
            :state="state4">
            <b-form-input id="name" type="text" :state="state4" v-model="model.firstName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="lastName"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.lastName)"
            :state="state5">
            <b-form-input id="lastName" type="text" :state="state5" v-model="model.lastName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Отчество"
            label-for="patronymicName"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="patronymicName" type="text" v-model="model.patronymicName"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Субъект ГП"
            label-for="scr"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.scrId)"
            :state="state9">
            <b-form-select id="scr"
                           :options="scr"
                           placeholder="Выберите ЖК"
                           v-model="model.scrId"
                           :state="state9">
              <template slot="first">
                <option :value="null" disabled>Выберите субъект ГП</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="ИНН"
            label-for="inn"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="lastName" type="text" v-model="model.inn"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Email"
            label-for="email"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.email(model.email)"
            :state="state6">
            <b-form-input id="lastName" type="text" :state="state6" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state7">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
            ></masked-input>
          </b-form-group>
          <b-form-group
            label="Лицевой счёт"
            label-for="account"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.account)"
            :state="state8">
            <b-form-input id="account" type="text" :state="state8" v-model="model.account"></b-form-input>
          </b-form-group>
          <!--<b-form-group-->
            <!--label="Гостевой доступ"-->
            <!--label-for="guestAccess"-->
            <!--:label-cols="3"-->
            <!--:horizontal="true">-->
            <!--<label class="switch switch-3d switch-primary">-->
              <!--<input type="checkbox" v-model="model.guestAccess" class="switch-input" checked>-->
              <!--<span class="switch-label" data-on="Да" data-off="Нет"></span>-->
              <!--<span class="switch-handle"></span>-->
            <!--</label>-->
          <!--</b-form-group>-->
          <b-form-group
            label="Квартиры"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <div class="address_picker">
              <multiselect v-model="value" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" :preserve-search="false" :searchable="false" placeholder="" label="name" track-by="name" :preselect-first="true">
                <template slot="tag" slot-scope="props"><span class="custom__tag"><span>{{ props.option.name }}</span><span class="custom__remove" @click="props.remove(props.option)">❌</span></span></template>
              </multiselect>
              <b-btn size="sm" class="address_picker__button_add" @click.prevent="viewModal" text="Button" variant="success"><i class="fa fa-plus fa-2x"></i></b-btn>
            </div>
          </b-form-group>
          <b-form-group
            label="Домофон"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-btn size="sm" @click.prevent="viewModalUserDataATS" text="Button" variant="primary">Учётные данные АТС</b-btn>
          </b-form-group>
          <b-form-group
            label="Доступные разделы"
            label-for="section"
            :label-cols="3"
            :horizontal="true">
            <b-form-group
              :label="`${item.name}`"
              label-for="section"
              :label-cols="3"
              :horizontal="true"
              class="mgt-5"
              v-for="item in section">
              <label class="mgt9 switch switch-3d switch-primary">
                <input type="checkbox" v-model="item.isBlocked" class="switch-input" checked>
                <span class="switch-label"></span>
                <span class="switch-handle"></span>
              </label>
            </b-form-group>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="addPremise" hide-footer title="Добавить квартиру">
      <b-form-group
        label="Жилищный комплекс"
        label-for="housingComplex"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(housingComplexId)"
        :state="state1">
        <b-form-select id="housingComplex"
                       :options="housingComplex"
                       placeholder="Выберите ЖК"
                       v-model="housingComplexId"
                       :state="state1">
          <template slot="first">
            <option :value="null" disabled>Выберите ЖК</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Дом"
        label-for="building"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(buildingId)"
        :state="state2">
        <b-form-select id="building"
                       :options="building"
                       placeholder="Выберите дом"
                       v-model="buildingId"
                       :state="state2">
          <template slot="first">
            <option :value="null" disabled>Выберите дом</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Квартира"
        label-for="premise"
        :label-cols="3"
        :horizontal="true"
        :invalid-feedback="validation.notSelect(premiseId)"
        :state="state3">
        <b-form-select id="premise"
                       :options="premise"
                       placeholder="Выберите квартиру"
                       v-model="premiseId"
                       :state="state3">
          <template slot="first">
            <option :value="null" disabled>Выберите квартиру</option>
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Владелец"
        label-for="premise"
        :label-cols="3"
        :horizontal="true">
        <label class="switch switch-3d switch-primary">
          <input type="checkbox" v-model="check" class="switch-input" checked>
          <span class="switch-label" data-on="Да" data-off="Нет"></span>
          <span class="switch-handle"></span>
        </label>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="saveAddress">Сохранить</b-btn>
    </b-modal>
    <b-modal ref="userDataATS" hide-footer title="Учётные данные АТС">
      <b-form-group
        v-if="value.length === 0"
        label="Привяжите квартиру, чтобы задать учётные данные АТС"
        label-for="file"
        :label-cols="12"
        :horizontal="true">
      </b-form-group>
      <b-form-group
        :label="`${item.name}`"
        label-for=""
        :label-cols="3"
        :horizontal="false"
        label-size="md"
        v-for=" (item, n) in value">
        <hr>
        <b-form-group
          label="Логин"
          label-for="link"
          :label-cols="3"
          :horizontal="true">
          <b-form-input id="link" type="text" v-model="item.login"></b-form-input>
        </b-form-group>
        <b-form-group
          label="Пароль"
          label-for="link"
          :label-cols="3"
          :horizontal="true">
          <b-form-input id="link" type="text" v-model="item.password"></b-form-input>
        </b-form-group>
      </b-form-group>
      <b-btn class="mt-3" variant="outline-success" block @click="hideModalUserDataATS">Применить</b-btn>
    </b-modal>
    <b-modal ref="myModalRef" hide-footer :title="titleText">
      <p class="my-4">{{ resultText }}</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import Multiselect from 'vue-multiselect'
  import validation from '../../config/validation'
  import MaskedInput from 'vue-text-mask'
  import loading from 'vue-full-loading'

  export default {
    name: 'New',
    components: {
      loading,
      Multiselect,
      MaskedInput
    },
    computed: {
      state1 () {
        return validation.numId(this.housingComplexId)
      },
      state2 () {
        return validation.numId(this.buildingId)
      },
      state3 () {
        return validation.numId(this.premiseId)
      },
      state4 () {
        return validation.long(this.model.firstName)
      },
      state5 () {
        return validation.long(this.model.lastName)
      },
      state6 () {
        return validation.emailBool(this.model.email)
      },
      state7 () {
        return validation.phoneNumberBool(this.phone)
      },
      state8 () {
        return validation.long(this.model.account)
      },
      state9 () {
        return validation.numId(this.model.scrId)
      }
    },
    methods: {
      viewModal () {
        let _this = this
        _this.housingComplexId = 0
        _this.check = true
        _this.$refs.addPremise.show()
      },
      hideModal () {
        this.$refs.addPremise.hide()
      },
      viewModalUserDataATS () {
        let _this = this
        _this.$refs.userDataATS.show()
      },
      hideModalUserDataATS () {
        this.$refs.userDataATS.hide()
      },
      saveAddress () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true && _this.state3 === true) {
          let fullAddress = null
          _this.housingComplex.forEach((item) => {
            if (item.value === _this.housingComplexId) {
              fullAddress = item.text
            }
          })
          _this.building.forEach((item) => {
            if (item.value === _this.buildingId) {
              fullAddress = fullAddress + ' ' + item.text
            }
          })
          _this.premise.forEach((item) => {
            if (item.value === _this.premiseId) {
              fullAddress = fullAddress + ' кв.' + item.text
            }
          })
          let addressData = {
            name: fullAddress,
            id: _this.premiseId,
            isOwner: _this.check,
            login: '',
            password: ''
          }
          _this.value.push(addressData)
          _this.hideModal()
        }
      },
      sendForm () {
        if (this.state4 && this.state5 && this.state6 && this.state7 && this.state8 && this.state9) {
          this.show = true
          this.model.phone = this.phone.replace(/\D/g, '').replace('7', '')
          this.model.sectionAccess = []
          this.section.forEach((item) => {
            let temp = {}
            temp.isBlocked = !item.isBlocked
            temp.id = item.id
            temp.name = item.name
            this.model.sectionAccess.push(temp)
          })
          this.value.forEach((item) => {
            let data = {
              premiseId: item.id,
              isOwner: item.isOwner,
              login: item.login,
              password: item.password
            }
            this.model.accommodation.push(data)
          })
          this.model.key = 'user'
          api.post(this.urls.users, this.model).then((response) => {
            this.show = false
            if (response.status) {
              if (response.data.error) {
                this.resultText = response.data.error.description
                this.titleText = 'Ошибка создания пользователя'
                this.phone = ''
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.show = false
                }, 3000)
              } else {
                this.resultText = 'Исполнитель успешно создан'
                this.titleText = 'Исполнитель успешно создан'
                this.$refs.myModalRef.show()
                setTimeout(() => {
                  this.$refs.myModalRef.hide()
                  this.$router.push('/users')
                }, 1000)
              }
            }
          })
        }
      },
      getHousingComplex () {
        let _this = this
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.housingComplex.push(temp)
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this
        if (_this.housingComplexId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.housingComplexId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    _this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      },
      getPremise () {
        let _this = this
        if ((_this.housingComplexId !== '') && (_this.streetId !== '') && (_this.buildingId !== '')) {
          let query = {
            hcId: _this.housingComplexId,
            streetId: _this.streetId,
            buildingId: _this.buildingId
          }
          api.get(_this.urls.premise, query).then(function (response) {
            let jData = response.data
            if (jData.status) {
              jData.data.forEach(function (spec) {
                let temp = {}
                temp.value = spec.id
                temp.text = spec.premiseNum
                _this.premise.push(temp)
              })
            }
          })
        }
      },
      getSections () {
        let _this = this
        api.get(_this.urls.section).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.id = spec.id
              temp.name = spec.name
              temp.isBlocked = true
              _this.section.push(temp)
            })
          }
        })
      },
      getScr () {
        let _this = this
        api.get(_this.urls.scr).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.scr.push(temp)
            })
          }
        })
      }
    },
    watch: {
      housingComplexId: function () {
        this.buildingId = 0
        this.premiseId = 0
        this.building = []
        this.premise = []
        this.getBuilding()
      },
      streetId: function () {
        this.building = []
        this.premise = []
      },
      buildingId: function () {
        this.premise = []
        this.premiseId = 0
        this.getPremise()
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'user'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getHousingComplex()
      _this.getSections()
      _this.getScr()
    },
    data () {
      return {
        model: {
          email: '',
          accommodation: [],
          roleId: 1,
          scrId: 0,
          // guestAccess: false,
          sectionAccess: []
        },
        date: '',
        accessList: {},
        value: [],
        options: [
        ],
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        premise: [
        ],
        section: [
        ],
        scr: [
        ],
        housingComplexId: 0,
        streetId: 0,
        buildingId: 0,
        premiseId: 0,
        urls: {
          users: 'users',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          premise: 'premise',
          section: 'section',
          scr: 'scr'
        },
        check: 'true',
        phone: '',
        resultText: '',
        titleText: '',
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

