<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Редактирование экстренной службы</strong>
          </template>
          <b-form-group
            label="Номер телефона"
            label-for="link"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.phoneNumber(phone)"
            :state="state1">
            <masked-input
              type="tel"
              name="phone"
              class="form-control"
              v-model="phone"
              :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
              :guide="true"
              :showMask="true"
              :keepCharPositions="true"
              v-bind:class="{invalid__dateTime: !state1, valid__dateTime: state1 === true}"
            ></masked-input>
          </b-form-group>
          <b-form-group
            label="Заголовок"
            label-for="hint"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.hint)"
            :state="state2">
            <b-form-input id="hint" type="text" :state="state2" v-model="model.hint"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Описание"
            label-for="description"
            :label-cols="3"
            :horizontal="true">
            <b-form-input id="description" type="text" v-model="model.description"></b-form-input>
          </b-form-group>
          <b-btn size="sm" v-if="accessList.isUpdate === true" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Экстренная служба успешно изменена">
      <p class="my-4">Экстренная служба успешно изменена</p>
    </b-modal>
  </div>
</template>


<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import MaskedInput from 'vue-text-mask'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading,
      MaskedInput
    },
    computed: {
      state1 () {
        return validation.phoneNumberBool(this.phone)
      },
      state2 () {
        return validation.long(this.model.hint)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true) {
          _this.show = true
          _this.model.phone = _this.phone.replace(/\D/g, '').replace('7', '')
          api.put(_this.urls.emergencyservice + '/' + _this.$route.params.id, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/emergencyservice/list')
              }, 1000)
            }
          })
        }
      },
      goBack () {
        this.$router.go(-1)
      }
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.emergencyservice + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
        _this.phone = `+7 (${_this.model.phone.substring(0, 3)}) ${_this.model.phone.substring(3, 6)}-${_this.model.phone.substring(6, 8)}-${_this.model.phone.substring(8, 10)}`
      })
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'emergencyService'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    watch: {
    },
    data () {
      return {
        model: {
        },
        date: '',
        phone: '',
        posts: [],
        accessList: {},
        urls: {
          emergencyservice: 'emergencyservice'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
