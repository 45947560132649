<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание названия комнаты</strong>
          </template>
          <b-form-group
            label="Наименование комнаты"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state1">
            <b-form-input id="id" type="text" :state="state1" v-model="model.name"></b-form-input>
          </b-form-group>
           <b-form-group
            label="Тип комнаты"
            label-for="category"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.roomTypeId)"
            :state="state2">
            <b-form-select id="category"
                           :options="roomtypes"
                           v-model="model.roomTypeId"
                           :state="state2">
              <template slot="first">
                <option :value="null" disabled>Выберите тип комнаты</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Иконка в активном состоянии"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-form-file id="file"
                         :plain="true"
                         v-model="lightFile"
                         :multiple="false"
                         accept="image/x-png">
            </b-form-file>
          </b-form-group>
          <b-form-group
            v-if="model.lightIcon !== ''"
            label=""
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <img v-if="model.lightIcon !== null" :src="model.lightIcon" alt="hex shorthand color" class="chat__picture m-1 pb-3">
          </b-form-group>
          <b-form-group
            label="Иконка в неактивном состоянии"
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <b-form-file id="file"
                         :plain="true"
                         v-model="darkFile"
                         :multiple="false"
                         accept="image/x-png">
            </b-form-file>
          </b-form-group>
          <b-form-group
            v-if="model.darkIcon !== ''"
            label=""
            label-for="file"
            :label-cols="3"
            :horizontal="true">
            <img v-if="model.darkIcon !== null" :src="model.darkIcon" alt="hex shorthand color" class="chat__picture m-1 pb-3">
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Название комнаты успешно добавлено">
      <p class="my-4">Название комнаты успешно добавлено</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'
  import consts from '../../config/const'

  export default {
    name: 'New',
    components: {
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.model.name)
      },
      state2 () {
        return validation.numId(this.model.roomTypeId)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (this.state1 && this.state2) {
          _this.show = true
          if (_this.model.lightIcon === '') {
            _this.model.lightIcon = null
          }
          if (_this.model.darkIcon === '') {
            _this.model.darkIcon = null
          }
          api.post(_this.urls.roomname, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/roomname/list')
              }, 1000)
            }
          })
        }
      },
      getRoomTypes () {
        let _this = this
        api.get(_this.urls.roomtype).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.roomtypes.push(temp)
            })
          }
        })
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'roomname'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted () {
      this.getRoomTypes()
    },
    watch: {
      'lightFile': function (newFile, oldFile) {
        let _this = this
        if (newFile) {
          let fd = new FormData()
          fd.append('file', newFile)
          api.post(_this.urls.file, fd).then(function (response) {
            let jData = response.data
            _this.model.lightIcon = consts.storageUrl + '/' + jData.data.url
          })
        } else {
          _this.model.lightIcon = ''
        }
      },
      'darkFile': function (newFile, oldFile) {
        let _this = this
        if (newFile) {
          let fd = new FormData()
          fd.append('file', newFile)
          api.post(_this.urls.file, fd).then(function (response) {
            let jData = response.data
            _this.model.darkIcon = consts.storageUrl + '/' + jData.data.url
          })
        } else {
          _this.model.darkIcon = ''
        }
      }
    },
    data () {
      return {
        model: {
          lightIcon: '',
          darkIcon: ''
        },
        accessList: {},
        roomtypes: [],
        lightFile: '',
        darkFile: '',
        urls: {
          roomname: 'roomname',
          roomtype: 'roomtype',
          file: 'file'
        },
        consts: consts,
        validation: validation,
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется'
      }
    }
  }
</script>
