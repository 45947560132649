<template>
  <b-card :header="caption">
    <b-row v-if="accessList.isWrite === true">
      <b-col lg="3">
        <b-btn v-if="false" class="mb-3 btn-success model_btn--add_new" @click.stop="newItem">Создать</b-btn>
      </b-col>
    </b-row>
    <b-table :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
      <template slot="buttons" slot-scope="data">
        <div class="table__one_buttons">
          <b-btn v-if="false" size="sm" class="change_size btn-warning" @click.stop="detail(data.item.id)"><i class="fa fa-pencil fa-2x"></i></b-btn>
          <b-btn size="sm" v-if="accessList.isDelete === true && false" class="change_size btn-danger" @click.stop="del(data.item.id)"><i class="fa fa-trash-o fa-2x"></i></b-btn>
        </div>
      </template>
    </b-table>
    <nav class="d-flex">
      <b-pagination :total-rows="getRowCount(items)" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
    </nav>
  </b-card>
</template>

<script>
  import api from '../../../config/api'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [
        ],
        accessList: {},
        fields: [
          {key: 'id', label: '#'},
          {key: 'name', label: 'Наименование'},
          {key: 'responseTimes', label: 'Время отклика(дней)'},
          {key: 'description', label: 'Описание'},
          {key: 'buttons', label: ' '}
        ],
        currentPage: 1,
        perPage: 20,
        totalRows: 0,
        urls: {
          list: 'requestnature'
        }
      }
    },
    methods: {
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      getRowCount (items) {
        return items.length
      },
      newItem () {
        let _this = this
        _this.$router.push('new/')
      },
      detail (id) {
        var _this = this
        _this.$router.push('/service_requests/requestnature/edit/' + id)
      },
      del (id) {
        var _this = this
        api.delete(_this.urls.list + '/' + id).then(function (response) {
          for (let i = 0; i < _this.items.length; i++) {
            if (String(_this.items[i].id) === String(id)) {
              _this.items.splice(i, 1)
            }
          }
        })
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'requestNat'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      let query = {
        removed: 0
      }
      api.get(_this.urls.list, query).then(function (response) {
        let jData = response.data
        if (jData.status) {
          _this.items = jData.data
        }
      })
    }
  }
</script>
