<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header class="mb-3">
            <strong>История изменений заявки <b-link :to="{ path: '/service_requests/edit/' + $route.params.id }">№{{ $route.params.id }}</b-link></strong>
          </template>
          <div class="container ml-0 mb-3 mt-3">
            <div class="spinner" v-if="!showTable"><i class="fa fa-spinner fa-spin fa-3x"></i></div>
            <div class="table_block">
              <b-table id="table_log"
                       :items="items"
                       :fields="fields"
                       :per-page="perPage"
                       :current-page="1"
                       v-if="showTable"
              >
                <template slot="createdAt" slot-scope="data">
                  {{ formatDate(data.item.createdAt) }}
                </template>
                <template slot="user" slot-scope="data">
                  <b-link :to="{ path: '/employee/edit/' + data.item.user.id }">
                    {{ data.item.user.firstName }}&nbsp;{{ data.item.user.lastName }}
                  </b-link>
                </template>
                <template slot="changeSet" slot-scope="data">
                  <div v-if="data.item.action === 'insert'">
                    <p><b>Создание заявки</b></p>
                    <b-container fluid v-for="(item, key) in JSON.parse(data.item.changeSet)">
                      <b-row><b>{{ item.label }}</b></b-row>
                      <b-row class="justify-content-md-start">
                        <b-col>{{ item.change[1] || '&lt;пусто&gt;'  }}</b-col>
                      </b-row>
                    </b-container>
                  </div>
                  <div v-if="data.item.action === 'update'">
                    <b-container fluid v-for="(item, key) in JSON.parse(data.item.changeSet)">
                      <b-row><b>{{ item.label }}</b></b-row>
                      <b-row class="justify-content-md-start">
                        <b-col>{{ item.change[0] || '&lt;пусто&gt;' }}</b-col>
                        <b-col md="auto">&rarr;</b-col>
                        <b-col>{{ item.change[1] || '&lt;пусто&gt;'  }}</b-col>
                      </b-row>
                    </b-container>
                  </div>
                </template>
              </b-table>
              <p v-if="!totalRows">Нет записей.</p>

              <b-pagination
                v-if="totalRows > 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="table_log"
                @input="changePage()"
              ></b-pagination>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import api from '../../config/api'
  import moment from 'moment'

  export default {
    name: 'Log',
    data () {
      return {
        items: [],
        fields: [
          {key: 'createdAt', label: 'Дата', thStyle: {'width': '15%'}},
          {key: 'user', label: 'Пользователь', thStyle: {'width': '20%'}},
          {key: 'changeSet', label: 'Список изменений'}
        ],
        currentPage: 1,
        perPage: 5,
        totalRows: 0,
        showTable: false
      }
    },
    methods: {
      getData () {
        this.showTable = false
        api.get('servicerequest/' + this.$route.params.id + '/log', {page: this.currentPage, limit: this.perPage})
          .then(response => {
            // console.log(response)
            const data = response.data
            if (data.status === true) {
              this.items = data.data
              this.totalRows = data.meta.pages * this.perPage
              this.showTable = true
            } else {
              this.totalRows = 0
              this.showTable = false
            }
          })
          .catch(error => {
            console.log(error)
            this.showTable = false
          })
      },
      formatDate (value) {
        if (value) {
          return moment(String(value)).format('DD.MM.YYYY HH:mm:ss')
        }
      },
      changePage () {
        this.getData()
        window.scrollTo(0, 0)
      }
    },
    mounted () {
      this.getData()
    }
  }
</script>
