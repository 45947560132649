<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Редактирование счётчика</strong>
          </template>
          <b-form-group
            label="Демо режим"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isDemo" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Тип счётчика"
            label-for="status"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.meterTypeId)"
            :state="state1">
            <b-form-select id="status"
                           :options="meterType"
                           v-model="model.meterTypeId"
                           :state="state1">
              <template slot="first">
                <option :value="null" disabled>Выберите тип счётчика</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Жилищный комплекс"
            label-for="housingComplex"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(housingComplexId)"
            :state="state2">
            <b-form-select id="housingComplex"
                           :options="housingComplex"
                           placeholder="Выберите ЖК"
                           v-model="housingComplexId"
                           :state="state2">
              <template slot="first">
                <option :value="null" disabled>Выберите ЖК</option>
              </template>
            </b-form-select>
          </b-form-group>
          <!--<b-form-group-->
            <!--label="Улица"-->
            <!--label-for="street"-->
            <!--:label-cols="3"-->
            <!--:horizontal="true"-->
            <!--:invalid-feedback="validation.notSelect(streetId)"-->
            <!--:state="state3">-->
            <!--<b-form-select id="street"-->
                           <!--:options="street"-->
                           <!--placeholder="Выберите улицу"-->
                           <!--v-model="streetId"-->
                           <!--:state="state3">-->
              <!--<template slot="first">-->
                <!--<option :value="null" disabled>Выберите улицу</option>-->
              <!--</template>-->
            <!--</b-form-select>-->
          <!--</b-form-group>-->
          <b-form-group
            label="Дом"
            label-for="building"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.buildingId)"
            :state="state4">
            <b-form-select id="building"
                           :options="building"
                           placeholder="Выберите дом"
                           v-model="model.buildingId"
                           :state="state4">
              <template slot="first">
                <option :value="null" disabled>Выберите дом</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Номер счётчика"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.number)"
            :state="state5">
            <b-form-input id="id" type="text" :state="state5" v-model="model.number"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер модема"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.modemNumber)"
            :state="state6">
            <b-form-input id="name" type="text" :state="state6" v-model="model.modemNumber"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Дата установки"
            label-for="installationDate"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.installationDate)"
            :state="state7">
            <datetime type="datetime"
                      id="installationDate"
                      v-model="date.installationDate"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Укажите дату установки счётчика"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
                      :state="state7">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Последнее обновление"
            label-for="lastTimestamp"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.lastTimestamp)"
            :state="state8">
            <datetime type="datetime"
                      id="lastTimestamp"
                      v-model="date.lastTimestamp"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Укажите дату последнего обновления"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state8, valid__dateTime: state8 === true}"
                      :state="state8">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Последняя поверка"
            label-for="lastTimestamp"
            :label-cols="3"
            :horizontal="true">
            <datetime type="datetime"
                      id="lastTimestamp"
                      v-model="date.verificDate"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Укажите дату последней поверки"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Регистратор"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-button-group class="btn_group__mg">
              <b-button v-for="i in model.registrators" @click="changeRegistrator(i.name)" variant="info" :pressed="focusRegistrator === i.name">{{i.name}}</b-button>
            </b-button-group>
          </b-form-group>
          <b-form-group
            label="Временной промежуток"
            label-for="date"
            :label-cols="3"
            :horizontal="true">
            <b-button-group class="btn_group__mg">
              <b-button @click="changeType('hour')" variant="info" :pressed="pickerType === 'day'">По часам</b-button>
              <b-button @click="changeType('day')" variant="info" :pressed="pickerType === 'month'">По дням</b-button>
              <b-button @click="changeType('month')" variant="info" :pressed="pickerType === 'year'">По месяцам</b-button>
            </b-button-group>
            <datepicker :minimumView="pickerType" :maximumView="pickerType" :initialView="pickerType" v-model="date.history" :format="customFormatter" input-class="data_picker__input" language="ru" placeholder="Выберите временной промежуток"></datepicker>
          </b-form-group>
          <b-table :hover="true" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields">
          </b-table>
          <b-btn size="sm" v-if="accessList.isUpdate === true && false" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
          <b-btn size="sm" v-if="accessList.isUpdate === false" @click.prevent="goBack" text="Button" variant="success">Назад</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Счётчик успешно изменён">
      <p class="my-4">Счётчик успешно изменён</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import Datepicker from 'vuejs-datepicker'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading,
      datetime: Datetime,
      Datepicker
    },
    computed: {
      state1 () {
        return validation.numId(this.model.meterTypeId)
      },
      state2 () {
        return validation.numId(this.housingComplexId)
      },
      // state3 () {
      //   return validation.numId(this.streetId)
      // },
      state4 () {
        return validation.numId(this.model.buildingId)
      },
      state5 () {
        return validation.long(this.model.number)
      },
      state6 () {
        return validation.long(this.model.modemNumber)
      },
      state7 () {
        return validation.dateSelectBool(this.date.installationDate)
      },
      state8 () {
        return validation.dateSelectBool(this.date.lastTimestamp)
      }
    },
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      changeRegistrator (registrator) {
        let _this = this
        _this.focusRegistrator = registrator
      },
      customFormatter (date) {
        let _this = this
        moment.locale('ru')
        if (_this.pickerType === 'day') {
          return moment(date).format('DD.MM.YYYY')
        } else if (_this.pickerType === 'month') {
          return moment(date).format('MM.YYYY')
        } else if (_this.pickerType === 'year') {
          return moment(date).format('YYYY')
        }
      },
      changeType (key) {
        let _this = this
        switch (key) {
          case 'hour':
            _this.pickerType = 'day'
            break
          case 'day':
            _this.pickerType = 'month'
            break
          case 'month':
            _this.pickerType = 'year'
            break
        }
      },
      whenTabInFocus () {
        let _this = this
        let query = {
          id: _this.model.buildingId
        }
        api.get(_this.urls.buildingAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.hComplexId = jData.data.housingComplexId
          }
          return api.get(_this.urls.housingComplex)
        }).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.id === _this.hComplexId) {
                _this.hComplex = item.name
              }
            })
          }
        })
      },
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true && _this.state4 === true && _this.state5 === true && _this.state6 === true && _this.state7 === true && _this.state8 === true) {
          _this.whenTabInFocus()
          _this.show = true
          _this.model.installationDate = moment(_this.date.installationDate).format('X')
          _this.model.lastTimestamp = moment(_this.date.lastTimestamp).format('X')
          if (_this.date.verificDate !== '') {
            _this.model.verificDate = moment(_this.date.verificDate).format('X')
          }
          api.put(_this.urls.meter + '/' + _this.$route.params.id, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({ path: '/meterhouse/list', query: { tab: _this.hComplex } })
              }, 1000)
            }
          })
        }
      },
      getTypes () {
        let _this = this
        api.get(_this.urls.metertype).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.meterType.push(temp)
            })
          }
        })
      },
      getBuildingAddress () {
        let _this = this
        let query = {
          id: _this.model.buildingId
        }
        api.get(_this.urls.buildingAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.housingComplexId = jData.data.housingComplexId
            _this.streetId = jData.data.streetId
          }
        }).then(() => {
          _this.getHousingComplex()
        })
      },
      getHousingComplex () {
        let _this = this
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.housingComplex.push(temp)
            })
          }
        }).then(() => {
          _this.getStreet()
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          _this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this
        if (_this.housingComplexId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.housingComplexId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    _this.building.push(temp)
                  }
                })
              }
            })
          })
          _this.firstLoadForm = false
        }
      },
      getMeterData () {
        var _this = this
        let query = {
          mrId: _this.currentPage,
          limit: _this.perPage
        }
        api.get(_this.urls.list, query).then(function (response) {
          let jMeta = response.data.meta
          _this.totalRows = jMeta.pages * _this.perPage - 1
          let jData = response.data
          if (jData.status) {
            _this.items = jData.data
            _this.items.forEach((item) => {
              item.requestType = item.requestType !== null ? item.requestType.name : ''
              item.id = item.id
              item.createDate = moment(item.createDate).format('DD.MM.YYYY HH:mm')
              item.requestNat = item.requestNat !== null ? item.requestNat.name : ''
              item.category = item.category !== null ? item.category.name : ''
              item.status = item.status !== null ? item.status.name : ''
              item.user = item.user.lastName + ' ' + item.user.firstName
              if (item.statusId === 1) {
                item._rowVariant = 'info'
              }
            })
          }
        })
      },
      getHistory () {
        let _this = this
        let query = {
          mrId: _this.model.id,
          timestamp: moment(_this.date.history).format('X'),
          limits: _this.pickerType.toUpperCase()
        }
        api.get(_this.urls.list, query).then(function (response) {
          let jData = response.data
          _this.items = jData.data
          _this.items.consumption = _this.items.begin - _this.items.end
        })
      },
      goBack () {
        this.$router.go(-1)
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'meterHouse'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      api.get(_this.urls.meter + '/' + _this.$route.params.id).then(function (response) {
        let jData = response.data
        _this.model = jData.data
        _this.date.lastTimestamp = moment(_this.model.lastTimestamp * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
        _this.date.installationDate = moment(_this.model.installationDate * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
        _this.focusRegistrator = _this.model.registrators[0].name
        _this.idRegistrator = _this.model.registrators[0].id
        if (_this.model.verificDate !== null) {
          _this.date.verificDate = moment(_this.model.verificDate * 1000).format('YYYY-MM-DDTHH:mm:ss.SSS')
        }
      }).then(() => {
        _this.getMeterData()
        _this.getTypes()
        _this.getBuildingAddress()
        _this.getHistory()
      })
    },
    watch: {
      'date.history': function () {
        let _this = this
        _this.getHistory()
      },
      focusRegistrator: function () {
        let _this = this
        _this.getHistory()
      },
      housingComplexId: function () {
        let _this = this
        this.building = []
        this.premise = []
        if (!this.firstLoadForm) {
          this.model.buildingId = 0
        }
        this.getBuilding()
        // this.getPremise()
      },
      streetId: function () {
        let _this = this
        if (_this.streetId !== '') {
          this.building = []
          this.premise = []
          this.getBuilding()
          // this.getPremise()
        }
      }
    },
    data () {
      return {
        model: {
          meterTypeId: 0,
          buildingId: 0,
          number: '',
          modemNumber: ''
        },
        accessList: {},
        pickerType: 'day',
        meterType: [],
        posts: [],
        items: [],
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        housingComplexId: 0,
        streetId: 0,
        hComplex: '',
        hComplexId: 0,
        firstLoadForm: true,
        fields: [
          {key: 'timestamp', label: 'Дата'},
          {key: 'begin', label: 'Начало'},
          {key: 'consumption', label: 'Расход'},
          {key: 'end', label: 'Конец'}
        ],
        date: {
          installationDate: '',
          lastTimestamp: '',
          history: moment().valueOf(),
          verificDate: ''
        },
        urls: {
          meter: 'meter',
          metertype: 'metertype',
          buildingAddress: 'building/buildingaddress',
          housingComplex: 'housingComplex',
          street: 'street',
          building: 'building',
          list: 'measurementhistory'
        },
        show: false,
        focusRegistrator: '',
        idRegistrator: '',
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
