<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="4">
          <b-card-group @keypress="formKeyPress">
            <b-card no-body class="p-4">
              <b-card-body>
                <h1>Вход</h1>
                <p class="text-muted">УК Железно, административная панель</p>
                <b-input-group class="mb-3">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-user"></i></span></div>
                  <masked-input
                    type="tel"
                    name="phone"
                    class="form-control"
                    v-model="phone"
                    :mask="['+','7', ' ','(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-',/\d/, /\d/]"
                    :guide="true"
                    :showMask="true"
                    :keepCharPositions="true"
                  >
                  </masked-input>
                </b-input-group>
                <b-input-group class="mb-4">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-lock"></i></span></div>
                  <input type="password" class="form-control" v-model="password" placeholder="Password">
                </b-input-group>
                <b-row>
                  <b-col cols="6">
                    <b-button variant="primary" @click="sendLoginForm" class="px-4">Войти</b-button>
                  </b-col>
                </b-row>
                <router-link to="/auth/contractor" class="login__link">Вход для исполнителей</router-link>
              </b-card-body>
            </b-card>
          </b-card-group>
          <b-modal ref="myModalRef" hide-footer title="Неверный логин или пароль">
            <p class="my-4">Неверный логин или пароль</p>
          </b-modal>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  import MaskedInput from 'vue-text-mask'
  import api from '../../config/api'
  import consts from '../../config/const.js'

  export default {
    name: 'Login',
    components: {
      MaskedInput
    },
    data () {
      return {
        phone: '',
        password: '',
        urls: {
          login: 'auth/v1/adminLogin',
          list: 'servicerequest'
        },
        model: [],
        consts: consts
      }
    },
    methods: {
      sendLoginForm () {
        var phoneMatch = this.phone.match(/^[+][7]\s[(]\d{3}[)]\s\d{3}[-]\d{2}[-]\d{2}$/)
        var phoneReady = this.phone.replace(/\D/g, '')
        phoneReady = phoneReady.replace('7', '')
        if (phoneMatch !== null && this.password) {
          api.post(this.urls.login, {
            phone: phoneReady,
            password: this.password
          }).then((response) => {
            let jData = response.data
            if (jData.status || (jData.status === undefined && jData.data)) {
              // status true..login..saveingTokenToCookie
              this.$cookie.set('authToken', jData.data.token, 1)
              this.$cookie.set('role', jData.data.roles[0], 1)
              this.$cookie.set('isAdmin', jData.data.roles[0], 1)
              this.$cookie.set('guid', jData.data.client.guid, 1)
              this.$store.state.user = jData.data
              localStorage.setItem('userId', jData.data.id)
              localStorage.setItem('userName', jData.data.firstName + ' ' + jData.data.lastName)
              if (this.$route.params.id) {
                location.href = `/service_requests/edit/${this.$route.params.id}`
              } else {
                location.href = '/'
              }
            } else {
              this.$refs.myModalRef.show()
              setTimeout(() => {
                this.$refs.myModalRef.hide()
              }, 3000)
            }
          })
        }
        // if ()
      },
      formKeyPress: function (e) {
        if (e.keyCode === 13) {
          this.sendLoginForm()
        }
      }
    },
    mounted: function () {
    }
  }
</script>

eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTg2LCJsYXN0TmFtZSI6ItCT0L7Qu9C-0LLQuNC9IiwiZmlyc3ROYW1lIjoi0J7Qu9C10LMiLCJwYXRyb255bWljTmFtZSI6ItCS0LDRgdC40LvRjNC10LLQuNGHIiwicm9sZUlkIjo5LCJwYXNzd29yZCI6IiQyYSQxMCRNb1U4T21JR3RmU1hCR2pZMWxFSFVPaGdsUXEycnhqTXF6d1NyYWI3RGx3SHFZZEFrM25VeSIsImVtYWlsIjoiZ29sb3ZpbkBhemJ1a2FieXRhLmNvbSIsInBob25lIjoiOTEyNzE5ODM0NCIsInJvbGUiOiJhZG1pbl9JbnpoZW5lciIsImlhdCI6MTU1NTA2NTMwNH0.ACb-uR9ukav6esElyVs3mIvXcVme_-IoySpbBnufgYU




