<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание контентной страницы</strong>
          </template>
          <b-form-group
            label="Наименование"
            label-for="phone"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.name)"
            :state="state1">
            <b-form-input id="phone" type="text" :state="state1" v-model="model.name"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Содержимое"
            label-for="content"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.content)"
            :state="state2">
            <vue-editor :content="model.content"
                        :state="state2"
                        v-model="model.content"
                        :editorToolbar="customToolbar"
                        v-bind:class="{invalid__dateTime: !state2, valid__dateTime: state2 === true}">
            </vue-editor>
          </b-form-group>
          <b-form-group
            label="Ключ"
            label-for="description"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.keyWord)"
            :state="state3">
            <b-form-input id="description" type="text" :state="state3" v-model="model.keyWord"></b-form-input>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Контентая страница успешно добавлена">
      <p class="my-4">Контентая страница успешно добавлена</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import customToolbar from '../../config/editor'
  import { VueEditor } from 'vue2-quill-editor'
  import loading from 'vue-full-loading'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      VueEditor,
      loading
    },
    computed: {
      state1 () {
        return validation.long(this.model.name)
      },
      state2 () {
        return validation.long(this.model.content)
      },
      state3 () {
        return validation.long(this.model.keyWord)
      }
    },
    methods: {
      sendForm () {
        let _this = this
        if (_this.state1 === true && _this.state2 === true && _this.state3 === true) {
          _this.show = true
          api.post(_this.urls.contentpage, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push('/contentpage/list')
              }, 1000)
            }
          })
        }
      }
    },
    mounted: function () {

    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'contentPage'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    watch: {
    },
    data () {
      return {
        model: {
          name: '',
          content: '',
          keyWord: ''
        },
        accessList: {},
        date: '',
        posts: [],
        urls: {
          contentpage: 'contentpage'
        },
        customToolbar: customToolbar,
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
