<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <template #header>
            <strong>Чат</strong>
            <b-btn size="sm" @click.prevent="goBack" class="float-right" text="Button" variant="success">Вернуться к списку заявок</b-btn>
          </template>
        <b-card-body id="nav-scroller"
                      ref="content"
                      :offset="200"
                      style="position:relative; height:60vh; overflow-y:scroll; display: flex; flex-direction: column;">
            <b-link href="" class="middle" @click.prevent="loadMore()">Загрузить ещё</b-link>
            <div v-viewer="{toolbar: false, navbar: false}" v-for="i in messages" class="leftUser">
              <h4 v-if="i.user !== null && (i.contentText !== '' || i.attachment !== null)" v-bind:class="{rightUser__header: isUser(i.user.id)}" id="fat">{{i.user.firstName.concat(' ' + i.user.lastName).concat()}}</h4>
              <h4 v-if="i.user === null && (i.contentText !== '' || i.attachment !== null)" id="fat">Системное сообщение:</h4>
              <p class="m-1 textMessage" v-if="i.contentText !== ''">{{ i.contentText }}</p>
              <img v-if="i.attachment !== null" :src="consts.storageUrl + '/' + i.attachment.imgPreview" alt="hex shorthand color" class="chat__picture m-1 pb-3">
              <hr class="chat__delimeter" v-if="i.contentText !== '' || i.attachment !== null">
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import vueCookie from 'vue-cookie'
  import api from '../../../config/api'
  import consts from '../../../config/const'
  import Viewer from 'v-viewer'
  import Vue from 'vue'
  import moment from 'moment'

  Vue.use(Viewer)

  export default {
    name: 'Chat',
    data () {
      return {
        isConnected: false,
        socketMessage: '',
        myIdIs: '',
        accessList: {},
        messages: [],
        msg: '',
        delItem: '',
        msgInputDisabled: false,
        pictureMsg: 0,
        urls: {
          list: 'chat/list',
          file: 'file'
        },
        header: '',
        consts: consts,
        images: [],
        file: '',
        token: null,
        socket: null
      }
    },
    watch: {
      file: function (value) {
        if (value !== '') {
          let _this = this
          let fd = new FormData()
          fd.append('file', value)
          api.post(_this.urls.file, fd).then(function (response) {
            let jData = response.data
            let pict = {
              height: jData.data.height,
              id: jData.data.id,
              url: jData.data.url,
              width: jData.data.width
            }
            _this.images.push(pict.url)
            _this.pictureMsg = pict.id
            _this.msg = value.name
            _this.msgInputDisabled = true
          })
        }
      }
    },
    methods: {
      goBack () {
        this.$router.push('/requests')
      },
      addBreak () {
        this.msg += '\r\n'
      },
      showModal (id) {
        var _this = this
        this.delItem = id
        _this.$refs.myModalRef.show()
      },
      hideModal () {
        this.$refs.myModalRef.hide()
      },
      isUser (id) {
        if (String(this.myIdIs) === String(id)) {
          return true
        } else {
          return false
        }
      },
      cancelPicture () {
        let _this = this
        _this.images.splice(-1, 1)
        _this.pictureMsg = 0
        _this.msg = ''
        _this.file = ''
        _this.msgInputDisabled = false
        document.getElementById('upload-photo').value = ''
      },
      loadMore () {
        let _this = this
        let msgData = {
          chatId: _this.$route.params.id,
          messageId: _this.messages[0].id
        }
        _this.$socket.emit('loadMore', msgData)
      },
      scrollToEnd () {
        let container = this.$el.querySelector('#nav-scroller')
        container.scrollTop = container.scrollHeight
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'chat'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.myIdIs = localStorage.getItem('userId')
      _this.$socket.on('message', function (msg) {
        let promise = new Promise((resolve, reject) => {
          if (String(msg.chatId) === String(_this.$route.params.id)) {
            msg.messages[0].timestamp = moment(msg.messages[0].timestamp * 1000).format('DD.MM.YYYY HH:mm')
            _this.messages.push(msg.messages[0])
            resolve()
          }
        })
        promise.then(() => {
          _this.scrollToEnd()
          let readMsg = {
            chatId: _this.$route.params.id,
            messageId: _this.messages[_this.messages.length - 1].id
          }
          if (String(_this.myIdIs) !== String(msg.messages[0].user.id)) {
            _this.$socket.emit('read', readMsg)
          }
        })
      })
      _this.$socket.on('loadMore', function (msg) {
        msg.messages.forEach((item) => {
          item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY HH:mm')
          if (msg.deletedMessage.indexOf(item.id) === -1) {
            _this.messages.unshift(item)
          }
        })
      })
      _this.$socket.on('deleteMessage', function (msg) {
        for (let i = 0; i < _this.messages.length; i++) {
          if (String(_this.messages[i].id) === String(msg.messageId)) {
            _this.messages.splice(i, 1)
          }
        }
      })
      let msgData = {
        chatId: _this.$route.params.id
      }
      _this.$socket.on('update', function (msg) {
        let promise = new Promise((resolve, reject) => {
          if (msg.messages.length > 1) {
            if (!((parseInt(msg.messages[0].id, 10)) < parseInt(msg.messages[1].id, 10))) {
              msg.messages.reverse()
            }
          }
          msg.messages.forEach((item) => {
            item.timestamp = moment(item.timestamp * 1000).format('DD.MM.YYYY HH:mm')
            if (item.attachment !== null) {
              _this.images.push(item.attachment.url)
            }
            if (msg.deletedMessage.indexOf(item.id) === -1) {
              _this.messages.push(item)
            }
          })
          resolve()
        })
        promise.then(result => {
          _this.scrollToEnd()
          let readMsg = {
            chatId: _this.$route.params.id,
            messageId: _this.messages[_this.messages.length - 1].id
          }
          console.log(msg.messages)
          console.log(_this.myIdIs, msg.messages[_this.messages.length - 1].user.id)
          if (String(_this.myIdIs) !== String(msg.messages[_this.messages.length - 1].user.id)) {
            _this.$socket.emit('read', readMsg)
          }
        })
      })
      _this.$socket.emit('update', msgData)
    },
    beforeDestroy () {
      this.$socket.removeListener('update')
      this.$socket.removeListener('message')
      this.$socket.removeListener('loadMore')
      this.$socket.removeListener('deleteMessage')
    }
  }
</script>

