<template>
  <div class="animated fadeIn">
    <template>
      <loading
        :show="show"
        :label="label">
      </loading>
    </template>
    <b-row>
      <b-col md="12">
        <b-card>
          <template #header>
            <strong>Создание счётчик</strong>
          </template>
          <b-form-group
            label="Демо режим"
            label-for="premise"
            :label-cols="3"
            :horizontal="true">
            <label class="switch switch-3d switch-primary">
              <input type="checkbox" v-model="model.isDemo" class="switch-input" checked>
              <span class="switch-label" data-on="Да" data-off="Нет"></span>
              <span class="switch-handle"></span>
            </label>
          </b-form-group>
          <b-form-group
            label="Тип счётчика"
            label-for="status"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.meterTypeId)"
            :state="state1">
            <b-form-select id="status"
                           :options="meterType"
                           v-model="model.meterTypeId"
                           :state="state1">
              <template slot="first">
                <option :value="null" disabled>Выберите тип счётчика</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Жилищный комплекс"
            label-for="housingComplex"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(housingComplexId)"
            :state="state2">
            <b-form-select id="housingComplex"
                           :options="housingComplex"
                           placeholder="Выберите ЖК"
                           v-model="housingComplexId"
                           :state="state2">
              <template slot="first">
                <option :value="null" disabled>Выберите ЖК</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Дом"
            label-for="building"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.notSelect(model.buildingId)"
            :state="state4">
            <b-form-select id="building"
                           :options="building"
                           placeholder="Выберите дом"
                           v-model="model.buildingId"
                           :state="state4">
              <template slot="first">
                <option :value="null" disabled>Выберите здание</option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group
            label="Номер счётчика"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.number)"
            :state="state5">
            <b-form-input id="id" type="text" :state="state5" v-model="model.number"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Номер модема"
            label-for="name"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.empty(model.modemNumber)"
            :state="state6">
            <b-form-input id="name" type="text" :state="state6" v-model="model.modemNumber"></b-form-input>
          </b-form-group>
          <b-form-group
            label="Дата установки"
            label-for="installationDate"
            :label-cols="3"
            :horizontal="true"
            :invalid-feedback="validation.dateSelect(date.installationDate)"
            :state="state7">
            <datetime type="datetime"
                      id="installationDate"
                      v-model="date.installationDate"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Укажите дату установки счётчика"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}"
                      v-bind:class="{invalid__dateTime: !state7, valid__dateTime: state7 === true}"
                      :state="state7">
            </datetime>
          </b-form-group>
          <b-form-group
            label="Последняя поверка"
            label-for="verificDate"
            :label-cols="3"
            :horizontal="true">
            <datetime type="datetime"
                      id="verificDate"
                      v-model="date.verificDate"
                      class="theme-orange"
                      input-class="dataTimePick"
                      placeholder="Укажите дату последней поверки"
                      moment-locale="ru"
                      :phrases="{ok: 'OK', cancel: 'Отменить'}">
            </datetime>
          </b-form-group>
          <b-btn size="sm" @click.prevent="sendForm" text="Button" variant="success">Сохранить</b-btn>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="myModalRef" hide-footer title="Счётчик успешно добавлен">
      <p class="my-4">Счётчик успешно добавлен</p>
    </b-modal>
  </div>
</template>

<script>
  import api from '../../config/api'
  import loading from 'vue-full-loading'
  import moment from 'moment'
  import { Datetime } from 'vue-datetime'
  import validation from '../../config/validation'

  export default {
    name: 'New',
    components: {
      loading,
      datetime: Datetime
    },
    computed: {
      state1 () {
        return validation.numId(this.model.meterTypeId)
      },
      state2 () {
        return validation.numId(this.housingComplexId)
      },
      state4 () {
        return validation.numId(this.model.buildingId)
      },
      state5 () {
        return validation.long(this.model.number)
      },
      state6 () {
        return validation.long(this.model.modemNumber)
      },
      state7 () {
        return validation.dateSelectBool(this.date.installationDate)
      }
    },
    methods: {
      whenTabInFocus () {
        let _this = this
        let query = {
          id: _this.model.buildingId
        }
        api.get(_this.urls.buildingAddress, query).then(function (response) {
          let jData = response.data
          if (jData.status) {
            _this.hComplexId = jData.data.housingComplexId
          }
          return api.get(_this.urls.housingComplex)
        }).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach((item) => {
              if (item.id === _this.hComplexId) {
                _this.hComplex = item.name
              }
            })
          }
        })
      },
      sendForm () {
        let _this = this
        if (_this.state1 && _this.state2 && _this.state4 && _this.state5 && _this.state6 && _this.state7) {
          _this.whenTabInFocus()
          _this.show = true
          _this.model.installationDate = moment(_this.date.installationDate).format('X')
          if (_this.date.verificDate !== '') {
            _this.model.verificDate = moment(_this.date.verificDate).format('X')
          }
          api.post(_this.urls.meter, _this.model).then(function (response) {
            _this.show = false
            if (response.status) {
              _this.$refs.myModalRef.show()
              setTimeout(function () {
                _this.$refs.myModalRef.hide()
                _this.$router.push({ path: '/meterhouse/list', query: { tab: _this.hComplex } })
              }, 1000)
            }
          })
        }
      },
      getTypes () {
        let _this = this
        api.get(_this.urls.metertype).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.meterType.push(temp)
            })
          }
        })
      },
      getHousingComplex () {
        let _this = this
        api.get(_this.urls.housingComplex).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.housingComplex.push(temp)
            })
          }
        }).then(() => {
          this.getStreet()
        })
      },
      getStreet () {
        let _this = this
        api.get(_this.urls.street).then(function (response) {
          let jData = response.data
          if (jData.status) {
            jData.data.forEach(function (spec) {
              let temp = {}
              temp.value = spec.id
              temp.text = spec.name
              _this.street.push(temp)
            })
          }
        }).then(() => {
          this.getBuilding()
        })
      },
      getBuilding () {
        let _this = this
        if (_this.housingComplexId !== '' && _this.streetId !== '') {
          _this.street.forEach((street) => {
            let query = {
              hcId: _this.housingComplexId,
              streetId: street.value
            }
            api.get(_this.urls.building, query).then(function (response) {
              let jData = response.data
              if (jData.status) {
                jData.data.forEach(function (spec) {
                  if (spec.id !== null) {
                    let temp = {}
                    temp.value = spec.id
                    temp.text = 'ул.' + street.text + ' д.' + spec.objectNumber
                    _this.building.push(temp)
                  }
                })
              }
            })
          })
        }
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'meterHouse'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      _this.getTypes()
      _this.getHousingComplex()
    },
    watch: {
      housingComplexId: function () {
        this.building = []
        this.premise = []
        this.getBuilding()
        // this.getPremise()
      }
      // streetId: function () {
      //   this.building = []
      //   this.premise = []
      //   this.getBuilding()
      //   this.getPremise()
      // }
    },
    data () {
      return {
        model: {
          meterTypeId: 0,
          buildingId: 0,
          number: '',
          modemNumber: '',
          isDemo: false
        },
        accessList: {},
        meterType: [],
        posts: [],
        housingComplex: [
        ],
        street: [
        ],
        building: [
        ],
        hComplex: '',
        hComplexId: 0,
        housingComplexId: 0,
        streetId: 0,
        date: {
          installationDate: '',
          verificDate: ''
        },
        urls: {
          meter: 'meter',
          metertype: 'metertype',
          housingComplex: 'housingComplex',
          buildingAddress: 'building/buildingaddress',
          street: 'street',
          building: 'building'
        },
        show: false,
        label: 'Пожалуйста, подождите, операция выполняется',
        validation: validation
      }
    }
  }
</script>
