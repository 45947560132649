<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6" lg="3">
        <div class="card card__custom_height card-inverse card-info">
          <div class="card-block">
            <div class="h1 text-muted text-right mb-2">
              <i class="icon-people"></i>
            </div>
            <div class="h4 mb-0">{{ statistics.users }}</div>
            <small class="text-muted text-uppercase font-weight-bold">зарегистрированные пользователи</small>
            <div class="progress progress-white progress-xs mt-1">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col sm="6" lg="3">
        <div class="card card__custom_height card-inverse card-info">
          <div class="card-block">
            <div class="h1 text-muted text-right mb-2">
              <i class="icon-paper-plane"></i>
            </div>
            <div class="h4 mb-0">{{ statistics.newBid }}</div>
            <small class="text-muted text-uppercase font-weight-bold">созданные заявки</small>
            <div class="progress progress-white progress-xs mt-1">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col sm="6" lg="3">
        <div class="card card__custom_height card-inverse card-info">
          <div class="card-block">
            <div class="h1 text-muted text-right mb-2">
              <i class="icon-wrench"></i>
            </div>
            <div class="h4 mb-0">{{ statistics.inProgressBid }}</div>
            <small class="text-muted text-uppercase font-weight-bold">заявки в работе</small>
            <div class="progress progress-white progress-xs mt-1">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col sm="6" lg="3">
        <div class="card card__custom_height card-inverse card-info">
          <div class="card-block">
            <div class="h1 text-muted text-right mb-2">
              <i class="icon-lock"></i>
            </div>
            <div class="h4 mb-0">{{ statistics.closedBid }}</div>
            <small class="text-muted text-uppercase font-weight-bold">закрытые заявки</small>
            <div class="progress progress-white progress-xs mt-1">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col sm="6" lg="3">
        <div class="card card__custom_height card-inverse card-info">
          <div class="card-block">
            <div class="h1 text-muted text-right mb-2">
              <i class="icon-star"></i>
            </div>
            <div class="h4 mb-0">{{ statistics.averageEvaluation }}</div>
            <small class="text-muted text-uppercase font-weight-bold">средняя оценка работы в заявках</small>
            <div class="progress progress-white progress-xs mt-1">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col sm="6" lg="3">
        <div class="card card__custom_height card-inverse card-info">
          <div class="card-block">
            <div class="h1 text-muted text-right mb-2">
              <i class="fa fa-bed"></i>
            </div>
            <div class="h4 mb-0">{{ statistics.OccupiedApartments }}</div>
            <small class="text-muted text-uppercase font-weight-bold">доля квартир, подключенных к приложению, от общего числа квартир</small>
            <div class="progress progress-white progress-xs mt-1">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </b-col>

      <b-col sm="6" lg="3">
        <div class="card card__custom_height card-inverse card-info">
          <div class="card-block">
            <div class="h1 text-muted text-right mb-2">
              <i class="fa fa-tasks"></i>
            </div>
            <div class="h4 mb-0">{{ paymentsLastUpdate }}</div>
            <small class="text-muted text-uppercase font-weight-bold">время последнего обмена платежными документами с ГИС ЖКХ</small>
            <div class="progress progress-white progress-xs mt-1">
              <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from './../config/api'
import moment from 'moment'

export default {
  name: 'dashboard',

  data: function () {
    return {
      urls: 'statistics/dashboard',
      statistics: {}
    }
  },

  computed: {
    paymentsLastUpdate: function () {
      return this.statistics.paymentsLastUpdate
        ? moment(this.statistics.paymentsLastUpdate).locale('ru').format('llll')
        : ''
    }
  },

  mounted () {
    api.get(this.urls).then((response) => {
      this.statistics = response.data.data
    })
  }
}
</script>
