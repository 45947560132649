<template>
  <b-card :header="caption">
    <template #header>
      <strong>Ответы на опрос: {{header}}</strong>
    </template>
    <b-table :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="items" :fields="fields" :current-page="1" :per-page="perPage">
    </b-table>
    <nav class="d-flex">
      <b-pagination :total-rows="getRowCount(items)" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
    </nav>
  </b-card>
</template>

<script>
  import api from '../../config/api'

  export default {
    name: 'c-table',
    props: {
      caption: {
        type: String,
        default: 'Table'
      },
      hover: {
        type: Boolean,
        default: false
      },
      striped: {
        type: Boolean,
        default: false
      },
      bordered: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: false
      }
    },
    data: () => {
      return {
        items: [],
        header: '',
        accessList: {},
        fields: [
          {key: 'user', label: 'Пользователь'},
          {key: 'answer', label: 'Ответ'}
        ],
        currentPage: 1,
        perPage: 20,
        totalRows: 0,
        urls: {
          list: 'poll'
        }
      }
    },
    methods: {
      getBadge (status) {
        return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
            : status === 'Pending' ? 'warning'
              : status === 'Banned' ? 'danger' : 'primary'
      },
      getRowCount (items) {
        return items.length
      }
    },
    created: function () {
      let _this = this
      let accsessKey = {
        keyWord: 'poll'
      }
      api.get('role/components/access', accsessKey).then((response) => {
        if (response.data.data.isRead === false) {
          _this.$router.push('/pages/403')
        } else {
          _this.accessList = response.data.data
        }
      })
    },
    mounted: function () {
      let _this = this
      let query = {
        pollId: _this.$route.params.id
      }
      api.get(_this.urls.list + '/usersanswer', query).then(function (response) {
        let jData = response.data
        if (jData.status) {
          _this.items = jData.data
          _this.header = jData.meta
        }
      })
    }
  }
</script>
